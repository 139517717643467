import PackageDetail from "../../Components/AdminDashboard/Packages/PackageDetail"

const PackageDetailPage = () => {
    return (
        <div className='page-body main-project'>
            <PackageDetail />
        </div>
    )
}

export default PackageDetailPage