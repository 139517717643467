import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from "reactstrap"
import LeadsListNav from "./LeadsListNav"
import styled from '@emotion/styled';
import { isBrowser, isMobile } from 'react-device-detect';
import DropdownWithHeader from "../../../Utils/CommonComponents/DropdownWithHeader";
import { StrLeadsList } from "../../../Utils/Constants";
import { Badges, H6, LI, UL, Image, H4, H2, Alerts, P } from "../../../AbstractElements";
import { dynamicImage } from "../../../Utils";
import LeadInfo from "./LeadInfo";
import { getLeadInfoAction, getLeadsAction } from '../../../ReduxToolkit/Reducers/Layout/Lead/LeadAction';
import { toast } from 'react-toastify';
import { leadFilterOprions, leadStatus, statusColor } from '../../../helpers';
import AdvanceFilter from './AdvanceFilter';
import LeadFilter from './LeadFilter';

const StyleListContainer = styled(Container)`
    height: 100%;
    /* .list-section {
        height: 100vh;
        padding-bottom: 10px;
    }
    .info-section {
        padding-bottom: 10px;
    } */

    .delivery-content {
        min-height: 400px;
        height: 500px;
        overflow-y: scroll; 
    }
    .order-list {
        height: 100%
    }
    .delivery-card {
        .card-header {
            .card-header-right-icon {
                display: block;
            }
        }
    }
    .lead-filter-dropdown .dropdown-menu {
        z-index: 99;
    }
    .leads-item {
        cursor: pointer;
        padding: 10px;
        border-radius: 10px;
        &:hover{
            background: #D0E8EB;
        }
    }
    .active {
        color: #fff;
    }
`
const StyleListContent = styled.div`
    .avatar {
        text-align: center;
        padding-bottom: 15px;
    }
`
const LeadsList = () => {
    const [leadsData, setLeadsData] = useState<any>();
    const [selectedLead, setSelectedLead] = useState<any>();
    const [filterAssignee, setFilterAssignee] = useState<string[]>([]);
    const [activeLeadId, setActiveLeadId] = useState<number>();
    const [open, setOpen] = useState(false);
    const [activeFilter, setActiveFilter] = useState('All');
    const [selectedFilters, setSelectedFilters] = useState(leadFilterOprions());
    const [isOpenAdvanceFilter, setIsOpenAdvanceFilter] = useState(false)

    const toggle = () => {
        setOpen(!open);
    };

    const toggleDrawer = () => {
        setIsOpenAdvanceFilter(!isOpenAdvanceFilter)
    }

    useEffect(() => {
        const statusFilter = selectedFilters.length > 0 && selectedFilters.filter((filter) => leadStatus().includes(filter));
        const assignedFilter = selectedFilters.length > 0 && selectedFilters.filter((filter) => !leadStatus().includes(filter));
        const payload = {
            assignee: filterAssignee ? filterAssignee : [],
            status: statusFilter,
            assignedStatus: assignedFilter,
        }
        const response = getLeadsAction(payload);
        response.then((result: any) => {
            if(result.status === 200) {
                setLeadsData(result.data);
                if(result.data.rows.length > 0) {
                    setActiveLeadId(result.data.rows[0].id);
                } else {
                    setActiveLeadId(0);
                    setSelectedLead({});
                }
            } else {
                toast.error(result.message);
            }
        });
    }, [selectedFilters, filterAssignee])

    const handleOnAssigneeChange = (event: any) => {
        const result = event.map((a: any) => a.value);
        setFilterAssignee(result);
    }

    useEffect(() => {
        fetchLeadInfo();
    }, [activeLeadId])

    const fetchLeadInfo = () => {
        if(!activeLeadId) {
            return;
        }
        const response = getLeadInfoAction({
            id: activeLeadId
        });
        response.then((result: any) => {
            if(result.status === 200) {
                setSelectedLead(result.data);
            } else {
                toast.error(result.message);
            }
        });
    }

    const handleFilterSelection = (value: string) => {
        if(selectedFilters.includes(value)) {
            const newArr = selectedFilters.filter(item => item !== value)
            setSelectedFilters(newArr);
        } else {
            setSelectedFilters([value, ...selectedFilters]);
        }
    }

    return (
        <StyleListContainer fluid>
            <Row className='project-cards'>
                { isBrowser &&
                    <Col md={12} className="project-list">
                        <LeadsListNav activeTab={activeFilter} setActiveTab={setActiveFilter} />
                    </Col>
                }
                <LeadFilter 
                    selectedFilters={selectedFilters}
                    handleOnClick={(item: any) => handleFilterSelection(item)}
                    handleOnAssigneeChange={(event: any) => handleOnAssigneeChange(event)} 
                />
                <Col xl={5} sm={12} className="list-section">
                {/* <div className={'col-xl-5 col-md-12 list-section'}> */}
                    <Card className={`title-line height-equal delivery-card`}>
                        <DropdownWithHeader end caret dropDownClass='lead-filter-dropdown' 
                            headerClass='pb-2 border-t-primary border-2 leads-filter' 
                            heading={StrLeadsList} span={`Total ${leadsData?.count ?? 0}`} 
                            spanClass='f-12 f-w-500 f-light d-block'
                            handleOnClick={(item: any) => handleFilterSelection(item)} 
                            dropDownTitle={'Filter'} dropDownList={leadFilterOprions()} 
                            selectedFilters={selectedFilters}
                            dropDownIcon={true}
                            advanceFilterBtn={true}
                            toggleDrawer={toggleDrawer}
                        />
                        <CardBody className="delivery-content pt-3" style={{zIndex: '0'}}>
                            <UL className="order-list mb-0 custom-scrollbar simple-list">
                                {leadsData && leadsData.rows.length > 0 ?
                                <>
                                {leadsData.rows.map((item: any) => (
                                    <LI key={item.id} className={`leads-item list-group-item-action list-group-item ${item.id === activeLeadId ? 'active bg-primary' : ''}`}>
                                        <StyleListContent className={`order-content`} onClick={() => setActiveLeadId(item.id)}>
                                            <div>
                                                <H4 className="mb-1">{item.fullName}</H4>
                                                <span> 
                                                    <span className={`${item.id === activeLeadId ? 'f-dark' : 'f-light'} f-w-500 f-14`}>{item.phoneNumber}</span>
                                                    <span className={`${item.id === activeLeadId ? 'f-dark' : 'f-light'} f-w-500 f-14`}>{item.email}</span>
                                                    <span className={`${item.id === activeLeadId ? 'f-dark' : 'f-light'} f-w-500 f-14`}>{item.city}</span>
                                                </span>
                                            </div>
                                            <div className="text-end">
                                                {/* <Dropdown isOpen={open} toggle={toggle} className="icon-dropdown">
                                                    <DropdownToggle color='transparent'><SvgIcon iconId='more-horizontal' /></DropdownToggle>
                                                    <DropdownMenu end={true}>
                                                        <DropdownItem href={`${process.env.PUBLIC_URL}/applications/ecommerce/cart`}>{'Add to cart'}</DropdownItem>
                                                        <DropdownItem href={Href}>{'Cancel'}</DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown> */}
                                                <div className="avatar">
                                                    <Image className={`img-40 rounded-circle`} src={dynamicImage(`user.png`)} alt="user profile" />
                                                </div>
                                                <Badges color={`${statusColor(item.status)}`} className={`${statusColor(item.status)} f-12`}>{item.status}</Badges>
                                            </div>
                                        </StyleListContent>
                                    </LI>
                                ))}
                                </>:
                                    <Alerts color={'secondary'} className='dark'>
                                        <H6>
                                            No Lead found
                                        </H6>
                                    </Alerts>
                                }
                            </UL>
                        </CardBody>
                    </Card>
                </Col>
                {/* <div className={'col-xl-7 col-md-12 info-section'}> */}
                <Col xl={7} sm={12} className="info-section">
                    <LeadInfo leadDetail={selectedLead} fetchLeadInfo={fetchLeadInfo} />
                </Col>
                {/* </div> */}
            </Row>
            <AdvanceFilter
                toggleDrawer={toggleDrawer}
                isOpenAdvanceFilter={isOpenAdvanceFilter}
                selectedFilters={selectedFilters}
                handleOnClick={(item: any) => handleFilterSelection(item)}
                handleOnAssigneeChange={(event: any) => handleOnAssigneeChange(event)} 
            />
        </StyleListContainer>
    )
}

export default LeadsList
