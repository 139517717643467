import React, { useEffect, useState } from 'react'
import { Container, Row, Card, Col } from 'reactstrap'
import { useNavigate } from 'react-router-dom'
import CommonCardHeader from '../../Utils/CommonComponents/CommonCardHeader'
import { CreateWorkspace, Emptysearch, WorkspaceTableTitle } from '../../Utils/Constants'
import CommonTable from '../Common/CommonTable'
import { useReduxDispatch, useReduxSelector } from '../../ReduxToolkit/Store';
import TablePagination, { LimitlistRecord } from '../Common/Pagination'
import { getWorkspaces, resetWorkspaceList } from '../../ReduxToolkit/Reducers/Layout/WorkspaceList/WorkspaceListAction'
import { workspaceHeader } from '../../Data/Table/TableHeader'
import { formatDate } from '../../helpers'
import { Badges, Btn, H3 } from '../../AbstractElements'

const Workspace = () => {
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [listData, setListDate] = useState([]);
    const dispatch = useReduxDispatch();
    const { data, isError, isSuccess, isLoading } = useReduxSelector(state => state.workspaceListReducer);

    useEffect(() => {
        dispatch(getWorkspaces({
            pageNo: currentPage,
            recordPerPage: LimitlistRecord,
            uuid: 1
        }));

        return () => {
            dispatch(resetWorkspaceList());
        };
    }, [currentPage])

    useEffect(() => {
        if(isSuccess && data && data.data) {
            setTotalPages(Math.ceil(data.data.count/LimitlistRecord));
            setListDate(data.data.rows);
        }
    }, [isSuccess])

    return <Container fluid>
        <Row>
            <Col sm={12}>
                <Card className='p-2'>
                    <div className='d-flex justify-content-between'>
                        <CommonCardHeader title={WorkspaceTableTitle} subTitle={[]} />
                        <Btn type='button' onClick={() => navigate(`/workspace/create`)} color='primary' className='m-t-10'>{CreateWorkspace}</Btn>
                    </div>
                    <div className="table-responsive custom-scrollbar">
                        <CommonTable strip={true} hover={true} headData={workspaceHeader} headClass='tbl-strip-thad-bdr' headRowClass='border-bottom-primary' >
                            {listData && listData.length ? listData.map((tbl: any) => (
                                <tr key={tbl.id} className='border-bottom-'>
                                    <th scope="row">{tbl.workSpaceName}</th>
                                    <td>{tbl.userInfo.email}</td>
                                    <td>{tbl.userInfo.phoneNumber}</td>
                                    <td>{tbl.userInfo.firstName}</td>
                                    <td>{tbl.userInfo.lastName}</td>
                                    <td>
                                        <Badges color={tbl.paymentStatus === 'Completed' ? 'success' : 'danger'}>{tbl.paymentStatus}</Badges>
                                    </td>
                                    <td>
                                        <Badges color={tbl.status === 'Active' ? 'success' : 'danger'}>{tbl.status}</Badges>
                                    </td>
                                    <td>{formatDate(tbl.startDate)}</td>
                                    <td>{formatDate(tbl.endDate)}</td>
                                    <td>
                                        <Btn type='button' className={'b-ln-height btn-square'}
                                            outline={false}
                                            onClick={() => navigate(`/workspace/edit?id=${tbl.id}`)}
                                            size={'sm'} color={'primary'}>
                                            Edit
                                        </Btn>
                                    </td>
                                </tr>
                            ))
                            :
                            <H3 className="badge-heading">
                                <Badges color='primary' className='m-20'>{Emptysearch}</Badges>
                            </H3>
                        }
                        </CommonTable>
                    </div>
                    <TablePagination 
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalPages={totalPages}
                    />
                </Card>
            </Col>
        </Row>
        </Container>
}

export default Workspace;