import React from 'react'
import SuperAdminDashboard from '../../Components/Dashboard/SuperAdminDashboard'
import AdminDashboard from '../../Components/AdminDashboard';

const DashboardPage = () => {
    const userLocalStorage = JSON.parse(localStorage.getItem('jwtToken')!) || null;

    return (
        <div className='page-body main-project'>
            { userLocalStorage.userRole.roleName === 'Super Admin' &&
                <SuperAdminDashboard />
            }
            { userLocalStorage.userRole.roleName === 'Admin' &&
                <AdminDashboard />
            }
        </div>
    )
}

export default DashboardPage
