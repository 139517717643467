import { Card, CardBody } from "reactstrap"
import styled from '@emotion/styled';
import AsyncSelect from 'react-select/async';
import Select, { IndicatorSeparatorProps } from 'react-select';
import { toast } from "react-toastify";
import { H2, UL, LI, P, H6, H4, Badges, Btn } from "../../../AbstractElements"
import { StrUserInfo } from "../../../Utils/Constants"
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import { Fragment, useEffect, useState } from "react";
import { formatDate, leadStatus, statusColor, userRoles, userStatusColor } from "../../../helpers";
import { assignManagerAction, userStatusUpdateAction, workspaceUsersAction } from "../../../ReduxToolkit/Reducers/Layout/Auth/LoginAction";
import { assignLeadAction, leadStatusUpdateAction } from "../../../ReduxToolkit/Reducers/Layout/Lead/LeadAction";
import 'react-quill/dist/quill.snow.css';
import { getWorkspaceManagersAction } from "../../../ReduxToolkit/Reducers/Layout/Workspace/WorkspaceAction";

const StyleCardBody = styled(CardBody)`
    min-height: 640px;
    overflow-y: scroll;
    .text-div {
        display: flex;
        gap: 50px;
        align-items: center;
    }
    .text-title {
        padding: 0px;
        width: 20%;
    }
`

const StyleFaEditIcon = styled.span`
    margin-left: 0px;
    cursor: pointer;
`

const StyleIndicatorSeparator = {
    alignSelf: 'stretch',
    backgroundColor: '#00B8D9',
    marginBottom: 8,
    marginTop: 8,
    width: 1,
};

const IndicatorSeparator = ({
    innerProps,
}: IndicatorSeparatorProps<any, true>) => {
    return <span style={StyleIndicatorSeparator} {...innerProps} />;
};

const UserInfo = (props: any) => {
    const { userDetail, fetchUserInfo } = props;
    const [managerListData, setManagerListData] = useState<any>();
    const [selectedAssigneeIndex, setSelectedAssigneeIndex] = useState<number>();
    const [selectedStatusIndex, setSelectedStatusIndex] = useState<number>();
    const [statusEditableMode, setStatusEditableMode] = useState<boolean>(false);
    const [statusOptions, setStatusOptions] = useState<any>([
        { value: 'Active', label: 'Active' },
        { value: 'Inactive', label: 'Inactive' }
    ]);
    
    useEffect(() => {
        if(userDetail && Object.keys(userDetail).length > 0) {
            const index = statusOptions.findIndex((u: any) => u.value === userDetail.status);
            setSelectedStatusIndex(index)
            const response = getWorkspaceManagersAction({});
            response.then((result: any) => {
                if (result.status === 200) {
                    const managerList = result.data.length > 0 && result.data.map((user: any) => {
                        return { value: user.id, label: `${user.firstName} ${user.lastName}` }
                    })
                    setManagerListData(managerList);
                    if(userDetail && userDetail.managerInfo && Object.keys(userDetail.managerInfo).length > 0 && result.data.length > 0) {
                        const selectedUserIndex = result.data.findIndex((u: any) => u.id === userDetail.managerInfo.id);
                        setSelectedAssigneeIndex(selectedUserIndex)
                    } else {
                        setSelectedAssigneeIndex(-1)
                    }
                }
            });
        }
    }, [userDetail])

    const filterAssignee = (inputValue: string) => {
        return managerListData.length > 0 && managerListData.filter((i: any) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const loadAssigneeOptions = (
        inputValue: string,
        callback: (options: any) => void
    ) => {
        setTimeout(() => {
            callback(filterAssignee(inputValue));
        }, 1000);
    };

    const handleLeadAssignment = (request: any) => {
        const response = assignManagerAction({
            managerId: request.value,
            userId: userDetail.id
        });
        response.then((result: any) => {
            if (result.status === 200) {
                const selectedUserIndex = managerListData.findIndex((u: any) => u.value === request.value);
                setSelectedAssigneeIndex(selectedUserIndex)
                toast.success(result.message);
            } else {
                toast.error(result.message);
            }
        })
    }

    const handleStatusChange = (request: any) => {
        const response = userStatusUpdateAction({
            status: request.value,
            userId: userDetail.id
        });
        response.then((result: any) => {
            if (result.status === 200) {
                const index = leadStatus().findIndex((u: any) => u === request.value);
                setSelectedStatusIndex(index)
                fetchUserInfo();
                toast.success(result.message);
            } else {
                toast.error(result.message);
            }
        })
    }

    return <Card>
        <CommonCardHeader headClass='border-t-primary border-2' title={StrUserInfo} />
        <StyleCardBody>
            {userDetail && Object.keys(userDetail).length > 0 &&
                <Fragment>
                    <div className='product-page-details pb-2'>
                        <div className='text-div'><span className="text-title">Name : </span><H2>{userDetail?.firstName} {userDetail?.lastName}</H2></div>
                    </div>
                    <div className='product-page-details pb-2'>
                        <div className='text-div'><span className="text-title">Status : </span>
                        {statusEditableMode === true ?
                        <>
                            <Select
                                closeMenuOnSelect={false}
                                components={{ IndicatorSeparator }}
                                value={selectedStatusIndex !== undefined && statusOptions[selectedStatusIndex]}
                                options={statusOptions}
                                onChange={(e) => handleStatusChange(e)}
                            />
                            <StyleFaEditIcon onClick={() => setStatusEditableMode(!statusEditableMode)}><i className="fa fa-times fa-lg"></i></StyleFaEditIcon>
                        </>
                        :
                        <>
                            <Badges color={`${userStatusColor(userDetail?.status)}`} className={`${userStatusColor(userDetail?.status)} f-12`}>{userDetail?.status}</Badges>
                            <StyleFaEditIcon onClick={() => setStatusEditableMode(!statusEditableMode)}><i className="fa fa-edit fa-lg"></i></StyleFaEditIcon>
                        </>
                        }
                        
                    </div>
                    </div>
                    <div className='product-page-details pb-2'>
                        <div className='text-div'><span className="text-title">Role : </span>
                        <Badges color={`primary`} className={`primary f-12`}>{userRoles(userDetail?.roleId)}</Badges>
                    </div>
                    </div>
                    <div className='product-page-details pb-2'>
                        <div className='text-div'><span className="text-title">Phone : </span><H6>{userDetail?.phoneNumber}</H6></div>
                    </div>
                    <div className='product-page-details pb-2'>
                        <div className='text-div'><span className="text-title">Email : </span><H6>{userDetail?.email}</H6></div>
                    </div>
                    <div className='product-page-details pb-2'>
                        <div className='text-div'><span className="text-title">City : </span><H6>{userDetail?.city}</H6></div>
                    </div>
                    { userDetail?.roleId === 3 &&
                        <div className='product-page-details pb-2'>
                            <div className='text-div'><span className="text-title">Manager : </span>
                                <H6>
                                    <AsyncSelect 
                                        value={selectedAssigneeIndex !== undefined && managerListData[selectedAssigneeIndex]}
                                        cacheOptions
                                        loadOptions={loadAssigneeOptions}
                                        defaultOptions={managerListData}
                                        components={{ IndicatorSeparator }}
                                        onChange={(e) => handleLeadAssignment(e)}
                                    />
                                </H6>
                            </div>
                        </div>
                    }
                    <div className='product-page-details pb-2'>
                        <div className='text-div'><span className="text-title">Created on : </span><H6>{formatDate(userDetail?.createdAt, 'DD-MM-YYYY h:mm:ss a')}</H6></div>
                    </div>
                    <div className='product-page-details pb-2'>
                        <div className='text-div'><span className="text-title">Last updated : </span><H6>{formatDate(userDetail?.updatedAt, 'DD-MM-YYYY h:mm:ss a')}</H6></div>
                    </div>
                    <hr />
                </Fragment>
            }
        </StyleCardBody>
    </Card>
}

export default UserInfo