import React, { useState } from 'react'
import { Card, CardBody, CardHeader, Col, Collapse, Input } from 'reactstrap';
import { ChevronDown, ChevronUp, Search } from 'react-feather';
import { Badges, Btn, H1, H4, H5, LI, OL, UL } from '../../../AbstractElements';
import { Filter, FindCourse, Rupee, StrPackagePrice } from '../../../Utils/Constants';

export const badgeListData = [
    {
        text: 'Stella Nowland',
        badgeClass: 'warning',
        badgeText: 'Freelance'
    },
    {
        text: 'Lola Stanford',
        badgeClass: 'danger text-white',
        badgeText: 'Issue'
    },
    {
        text: 'Caitlin Coungeau',
        badgeClass: 'primary text-white',
        badgeText: 'Social'
    },
    {
        text: 'Graciela W. McClaran',
        badgeClass: 'danger text-white',
        badgeText: 'Issue'
    },
    {
        text: 'Lucinda Moseley',
        badgeClass: 'success text-white',
        badgeText: 'work'
    }
]

export default function PackagePriceCard(props: any) {
    const { detail } = props;
    const [isFilter, setIsFilter] = useState(true);
    const toggle = () => { setIsFilter(!isFilter); }
    return (
        <Col xl={12}>
            <Card>
                <CardHeader>
                    <H5 className='mb-0'>
                        <Btn color='transparent' className='btn-link d-flex align-items-center gap-2 justify-content-between' onClick={toggle}>{StrPackagePrice}
                            {isFilter ? <ChevronDown /> : <ChevronUp />}
                        </Btn>
                    </H5>
                </CardHeader>
                <Collapse isOpen={isFilter}>
                    <CardBody className='filter-cards-view animate-chk'>
                        <UL className='list-group badge-list list-content list-group-flush'>
                            <LI className="d-flex justify-content-between align-items-start flex-wrap">
                                <H5 className='txt-dark txt-secondary'>{'Vehicle Charges'}</H5>
                                <H4 className='font-dark'>{detail?.packageCharges}</H4>
                            </LI>
                            {/* <LI className="d-flex justify-content-between align-items-start flex-wrap">
                                <H5 className='txt-dark txt-secondary'>{'Room Charges'}</H5>
                                <H4 className='font-dark'>{detail.packageCharges}</H4>
                            </LI> */}
                            <LI className="d-flex justify-content-between align-items-start flex-wrap">
                                <H5 className='txt-dark txt-secondary'>{'Discount'}</H5>
                                <H4 className='font-dark'>{detail?.packageDiscount}</H4>
                            </LI>
                            <LI className="d-flex justify-content-between align-items-start flex-wrap">
                                <H5 className='txt-dark txt-secondary'>{'Tax'}</H5>
                                <H4 className='font-dark'>0</H4>
                            </LI>
                            <LI className="d-flex justify-content-between align-items-start flex-wrap">
                                <H5 className='txt-dark txt-primary'>{'Total'}</H5>(Per Person)
                                <H4 className='font-dark'>{Rupee} {detail ? parseInt(detail.packageCharges)-parseInt(detail.packageDiscount) : 0}</H4>
                            </LI>
                        </UL>
                    </CardBody>
                </Collapse>
            </Card>
        </Col>
    );
};

