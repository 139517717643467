import React, { useState } from 'react'
import { Card, CardBody, CardHeader, Col, Collapse } from 'reactstrap';
import { ChevronDown, ChevronUp, Clock, CreditCard, Gift, Truck } from 'react-feather';
import { Btn, H5, H6, LI, P, UL } from '../../../AbstractElements';
import { Href, StrNeedHelp, UpcomingCoursesTitle } from '../../../Utils/Constants';

const shippingData = [
    {
        id: 1,
        icon: <Truck />,
        title: 'Free Shipping',
        subTitle: 'Free Shipping World Wide'
    },
    {
        id: 2,
        icon: <Clock />,
        title: '24 X 7 Service',
        subTitle: 'Online Service For New Customer'
    },
    {
        id: 3,
        icon: <Gift />,
        title: 'Festival Offer',
        subTitle: 'New Online Special Festival'
    },
    {
        id: 4,
        icon: <CreditCard />,
        title: 'Online Payment',
        subTitle: 'Contrary To Popular Belief.'
    },
];

export const upcomingCoursesData = [
    {
        id: 1,
        title: 'UX Development',
        anchorText: 'Howard Moore',
        value: 18,
        month: 'Dec',
        star: [
            { id: 1, class: 'star' },
            { id: 2, class: 'star' },
            { id: 3, class: 'star' },
            { id: 4, class: 'star' },
            { id: 5, class: 'star-half-o' },
        ]
    },
    {
        id: 2,
        title: 'Business Analyst',
        anchorText: 'Crystal Markey',
        value: 28,
        month: 'Dec',
        star: [
            { id: 1, class: 'star' },
            { id: 2, class: 'star' },
            { id: 3, class: 'star' },
            { id: 4, class: 'star' },
            { id: 5, class: 'star' },
        ]
    },
    {
        id: 3,
        title: 'Web Development',
        anchorText: 'Jerry Reel',
        value: 25,
        month: 'Jan',
        star: [
            { id: 1, class: 'star' },
            { id: 2, class: 'star' },
            { id: 3, class: 'star' },
            { id: 4, class: 'star' },
            { id: 5, class: 'star-o' },
        ]
    },
];

export default function NeedHelpCard() {
    const [isDevelopment, setIsDevelopment] = useState(true);
    const toggle = () => { setIsDevelopment(!isDevelopment); }
    return (
        <Col xl={12}>
            <Card>
                <CardHeader>
                    <H5 className="mb-0">
                        <Btn color='transparent' className='btn-link d-flex align-items-center gap-2 justify-content-between' onClick={toggle}>
                            {StrNeedHelp} {isDevelopment ? <ChevronDown /> : <ChevronUp />}
                        </Btn>
                    </H5>
                </CardHeader>
                <Collapse isOpen={isDevelopment}>
                    <CardBody className="upcoming-course">
                        <div className="collection-filter-block">
                            <UL className="pro-services simple-list">
                                {shippingData.map((data) => (
                                    <LI key={data.id}>
                                        <div className="d-flex">
                                            {data.icon}
                                            <div className="flex-grow-1">
                                                <H5>{data.title}</H5>
                                                <P>{data.subTitle}</P>
                                            </div>
                                        </div>
                                    </LI>
                                ))}
                            </UL>
                        </div>
                    </CardBody>
                </Collapse>
            </Card>
        </Col>
    )
}
