import React, { useState } from 'react'
import { Card, CardBody, Carousel, CarouselCaption, CarouselControl, CarouselIndicators, CarouselItem, Col } from 'reactstrap';
import CommonCardHeader from '../../../Utils/CommonComponents/CommonCardHeader';
import { DarkVariantTitle } from '../../../Utils/Constants';
import { Image } from '../../../AbstractElements';
import { dynamicImage } from '../../../Utils';
import { gDriveImgThumbnailLink } from '../../../Utils/Constants/ElementContans';

export const darkVariantSubTitle = [
    {
        text: 'Add ',
        code: '.carousel-dark'
    },
    {
        text: '  to the ',
        code: '.carousel'
    },
    {
        text: '  for darker controls, indicators, and captions. '
    }
];

// export const darkVariantData = [
//     { id: 1, image: '1.jpg', captionHeader: 'We decorate our homes', captionText: 'If you have been dreaming about bringing your living room together, our designers are here to help. Come see what we can do for your space.' },
//     { id: 2, image: '3.jpg', captionHeader: 'This couch is a great topic of conversation.', captionText: 'A sofa is the ideal spot to enjoy a movie, nod off, and wake up.' },
//     { id: 3, image: '6.jpg', captionHeader: 'Sometimes all you really need to unwind is a comfortable couch.', captionText: 'A house you can create with your friends is a great place.' },
// ];

// export const owlCarouselData = [
//     { id: 1, image: '11.jpg' },
//     { id: 2, image: '9.jpg' },
//     { id: 3, image: '6.jpg' },
// ];

export interface GoToIndexProp {
    (newIndex: number): void;
}

export interface OwlCarouselItem {
    id: number;
    image: string;
    captionHeader?: string;
    captionText?: string;
    interval?: string;
}

export default function Slider(props: any) {
    const { darkVariantData } = props;
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === darkVariantData.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };
    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? darkVariantData.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };
    const goToIndex: GoToIndexProp = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };
    const slides = darkVariantData.map((item: OwlCarouselItem) => {
        return (
            <CarouselItem onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} key={item.id}>
                <Image src={`${gDriveImgThumbnailLink}${item.image}`} alt='drawing-room' className='d-block w-100' />
                <CarouselCaption 
                    className='d-none d-md-block carousel-opacity'
                    captionText={''}
                    captionHeader={''}
                />
            </CarouselItem>
        );
    });
    return (
        <Carousel dark={true} activeIndex={activeIndex} next={next} previous={previous}>
            <CarouselIndicators items={darkVariantData} activeIndex={activeIndex} onClickHandler={goToIndex} />
            {slides}
            <CarouselControl direction='prev' directionText='Previous' onClickHandler={previous} />
            <CarouselControl direction='next' directionText='Next' onClickHandler={next} />
        </Carousel>
    );
}

