import React from 'react'
import { Card, Col, FormGroup, Nav, NavItem, NavLink, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import { PlusSquare, Target } from 'react-feather'
import styled from '@emotion/styled';
import { All, Href, StrGenerateLead, StrUploadLeads } from '../../../Utils/Constants'
import { leadStatus } from '../../../helpers'

interface ProjectListNavProps {
    activeTab: string;
    setActiveTab: (value: string) => void;
}

const StyleCard = styled(Card)`
    .btn-formgroup {
        margin-bottom: 0px !important;
    }
`

export default function LeadsListNav({ activeTab, setActiveTab }: ProjectListNavProps) {
    return (
        <StyleCard>
            <Row>
                <Col md={6}>
                    <Nav className="border-tab" tabs>
                        {/* <NavItem>
                            <NavLink href={Href} className={activeTab === "All" ? "active" : ""} onClick={() => setActiveTab("All")}><Target />{All}</NavLink>
                        </NavItem>
                        { leadStatus().map((status) => {
                            return (
                                <NavItem>
                                    <NavLink href={Href} className={activeTab === status ? "active" : ""} onClick={() => setActiveTab(status)}><Target />{status}</NavLink>
                                </NavItem>
                            )
                        })} */}
                        {/* <NavItem>
                            <NavLink href={Href} className={activeTab === "1" ? "active" : ""} onClick={() => setActiveTab("1")}><Target />{All}</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href={Href} className={activeTab === "2" ? "active" : ""} onClick={() => setActiveTab("2")}><Info />{Doing}</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href={Href} className={activeTab === "3" ? "active" : ""} onClick={() => setActiveTab("3")}> <CheckCircle /> {Done}</NavLink>
                        </NavItem> */}
                    </Nav>
                </Col>
                <Col md={6}>
                    <div className='d-flex justify-content-end'>
                        <FormGroup className="mb-0 btn-formgroup">
                            <Link className="btn btn-danger" to={`/leads/upload`}>
                                <PlusSquare /> {StrUploadLeads}
                            </Link>
                        </FormGroup>
                        <FormGroup className="mb-0 btn-formgroup">
                            <Link className="btn btn-primary" to={`/leads/generate`}>
                                <PlusSquare /> {StrGenerateLead}
                            </Link>
                        </FormGroup>
                    </div>
                </Col>
            </Row>
        </StyleCard>
    )
}
