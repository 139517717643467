import React, { useEffect, useState } from 'react'
import { Container, Row } from 'reactstrap'
import WelcomeCard from '../Common/WelcomeCard';
import ProjectWidget from '../../Utils/CommonComponents/DashboardCommon/ProjectCommon/ProjectWidget';
import Widget1Cards from '../../Utils/CommonComponents/DashboardCommon/ProjectCommon/Widget1Cards';
import RecentWorkspace from '../Common/RecentWorkspace';
import { useReduxDispatch, useReduxSelector } from '../../ReduxToolkit/Store';
import { getWorkspaces, resetWorkspaceList } from '../../ReduxToolkit/Reducers/Layout/WorkspaceList/WorkspaceListAction';
import WorkspaceStatusWidget from '../../Utils/CommonComponents/DashboardCommon/SuperAdmin/WorkspaceStatusWidget';
import { getWorkspaceCount, resetWorkspaceCount } from '../../ReduxToolkit/Reducers/Layout/WorkspaceCount/WorkspaceCountAction';

const SuperAdminDashboard = () => {
    const { data, isError, isSuccess, isLoading } = useReduxSelector(state => state.workspaceListReducer);
    const workspaceCountReducer = useReduxSelector(state => state.workspaceCountReducer);
    const dispatch = useReduxDispatch();
    const [listData, setListDate] = useState([]);
    const [workspacesCountObj, setWorkspacesCountObj] = useState({});
    
    useEffect(() => {
        dispatch(getWorkspaces({
            pageNo: 1,
            recordPerPage: 5,
            uuid: 1,
            orderBy: 'DESC'
        }));

        dispatch(getWorkspaceCount({
            filterBy: 'status'
        }));

        return () => {
            dispatch(resetWorkspaceList());
            dispatch(resetWorkspaceCount());
        };
    }, [])

    useEffect(() => {
        if(isSuccess && data && data.data) {
            setListDate(data.data.rows);
        }
    }, [isSuccess])

    useEffect(() => {
        if(workspaceCountReducer.isSuccess) {
            setWorkspacesCountObj(workspaceCountReducer.data.data);
        }
    }, [workspaceCountReducer.isSuccess])

    return <Container fluid>
        <Row>
            <WelcomeCard />
            <WorkspaceStatusWidget workspacesCountObj={workspacesCountObj} />
            <Widget1Cards />
            <RecentWorkspace listData={listData} />
        </Row>
        </Container>
}

export default SuperAdminDashboard;
