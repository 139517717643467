import { createHotelService, createPaymentTypeService, createRoomTypeService, deleteHotelRoomTypeService, deletePaymentTypeService, getHotelMealPlanTypeService, getHotelPaymentTypeService, getHotelRoomTypeService, getHotelsDetailService, getHotelsService, getPaymentTypeByIdService, getRoomTypeByIdService, gethotelDropdownListService, updateHotelService, updatePaymentTypeService, updateRoomTypeService, uploadHotelGalleryService } from '../../../../Services/Service';

export const getHotelMealPlanTypeAction = async (payload: any) => {
    return await getHotelMealPlanTypeService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const createRoomTypeAction = async (payload: any) => {
    return await createRoomTypeService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const updateRoomTypeAction = async (payload: any) => {
    return await updateRoomTypeService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const deleteHotelRoomTypeAction = async (payload: any) => {
    return await deleteHotelRoomTypeService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const getRoomTypeByIdAction = async (payload: any) => {
    return await getRoomTypeByIdService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const getHotelRoomTypeAction = async (payload: any) => {
    return await getHotelRoomTypeService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const createPaymentTypeAction = async (payload: any) => {
    return await createPaymentTypeService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const updatePaymentTypeAction = async (payload: any) => {
    return await updatePaymentTypeService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const deletePaymentTypeAction = async (payload: any) => {
    return await deletePaymentTypeService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const getPaymentTypeByIdAction = async (payload: any) => {
    return await getPaymentTypeByIdService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const getHotelPaymentTypeAction = async (payload: any) => {
    return await getHotelPaymentTypeService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const createHotelAction = async (payload: any) => {
    return await createHotelService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const updateHotelAction = async (payload: any) => {
    return await updateHotelService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const getHotelsAction = async (payload: any) => {
    return await getHotelsService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const getHotelsDetailAction = async (payload: any) => {
    return await getHotelsDetailService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const gethotelDropdownListAction = async (payload: any) => {
    return await gethotelDropdownListService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const uploadHotelGalleryAction = async (payload: any) => {
    return await uploadHotelGalleryService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};