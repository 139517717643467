import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Container, Input, Row } from 'reactstrap';
import styled from '@emotion/styled';
import Drawer from 'react-modern-drawer'
import AsyncSelect from 'react-select/async';
import { IndicatorSeparatorProps } from 'react-select';
import CommonCardHeader from '../../../Utils/CommonComponents/CommonCardHeader';
import { leadFilterOprions } from '../../../helpers';
import { Btn, H4, H6 } from '../../../AbstractElements';
import 'react-modern-drawer/dist/index.css'
import { Close } from '../../../Utils/Constants';
import { workspaceUsersAction } from '../../../ReduxToolkit/Reducers/Layout/Auth/LoginAction';

const StyleListContainer = styled(Container)`
    .h4-title {
        overflow: hidden;
        text-align: center;   
        padding-bottom: 10px;      
    }
    .h4-title::before,
    .h4-title::after {
        background-color: #000;
        content: "";
        display: inline-block;
        height: 1px;
        position: relative;
        vertical-align: middle;
        width: 50%;
    }

    .h4-title::before {
        right: 0.5em;
        margin-left: -50%;
    }

    .h4-title::after {
        left: 0.5em;
        margin-right: -50%;
    }
    .assignee-select-box {
        width: 100%;
    }
`

const StyleIndicatorSeparator = {
    alignSelf: 'stretch',
    backgroundColor: '#00B8D9',
    marginBottom: 8,
    marginTop: 8,
    width: 1,
};

const IndicatorSeparator = ({
    innerProps,
}: IndicatorSeparatorProps<any, true>) => {
    return <span style={StyleIndicatorSeparator} {...innerProps} />;
};

const AdvanceFilter = (props: any) => {
    const { toggleDrawer, isOpenAdvanceFilter, selectedFilters, handleOnClick, handleOnAssigneeChange } = props;
    const [ assigneeListData, setAssigneeListData ] = useState<any>();
    const [ selectedAssignee, setSelectedAssignee ] = useState<any>();

    useEffect(() => {
        if(isOpenAdvanceFilter) {
            const response = workspaceUsersAction({});
            response.then((result: any) => {
                if (result.status === 200) {
                    const assigneeList = result.data.length > 0 && result.data.map((user: any) => {
                        return { value: user.id, label: `${user.firstName} ${user.lastName}` }
                    })
                    setAssigneeListData(assigneeList);
                }
            });
        }
    }, [isOpenAdvanceFilter])
    
    const filterAssignee = (inputValue: string) => {
        return assigneeListData.length > 0 && assigneeListData.filter((i: any) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const loadAssigneeOptions = (
        inputValue: string,
        callback: (options: any) => void
    ) => {
        setTimeout(() => {
            callback(filterAssignee(inputValue));
        }, 1000);
    };

    const handleAssigneeSelector = (event: any) => {
        setSelectedAssignee(event);
        handleOnAssigneeChange(event);
    }
    
    return (<Drawer
        open={isOpenAdvanceFilter}
        enableOverlay={true}
        size={400}
        onClose={toggleDrawer}
        direction='right'
        className=''
    >
        <StyleListContainer fluid>
            <Card className='title-line'>
                <CommonCardHeader title={'Advance Filter'} subTitle={[]} />
                <CardBody>
                    <H4 className='h4-title'>Select Status</H4>
                    <div className="d-flex flex-column flex-wrap mb-2">
                        {leadFilterOprions().map((item) => {
                            return (
                                <div className="p-2" onClick={() => handleOnClick(item)}>
                                    <Input className="checkbox-primary m-r-10 p-2" checked={selectedFilters?.includes(item)} type="checkbox" value={item} /><span className="bg-white text-dark">{item}</span>
                                </div>
                            )
                        })}
                    </div>
                    <H4 className='h4-title'>Select Assignee</H4>
                    <div className="d-flex flex-column mb-2">
                        <AsyncSelect 
                            value={selectedAssignee}
                            cacheOptions
                            loadOptions={loadAssigneeOptions}
                            defaultOptions={assigneeListData}
                            components={{ IndicatorSeparator }}
                            onChange={(e) => handleAssigneeSelector(e)}
                            isMulti={true}
                            className='assignee-select-box'
                        />
                    </div>
                    <hr />
                    <div className={`d-grid gap-2 buttons-box`}>
                        <Btn type='button' className='' color='primary' onClick={toggleDrawer}>{'Search'}</Btn>
                    </div>
                </CardBody>
            </Card>
        </StyleListContainer>
    </Drawer>)
}

export default AdvanceFilter;