import React from 'react'
import { Card, CardBody, CardHeader, Col } from 'reactstrap'
import { Badges, H2, H3 } from '../../../AbstractElements'
import { Emptysearch, Href, TopRecentWorkspace } from '../../../Utils/Constants'
import SvgIcon from '../../../Utils/CommonComponents/CommonIcons/CommonSvgIcons'
import { useNavigate } from 'react-router-dom'
import CommonTable from '../CommonTable'
import { workspaceDashboardHeader } from '../../../Data/Table/TableHeader'
import { formatDate } from '../../../helpers'

export default function RecentWorkspace(props: any) {
    const navigate = useNavigate();
    const { listData } = props;

    return (
        <Col xl={12}>
            <Card className="height-equal title-line overflow-hidden">
                <CardHeader className="card-no-border">
                    <div className="header-top">
                        <H2>{TopRecentWorkspace}</H2>
                        <div className="card-header-right-icon">
                            <a className="link-with-icon" href={'#'} onClick={() => navigate(`/workspace`)}>{'View all'}
                                <SvgIcon iconId='arrow-two-tone' />
                            </a>
                        </div>
                    </div>
                </CardHeader>
                <CardBody className="project-table p-0">
                <div className="table-responsive custom-scrollbar">
                        <CommonTable strip={true} hover={true} headData={workspaceDashboardHeader} headClass='tbl-strip-thad-bdr' headRowClass='border-bottom-primary'>
                            {listData && listData.length ? listData.map((tbl: any) => (
                                <tr key={tbl.id} className='border-bottom-'>
                                    <th scope="row">{tbl.workSpaceName}</th>
                                    <td>{tbl.userInfo.email}</td>
                                    <td>{tbl.userInfo.phoneNumber}</td>
                                    <td>{tbl.userInfo.firstName}</td>
                                    <td>{tbl.userInfo.lastName}</td>
                                    <td>
                                        <Badges color={tbl.paymentStatus === 'Completed' ? 'success' : 'danger'}>{tbl.paymentStatus}</Badges>
                                    </td>
                                    <td>
                                        <Badges color={tbl.status === 'Active' ? 'success' : 'danger'}>{tbl.status}</Badges>
                                    </td>
                                    <td>{formatDate(tbl.startDate)}</td>
                                    <td>{formatDate(tbl.endDate)}</td>
                                </tr>
                            ))
                            :
                            <H3 className="badge-heading">
                                <Badges color='primary' className='m-20'>{Emptysearch}</Badges>
                            </H3>
                        }
                        </CommonTable>
                    </div>
                </CardBody>
            </Card>
        </Col>
    )
}
