import DashboardPage from "../Pages/Dashboard"
import HotelsPage from "../Pages/Hotels"
import HotelCreatePage from "../Pages/Hotels/HotelCreatePage"
import InvitiesPage from "../Pages/Invities"
import LeadsPage from "../Pages/Leads"
import GenerateLeadPage from "../Pages/Leads/GenerateLeadPage"
import UploadLeadPage from "../Pages/Leads/UploadLeadPage"
import RoomTypesPage from "../Pages/RoomTypes"
import UsersPage from "../Pages/Users"
import WorkspaceListPage from "../Pages/Workspace"
import WorkspaceCreatePage from "../Pages/Workspace/WorkspaceCreatePage"
import WorkspaceEditPage from "../Pages/Workspace/WorkspaceEditPage"
import PackagesPage from "../Pages/Packages"
import PackageCreatePage from "../Pages/Packages/PackageCreatePage"
import PackageDetailPage from "../Pages/Packages/PackageDetailPage"
import BookPackage from "../Pages/Packages/BookPackagePage"
import PaymentReferencesPage from "../Pages/PaymentReferences"
import LocationsPage from "../Pages/Locations"
import VehiclesPage from "../Pages/Vehicles"
import InvoicePage from "../Pages/Packages/InvoicePage"

export const routes = [
    { path: `/dashboard`, element: <DashboardPage /> },
    { path: `/leads`, element: <LeadsPage /> },
    { path: `/leads/generate`, element: <GenerateLeadPage /> },
    { path: `/leads/upload`, element: <UploadLeadPage /> },
    { path: `/leads/book-package`, element: <BookPackage /> },
    { path: `/leads/invoice`, element: <InvoicePage /> },
    { path: `/room-types`, element: <RoomTypesPage /> },
    { path: `/payment-references`, element: <PaymentReferencesPage /> },
    { path: `/locations`, element: <LocationsPage /> },
    { path: `/vehicles`, element: <VehiclesPage /> },
    { path: `/hotels`, element: <HotelsPage /> },
    { path: `/hotels/create`, element: <HotelCreatePage /> },
    { path: `/hotels/update`, element: <HotelCreatePage /> },
    { path: `/packages`, element: <PackagesPage /> },
    { path: `/packages/create`, element: <PackageCreatePage /> },
    { path: `/packages/update`, element: <PackageCreatePage /> },
    { path: `/packages/detail`, element: <PackageDetailPage /> },
    { path: `/invities`, element: <InvitiesPage /> },
    { path: `/users`, element: <UsersPage /> },
    { path: `/workspace`, element: <WorkspaceListPage /> },
    { path: `/workspace/create`, element: <WorkspaceCreatePage /> },
    { path: `/workspace/edit?`, element: <WorkspaceEditPage /> }
]

export const roleBasedRoutes: any = {
    'Super Admin': ['/', '/dashboard', '/workspace', '/workspace/create', '/workspace/edit'],
    'Admin': ['/', '/dashboard', '/leads', '/leads/generate', '/leads/upload', '/leads/book-package', '/leads/invoice', '/hotels', '/hotels/create', 'hotels/update', '/invities', '/users', '/packages', '/packages/create', '/packages/update', '/packages/detail', '/room-types', '/payment-references', '/locations', '/vehicles'],
    'Manager': ['/', '/dashboard', '/leads', '/leads/generate', '/leads/upload', '/leads/book-package', '/leads/invoice', '/hotels', '/hotels/create', 'hotels/update', '/invities', '/users', '/packages', '/packages/create', '/packages/update', '/packages/detail', '/room-types', '/payment-references', '/locations', '/vehicles'],
    'User': ['/', '/dashboard', '/leads', '/leads/generate', '/leads/upload', '/leads/book-package', '/leads/invoice', '/hotels', '/hotels/create', 'hotels/update', '/invities', '/users', '/packages', '/packages/create', '/packages/update', '/packages/detail', '/room-types', '/payment-references', '/locations', '/vehicles'],
}
