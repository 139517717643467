import { createPackageService, getPackageDetailService, getPackagesByLocationService, getPackagesService, updateInclusionExclusionService, updatePackageItineraryService, updatePackageService, updateTermConditionService, uploadPackageGalleryService } from "../../../../Services/Service";

export const createPackageAction = async (payload: any) => {
    return await createPackageService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const uploadPackageGalleryAction = async (payload: any) => {
    return await uploadPackageGalleryService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const updatePackageItineraryAction = async (payload: any) => {
    return await updatePackageItineraryService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const updateInclusionExclusionAction = async (payload: any) => {
    return await updateInclusionExclusionService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const updateTermConditionAction = async (payload: any) => {
    return await updateTermConditionService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const updatePackageAction = async (payload: any) => {
    return await updatePackageService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const getPackagesAction = async (payload: any) => {
    return await getPackagesService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const getPackageDetailAction = async (payload: any) => {
    return await getPackageDetailService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const getPackagesByLocationAction = async (payload: any) => {
    return await getPackagesByLocationService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};