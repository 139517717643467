
import createGenericSlice from '../../genericSlice';
import { GenericStateInterface } from '../../reduxDTO';

const loginSlice = createGenericSlice({
    name: 'userLogin',
    initialState: { status: 'loading' } as GenericStateInterface,
    reducers: {
        setSignOut: () => {}
    },
});

export const { setSuccess, setLoading, setError, setReset, setSignOut } = loginSlice.actions;
export default loginSlice.reducer;