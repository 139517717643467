import { useState } from "react";
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { TagsInput } from "react-tag-input-component";
import { Row, Col, FormGroup, Label, Input, FormFeedback } from "reactstrap";
import { H2, P, Btn } from "../../../AbstractElements";
import { InviteMembers, InvitationType, UsersEmail, Cancel, SendInvitation } from "../../../Utils/Constants";
import CommonModal from "../../Common/CommonModal";
import { toast } from "react-toastify";
import { sendUserInvitaionAction } from "../../../ReduxToolkit/Reducers/Layout/Auth/LoginAction";

const InviteFormSchema = Yup.object().shape({
    invitationType: Yup.string().required('Invitation type is required'),
    userEmails: Yup.array().required('User emails are required')
}).required()

const InviteMembermodal = (props: any) => {
    const { data, toggle, modal, setModal, handleCallback } = props;
    const [showLoader, setShowLoader] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState<any>(null);
    
    const handleSubmit = (request: any) => {
        setShowLoader(true);
        const payload = {
            invitationType: request.invitationType,
            userEmails: request.userEmails,
        };
        const response = sendUserInvitaionAction(payload);
        response.then((result: any) => {
            setShowLoader(false);
            if(result.status === 200) {
                setModal(!modal);
                toast.success(result.message);
                if(handleCallback) {
                    handleCallback();
                }
            } else {
                toast.error(result.data.errors.msg);
            }
        });
    }

    return <CommonModal modalData={data}>
        <div className="modal-toggle-wrapper">
            <H2>{InviteMembers}</H2>
            <Formik
                initialValues={{
                    invitationType: 'User',
                    userEmails: null,
                }}
                validationSchema={InviteFormSchema}
                onSubmit={values => {
                    handleSubmit(values);
                }}
            >
                {({ errors, touched, setFieldValue, values }) => (
                    <Form className="theme-form">
                        <P>{"Enter Members Email address"}</P>
                        <Row>
                            <Col sm={12}>
                                <FormGroup>
                                    <Col><Label>{InvitationType}</Label></Col>
                                    <Field name="invitationType" render={(field: any) => <Input onChange={(e) => setFieldValue('invitationType', e.target.value)} type="select" {...field} value={values.invitationType} invalid={ touched.invitationType && errors.invitationType ? true : false }>
                                        <option value={'User'}>User</option>
                                        <option value={'Manager'}>Manager</option>
                                    </Input> } />
                                    {touched.invitationType && errors.invitationType && <FormFeedback>{errors.invitationType}</FormFeedback>}
                                </FormGroup>
                            </Col>
                            <Col sm={12}>
                                <FormGroup>
                                    <Col><Label>{UsersEmail}</Label></Col>
                                    <Field name="userEmails" render={({}) => <TagsInput
                                            value={selectedUsers}
                                            onChange={(e) => setFieldValue('userEmails', e)}
                                            // onChange={setSelectedUsers}
                                            name="userEmails"
                                            placeHolder="Enter Emails"
                                        />} />
                                    <em>press enter or comma to add new user</em>
                                    {touched.userEmails && errors.userEmails && <FormFeedback>{errors.userEmails}</FormFeedback>}
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup className='p-t-20'>
                            <div className='d-flex justify-content-end'>
                                <Btn color='danger' className="align-items-center text-light m-r-5" type="button" onClick={toggle}>
                                    {Cancel}
                                </Btn>
                                <Btn color='primary' className="align-items-center text-light" type="submit">
                                    {SendInvitation}
                                </Btn>
                            </div>
                        </FormGroup>
                    </Form>
                )}
            </Formik>
        </div>
    </CommonModal>
}

export default InviteMembermodal