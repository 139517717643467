import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Form, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import Dropzone from 'react-dropzone-uploader';
import { Gallery, Item } from 'react-photoswipe-gallery'
import { Btn, H4, H6, Image, LI, P, UL } from '../../../AbstractElements';
import SvgIcon from '../../../Utils/CommonComponents/CommonIcons/CommonSvgIcons';
import { Cancel, Href, Previous, Submit, SubmitAndNext } from '../../../Utils/Constants';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { uploadPackageGalleryAction } from '../../../ReduxToolkit/Reducers/Layout/Package/PackageAction';
import { toast } from 'react-toastify';
import Loader from '../../../Layout/Loader';
import CommonCardHeader from '../../../Utils/CommonComponents/CommonCardHeader';
import { ActiveCallbackProp } from './PackageTabContents';
import Slider from 'react-slick';
import { dynamicNumber, dynamicImage } from '../../../Utils';
import { autoPlaySliderOptions, gDriveImgThumbnailLink } from '../../../Utils/Constants/ElementContans';
import CommonCarousel from '../../Common/CommonCarousel';

const productGalleryData = [
    {
        id: 1,
        title: 'Package Gallery',
        icon: 'file-upload1',
        spanText: 'Add Package Gallery Images (Maximum - 3 files in one time)'
    },
];

export default function PackageGalleryForm({ activeCallBack, toggle, detail, showLoader, setShowLoader }: ActiveCallbackProp) {
    const [searchParams] = useSearchParams();
    const packageId = searchParams.get('id');
    const [stateModal, setStateModal] = useState({ modalOpen: false, tooltipOpen: false });
    const navigate = useNavigate();
    const [uploadedFiles, setUploadedFiles] = useState<any>([]);

    if(!packageId) {
        activeCallBack(1);
    }

    const handleModalToggle = () => {
        setStateModal(prevState => ({
            ...prevState,
            modalOpen: !prevState.modalOpen
        }));
    };

    const modalData = {
        isOpen: stateModal.modalOpen,
        header: true,
        footer: true,
        toggler: handleModalToggle,
        center: true,
        size: 'xl',
        title: '',
        class: '',
        bodyClass: ''
    };

    const getUploadParams = () => {
        return { url: "https://httpbin.org/post", };
    };

    // called every time a file's `status` changes
    const handleChangeStatus = ({ meta, file }: any, status: any) => {
        if(['uploading'].includes(status)) {
            setShowLoader(true);
        }
        if(status === 'done') {
            setShowLoader(false);
            setUploadedFiles([file, ...uploadedFiles]);
        }
    }
    
    // receives array of files that are done uploading when submit button is clicked
    const handleSubmit = () => { 
        if(uploadedFiles.length > 0) {
            setShowLoader(true);
            const response = uploadPackageGalleryAction({
                packageId: packageId,
                gallery: uploadedFiles
            });
            response.then((result: any) => {
                if (result.status === 200) {
                    setShowLoader(false);
                    toast.success(result.message);
                    activeCallBack(3)
                } else {
                    toast.error(result.message);
                }
            });
        } else {
            activeCallBack(3)
        }
     }

    return (
        <div className="sidebar-body">
            <Loader show={showLoader} />
            <Card>
                <CardBody className="my-gallery gallery-with-description mb-0">
                    <Row>
                        <Gallery withCaption>
                            <Slider {...autoPlaySliderOptions}>
                            {detail && detail.packageImages && detail.packageImages.length > 0 && detail.packageImages.map((item: any, i: number) => (
                                <Col xl={3} sm={6} key={i}>
                                    <figure itemProp="caption description">
                                        <Item original={`${gDriveImgThumbnailLink}${item.image}`} caption="Using a camera's lens to record moments in time is the art form known as photography. To produce aesthetically striking photos, it entails the deft use of light, composition, and subject matter." width="802" height="476">
                                            {({ ref, open }) => (
                                                <>
                                                    <img style={{height: '150px'}} onClick={open} className='img-thumbnail border-bottom-0 p-2 rounded-0 rounded-top-1' ref={ref as React.MutableRefObject<HTMLImageElement>} src={`${gDriveImgThumbnailLink}${item.image}`} alt='Image_description' />
                                                    {/* <div className='caption border-top-0 p-2'>
                                                        <H4>{'}</H4>
                                                        <P>{"Using a camera's lens to record moments in time is the art form known as photography. To produce aesthetically striking photos, it entails the deft use of light, composition, and subject matter."}</P>
                                                    </div> */}
                                                </>
                                            )}
                                        </Item>
                                    </figure>
                                </Col>
                            ))}
                            </Slider>
                        </Gallery>
                    </Row>
                </CardBody>
            </Card>
            {productGalleryData.map((item) => (
                <div className="product-upload" key={item.id}>
                    {/* <CommonCardHeader title={'Manage Gallery'} subTitle={[]} /> */}
                    <Form>
                        <Dropzone
                            autoUpload={true}
                            multiple={true}
                            maxFiles={3}
                            getUploadParams={getUploadParams}
                            // onSubmit={handleSubmit}
                            onChangeStatus={handleChangeStatus}
                            accept="image/*"
                            submitButtonDisabled
                            inputContent={
                                <div className='dz-message needsclick'>
                                    <SvgIcon iconId={item.icon} />
                                    <H6>{'Drag files here'}</H6>
                                    <span className='note needsclick'>{item.spanText}</span>
                                </div>
                            } />
                    </Form>
                </div>
            ))}
            <div className="product-buttons1 position-bottom">
                <div className="d-md-flex justify-content-md-start common-flex">
                    { toggle ?
                        <Btn type='button' color='secondary' onClick={toggle}>{Cancel}</Btn>
                        :
                        <Btn type='button' color='secondary' onClick={() => navigate('/packages')}>{Cancel}</Btn>
                    }
                    <Btn type='button' color='danger' onClick={() => activeCallBack(1)}>{Previous}</Btn>
                    <Btn type='submit' color='primary' onClick={() =>handleSubmit()}>{SubmitAndNext}</Btn>
                </div>
            </div>
        </div>
    )
}
