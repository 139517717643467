
import { useEffect, useState } from "react";
import styled from "@emotion/styled"
import { toast } from 'react-toastify';
import { Formik, Form, Field } from 'formik';
import AsyncSelect from 'react-select/async';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ConnectedFocusError } from 'focus-formik-error'
import * as Yup from 'yup';
import Select, { IndicatorSeparatorProps } from 'react-select';
import { BookPackageDetailType } from "../../../../Types/InterfaceData.type";
import { Col, FormFeedback, FormGroup, Input, InputGroup, Label, Row } from "reactstrap";
import { Cancel, StrAdult, StrBookingComment, StrBookingSource, StrChild, StrDestination, StrInfant, StrLocationFrom, StrPackageDuration, StrPlacesToVisit, StrRefereceNumber, StrTravelEndDate, StrTravelStartDate, SubmitAndNext } from "../../../../Utils/Constants";
import { StyleIndicatorSeparator } from "../../../Common/CommonStyles";
import { bookingSourceTypes, genderTypes } from "../../../../helpers";
import { Btn } from "../../../../AbstractElements";
import CommonTouchspin from "../../../../CommonElements/TouchSpin/CommonTouchspin";
import ReactQuill from "react-quill";
import { getLocationsAction } from "../../../../ReduxToolkit/Reducers/Layout/Location/LocationAction";
import { createLeadBookingAction } from "../../../../ReduxToolkit/Reducers/Layout/Lead/LeadAction";
import DatePickerComponent from "../../../Common/DatePickerComponent";

const StyleForm = styled.div`
    .ql-editor{
        height: 150px;
        max-height: 150px;
        overflow: auto;
    }
`
interface ActiveCallbackProp {
    toggle?: () => void,
    activeCallBack: (tab: number) => void,
    handleCallback: () => void,
    detail?: BookPackageDetailType,
    locationsList: any,
    showLoader: boolean,
    setShowLoader: (val: boolean) => void,
    leadId: string,
}

const IndicatorSeparator = ({
    innerProps,
}: IndicatorSeparatorProps<any, true>) => {
    return <span style={StyleIndicatorSeparator} {...innerProps} />;
};

const formSchema = Yup.object().shape({
    source: Yup.string().required('Booking Source is required'),
    locationFrom: Yup.string().required('Source is required'),
    destination: Yup.string().required('Destination is required'),
})

const UserDetailForm = ({ toggle, activeCallBack, showLoader, setShowLoader, leadId, detail, handleCallback, locationsList }: ActiveCallbackProp) => {
    const bookingSourceTypeOpt = bookingSourceTypes();
    const genderTypesOpt = genderTypes();
    const navigate = useNavigate();
    const [totalPassenger, setTotalPassenger] = useState<number>(1);
    const [passengerDetailsArr, setPassengerDetailsArr] = useState<any>([]);
    const [tStartDate, setTStartDate] = useState(new Date());
    const [tEndDate, setTEndDate] = useState(new Date());

    useEffect(() => {
        if(detail) {
            setPassengerDetailsArr(detail.passengerDetails);
            setTotalPassenger(detail.adults + detail.childs + detail.infants);
        }
    }, [detail])

    useEffect(() => {
        if(totalPassenger < passengerDetailsArr.length) {
            setPassengerDetailsArr(passengerDetailsArr.slice(0, -1));
        }
    }, [totalPassenger])

    const getSelectedSource = (sourceType: string) => {
        if(!sourceType) return null;

        return bookingSourceTypeOpt && bookingSourceTypeOpt.length > 0 && bookingSourceTypeOpt.find((arr: any) => arr.value === sourceType);
    }

    const handlePassengerDetail = (index: number, type: string, value: string) => {
        let pDetailArr = {};
        let tmpPassengerDetailsArr = [...passengerDetailsArr];
        if(passengerDetailsArr[index]) {
            pDetailArr = passengerDetailsArr[index];
        } else {
            pDetailArr = {
                passengerName: '',
                passengerGender: '',
                passengerAge: 0
            }
        }
        tmpPassengerDetailsArr[index] = pDetailArr;
        if(type === 'passengerName') {
            tmpPassengerDetailsArr[index] = { 
                passengerName: value,
                passengerGender: tmpPassengerDetailsArr[index]['passengerGender'],
                passengerAge: tmpPassengerDetailsArr[index]['passengerAge']
            }
        } else if(type === 'passengerGender') {
            tmpPassengerDetailsArr[index] = { 
                passengerName: tmpPassengerDetailsArr[index]['passengerName'],
                passengerGender: value,
                passengerAge: tmpPassengerDetailsArr[index]['passengerAge']
            }
        } else if(type === 'passengerAge') {
            tmpPassengerDetailsArr[index] = { 
                passengerName: tmpPassengerDetailsArr[index]['passengerName'],
                passengerGender: tmpPassengerDetailsArr[index]['passengerGender'],
                passengerAge: value
            }
        }
        setPassengerDetailsArr(tmpPassengerDetailsArr);
    }

    const handleSubmit = (request: any) => {
        setShowLoader(true);
        const payload = {...request, ...{
            leadId: parseInt(leadId),
            passengerDetails: passengerDetailsArr
        }};
        const response = createLeadBookingAction(payload);
        response.then((result: any) => {
            setShowLoader(false);
            if (result.status === 200) {
                toast.success(result.message);
                activeCallBack(2);
                handleCallback();
            } else {
                toast.error(result.message);
            }
        });
    }

    const getSelectedLocation = (location: string) => {
        if(!location) return null;

        const selected = locationsList && locationsList.length > 0 && locationsList.find((ar: any) => ar.value === parseInt(location))
        return { value: selected.value, label: selected.label }
    }
    
    return (<StyleForm>
        <Formik
            enableReinitialize={detail && Object.keys(detail).length > 0}
            initialValues={{
                source: detail && detail.source,
                refereceNumber: detail && detail.refereceNumber,
                salesTeamMemberId: detail && detail.salesTeamMemberId,
                travelStartDate: detail && detail.travelStartDate ? detail.travelStartDate : tStartDate,
                travelEndDate: detail && detail.travelEndDate ? detail.travelEndDate : tEndDate,
                duration: detail && detail.duration,
                placesVisit: detail && detail.placesVisit,
                locationFrom: detail && detail.locationFrom,
                destination: detail && detail.destination,
                adults: detail && detail.adults ? detail.adults : 1,
                childs: detail && detail.childs ? detail.childs : 0,
                infants: detail && detail.infants ? detail.infants : 0,
                comment: detail && detail.comment ? detail.comment : '',
            }}
            validationSchema={formSchema}
            onSubmit={values => {
                handleSubmit(values);
            }}
        >
            {({ errors, touched, setFieldValue, values, setTouched }) => (
                <Form>
                    <ConnectedFocusError />
                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <Label className='required-field'><strong>{StrBookingSource}</strong></Label>
                                <Field name="source" render={(field: any) => <Select
                                    {...field}
                                    closeMenuOnSelect={true}
                                    components={{ StyleIndicatorSeparator }}
                                    value={values.source ? getSelectedSource(values.source) : ''}
                                    options={bookingSourceTypeOpt}
                                    onChange={(e: any) => {
                                        setFieldValue('source', e.value)
                                    }}
                                />} />
                                <small>Please provide source details. e.g. Lead came from B2B or from a source</small>
                                {touched.source && errors.source && <FormFeedback>{errors.source}</FormFeedback>}
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Label><strong>{StrRefereceNumber}</strong>(optional)</Label>
                                <Input type="text" tag={Field} placeholder={''} name="refereceNumber" value={values.refereceNumber} invalid={touched.refereceNumber && errors.refereceNumber ? true : false} />
                                {touched.refereceNumber && errors.refereceNumber && <FormFeedback>{errors.refereceNumber}</FormFeedback>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <Label className='required-field'><strong>{StrTravelStartDate}</strong></Label>
                                <Field name="travelStartDate" render={(field: any) => <InputGroup {...field} className="flatpicker-calender"><DatePickerComponent startDate={new Date(values.travelStartDate)} handleChange={(date: Date) => {setTStartDate(date); setFieldValue('travelStartDate', date)}} /></InputGroup>} />
                                {touched.travelStartDate && errors.travelStartDate && <FormFeedback>{errors.travelStartDate}</FormFeedback>}
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Label className='required-field'><strong>{StrTravelEndDate}</strong></Label>
                                <Field name="travelEndDate" render={(field: any) => <InputGroup {...field} className="flatpicker-calender"><DatePickerComponent startDate={new Date(values.travelEndDate)} handleChange={(date: Date) => {setTEndDate(date); setFieldValue('travelEndDate', date)}} /></InputGroup>} />
                                {touched.travelEndDate && errors.travelEndDate && <FormFeedback>{errors.travelEndDate}</FormFeedback>}
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Label className='required-field'><strong>{StrPackageDuration}</strong></Label>
                                <Input type="text" tag={Field} placeholder={'eg. 4 Nights & 5 Days'} name="duration" value={values.duration} invalid={touched.duration && errors.duration ? true : false} />
                                {touched.duration && errors.duration && <FormFeedback>{errors.duration}</FormFeedback>}
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Label className='required-field'><strong>{StrPlacesToVisit}</strong></Label>
                                <Input type="text" tag={Field} placeholder={'eg. 2N Phuket | 2N Krabi'} name="placesVisit" value={values.placesVisit} invalid={touched.placesVisit && errors.placesVisit ? true : false} />
                                {touched.placesVisit && errors.placesVisit && <FormFeedback>{errors.placesVisit}</FormFeedback>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <Col><Label className='required-field'>{StrLocationFrom}</Label></Col>
                                <Field name="locationFrom" render={(field: any) => <Select
                                    {...field}
                                    closeMenuOnSelect={true}
                                    components={{ StyleIndicatorSeparator }}
                                    value={values.locationFrom ? getSelectedLocation(values.locationFrom) : ''}
                                    options={locationsList}
                                    onChange={(e: any) => {
                                        setFieldValue('locationFrom', e.value)
                                    }}
                                />} />
                                {touched.locationFrom && errors.locationFrom && <FormFeedback>{errors.locationFrom}</FormFeedback>}
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Col><Label className='required-field'>{StrDestination}</Label></Col>
                                <Field name="destination" render={(field: any) => <Select
                                    {...field}
                                    closeMenuOnSelect={true}
                                    components={{ StyleIndicatorSeparator }}
                                    value={values.destination ? getSelectedLocation(values.destination) : ''}
                                    options={locationsList}
                                    onChange={(e: any) => {
                                        setFieldValue('destination', e.value)
                                    }}
                                />} />
                                {touched.destination && errors.destination && <FormFeedback>{errors.destination}</FormFeedback>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                            <FormGroup>
                                <Col><Label className='required-field'>{StrAdult}</Label></Col>
                                <Field name="adults" render={(field: any) => <CommonTouchspin 
                                    color="primary" 
                                    outline={true} 
                                    btnClass={`spin-border-primary`} 
                                    align="left"
                                    touchspinValue={values.adults}
                                    minValue={1}
                                    setTouchspinValue={(value: number) => {
                                        setFieldValue('adults', value);
                                        setTotalPassenger(values.childs + values.infants + value);
                                    }}
                                />} />
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup>
                                <Col><Label>{StrChild}</Label></Col>
                                <Field name="childs" render={(field: any) => <CommonTouchspin 
                                    color="primary" 
                                    outline={true} 
                                    btnClass={`spin-border-primary`} 
                                    align="left"
                                    minValue={0}
                                    touchspinValue={values.childs}
                                    setTouchspinValue={(value: number) => {
                                        setFieldValue('childs', value);
                                        setTotalPassenger(values.adults + values.infants + value);
                                    }}
                                />} />
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup>
                                <Col><Label>{StrInfant}</Label></Col>
                                <Field name="infants" render={(field: any) => <CommonTouchspin 
                                    color="primary" 
                                    outline={true} 
                                    btnClass={`spin-border-primary`} 
                                    align="left"
                                    minValue={0}
                                    touchspinValue={values.infants}
                                    setTouchspinValue={(value: number) => {
                                        setFieldValue('infants', value);
                                        setTotalPassenger(values.adults + values.childs + value);
                                    }}
                                />} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className='pt-2'>
                        {totalPassenger && totalPassenger > 0 && [...Array(totalPassenger)].map((item: any, index: number) => {
                            return (
                                <Col sm={12} className='pb-2'>
                                    <div className="card-wrapper border rounded-3 checkbox-checked mb-2">
                                        <Row>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label className='required-field'><strong>{'Passenger Name'}</strong></Label>
                                                    <Input type="text" required placeholder={'Passenger Name'} name="passengerName" value={passengerDetailsArr && passengerDetailsArr[index] ? passengerDetailsArr[index]['passengerName'] : '' } onChange={(e: any) => handlePassengerDetail(index, 'passengerName', e.target.value)} />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label className='required-field'><strong>{'Passenger Gender'}</strong></Label>
                                                    <Field name="passengerGender" render={(field: any) => <Select
                                                        {...field}
                                                        required
                                                        closeMenuOnSelect={true}
                                                        components={{ StyleIndicatorSeparator }}
                                                        value={passengerDetailsArr && passengerDetailsArr[index] ? passengerDetailsArr[index]['passengerGender'] : '' }
                                                        options={genderTypesOpt}
                                                        onChange={(e: any) => {
                                                            handlePassengerDetail(index, 'passengerGender', e)
                                                        }}
                                                    />} />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Label className='required-field'><strong>{'Passenger Age'}</strong></Label>
                                                    <Input required type="number" min={0} placeholder={'Passenger Age'} name="passengerAge" value={passengerDetailsArr && passengerDetailsArr[index] ? passengerDetailsArr[index]['passengerAge'] : 0 } onChange={(e: any) => handlePassengerDetail(index, 'passengerAge', e.target.value)} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            )
                        })}
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <FormGroup>
                                <Col><Label>{StrBookingComment}</Label></Col>
                                <Field name="comment" render={(field: any) => <ReactQuill {...field} theme='snow' placeholder={StrBookingComment} value={values.comment} onChange={(value) => setFieldValue('comment', value)} />} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup className='p-t-20'>
                        <div className="d-md-flex justify-content-md-start common-flex">
                            { toggle ?
                                <Btn type='button' color='secondary' onClick={toggle}>{Cancel}</Btn>
                                :
                                <Btn type='button' color='secondary' onClick={() => navigate('/packages')}>{Cancel}</Btn>
                            }
                            <Btn type='submit' color='primary' disabled={showLoader}>{SubmitAndNext}</Btn>
                        </div>
                    </FormGroup>
                </Form>
            )}
        </Formik>
    </StyleForm>)
}

export default UserDetailForm