import { useEffect, useState } from "react";
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { Row, Col, FormGroup, Label, Input, FormFeedback } from "reactstrap";
import { H2, P, Btn } from "../../../AbstractElements";
import Select from 'react-select';
import { Cancel, StrAcAvailable, StrSeatsAvailable, StrSubmit, StrVehicleName, StrVehicles } from "../../../Utils/Constants";
import CommonModal from "../../Common/CommonModal";
import { toast } from "react-toastify";
import Loader from "../../../Layout/Loader";
import { createVehicleAction, getVehicleByIdAction, updateVehicleAction } from "../../../ReduxToolkit/Reducers/Layout/Vehicle/VehicleAction";
import { StyleIndicatorSeparator } from "../../Common/CommonStyles";
import { vehicleAcTypes } from "../../../helpers";

const InviteFormSchema = Yup.object().shape({
    name: Yup.string().required('Vehicle name is required'),
    seats: Yup.string().required('Seats is required')
}).required()

interface DetailType {
    id: number,
    name: string,
    seats: number,
    acAvailable: string
}

const VehicleForm = (props: any) => {
    const vehicleAcTypesOpt = vehicleAcTypes();
    const { data, toggle, modal, setModal, handleCallback, itemId, setSelectedItemId } = props;
    const [showLoader, setShowLoader] = useState(false);
    const [detail, setDetail] = useState<DetailType>();

    useEffect(() => {
        if(itemId > 0) {
            setShowLoader(true);
            const response = getVehicleByIdAction({id: itemId});
            response.then((result: any) => {
                setShowLoader(false);
                if(result.status === 200) {
                    setDetail(result.data);
                    toggle();
                } else {
                    toast.error(result.message);
                }
            });
        } else {
            setDetail({
                id: 0,
                name: '',
                seats: 0,
                acAvailable: ''
            });
        }
    }, [itemId])

    const getSelectedAcAvailable = (type: string) => {
        if(!type) return null;

        return vehicleAcTypesOpt && vehicleAcTypesOpt.length > 0 && vehicleAcTypesOpt.find((arr: any) => arr.value === type);
    }

    const handleSubmit = (request: any) => {
        if(itemId > 0) {
            setShowLoader(true);
            request['id'] = itemId;
            const response = updateVehicleAction(request);
            response.then((result: any) => {
                setShowLoader(false);
                if(result.status === 200) {
                    toast.success(result.message);
                    handleCallback();
                    toggle();
                } else {
                    toast.error(result.message);
                }
            });
        } else {
            setShowLoader(true);
            const response = createVehicleAction(request);
            response.then((result: any) => {
                setShowLoader(false);
                if(result.status === 200) {
                    setModal(!modal);
                    toast.success(result.message);
                    handleCallback();
                    toggle();
                } else {
                    toast.error(result.message);
                }
            });
        }
    }

    return <CommonModal modalData={data}>
        <Loader show={showLoader} />
        <div className="modal-toggle-wrapper">
            <H2>{StrVehicles}</H2>
            <Formik
                initialValues={{
                    name: detail && detail.name,
                    seats: detail && detail.seats ? detail.seats : 4,
                    acAvailable: detail && detail.acAvailable ? detail.acAvailable : 'ac'
                }}
                validationSchema={InviteFormSchema}
                onSubmit={values => {
                    handleSubmit(values);
                }}
            >
                {({ errors, touched, setFieldValue, values }) => (
                    <Form className="theme-form">
                        <P>{"Enter Transport details"}</P>
                        <Row>
                            <Col sm={12}>
                                <FormGroup>
                                    <Col><Label className="required-field">{StrVehicleName}</Label></Col>
                                    <Input type="text" tag={Field} placeholder={''} name="name" value={values.name} invalid={touched.name && errors.name ? true : false} />
                                    {touched.name && errors.name && <FormFeedback>{errors.name}</FormFeedback>}
                                </FormGroup>
                            </Col>
                            <Col sm={12}>
                                <FormGroup>
                                    <Col><Label className="required-field">{StrSeatsAvailable}</Label></Col>
                                    <Input type="number" tag={Field} placeholder={''} name="seats" value={values.seats} invalid={touched.seats && errors.seats ? true : false} />
                                    {touched.seats && errors.seats && <FormFeedback>{errors.seats}</FormFeedback>}
                                </FormGroup>
                            </Col>
                            <Col sm={12}>
                                <FormGroup>
                                    <Col><Label className="required-field">{StrAcAvailable}</Label></Col>
                                    <Field name="acAvailable" render={(field: any) => <Select
                                        {...field}
                                        closeMenuOnSelect={true}
                                        components={{ StyleIndicatorSeparator }}
                                        value={values.acAvailable ? getSelectedAcAvailable(values.acAvailable) : ''}
                                        options={vehicleAcTypesOpt}
                                        onChange={(e: any) => {
                                            setFieldValue('acAvailable', e.value)
                                        }}
                                    />} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup className='p-t-20'>
                            <div className='d-flex justify-content-end'>
                                <Btn color='danger' disabled={showLoader} className="align-items-center text-light m-r-5" type="button" onClick={() => {setSelectedItemId(0);toggle()}}>
                                    {Cancel}
                                </Btn>
                                <Btn color='primary' disabled={showLoader} className="align-items-center text-light" type="submit">
                                    {StrSubmit}
                                </Btn>
                            </div>
                        </FormGroup>
                    </Form>
                )}
            </Formik>
        </div>
    </CommonModal>
}

export default VehicleForm