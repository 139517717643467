import Workspace from "../../Components/Workspace"

const WorkspaceListPage = () => {
    return (
        <div className='page-body main-project'>
            <Workspace />
        </div>
    )
}

export default WorkspaceListPage