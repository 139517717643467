import UploadLead from "../../Components/AdminDashboard/Leads/UploadLead"

const UploadLeadPage = () => {
    return (
        <div className='page-body main-project'>
            <UploadLead />
        </div>
    )
}

export default UploadLeadPage