import styled from "@emotion/styled"
import { Card, CardBody, CardFooter, Col, Container, Row } from "reactstrap"
// import PackageListFilter from "./PackageListFilter"
import parse from 'html-react-parser';
import { Ribbon, Image, P, H4, LI, UL, Alerts } from "../../../AbstractElements"
import { Rupee } from "../../../Utils/Constants"
import { isBrowser } from "react-device-detect"
import PackagesListNav from "./PackagesListNav"
import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import PaginationCard from "../../Common/PaginationCard"
import { getPackageDetailAction, getPackagesAction } from "../../../ReduxToolkit/Reducers/Layout/Package/PackageAction"
import { toast } from "react-toastify"
import './index.css';
import { defaultImage, gDriveImgThumbnailLink, perPageLimit } from "../../../Utils/Constants/ElementContans";
import { Info } from "react-feather";

const StyleListContainer = styled(Container)`
    height: 100%;
`
const defaultImageLink = gDriveImgThumbnailLink + defaultImage;

const PackagesList = () => {
    const navigate = useNavigate();
    const [listData, setListData] = useState<any>();
    const [currentPage, setCurrentPage] = useState(1);
    const [packageId, setPackageId] = useState<string>();
    const [activeFilter, setActiveFilter] = useState('All');
    const [stateModal, setStateModal] = useState({ modalOpen: false, tooltipOpen: false });
    const [detail, setDetail] = useState<any>()

    useEffect(() => {
        const response = getPackagesAction({pageNo: currentPage, recordPerPage: perPageLimit});
        response.then((result: any) => {
            if(result.status === 200) {
                setListData(result.data);
            } else {
                toast.error(result.message);
            }
        });
    }, [currentPage])

    useEffect(() => {
        if(packageId) {
            const response = getPackageDetailAction({ id: packageId });
            response.then((result: any) => {
                if (result.status === 200) {
                    setDetail(result.data);
                } else {
                    toast.error(result.message);
                }
            });
        }
    }, [packageId])

    const handleModalToggle = () => {
        setStateModal(prevState => ({
            ...prevState,
            modalOpen: !prevState.modalOpen
        }));
    };

    const modalData = {
        isOpen: stateModal.modalOpen,
        header: true,
        footer: true,
        toggler: handleModalToggle,
        center: true,
        size: 'xl',
        title: 'Update Package',
        class: '',
        bodyClass: ''
    };

    const onClickHandle = (data: any) => {

    };
    return (
        <Container className={`product-wrapper "sidebaron"}`} fluid>
            <div className="product-grid">
                {isBrowser &&
                    <Col md={12} className="project-list">
                        <PackagesListNav activeTab={activeFilter} setActiveTab={setActiveFilter} />
                    </Col>
                }
                {/* <PackageListFilter /> */}
                <div className={`product-wrapper-grid`}>
                    <Row>
                        {listData && listData.rows.length > 0 ?
                        listData.rows.map((item: any, index: number) => {
                            return (
                                <Col xxl={3} md={6} className="box-col-6" key={index}>
                                    <Card className="features-faq product-box">
                                        <div className="faq-image product-img">
                                            <Ribbon className={'ribbon ribbon-bookmark ribbon-right ribbon-dark'}>{item.duration}</Ribbon>
                                            { item.packageImages && item.packageImages.length > 0 ?
                                                <Image style={{minHeigth: '150px'}} className="img-fluid" src={`${gDriveImgThumbnailLink}${item.packageImages[0].image}`} alt="featured image" />
                                                :
                                                <Image style={{minHeigth: '150px'}} className="img-fluid" src={`${defaultImageLink}`} alt="featured image" />
                                            }
                                            <div className="product-hover">
                                                <UL className='simple-list flex-row'>
                                                    <Link to={`/packages/detail?id=${item.id}`} target="_blank">
                                                        <LI><i className="icon-eye"></i></LI>
                                                    </Link>
                                                    <LI onClick={() => { navigate(`/packages/update?id=${item.id}`) }}><i className="icon-pencil-alt"></i></LI>
                                                </UL>
                                            </div>
                                        </div>
                                        <CardBody className="p-2">
                                            <H4 className="f-w-600 pb-1 package-title">{item.packageName}</H4>
                                            <P className="package-detail">{item.description ? parse(item.description) : ''}</P>
                                        </CardBody>
                                        <CardFooter className="p-2">
                                            {/* <span>{'Test'}</span>
                                            <span className="pull-right"><Rating fillColor="#7366ff" initialValue={Math.random() * 5} size={14} /></span> */}
                                            <div className="product-price">
                                                {Rupee} {parseInt(item.packageCharges)-parseInt(item.packageDiscount)}
                                                { parseInt(item.packageDiscount) > 0 &&
                                                    <span className="pull-right"><del>{Rupee} {parseInt(item.packageCharges)}</del></span>
                                                }
                                            </div>
                                        </CardFooter>
                                    </Card>
                                </Col>
                            )
                        }): 
                        <Alerts color='light-danger' className={`light alert-dismissible fade text-dark border-left-wrapper show`}>
                            <Info />
                            <P>{'No Package Found.'}</P>
                        </Alerts>
                        }
                        <PaginationCard
                            totalPages={listData && listData.count ? (listData.count - 1) / perPageLimit + 1 : 0}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    </Row>
                </div>
            </div>
            {/* <Modal className={modalData.class} isOpen={modalData.isOpen} toggle={modalData.toggler} centered={modalData.center} size={modalData.size}>
                {modalData.header && <ModalHeader toggle={modalData.toggler}>
                    {modalData.title}
                </ModalHeader>}
                <ModalBody className={modalData.bodyClass}>
                    <PackageForm detail={detail} toggle={() => {modalData.toggler()}} />
                </ModalBody>
            </Modal> */}
        </Container>
    )
}

export default PackagesList