import React, { Fragment } from 'react'
import { CardHeader } from 'reactstrap'
import styled from '@emotion/styled';
import { CommonCardHeaderProps } from '../../Types/CommonComponent.type'
import { Btn, H2, P } from '../../AbstractElements'
import { Download } from 'react-feather';

const StyleHeader = styled(CardHeader)`
    display: flex;
    justify-content: space-between;
`

export default function CommonCardHeader({ headClass, title, subTitle, titleClass, btnText, faIcon='download', handleOnClick }: CommonCardHeaderProps) {
    return (
        <StyleHeader className={headClass}>
            <div>
                <H2 className={`mb-0 ${titleClass ? titleClass : ''}`}>{title}</H2>
                {subTitle && (
                    <P className="f-m-light mt-0">
                        {subTitle.map((data, index) => (
                            <Fragment key={index}>
                                {data?.text} {data.code && <code>{data.code}</code>}
                            </Fragment>
                        ))}
                    </P>
                )}
            </div>
            { btnText &&
                <div>
                    <Btn color='primary' type='button' onClick={handleOnClick} className="btn-hover-effect">
                        { faIcon &&
                            <i className={`fa fa-${faIcon} fa-sm p-r-5`}></i> 
                        }
                        {btnText}
                    </Btn>
                </div>
            }
        </StyleHeader>
    )
}
