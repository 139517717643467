import React, { useEffect } from 'react'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { roleBasedRoutes } from './Route';

export default function PrivateRoutes() {
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        if(['/login', '/unauthorised'].includes(location.pathname)) {
            localStorage.clear();
            return;
        }
        const userLocalStorage = JSON.parse(localStorage.getItem('jwtToken')!) || null;
        if(userLocalStorage !== null) {
            if(userLocalStorage && userLocalStorage.userRole) {
                const userRole = userLocalStorage.userRole.roleName;
                const userRoleRoutes = roleBasedRoutes[userRole];
                if(!userRoleRoutes.includes(location.pathname)) {
                    navigate('/unauthorised');
                }
            }
        }
    }, [location.pathname]);

    const login = JSON.parse(localStorage.getItem("login")!) || false;
    return login !== false ? <Outlet /> : <Navigate to={`/login`} />
}
