import { useEffect, useState } from 'react'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import styled from '@emotion/styled';
import { InviteMembers, StrInvitiesList } from '../../../Utils/Constants'
import { Badges, H5, H6, Image, LI, UL } from '../../../AbstractElements'
import { dynamicImage } from '../../../Utils'
import CommonCardHeader from '../../../Utils/CommonComponents/CommonCardHeader';
import { toast } from 'react-toastify';
import { getInvitedUsersAction } from '../../../ReduxToolkit/Reducers/Layout/Auth/LoginAction';
import { formatDate } from '../../../helpers';
import InviteMembermodal from './InviteMembermodal';
// import CustomerOrderDropdown from './CustomerOrderDropdown'

const StyleContainer = styled(Container)`
`

interface InvitiesProps {
    id: number,
    userType: string,
    email: string,
    status: string,
    createdAt: string
}

interface ListDataProps {
    count: number,
    rows: InvitiesProps[]
}

const InvitiesList = () => {
    const [modal, setModal] = useState(false);
    const toggle = () => { setModal(!modal); }
    const data = {
        isOpen: modal,
        toggler: toggle,
        size: 'md'
    }
    const [listData, setListData] = useState<ListDataProps>();

    useEffect(() => {
        handleGetInvities();
    }, [])

    const handleGetInvities = () => {
        const response = getInvitedUsersAction({});
        response.then((result: any) => {
            if(result.status === 200) {
                setListData(result.data);
            } else {
                toast.error(result.message);
            }
        });
    }

    return (
        <StyleContainer fluid>
            <Row className='project-cards'>
                <Col xl={12} sm={6} className="col-xl-30 order-xl-ii customer-order">
                    <Card className='title-line'>
                        <CommonCardHeader headClass='border-t-primary border-2 pb-2' 
                            title={StrInvitiesList} 
                            subTitle={[{
                                text: 'Total',
                                code: `${listData && listData.count}`
                            }]}
                            btnText={InviteMembers} 
                            faIcon={'user-plus'} handleOnClick={toggle} 
                        />
                        <CardBody className="transaction-list recent-orders">
                            <UL className="order-list mb-0 simple-list">
                                {listData && listData?.rows && listData?.rows.length > 0 && listData?.rows.map((item) => (
                                    <LI key={item.id}>
                                        <div className="light-card"> <Image src={dynamicImage(`user.png`)} alt="TV" /></div>
                                        <div className="order-content">
                                            <div>
                                                <H5 className="mb-1">{item.email}</H5>
                                                <span>
                                                    <Badges color='light-primary'>{item.status}</Badges>
                                                    <span className="f-light f-w-500 f-15 ms-2">{item.userType}</span>
                                                </span>
                                            </div>
                                            <div className="text-end">
                                                {/* <Dropdown isOpen={openMenu} toggle={() => setOpenMenu(!openMenu)} className="icon-dropdown">
                                                    <DropdownToggle color='transparent'><SvgIcon iconId='more-horizontal' /></DropdownToggle>
                                                    <DropdownMenu end={true}>
                                                        <DropdownItem href={Href}>{'Cancel'}</DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown> */}
                                                <span className="f-light f-w-500 f-15">{formatDate(item.createdAt, 'DD/MM/YY h:mm a')}</span>
                                            </div>
                                        </div>
                                    </LI>
                                ))}
                            </UL>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <InviteMembermodal data={data} toggle={toggle} modal={modal} setModal={setModal} handleCallback={handleGetInvities} />
        </StyleContainer>
    )
}

export default InvitiesList;
