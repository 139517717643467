import { createVehicleService, updateVehicleService, deleteVehicleService, getVehicleByIdService, getVehiclesService } from "../../../../Services/Service";

export const createVehicleAction = async (payload: any) => {
    return await createVehicleService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const updateVehicleAction = async (payload: any) => {
    return await updateVehicleService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const deleteVehicleAction = async (payload: any) => {
    return await deleteVehicleService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const getVehicleByIdAction = async (payload: any) => {
    return await getVehicleByIdService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const getVehiclesAction = async (payload: any) => {
    return await getVehiclesService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};