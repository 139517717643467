import React, { useEffect, useRef, useState } from 'react'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import InvoiceHeader from './InvoiceHeader';
import InvoiceDetails from './InvoiceDetails';
import InvoicePassengerTable from './InvoicePassengerTable';
import Invoice6Button from './Invoice6Button';
import { getLeadBookingAction } from '../../../../../ReduxToolkit/Reducers/Layout/Lead/LeadAction';
import ItineraryInfo from './ItineraryInfo';

export default function InvoiceContainer() {
    const componentRef = useRef<HTMLDivElement | null>(null);
    const [detail, setDetail] = useState<any>()
    const [ searchParams ] = useSearchParams();

    useEffect(() => {
        const response = getLeadBookingAction({bookingId: searchParams.get('bookingId')});
        response.then((result: any) => {
            if (result.status === 200) {
                setDetail(result.data);
            }
        })
    }, [])

    console.log(detail, 'detail');
    return (
        <Container>
            <div ref={componentRef}>
                <Row>
                    <Col sm={12}>
                        <Card>
                            <CardBody>
                                <div className="invoice">
                                    <div>
                                        { detail &&
                                            <>
                                                <InvoiceHeader detail={detail} />
                                                <hr />
                                                <InvoiceDetails detail={detail} />
                                                <InvoicePassengerTable detail={detail} />
                                            </>
                                        }
                                    </div>
                                    <Invoice6Button componentRef={componentRef} />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Container>
    )
}
