import { Link } from "react-router-dom"
import { dynamicImage } from "../../../Utils"
import { Image } from '../../../AbstractElements';

const AppLogo = () => {
    return (
        <div>
            <Link className='logo' to={`/`}>
                <Image className="img-fluid for-light" src={dynamicImage(`logo/app-logo.jpeg`)} alt="looginpage" />
                <Image className="img-fluid for-dark" src={dynamicImage(`logo/app-logo.jpeg`)} alt="looginpage"></Image>
            </Link>
        </div>
    )
}

export default AppLogo