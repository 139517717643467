import axiosInstance from './AxiosInstance';
export const userLoginService = async (payload: any) => {
    const response = await axiosInstance.post('users/login', payload);
    return response;
};
export const createWorkspaceService = async (payload: any) => {
    const response = await axiosInstance.post('workspaces', payload);
    return response;
};

export const updateWorkspaceService = async (payload: any) => {
    const response = await axiosInstance.put(`workspaces/${payload.id}`, payload);
    return response;
};

export const getWorkspaceService = async (payload: any) => {
    const response = await axiosInstance.get('workspaces', { params: payload });
    return response;
};

export const getWorkspaceCountService = async (payload: any) => {
    const response = await axiosInstance.get('workspaces/count', { params: payload });
    return response;
};

export const getWorkspaceById = async (payload: any) => {
    const response = await axiosInstance.get(`workspaces/${payload.id}`, { params: payload });
    return response;
};

export const sendUserInvitation = async (payload: any) => {
    const response = await axiosInstance.post(`users/send-invitation`, payload);
    return response;
};

export const validateUserInvitation = async (payload: any) => {
    const response = await axiosInstance.get(`users/validate-invitation`, { params: payload });
    return response;
};

export const userSignupService = async (payload: any) => {
    const response = await axiosInstance.post(`users`, payload);
    return response;
};

export const forgotPasswordService = async (payload: any) => {
    const response = await axiosInstance.post(`users/forgot-password`, payload);
    return response;
};

export const resetPasswordService = async (payload: any) => {
    const response = await axiosInstance.post(`users/reset-password`, payload);
    return response;
};

export const getLeadsService = async (payload: any) => {
    const response = await axiosInstance.get(`leads`, { params: payload });
    return response;
};

export const getLeadInfoService = async (payload: any) => {
    const response = await axiosInstance.get(`leads/${payload.id}`, { params: payload });
    return response;
};

export const generateLeadService = async (payload: any) => {
    const response = await axiosInstance.post(`leads/generate-lead`, payload);
    return response;
};

export const workspaceUsersService = async (payload: any) => {
    const response = await axiosInstance.get(`users/workspace-users`, payload);
    return response;
};

export const assignLeadService = async (payload: any) => {
    const response = await axiosInstance.post(`leads/assign-lead`, payload);
    return response;
};

export const setLeadReminderService = async (payload: any) => {
    const response = await axiosInstance.post(`leads/set-lead-reminder`, payload);
    return response;
};

export const createLeadBookingService = async (payload: any) => {
    const response = await axiosInstance.post(`leads/booking`, payload);
    return response;
};

export const manageBookingHotelDetailService = async (payload: any) => {
    const response = await axiosInstance.post(`leads/booking/hotel-details`, payload);
    return response;
};

export const manageBookingPriceDetailService = async (payload: any) => {
    const response = await axiosInstance.post(`leads/booking/price-details`, payload);
    return response;
};

export const getLeadBookingService = async (payload: any) => {
    const response = await axiosInstance.get(`bookings/${payload.bookingId}`, { params: payload });
    return response;
};

export const getLeadBookingByLeadService = async (payload: any) => {
    const response = await axiosInstance.get(`leads/booking/${payload.leadId}`, { params: payload });
    return response;
};

export const leadStatusUpdateService = async (payload: any) => {
    const response = await axiosInstance.post(`leads/update-status`, payload);
    return response;
};

export const leadCommentUpdateService = async (payload: any) => {
    const response = await axiosInstance.post(`leads/update-comment`, payload);
    return response;
};

export const bulkLeadGenerationService = async (payload: any) => {
    const response = await axiosInstance.post(`leads/bulk-upload`, payload);
    return response;
};

export const bulkLeadGenerationCsvService = async (payload: any) => {
    const response = await axiosInstance.post(`leads/bulk-upload-csv`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    });
    return response;
};

export const getInvitedUsersService = async (payload: any) => {
    const response = await axiosInstance.get(`users/invitied-users`, payload);
    return response;
};

export const getUsersService = async (payload: any) => {
    const response = await axiosInstance.get(`users/user-list`, payload);
    return response;
};

export const getUserDetailService = async (payload: any) => {
    const response = await axiosInstance.get(`users/user-info`, { params: payload });
    return response;
};

export const getWorkspaceManagersService = async (payload: any) => {
    const response = await axiosInstance.get(`users/managers`, { params: payload });
    return response;
};

export const assignManagerService = async (payload: any) => {
    const response = await axiosInstance.post(`users/assign-manager`, payload);
    return response;
};

export const userStatusUpdateService = async (payload: any) => {
    const response = await axiosInstance.post(`users/update-status`, payload);
    return response;
};

export const getHotelMealPlanTypeService = async (payload: any) => {
    const response = await axiosInstance.get(`hotels/meal-plans`, { params: payload });
    return response;
};

export const createRoomTypeService = async (payload: any) => {
    const response = await axiosInstance.post(`hotels/room-types/create`, payload);
    return response;
};

export const updateRoomTypeService = async (payload: any) => {
    const response = await axiosInstance.put(`hotels/room-types/update/${payload.id}`, payload);
    return response;
};

export const deleteHotelRoomTypeService = async (payload: any) => {
    const response = await axiosInstance.delete(`hotels/room-types/delete/${payload.id}`);
    return response;
};

export const getRoomTypeByIdService = async (payload: any) => {
    const response = await axiosInstance.get(`hotels/room-types/${payload.id}`, { params: payload });
    return response;
};

export const getHotelRoomTypeService = async (payload: any) => {
    const response = await axiosInstance.get(`hotels/room-types`, { params: payload });
    return response;
};

export const createPaymentTypeService = async (payload: any) => {
    const response = await axiosInstance.post(`hotels/payment-types/create`, payload);
    return response;
};

export const updatePaymentTypeService = async (payload: any) => {
    const response = await axiosInstance.put(`hotels/payment-types/update/${payload.id}`, payload);
    return response;
};

export const deletePaymentTypeService = async (payload: any) => {
    const response = await axiosInstance.delete(`hotels/payment-types/delete/${payload.id}`);
    return response;
};

export const getPaymentTypeByIdService = async (payload: any) => {
    const response = await axiosInstance.get(`hotels/payment-types/${payload.id}`, { params: payload });
    return response;
};

export const getHotelPaymentTypeService = async (payload: any) => {
    const response = await axiosInstance.get(`hotels/payment-types`, { params: payload });
    return response;
};

export const createVehicleService = async (payload: any) => {
    const response = await axiosInstance.post(`vehicles/create`, payload);
    return response;
};

export const updateVehicleService = async (payload: any) => {
    const response = await axiosInstance.put(`vehicles/update/${payload.id}`, payload);
    return response;
};

export const deleteVehicleService = async (payload: any) => {
    const response = await axiosInstance.delete(`vehicles/delete/${payload.id}`);
    return response;
};

export const getVehicleByIdService = async (payload: any) => {
    const response = await axiosInstance.get(`vehicles/${payload.id}`, { params: payload });
    return response;
};

export const getVehiclesService = async (payload: any) => {
    const response = await axiosInstance.get(`vehicles`, { params: payload });
    return response;
};

export const createLocationService = async (payload: any) => {
    const response = await axiosInstance.post(`locations/create`, payload);
    return response;
};

export const updateLocationService = async (payload: any) => {
    const response = await axiosInstance.put(`locations/update/${payload.id}`, payload);
    return response;
};

export const deleteLocationService = async (payload: any) => {
    const response = await axiosInstance.delete(`locations/delete/${payload.id}`);
    return response;
};

export const getLocationByIdService = async (payload: any) => {
    const response = await axiosInstance.get(`locations/${payload.id}`, { params: payload });
    return response;
};

export const getLocationsService = async (payload: any) => {
    const response = await axiosInstance.get(`locations`, { params: payload });
    return response;
};

export const createHotelService = async (payload: any) => {
    const response = await axiosInstance.post(`hotels/create`, payload);
    return response;
};

export const updateHotelService = async (payload: any) => {
    const response = await axiosInstance.put(`hotels/${payload.id}`, payload);
    return response;
};

export const getHotelsService = async (payload: any) => {
    const response = await axiosInstance.get(`hotels`, { params: payload });
    return response;
};

export const getHotelsDetailService = async (payload: any) => {
    const response = await axiosInstance.get(`hotels/${payload.id}`, { params: payload });
    return response;
};

export const gethotelDropdownListService = async (payload: any) => {
    const response = await axiosInstance.get(`hotels/hotel-dropdown-list`, { params: payload });
    return response;
};

export const uploadHotelGalleryService = async (payload: any) => {
    const response = await axiosInstance.post(`hotels/upload-gallery`, payload, {
        headers: {'Content-Type': 'multipart/form-data'}
    });
    return response;
};

export const createPackageService = async (payload: any) => {
    const response = await axiosInstance.post(`packages/create`, payload);
    return response;
};

export const uploadPackageGalleryService = async (payload: any) => {
    const response = await axiosInstance.post(`packages/upload-gallery`, payload, {
        headers: {'Content-Type': 'multipart/form-data'}
    });
    return response;
};

export const updatePackageItineraryService = async (payload: any) => {
    const response = await axiosInstance.put(`packages/update-itinerary`, payload);
    return response;
};

export const updateInclusionExclusionService = async (payload: any) => {
    const response = await axiosInstance.put(`packages/update-inclusion-exclusion`, payload);
    return response;
};

export const updateTermConditionService = async (payload: any) => {
    const response = await axiosInstance.put(`packages/update-term-condition`, payload);
    return response;
};

export const updatePackageService = async (payload: any) => {
    const response = await axiosInstance.put(`packages/${payload.id}`, payload);
    return response;
};

export const getPackagesService = async (payload: any) => {
    const response = await axiosInstance.get(`packages`, { params: payload });
    return response;
};

export const getPackageDetailService = async (payload: any) => {
    const response = await axiosInstance.get(`packages/${payload.id}`, { params: payload });
    return response;
};

export const getPackagesByLocationService = async (payload: any) => {
    const response = await axiosInstance.get(`packages/packagesByLocation`, { params: payload });
    return response;
};