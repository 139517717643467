import WorkspaceCreate from "../../Components/Workspace/WorkspaceCreate"

const WorkspaceCreatePage = () => {
    return (
        <div className='page-body main-project'>
            <WorkspaceCreate />
        </div>
    )
}

export default WorkspaceCreatePage