import { useEffect, useState } from "react";
import { Formik, Form, Field } from 'formik';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ConnectedFocusError } from 'focus-formik-error'
import makeAnimated from 'react-select/animated';
import * as Yup from 'yup';
import Select, { IndicatorSeparatorProps } from 'react-select';
import { Col, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import { BookPackageDetailType } from "../../../../Types/InterfaceData.type";
import { Badges, Btn, H4 } from "../../../../AbstractElements";
import { Cancel, StrItinerary, StrMealPlan, SubmitAndNext } from "../../../../Utils/Constants";
import { getPackageDetailAction, getPackagesByLocationAction } from "../../../../ReduxToolkit/Reducers/Layout/Package/PackageAction";
import ReactQuill from "react-quill";
import { gethotelDropdownListAction, getHotelMealPlanTypeAction } from "../../../../ReduxToolkit/Reducers/Layout/Hotel/HotelAction";
import { getVehiclesAction } from "../../../../ReduxToolkit/Reducers/Layout/Vehicle/VehicleAction";
import { allowExtraBedTypes } from "../../../../helpers";
import { manageBookingHotelDetailAction } from "../../../../ReduxToolkit/Reducers/Layout/Lead/LeadAction";
import { toast } from "react-toastify";

interface ActiveCallbackProp {
    toggle?: () => void,
    activeCallBack: (tab: number) => void,
    handleCallback: () => void,
    detail?: BookPackageDetailType,
    locationsList: any,
    showLoader: boolean,
    setShowLoader: (val: boolean) => void,
    leadId: string,
}

const StyleIndicatorSeparator = {
    alignSelf: 'stretch',
    backgroundColor: '#00B8D9',
    marginBottom: 8,
    marginTop: 8,
    width: 1,
};

const IndicatorSeparator = ({
    innerProps,
}: IndicatorSeparatorProps<any, true>) => {
    return <span style={StyleIndicatorSeparator} {...innerProps} />;
};

const formSchema = Yup.object().shape({
    packageName: Yup.string().required('Package name is required'),
})
const animatedComponents = makeAnimated();

const HotelDetailForm = ({ toggle, activeCallBack, handleCallback, showLoader, setShowLoader, leadId, detail, locationsList }: ActiveCallbackProp) => {
    const navigate = useNavigate();
    const allowExtraBedTypesOpt = allowExtraBedTypes();
    const [packageListData, setPackageListData] = useState<any>();
    const [packageName, setPackageName] = useState<string>('');
    const [itineraryData, setItineraryData] = useState<any>([])
    const [dropdownHotelList, setDropdownHotelList] = useState<any>([])
    const [hotelList, setHotelList] = useState<any>([])
    const [mealTypeOptions, setMealTypeOptions] = useState<any>([]);
    const [transportTypeOptions, setTransportTypeOptions] = useState<any>([]);
    const [dropdownHotelRoomList, setDropdownHotelRoomList] = useState<any>([])

    if (!detail) {
        activeCallBack(1);
    }

    useEffect(() => {
        if(detail && detail.packageName) {
            setPackageName(detail.packageName);
        }
        if(detail && detail.itineraryDetails) {
            setItineraryData(detail.itineraryDetails);
        }
        if (detail && detail.destination) {
            const response = getPackagesByLocationAction({ locationId: detail.destination });
            response.then((result: any) => {
                if (result.status === 200) {
                    const packageList = result.data.length > 0 && result.data.map((packageD: any) => {
                        return { value: packageD.id, label: `${packageD.packageName}` }
                    })
                    setPackageListData(packageList);
                }
            })
        }
        const response = gethotelDropdownListAction({});
        response.then((result: any) => {
            if (result.status === 200) {
                setHotelList(result.data);
                const listAr = result.data.map((Ob: any) => {
                    return { value: Ob.id, label: Ob.name }
                })
                setDropdownHotelList(listAr);
            }
        });
        const responseMeal = getHotelMealPlanTypeAction({});
        responseMeal.then((result: any) => {
            if (result.status === 200) {
                const mealTypeAr = result.data.map((Ob: any) => {
                    return { value: Ob.id, label: Ob.name }
                })
                setMealTypeOptions(mealTypeAr);
            }
        })
        const responseTransport = getVehiclesAction({});
        responseTransport.then((result: any) => {
            if (result.status === 200) {
                const transportAr = result.data.rows.map((Ob: any) => {
                    const acType = Ob.acAvailable === 'ac' ? 'With AC' : 'Without AC';
                    return { value: Ob.id, label: <span dangerouslySetInnerHTML={{ __html: Ob.name + ' (Seats : ' + Ob.seats + ', ' + acType + ')' }} /> }
                })
                setTransportTypeOptions(transportAr);
            }
        })
    }, [detail])

    const handleHotelRoomOptions = (value: any) => {
        const selectedHotelAr = hotelList.find((ob: any) => ob.id === value.value);
        const listAr = selectedHotelAr.hotelRoomsDetails.map((Ob: any) => {
            return { value: Ob.hotelRoomType.id, label: Ob.hotelRoomType.name }
        })
        setDropdownHotelRoomList(listAr);
    }

    const getHotelRoomTypeList = (hotelId: string) => {
        const selectedHotelAr = hotelList.find((ob: any) => ob.id === hotelId);
        return selectedHotelAr && selectedHotelAr.hotelRoomsDetails && selectedHotelAr.hotelRoomsDetails.map((Ob: any) => {
            return { value: Ob.hotelRoomType.id, label: Ob.hotelRoomType.name }
        })
    }

    const getSelectedRoom = (value: string, hotelId: string) => {
        if (!value) return null;

        const selectedHotelAr = hotelList.find((ob: any) => ob.id === hotelId);
        const listAr = selectedHotelAr && selectedHotelAr.hotelRoomsDetails.map((Ob: any) => {
            return { value: Ob.hotelRoomType.id, label: Ob.hotelRoomType.name }
        })

        const hotelRoomObj = listAr && listAr.length > 0 && listAr.find((arr: any) => arr.value === value);
        return hotelRoomObj;
    }

    const getSelectedHotel = (value: string) => {
        if (!value) return null;

        const hotelObj = dropdownHotelList && dropdownHotelList.length > 0 && dropdownHotelList.find((arr: any) => arr.value === value);
        return hotelObj;
    }
    
    const handleSubmit = (request: any) => {
        if(itineraryData.length > 0) {
            setShowLoader(true);
            const payload = {
                ...request, ...{
                    bookingId: detail?.id,
                    itineraryData: itineraryData
                }
            };
            const response = manageBookingHotelDetailAction(payload);
            response.then((result: any) => {
                setShowLoader(false);
                if (result.status === 200) {
                    toast.success(result.message);
                    handleCallback();
                    activeCallBack(3);
                } else {
                    toast.error(result.message);
                }
            });
        } else {
            toast.error('Please add itinerary data');
        }
    }

    const getSelectedLocation = (location: string) => {
        if (!location) return { value: '', label: 'All' };

        const selected = locationsList && locationsList.length > 0 && locationsList.find((ar: any) => ar.value === parseInt(location))
        return { value: selected.value, label: selected.label }
    }

    const getSelectedPackage = (value: string) => {
        if (!value) return null;

        const selected = packageListData && packageListData.length > 0 && packageListData.find((ar: any) => ar.value === parseInt(value))
        return { value: selected.value, label: selected.label }
    }

    const handlePackageSelection = (packageId: number) => {
        const response = getPackageDetailAction({ id: packageId });
        response.then((result: any) => {
            if (result.status === 200) {
                setPackageName(result.data.packageName);
                setItineraryData(result.data.ItineraryDetails);
            }
        })
    }

    const handleAddMoreItineraryClick = () => {
        const itineraryDataTmp = [...itineraryData];
        setItineraryData([...itineraryDataTmp, {
            hotelId: null,
            roomTypeId: null,
            title: null,
            detail: null,
            mealPlan: null,
            noOfBeds: null,
            allowExtraBeds: null,
            hotelCharges: null,
            transportType: null,
            transportCharges: null
        }]);
    }

    const handleItineraryData = (value: any, index: number, type: string) => {
        const itineraryDataTmp = [...itineraryData];
        if (type === 'title') {
            itineraryDataTmp[index] = {
                title: value,
                hotelId: itineraryDataTmp[index]['hotelId'],
                roomTypeId: itineraryDataTmp[index]['roomTypeId'],
                detail: itineraryDataTmp[index]['detail'],
                mealPlan: itineraryDataTmp[index]['mealPlan'],
                noOfBeds: itineraryDataTmp[index]['noOfBeds'],
                allowExtraBeds: itineraryDataTmp[index]['allowExtraBeds'],
                hotelCharges: itineraryDataTmp[index]['hotelCharges'],
                transportType: itineraryDataTmp[index]['transportType'],
                transportCharges: itineraryDataTmp[index]['transportCharges'],
            }
        } else if (type === 'detail') {
            itineraryDataTmp[index] = {
                title: itineraryDataTmp[index]['title'],
                hotelId: itineraryDataTmp[index]['hotelId'],
                roomTypeId: itineraryDataTmp[index]['roomTypeId'],
                detail: value,
                mealPlan: itineraryDataTmp[index]['mealPlan'],
                noOfBeds: itineraryDataTmp[index]['noOfBeds'],
                allowExtraBeds: itineraryDataTmp[index]['allowExtraBeds'],
                hotelCharges: itineraryDataTmp[index]['hotelCharges'],
                transportType: itineraryDataTmp[index]['transportType'],
                transportCharges: itineraryDataTmp[index]['transportCharges'],
            }
        } else if (type === 'hotelId') {
            itineraryDataTmp[index] = {
                title: itineraryDataTmp[index]['title'],
                hotelId: value.value,
                roomTypeId: itineraryDataTmp[index]['roomTypeId'],
                detail: itineraryDataTmp[index]['detail'],
                mealPlan: itineraryDataTmp[index]['mealPlan'],
                noOfBeds: itineraryDataTmp[index]['noOfBeds'],
                allowExtraBeds: itineraryDataTmp[index]['allowExtraBeds'],
                hotelCharges: itineraryDataTmp[index]['hotelCharges'],
                transportType: itineraryDataTmp[index]['transportType'],
                transportCharges: itineraryDataTmp[index]['transportCharges'],
            }
        } else if (type === 'roomTypeId') {
            itineraryDataTmp[index] = {
                title: itineraryDataTmp[index]['title'],
                hotelId: itineraryDataTmp[index]['hotelId'],
                roomTypeId: value.value,
                detail: itineraryDataTmp[index]['detail'],
                mealPlan: itineraryDataTmp[index]['mealPlan'],
                noOfBeds: itineraryDataTmp[index]['noOfBeds'],
                allowExtraBeds: itineraryDataTmp[index]['allowExtraBeds'],
                hotelCharges: itineraryDataTmp[index]['hotelCharges'],
                transportType: itineraryDataTmp[index]['transportType'],
                transportCharges: itineraryDataTmp[index]['transportCharges'],
            }
        } else if (type === 'mealPlan') {
            itineraryDataTmp[index] = {
                title: itineraryDataTmp[index]['title'],
                hotelId: itineraryDataTmp[index]['hotelId'],
                roomTypeId: itineraryDataTmp[index]['roomTypeId'],
                detail: itineraryDataTmp[index]['detail'],
                mealPlan: value.value,
                noOfBeds: itineraryDataTmp[index]['noOfBeds'],
                allowExtraBeds: itineraryDataTmp[index]['allowExtraBeds'],
                hotelCharges: itineraryDataTmp[index]['hotelCharges'],
                transportType: itineraryDataTmp[index]['transportType'],
                transportCharges: itineraryDataTmp[index]['transportCharges'],
            }
        } else if (type === 'noOfBeds') {
            itineraryDataTmp[index] = {
                title: itineraryDataTmp[index]['title'],
                hotelId: itineraryDataTmp[index]['hotelId'],
                roomTypeId: itineraryDataTmp[index]['roomTypeId'],
                detail: itineraryDataTmp[index]['detail'],
                mealPlan: itineraryDataTmp[index]['mealPlan'],
                noOfBeds: value,
                allowExtraBeds: itineraryDataTmp[index]['allowExtraBeds'],
                hotelCharges: itineraryDataTmp[index]['hotelCharges'],
                transportType: itineraryDataTmp[index]['transportType'],
                transportCharges: itineraryDataTmp[index]['transportCharges'],
            }
        } else if (type === 'allowExtraBeds') {
            itineraryDataTmp[index] = {
                title: itineraryDataTmp[index]['title'],
                hotelId: itineraryDataTmp[index]['hotelId'],
                roomTypeId: itineraryDataTmp[index]['roomTypeId'],
                detail: itineraryDataTmp[index]['detail'],
                mealPlan: itineraryDataTmp[index]['mealPlan'],
                noOfBeds: itineraryDataTmp[index]['noOfBeds'],
                allowExtraBeds: value.value,
                hotelCharges: itineraryDataTmp[index]['hotelCharges'],
                transportType: itineraryDataTmp[index]['transportType'],
                transportCharges: itineraryDataTmp[index]['transportCharges'],
            }
        } else if (type === 'hotelCharges') {
            itineraryDataTmp[index] = {
                title: itineraryDataTmp[index]['title'],
                hotelId: itineraryDataTmp[index]['hotelId'],
                roomTypeId: itineraryDataTmp[index]['roomTypeId'],
                detail: itineraryDataTmp[index]['detail'],
                mealPlan: itineraryDataTmp[index]['mealPlan'],
                noOfBeds: itineraryDataTmp[index]['noOfBeds'],
                allowExtraBeds: itineraryDataTmp[index]['allowExtraBeds'],
                hotelCharges: value,
                transportType: itineraryDataTmp[index]['transportType'],
                transportCharges: itineraryDataTmp[index]['transportCharges'],
            }
        } else if (type === 'transportType') {
            itineraryDataTmp[index] = {
                title: itineraryDataTmp[index]['title'],
                hotelId: itineraryDataTmp[index]['hotelId'],
                roomTypeId: itineraryDataTmp[index]['roomTypeId'],
                detail: itineraryDataTmp[index]['detail'],
                mealPlan: itineraryDataTmp[index]['mealPlan'],
                noOfBeds: itineraryDataTmp[index]['noOfBeds'],
                allowExtraBeds: itineraryDataTmp[index]['allowExtraBeds'],
                hotelCharges: itineraryDataTmp[index]['hotelCharges'],
                transportType: value.value,
                transportCharges: itineraryDataTmp[index]['transportCharges'],
            }
        }  else if (type === 'transportCharges') {
            itineraryDataTmp[index] = {
                title: itineraryDataTmp[index]['title'],
                hotelId: itineraryDataTmp[index]['hotelId'],
                roomTypeId: itineraryDataTmp[index]['roomTypeId'],
                detail: itineraryDataTmp[index]['detail'],
                mealPlan: itineraryDataTmp[index]['mealPlan'],
                noOfBeds: itineraryDataTmp[index]['noOfBeds'],
                allowExtraBeds: itineraryDataTmp[index]['allowExtraBeds'],
                hotelCharges: itineraryDataTmp[index]['hotelCharges'],
                transportType: itineraryDataTmp[index]['transportType'],
                transportCharges: value,
            }
        }
        setItineraryData(itineraryDataTmp);
    }

    const handleRemoveItineraryClick = (index: number) => {
        const itineraryDataTmp = [...itineraryData];
        itineraryDataTmp.splice(index, 1);
        setItineraryData(itineraryDataTmp);
    }

    const getSelectedMeal = (value: string) => {
        if(!value) return null;

        const mealObj = mealTypeOptions && mealTypeOptions.length > 0 && mealTypeOptions.find((arr: any) => arr.value === value);
        return mealObj;
    }

    const getSelectedTransport = (value: string) => {
        if(!value) return null;

        const transportObj = transportTypeOptions && transportTypeOptions.length > 0 && transportTypeOptions.find((arr: any) => arr.value === value);
        return transportObj;
    }

    const getSelectedAllowBeds = (value: string) => {
        if(!value) return null;

        const allowBedsObj = allowExtraBedTypesOpt && allowExtraBedTypesOpt.length > 0 && allowExtraBedTypesOpt.find((arr: any) => arr.value === value);
        return allowBedsObj;
    }

    return (
        <>
            <Formik
                enableReinitialize={detail && Object.keys(detail).length > 0}
                initialValues={{
                    location: detail && detail.destination,
                    package: '',
                    packageName: packageName,
                }}
                validationSchema={formSchema}
                onSubmit={values => {
                    handleSubmit(values);
                }}
            >
                {({ errors, touched, setFieldValue, values, setTouched }) => (
                    <Form>
                        <ConnectedFocusError />
                        <Row>
                            <Col sm={4}>
                                <FormGroup>
                                    <Label>{"Location"}</Label>
                                    <Field name="location" render={(field: any) => <Select
                                        {...field}
                                        closeMenuOnSelect={true}
                                        components={{ StyleIndicatorSeparator }}
                                        value={getSelectedLocation(values.location ?? '')}
                                        options={locationsList}
                                        onChange={(e: any) => {
                                            setFieldValue('location', e.value)
                                        }}
                                    />} />
                                </FormGroup>
                            </Col>
                            <Col sm={8}>
                                <FormGroup>
                                    <Label>{'Select Package'}</Label>
                                    <Field name="package" render={(field: any) => <Select
                                        {...field}
                                        closeMenuOnSelect={true}
                                        components={{ StyleIndicatorSeparator }}
                                        // value={getSelectedPackage(values.package ?? '')}
                                        options={packageListData}
                                        onChange={(e: any) => {
                                            setFieldValue('package', e.value)
                                            handlePackageSelection(e.value);
                                        }}
                                    />} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col sm={12}>
                                <FormGroup>
                                    <Label>{"Package Name"}</Label>
                                    <Input type="text" tag={Field} placeholder={'Package Name'} name="packageName" value={values.packageName} />
                                </FormGroup>
                            </Col>
                            {itineraryData.length > 0 && itineraryData.map((item: any, index: number) => {
                                return (
                                    <Col sm={12} className='pb-3'>
                                        <div className="card-wrapper border rounded-3 checkbox-checked">
                                            <FormGroup>
                                                <Label className='f-w-700 font-dark'><strong>{StrItinerary}</strong></Label>
                                            </FormGroup>
                                            <Row>
                                                <Col sm={12}>
                                                    <FormGroup>
                                                        <Label className='required-field'><strong>{'Title'}</strong></Label>
                                                        <Input type="text" tag={Field} placeholder={'eg. Day 1'} name="title" value={item.title} onChange={(e: any) => handleItineraryData(e.target.value, index, 'title')} />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={12}>
                                                    <FormGroup>
                                                        <Label className='required-field'><strong>{'Detail'}</strong></Label>
                                                        <ReactQuill theme='snow' placeholder={'Itinerary Details'} value={item.detail} onChange={(e) => handleItineraryData(e, index, 'detail')} />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={3}>
                                                    <FormGroup>
                                                        <Label><strong>{'Hotel'}</strong></Label>
                                                        <Field render={(field: any) => <Select
                                                            {...field}
                                                            closeMenuOnSelect={true}
                                                            components={animatedComponents}
                                                            isMulti={false}
                                                            options={dropdownHotelList}
                                                            value={item.hotelId ? getSelectedHotel(item.hotelId) : ''}
                                                            onChange={(e: any) => { handleItineraryData(e, index, 'hotelId'); handleHotelRoomOptions(e) }}
                                                        />} />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={3}>
                                                    <FormGroup>
                                                        <Label><strong>{'Room Type'}</strong></Label>
                                                        <Field render={(field: any) => <Select
                                                            {...field}
                                                            closeMenuOnSelect={true}
                                                            components={animatedComponents}
                                                            isMulti={false}
                                                            options={item.hotelId ? getHotelRoomTypeList(item.hotelId) : []}
                                                            value={item.hotelId ? getSelectedRoom(item.roomTypeId, item.hotelId) : ''}
                                                            onChange={(e: any) => handleItineraryData(e, index, 'roomTypeId')}
                                                        />} />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={2}>
                                                    <FormGroup>
                                                        <Label><strong>{StrMealPlan}</strong></Label>
                                                        <Field render={(field: any) => <Select
                                                            {...field}
                                                            closeMenuOnSelect={true}
                                                            components={animatedComponents}
                                                            value={item.mealPlan ? getSelectedMeal(item.mealPlan) : ''}
                                                            isMulti={false}
                                                            options={mealTypeOptions}
                                                            onChange={(e: any) => handleItineraryData(e, index, 'mealPlan')}
                                                        />} />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={2}>
                                                    <FormGroup>
                                                        <Label><strong>{'No. of Beds'}</strong></Label>
                                                        <Input type="number" min={0} placeholder={'0'} tag={Field} value={item.noOfBeds} onChange={(e: any) => handleItineraryData(e.target.value, index, 'noOfBeds')} />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={2}>
                                                    <FormGroup>
                                                        <Label><strong>{'Hotel Charges'}</strong></Label>
                                                        <Input type="number" min={0} placeholder={'0'} tag={Field} value={item.hotelCharges} onChange={(e: any) => handleItineraryData(e.target.value, index, 'hotelCharges')} />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={4}>
                                                    <FormGroup>
                                                        <Label><strong>{'Allowed extra bed(s)'}</strong></Label>
                                                        <Field render={(field: any) => <Select
                                                            {...field}
                                                            closeMenuOnSelect={true}
                                                            components={animatedComponents}
                                                            isMulti={false}
                                                            options={allowExtraBedTypesOpt}
                                                            value={getSelectedAllowBeds(item.allowExtraBeds ?? '')}
                                                            onChange={(e: any) => handleItineraryData(e, index, 'allowExtraBeds')}
                                                        />} />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <hr />
                                            <Row>
                                                <Col sm={6}>
                                                    <FormGroup>
                                                        <Label><strong>{"Transport Type"}</strong></Label>
                                                        <Field name="transportType" render={(field: any) => <Select
                                                            {...field}
                                                            closeMenuOnSelect={true}
                                                            components={{ StyleIndicatorSeparator }}
                                                            value={getSelectedTransport(item.transportType ?? '')}
                                                            options={transportTypeOptions}
                                                            onChange={(e: any) => {
                                                                handleItineraryData(e, index, 'transportType')
                                                            }}
                                                        />} />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={6}>
                                                    <FormGroup>
                                                        <Label><strong>{"Transport Charges"}</strong></Label>
                                                        <Input type="number" min={0} placeholder={'0'} tag={Field} value={item.transportCharges} onChange={(e: any) => handleItineraryData(e.target.value, index, 'transportCharges')} />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <hr />
                                            <Row>
                                                <Col sm={1}>
                                                    <Btn type='button' className={'b-ln-height b-r-8'}
                                                        outline={false}
                                                        size={'sm'}
                                                        color={'danger'}
                                                        onClick={() => { handleRemoveItineraryClick(index) }}
                                                    >
                                                        {'Remove'}
                                                    </Btn>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                )
                            })}
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <div className={`d-grid gap-2 buttons-box col-12 mx-auto`}>
                                    <Btn type='button' onClick={() => handleAddMoreItineraryClick()} color={'light-info'}>{'Click To Add Itinerary'}</Btn>
                                </div>
                            </Col>
                        </Row>
                        <FormGroup className='p-t-20'>
                            <div className="d-md-flex justify-content-md-start common-flex">
                                {toggle ?
                                    <Btn type='button' color='secondary' onClick={toggle}>{Cancel}</Btn>
                                    :
                                    <Btn type='button' color='secondary' onClick={() => navigate('/packages')}>{Cancel}</Btn>
                                }
                                <Btn type='submit' color='primary' disabled={showLoader}>{SubmitAndNext}</Btn>
                            </div>
                        </FormGroup>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default HotelDetailForm;