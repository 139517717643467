import { createLocationService, updateLocationService, deleteLocationService, getLocationByIdService, getLocationsService } from "../../../../Services/Service";

export const createLocationAction = async (payload: any) => {
    return await createLocationService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const updateLocationAction = async (payload: any) => {
    return await updateLocationService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const deleteLocationAction = async (payload: any) => {
    return await deleteLocationService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const getLocationByIdAction = async (payload: any) => {
    return await getLocationByIdService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const getLocationsAction = async (payload: any) => {
    return await getLocationsService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};