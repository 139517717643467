import React from 'react'
import { Col, Row } from 'reactstrap'
import { H4, H6, Image, P } from '../../../../../AbstractElements'
import { dynamicImage } from '../../../../../Utils'

export default function InvoiceDetails(props: any) {
    const { detail } = props;

    return (
        <Row>
            <Col sm={6}>
                <div className="d-flex">
                    <div className="media-left">
                        <Image className="media-object rounded-circle img-60" src={dynamicImage(`user/1.jpg`)} alt="user" />
                    </div>
                    <div className="flex-grow-1 m-l-20">
                        <H4 className="media-heading">{detail.leadInfo.fullName}</H4>
                        <P>
                            {detail.leadInfo.email}<br /><span>{detail.leadInfo.phoneNumber}</span>
                            , <span>{detail.leadInfo.city}</span>
                        </P>
                    </div>
                </div>
            </Col>
            <Col sm={6}>
                <div className="text-md-end" id="project">
                    <H6>{detail.packageName}</H6>
                    <P>
                        {detail.duration}
                        <br />{detail.placesVisit}
                    </P>
                </div>
            </Col>
        </Row>
    )
}
