import { Card, CardBody, Container, Modal, ModalBody, ModalHeader } from "reactstrap"
import styled from '@emotion/styled';
import Select, { IndicatorSeparatorProps } from 'react-select';
import { H2, H6, H4, Badges, Btn } from "../../../AbstractElements"
import { StrHotelInfo } from "../../../Utils/Constants"
import StarRating from "../../Common/StarRating";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import { Fragment, useEffect, useState } from "react";
import { formatDate, statusColor } from "../../../helpers";
import { getHotelsDetailAction } from "../../../ReduxToolkit/Reducers/Layout/Hotel/HotelAction";
import HotelForm from "./HotelForm";
import HotelGalleryForm from "./HotelGalleryForm";

const StyleContainer = styled(Container)`
    
`

const StyleCardBody = styled(CardBody)`
    min-height: 400px;
    overflow-y: scroll;
    .text-div {
        display: flex;
        gap: 50px;
        align-items: center;
    }
    .text-title {
        padding: 0px;
        width: 20%;
    }
    .ql-editor{
        height: 200px;
        max-height: 200px;
        overflow: auto;
    }
    .comment-title {
        padding-bottom: 10px;
    }
`

const StyleFaEditIcon = styled.span`
    margin-left: 0px;
    cursor: pointer;
`

const StyleHistoryDiv = styled.div`
    overflow-y: auto;
    max-height: 400px;
`

const StyleIndicatorSeparator = {
    alignSelf: 'stretch',
    backgroundColor: '#00B8D9',
    marginBottom: 8,
    marginTop: 8,
    width: 1,
};

const IndicatorSeparator = ({
    innerProps,
}: IndicatorSeparatorProps<any, true>) => {
    return <span style={StyleIndicatorSeparator} {...innerProps} />;
};

const HotelInfo = (props: any) => {
    const { selectedItem, activeItemId } = props;
    const [stateModal, setStateModal] = useState({ modalOpen: false, tooltipOpen: false });
    const [detail, setDetail] = useState<any>()
    const [selectedStatusIndex, setSelectedStatusIndex] = useState<number>();
    const [statusEditableMode, setStatusEditableMode] = useState<boolean>(false);
    const [statusOptions, setStatusOptions] = useState<any>();

    const handleModalToggle = () => {
        setStateModal(prevState => ({
            ...prevState,
            modalOpen: !prevState.modalOpen
        }));
    };

    const modalData = {
        isOpen: stateModal.modalOpen,
        header: true,
        footer: true,
        toggler: handleModalToggle,
        center: true,
        size: 'xl',
        title: 'Update Hotel',
        class: '',
        bodyClass: ''
    };

    useEffect(() => {
        if(activeItemId) {
            const response = getHotelsDetailAction({id: activeItemId});
            response.then((result: any) => {
                if (result.status === 200) {
                    setDetail(result.data);
                }
            });
        }
    }, [activeItemId])

    return <Card>
        <CommonCardHeader headClass='border-t-primary border-2 pb-2' title={StrHotelInfo} btnText={detail && Object.keys(detail).length > 0 ? 'Edit' : ''} faIcon={'edit'} handleOnClick={() => handleModalToggle()} />
        <StyleCardBody>
            {detail && Object.keys(detail).length > 0 &&
                <Fragment>
                    <div className='product-page-details pb-2'>
                        <div className='text-div'><span className="text-title">Name : </span><H2>{detail?.name}</H2></div>
                    </div>
                    <div className='product-page-details pb-2'>
                        <div className='text-div'><span className="text-title">Group Name : </span><H6>{detail?.groupName}</H6></div>
                    </div>
                    <div className='product-page-details pb-2'>
                        <div className='text-div'><span className="text-title">Email : </span><H6>{detail?.email}</H6></div>
                    </div>
                    <div className='product-page-details pb-2' style={{display: 'none'}}>
                        <div className='text-div'><span className="text-title">Status : </span>
                        {statusEditableMode === true ?
                        <>
                            <Select
                                closeMenuOnSelect={false}
                                components={{ IndicatorSeparator }}
                                value={selectedStatusIndex !== undefined && statusOptions[selectedStatusIndex]}
                                options={statusOptions}
                                // onChange={(e) => handleStatusChange(e)}
                            />
                            <StyleFaEditIcon onClick={() => setStatusEditableMode(!statusEditableMode)}><i className="fa fa-times fa-lg"></i></StyleFaEditIcon>
                        </>
                        :
                        <>
                            <Badges color={`${statusColor(detail?.status)}`} className={`${statusColor(detail?.status)} f-12`}>{detail?.status}</Badges>
                            <StyleFaEditIcon onClick={() => setStatusEditableMode(!statusEditableMode)}><i className="fa fa-edit fa-lg"></i></StyleFaEditIcon>
                        </>
                        }
                        
                    </div>
                    </div>
                    <div className='product-page-details pb-2'>
                        <div className='text-div'><span className="text-title">Location : </span><H6>{detail?.location}</H6></div>
                    </div>
                    <div className='product-page-details pb-2'>
                        <div className='text-div'><span className="text-title">Street Address : </span><H6>{detail?.streetAddress}</H6></div>
                    </div>
                    <div className='product-page-details pb-2'>
                        <div className='text-div'><span className="text-title">Locality : </span><H6>{detail?.locality}</H6></div>
                    </div>
                    <div className='product-page-details pb-2'>
                        <div className='text-div'><span className="text-title">Landmark : </span><H6>{detail?.landmark}</H6></div>
                    </div>
                    <div className='product-page-details pb-2'>
                        <div className='text-div'><span className="text-title">City : </span><H6>{detail?.locationInfo.name}</H6></div>
                    </div>
                    <div className='product-page-details pb-2'>
                        <div className='text-div'><span className="text-title">Pincode : </span><H6>{detail?.pincode}</H6></div>
                    </div>
                    <div className='product-page-details pb-2'>
                        <div className='text-div'><span className="text-title">Checkin Time : </span><H6>{formatDate(detail?.checkInTime, 'h:mm a')}</H6></div>
                    </div>
                    <div className='product-page-details pb-2'>
                        <div className='text-div'><span className="text-title">Checkout Time : </span><H6>{formatDate(detail?.checkOutTime, 'h:mm a')}</H6></div>
                    </div>
                    <div className='product-page-details pb-2'>
                        <div className='text-div'><span className="text-title">Child Min Age : </span><H6>{detail?.childMinAge}</H6></div>
                    </div>
                    <div className='product-page-details pb-2'>
                        <div className='text-div'><span className="text-title">Child Max Age : </span><H6>{detail?.childMaxAge}</H6></div>
                    </div>
                    <div className='product-page-details pb-2'>
                        <div className='text-div'><span className="text-title">Created on : </span><H6>{formatDate(detail?.createdAt, 'DD-MM-YYYY h:mm a')}</H6></div>
                    </div>
                    <div className='product-page-details pb-2'>
                        <div className='text-div'><span className="text-title">Last updated : </span><H6>{formatDate(detail?.updatedAt, 'DD-MM-YYYY h:mm a')}</H6></div>
                    </div>
                    <hr />
                    <StarRating allowFraction={true} label={'Rating'} initialRating={detail.rating} readonly={true} />
                    <hr />
                    <div className="m-t-15 common-flex">
                        <HotelGalleryForm hotelId={detail.id} gallery={detail.hotelImages} />
                    </div>
                    <hr />
                </Fragment>
            }
            <StyleContainer fluid>
                <Modal className={modalData.class} isOpen={modalData.isOpen} toggle={modalData.toggler} centered={modalData.center} size={modalData.size}>
                    {modalData.header && <ModalHeader toggle={modalData.toggler}>
                        {modalData.title}
                    </ModalHeader>}
                    <ModalBody className={modalData.bodyClass}>
                        <HotelForm detail={detail} handleModalToggle={handleModalToggle} />
                    </ModalBody>
                </Modal>
            </StyleContainer>
        </StyleCardBody>
    </Card>
}

export default HotelInfo