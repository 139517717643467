import React from 'react'
import { Col, Container, FormFeedback, FormGroup, Input, Row } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Btn, H2, P } from '../../AbstractElements';
import { Email, ResetYourPassword, SignIn, Submit } from '../../Utils/Constants';
import AppLogo from '../../Components/Common/AppLogo';
import { forgotPasswordAction } from '../../ReduxToolkit/Reducers/Layout/Auth/LoginAction';
import { toast } from 'react-toastify';

const ForgotFormSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email').required('Email is required'),
}).required()

export const ForgotPassword = () => {
    const navigate = useNavigate();

    const handleSubmit = (request: any) => {
        const response = forgotPasswordAction({
            email: request.email
        });
        response.then((result: any) => {
            if(result.status === 200) {
                toast.success(result.message);
                navigate('/login');
            } else {
                toast.error(result.message);
            }
        });
    }

    return (
        <div className="page-wrapper">
            <Container fluid className="p-0">
                <Row>
                    <Col xs={12}>
                        <div className="login-card login-dark">
                            <div>
                                <AppLogo />
                                <div className="login-main">
                                    <Formik
                                        initialValues={{
                                            email: ''
                                        }}
                                        validationSchema={ForgotFormSchema}
                                        onSubmit={values => {
                                            handleSubmit(values);
                                        }}
                                    >
                                    {({ errors, touched, setFieldValue, values }) => (
                                    <Form className="theme-form">
                                        <H2>{ResetYourPassword}</H2>
                                        <Row className="g-2 pt-3">
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Input type="email" placeholder={Email} name="email" tag={Field} value={values.email} invalid={ touched.email && errors.email ? true : false } />
                                                    {touched.email && errors.email && <FormFeedback>{errors.email}</FormFeedback>}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <FormGroup className="mb-0">
                                            <Btn color='primary' className="w-100" block type='submit'>{Submit}</Btn>
                                        </FormGroup>
                                        <P className="mt-4 mb-0 text-center">{"Already have an account?"}
                                            <Link to={`/login`} className='ms-2'>{SignIn}</Link>
                                        </P>
                                    </Form>
                                    )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ForgotPassword;
