
import createGenericSlice from '../../genericSlice';
import { GenericStateInterface } from '../../reduxDTO';

const workspaceCountSlice = createGenericSlice({
    name: 'workspaceCount',
    initialState: { status: 'loading' } as GenericStateInterface,
    reducers: {
        setSignOut: () => {}
    },
});

export const { setSuccess, setLoading, setError, setReset, setSignOut } = workspaceCountSlice.actions;
export default workspaceCountSlice.reducer;