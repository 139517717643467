import { useEffect, useState } from "react";
import { Input } from "reactstrap";
import { H6 } from "../../../AbstractElements";

const PercentageBox = (props: any) => {
    const { leadDistributionDetails, userId, handlePercentageChange } = props;
    const [value, setValue] = useState('');

    useEffect(() => {
        if(leadDistributionDetails && leadDistributionDetails['percentage']) {
            setValue(leadDistributionDetails['percentage']);
        }
    }, [leadDistributionDetails['percentage']])
    
    return <>
        <i className="fa fa-user fa-lg p-r-5"></i><H6 className='user-label'>{leadDistributionDetails['label']}</H6>
        <Input type="number" className='input-section' placeholder={'0'} onChange={(e) => { setValue(e.target.value); handlePercentageChange(e, userId, leadDistributionDetails['label'])}} value={value}/><span className='percentage'>%</span>
        <span className='lead-distributed txt-primary'>Count : {leadDistributionDetails && leadDistributionDetails ? leadDistributionDetails['leadCount'] : 0}</span>
    </>
}

export default PercentageBox;