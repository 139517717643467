import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';

export default function TablePagination(props: any) {
    const { currentPage, totalPages, setCurrentPage } = props;
    return (
        <div className='d-flex justify-content-end p-t-20 p-b-10'>
            <ResponsivePagination
                maxWidth={200}
                current={currentPage}
                total={totalPages}
                onPageChange={setCurrentPage}
            />
        </div>
    )
}

export const LimitlistRecord = 10;
