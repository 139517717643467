import React from 'react'
import { Col, Nav, NavItem, NavLink } from 'reactstrap'
import { Href } from '../../../Utils/Constants'
import SvgIcon from '../../../Utils/CommonComponents/CommonIcons/CommonSvgIcons'
import { H6, P } from '../../../AbstractElements'

interface LeadFormNavProps {
    steps: string;
    setSteps: (step: string) => void;
}

export const addPLeadNav = [
    {
        id: 'lead-upload',
        icon: 'product-detail',
        title: 'Upload CSV',
        subTitle: 'Upload CSV file to add lead'
    },
    {
        id: 'lead-distribution',
        icon: 'product-category',
        title: 'Lead Assignment',
        subTitle: 'Select Lead Assignee'
    }
];

export default function LeadFormNav({ steps, setSteps }: LeadFormNavProps) {
    return (
        <Col xxl={3} xl={4} className="box-col-4e sidebar-left-wrapper">
            <Nav className="sidebar-left-icons" pills>
                {addPLeadNav.map((data: any) => (
                    <NavItem key={data.id}>
                        <NavLink className={`${steps === data.id ? "active" : ''}`} 
                            href={Href} 
                            // onClick={() => setSteps(data.id)}
                        >
                            <div className="nav-rounded">
                                <div className="product-icons">
                                    <SvgIcon className="stroke-icon" iconId={data.icon} />
                                </div>
                            </div>
                            <div className="product-tab-content">
                                <H6>{data.title}</H6>
                                <P>{data.subTitle}</P>
                            </div>
                        </NavLink>
                    </NavItem>
                ))}
            </Nav>
        </Col>
    )
}

