import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import SignInForm from './SignInForm'
import { Image } from '../../AbstractElements';
import { dynamicImage } from '../../Utils';

export default function SignIn() {
    return (
        <Container className="p-0" fluid>
            {/* <Row>
                <Col xl={6} className="b-center sm-size p-0 center-block">
                    <Image className="img-fluid for-light loginImageBg3" src={dynamicImage(`logo/app-logo.jpeg`)} alt="looginpage" />
                </Col>
                <Col xl={6} className="p-0">
                    <div className="login-card login-dark">
                        <SignInForm />
                    </div>
                </Col>
            </Row> */}
            <Row className="m-0">
                <Col xs={12} className="p-0">
                    <div className="login-card login-dark">
                        <SignInForm />
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
