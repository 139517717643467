import HotelCreate from "../../Components/AdminDashboard/Hotels/HotelCreate"

const HotelCreatePage = () => {
    return (
        <div className='page-body main-project'>
            <HotelCreate />
        </div>
    )
}

export default HotelCreatePage