import { Row, Col, Card, CardBody, Container } from 'reactstrap';
import { StrCreateHotel } from '../../../Utils/Constants';
import CommonCardHeader from '../../../Utils/CommonComponents/CommonCardHeader';
import HotelForm from './HotelForm';

const simpleTabSubTitle = [
    {
        text: 'Enter / Select',
        code: 'Hotel'
    },
    {
        text: ' details to  ',
        code: 'create '
    }
];

export default function HotelCreate(): JSX.Element {
    return (
        <Container fluid>
            <Row>
                <Col sm={12} xl={12}>
                    <Card className='title-line'>
                        <CommonCardHeader title={StrCreateHotel} subTitle={simpleTabSubTitle} />
                        <CardBody>
                            <HotelForm />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}
