import React, { FormEvent, useEffect, useState } from 'react'
import { Col, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Btn, H2, H6, Image, P } from '../../AbstractElements';
import { dynamicImage, dynamicSvg } from '../../Utils';
import { CreateAccount, DontHaveAccount, UserName, ForgotPassword, Password, RememberPassword, SignIn, SignInAccount, SignInWith } from '../../Utils/Constants';
import SocialLink from './SocialLink';
import { resetLogin, userLogin } from '../../ReduxToolkit/Reducers/Layout/Auth/LoginAction';
import { useReduxDispatch, useReduxSelector } from '../../ReduxToolkit/Store';
import AppLogo from '../../Components/Common/AppLogo';
import Loader from '../../Layout/Loader';

const LoginFormSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Paswword is required'),
}).required()

export default function SignInForm() {
    const navigate = useNavigate();
    const dispatch = useReduxDispatch();
    const { data, isError, isSuccess, isLoading } = useReduxSelector(state => state.loginReducer);
    const [isPasswordVisible, setPasswordVisible] = useState(false);
    const toggle = () => setPasswordVisible(!isPasswordVisible);
    
    const handleSubmit = (request: any) => {
        const payload = {
            username: request.username,
            password: request.password,
        };
        dispatch(userLogin(payload));
    };

    useEffect(() => {
        return () => {
            dispatch(resetLogin());
        };
    }, [])

    useEffect(() => {
        if (isSuccess) {
            toast.success("Login Success...!");
            navigate(`/dashboard`);
            localStorage.setItem("login", JSON.stringify(true));
            localStorage.setItem("jwtToken", JSON.stringify(data.data));
        }
    }, [isSuccess])
    
    useEffect(() => {
        if (isError) {
            toast.error(data.message);
        }
    }, [isError])

    return (
        <div>
            <AppLogo />
            <Loader show={isLoading} />
            <div className="login-main">
                <Formik
                    initialValues={{
                        username: '',
                        password: ''
                    }}
                    validationSchema={LoginFormSchema}
                    onSubmit={values => {
                        handleSubmit(values);
                    }}
                >
                    {({ errors, touched, setFieldValue, values }) => (
                        <Form className="theme-form">
                            <H2>{SignInAccount}</H2>
                            <P>{"Enter your username & password to login"}</P>
                            <FormGroup>
                                <Col><Label>{UserName}</Label></Col>
                                <Input type="text" tag={Field} placeholder={UserName} name="username" value={values.username} invalid={ touched.username && errors.username ? true : false } />
                                {touched.username && errors.username && <FormFeedback>{errors.username}</FormFeedback>}
                            </FormGroup>
                            <FormGroup>
                                <Col><Label>{Password}</Label></Col>
                                <div className="form-input position-relative">
                                    <Input type={isPasswordVisible ? 'text' : 'password'} name="password" placeholder={Password} onChange={(e) => setFieldValue('password', e.target.value)} invalid={ touched.password && errors.password ? true : false } />
                                    <div className='show-hide' onClick={toggle}>
                                        <span className={!isPasswordVisible ? 'show' : ''}></span>
                                    </div>
                                    {touched.password && errors.password && <FormFeedback>{errors.password}</FormFeedback>}
                                </div>
                            </FormGroup>
                            <div className="mb-0 form-group">
                                <div className="checkbox p-0">
                                    <Input id="checkbox1" type="checkbox" />
                                    <Label className="text-muted" htmlFor="checkbox1">{RememberPassword}</Label>
                                </div>
                                <Link className="link" to={`/forgot-password`}>{ForgotPassword}</Link>
                                <div className="text-end mt-3">
                                    <Btn color='primary' className="w-100" block disabled={isLoading}>{SignIn}</Btn>
                                </div>
                            </div>
                            {/* <H6 className="text-muted mt-4 or">{SignInWith}</H6> */}
                            {/* <SocialLink /> */}
                            {/* <P className="mt-4 mb-0 text-center">{DontHaveAccount}
                                <Link className="ms-2" to={`/signup`}>{CreateAccount}</Link>
                            </P> */}
                        </Form>
                    )}
                </Formik>
            </div>
        </div >
    )
}
