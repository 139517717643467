import React, { FormEvent, useEffect, useState } from 'react'
import { Container, Col, Row, FormGroup, Input, Label, FormText, FormFeedback } from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Country as CountryList, State as StateList, City as CityList }  from 'country-state-city';
import { Btn, H2, H6, Image, P } from '../../AbstractElements';
import { dynamicImage, dynamicSvg } from '../../Utils';
import {
    Password, RememberPassword, SignIn, SignInWith, SignUpAccount, Email, FirstName, LastName, PhoneNumber, Gender, SignUp, AlreadyHaveAccount, Country, State, City
} from '../../Utils/Constants';
import { userSignupAction, validateUserInvitaionAction } from '../../ReduxToolkit/Reducers/Layout/Auth/LoginAction';
import { InviteDetailType } from '../../Types/InterfaceData.type';
import AppLogo from '../../Components/Common/AppLogo';
import Loader from '../../Layout/Loader';

enum GenderEnum {
    female = "female",
    male = "male",
    other = "other",
}

interface RegisterFormInput {
    email: string,
    firstName: string,
    lastName: string,
    phoneNumber: number,
    password: string,
    gender: GenderEnum
    country: string,
    state: string,
    city: string,
}
const phoneRegExp = /^[6-9]{1}[0-9]{9}$/
const RegisterFormSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    // email: Yup.string().email('Email must be a valid email').required('Email is required'),
    phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Phone number is required'),
    password: Yup.string().required('Paswword is required'),
    gender: Yup.string().required('Gender is required'),
    // country: Yup.string().required('Country is required'),
    // state: Yup.string().required('State is required'),
    // city: Yup.string().required('City is required'),
}).required()

export default function SignUpForm() {
    const navigate = useNavigate();
    const [showLoader, setShowLoader] = useState(false);
    const [ searchParams ] = useSearchParams();
    const [ countriesArr ] = useState(CountryList.getCountryByCode('IN'));
    const [ stateArr ] = useState(StateList.getStatesOfCountry('IN'));
    const [ seletedState, setSelectedState ] = useState('');
    const [ cityArr, setCityArr ] = useState<any>([]);
    const [ inviteDetails, setInviteDetails ] = useState<InviteDetailType>();
    const [isPasswordVisible, setPasswordVisible] = useState(false);
    const toggle = () => setPasswordVisible(!isPasswordVisible);

    useEffect(() => {
        setShowLoader(true);
        const response = validateUserInvitaionAction({
            requestId: searchParams.get('requestId')
        });
        response.then((result: any) => {
            setShowLoader(false);
            if(result.status === 200) {
                setInviteDetails(result.data);
            } else {
                toast.error(result.message);
                navigate('/login');
            }
        });
    }, [])

    useEffect(() => {
        if(seletedState) {
            const arr = CityList.getCitiesOfState('IN', seletedState);
            setCityArr(arr);
        }
    }, [seletedState])

    const handleSubmit = (request: any) => {
        setShowLoader(true);
        const payload = {
            firstName: request.firstName,
            lastName: request.lastName,
            email: request.email,
            username: request.email,
            phoneNumber: request.phoneNumber,
            password: request.password,
            gender: request.gender,
            country: request.country,
            state: request.state,
            city: request.city
        };
        const response = userSignupAction(payload);
        response.then((result: any) => {
            setShowLoader(false);
            if(result.status === 200) {
                toast.error(result.message);
                navigate('/login');
            } else {
                toast.error(result.message);
            }
        });
    };

    return (
        <Container fluid>
            <Loader show={showLoader} />
            <AppLogo />
            <div className="login-main">
                <Formik
                    initialValues={{
                        firstName: '',
                        lastName: '',
                        email: inviteDetails?.email,
                        phoneNumber: '',
                        password: '',
                        gender: 'male',
                        country: 'IN',
                        state: '',
                        city: ''
                    }}
                    enableReinitialize={true}
                    validationSchema={RegisterFormSchema}
                    onSubmit={values => {
                        handleSubmit(values);
                        // same shape as initial values
                        // console.log(values);
                    }}
                >
                    {({ errors, touched, setFieldValue, values }) => (
                        <Form className="theme-form">
                            <H2>{SignUpAccount}</H2>
                            <P>{"Enter your details to signup"}</P>
                            <Row className="g-2">
                                <Col sm={6}>
                                    <FormGroup>
                                        <Col><Label>{FirstName}</Label></Col>
                                        <Input type="text" placeholder={FirstName} name="firstName" tag={Field} value={values.firstName} invalid={ touched.firstName && errors.firstName ? true : false } />
                                        {touched.firstName && errors.firstName && <FormFeedback>{errors.firstName}</FormFeedback>}
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Col><Label>{LastName}</Label></Col>
                                        <Input type="text" placeholder={LastName} name="lastName" tag={Field} value={values.lastName} invalid={ touched.lastName && errors.lastName ? true : false } />
                                        {touched.lastName && errors.lastName && <FormFeedback>{errors.lastName}</FormFeedback>}
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Col><Label>{Email}</Label></Col>
                                        <Input type="email" placeholder={Email} name="email" tag={Field} value={values.email} invalid={ touched.email && errors.email ? true : false } disabled readOnly />
                                        {touched.email && errors.email && <FormFeedback>{errors.email}</FormFeedback>}
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Col><Label>{PhoneNumber}</Label></Col>
                                        <Input type="number" placeholder={PhoneNumber} name="phoneNumber" tag={Field} value={values.phoneNumber} invalid={ touched.phoneNumber && errors.phoneNumber ? true : false } />
                                        {touched.phoneNumber && errors.phoneNumber && <FormFeedback>{errors.phoneNumber}</FormFeedback>}
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Col><Label>{Password}</Label></Col>
                                        <div className="form-input position-relative">
                                            <Input type={isPasswordVisible ? 'text' : 'password'} placeholder={Password} name='password' tag={Field} invalid={ touched.password && errors.password ? true : false } />
                                            <div className='show-hide' onClick={toggle}>
                                                <span className={!isPasswordVisible ? 'show' : ''}></span>
                                            </div>
                                            {touched.password && errors.password && <FormFeedback>{errors.password}</FormFeedback>}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <Col><Label>{Gender}</Label></Col>
                                    <Field name="gender" render={(field: any) => <Input onChange={(e) => setFieldValue('gender', e.target.value)} type="select" {...field} value={values.gender} invalid={ touched.gender && errors.gender ? true : false }>
                                        <option value='' disabled>{'Choose...'}</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                        <option value="other">Other</option>
                                    </Input> } />
                                    {touched.gender && errors.gender && <FormFeedback>{errors.gender}</FormFeedback>}
                                </Col>
                                <Col sm={12}>
                                    <Col><Label>{Country}</Label></Col>
                                    <Field name="country" render={(field: any) => <Input onChange={(e) => setFieldValue('country', e.target.value)} type="select" {...field} value={values.country} invalid={ touched.country && errors.country ? true : false }>
                                        <option value=''>{'Choose...'}</option>
                                        { countriesArr && Object.keys(countriesArr).length && <option value={countriesArr.isoCode}>{countriesArr.name}</option> }
                                    </Input> } />
                                    {touched.country && errors.country && <FormFeedback>{errors.country}</FormFeedback>}
                                </Col>
                                <Col sm={6}>
                                    <Col><Label>{State}</Label></Col>
                                    <Field name="state" render={(field: any) => <Input onChange={(e) => { setSelectedState(e.target.value); setFieldValue('state', e.target.value) }} type="select" {...field} value={values.state} invalid={ touched.state && errors.state ? true : false }>
                                        <option id='' value=''>{'Choose...'}</option>
                                        { stateArr.length > 0 && stateArr.map((stateObj) => {
                                            return <option value={stateObj.isoCode}>{stateObj.name}</option>
                                        })}
                                    </Input> } />
                                    {touched.state && errors.state && <FormFeedback>{errors.state}</FormFeedback>}
                                </Col>
                                <Col sm={6}>
                                    <Col><Label>{City}</Label></Col>
                                    <Field name="city" render={(field: any) => <Input onChange={(e) => setFieldValue('city', e.target.value)} type="select" {...field} value={values.city} invalid={ touched.city && errors.city ? true : false }>
                                        <option value=''>{'Choose...'}</option>
                                        { cityArr.length > 0 && cityArr.map((cityObj: any) => {
                                            return <option value={cityObj.isoCode}>{cityObj.name}</option>
                                        })}
                                    </Input> } />
                                    {touched.city && errors.city && <FormFeedback>{errors.city}</FormFeedback>}
                                </Col>
                            </Row>
                            <div className="mb-0 form-group">
                                <div className="text-end mt-3">
                                    <Btn color='primary' type='submit' className="w-100" block>{SignUp}</Btn>
                                </div>
                            </div>
                            <P className="mt-4 mb-0 text-center">{AlreadyHaveAccount}
                                <Link className="ms-2" to={`/login`}>{SignIn}</Link>
                            </P>
                        </Form>
                    )}
                </Formik>
            </div>
        </Container>
    )
}
