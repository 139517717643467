import React from 'react'
import { Card, CardBody, CardHeader, Col } from 'reactstrap'
import { Badges, H2, H5 } from '../../AbstractElements'
import { Emptysearch, Href, TeamOverviewHeader } from '../../Utils/Constants'
import SvgIcon from '../../Utils/CommonComponents/CommonIcons/CommonSvgIcons'
import { useNavigate } from 'react-router-dom'
import CommonTable from '../Common/CommonTable'
import { AdminDashboardTeamOverview, InvitiesDashboardHeader } from '../../Data/Table/TableHeader'
import { formatDate } from '../../helpers'

export default function TeamOverview(props: any) {
    const navigate = useNavigate();
    const { listData } = props;

    return (
        <Col xl={6}>
            <Card className="height-equal title-line overflow-hidden">
                <CardHeader className="card-no-border">
                    <div className="header-top">
                        <H2>{TeamOverviewHeader}</H2>
                        <div className="card-header-right-icon">
                            <a className="link-with-icon" href={'#'} onClick={() => navigate(`/workspace`)}>{'View all'}
                                <SvgIcon iconId='arrow-two-tone' />
                            </a>
                        </div>
                    </div>
                </CardHeader>
                <CardBody className="project-table p-0">
                <div className="table-responsive custom-scrollbar">
                        <CommonTable strip={true} hover={true} headData={AdminDashboardTeamOverview} headClass='tbl-strip-thad-bdr' headRowClass='border-bottom-primary'>
                            {listData && listData.length ? listData.map((tbl: any) => (
                                <tr key={tbl.id} className='border-bottom-'>
                                    <th scope="row">{tbl.workspaces[0].workSpaceName}</th>
                                    <td>{tbl.email}</td>
                                    <td>{tbl.phoneNumber}</td>
                                    <td>{tbl.firstName}</td>
                                    <td>{tbl.lastName}</td>
                                    <td>
                                        <Badges color={tbl.workspaces[0].paymentStatus === 'Completed' ? 'success' : 'danger'}>{tbl.workspaces[0].paymentStatus}</Badges>
                                    </td>
                                    <td>
                                        <Badges color={tbl.workspaces[0].status === 'Active' ? 'success' : 'danger'}>{tbl.workspaces[0].status}</Badges>
                                    </td>
                                    <td>{formatDate(tbl.workspaces[0].startDate)}</td>
                                    <td>{formatDate(tbl.workspaces[0].endDate)}</td>
                                </tr>
                            ))
                            :
                            <H5 className="badge-heading">
                                <Badges color='secondary' className='m-20'>{Emptysearch}</Badges>
                            </H5>
                        }
                        </CommonTable>
                    </div>
                </CardBody>
            </Card>
        </Col>
    )
}
