import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // we init with resources
        resources: {
            en: {
                translations: {
                    "General": "General",
                    "Dashboard": "Dashboard",
                    "Default": "Default",
                    "Project": "Project",
                    "Ecommerce": "Ecommerce",
                    "Education": "Education",
                    "Widgets": "Widgets",
                    "Chart": "Chart",
                    "Applications": "Applications",
                    "Project List": "Project List",
                    "Create New": "Create New",
                    "File Manager": "File Manager",
                    "Add Product": "Add Product",
                    "Product": "Product",
                    "Product Page": "Product Page",
                    "Product List": "Product List",
                    "Payment Details": "Payment Details",
                    "Order History": "Order History",
                    "Invoice": "Invoice",
                    "Invoice-1": "Invoice-1",
                    "Invoice-2": "Invoice-2",
                    "Invoice-3": "Invoice-3",
                    "Invoice-4": "Invoice-4",
                    "Invoice-5": "Invoice-5",
                    "Invoice-6": "Invoice-6",
                    "Cart": "Cart",
                    "Wishlist": "Wishlist",
                    "Checkout": "Checkout",
                    "Pricing": "Pricing",
                    "Chats": "Chats",
                    "Users": "Users",
                    "Letter Box": "Letter Box",
                    "Private Chat": "Private Chat",
                    "Group Chat": "Group Chat",
                    "User Profile": "User Profile",
                    "User Edit": "User Edit",
                    "User Cards": "User Cards",
                    "Bookmarks": "Bookmarks",
                    "Contacts": "Contacts",
                    "Tasks": "Tasks",
                    "Calendar": "Calendar",
                    "Social App": "Social App",
                    "To-Do": "To-Do",
                    "Search Result": "Search Result",
                    "Form Validation": "Form Validation",
                    "Base Inputs": "Base Inputs",
                    "Checkbox & Radio": "Checkbox & Radio",
                    "Input Groups": "Input Groups",
                    "Input Mask": "Input Mask",
                    "Mega Options": "Mega Option",
                    "Datepicker": "Datepicker",
                    "Touchspin": "Touchspin",
                    "Switch": "Switch",
                    "Forms": "Forms",
                    "Form Controls": "Form Controls",
                    "Forms & Table": "Forms & Table",
                    "Typeahead": "Typeahead",
                    "Clipboard": "Clipboard",
                    "Form Widgets": "Form Widgets",
                    "Form Wizard 1": "Form Wizard 1",
                    "Form Wizard 2": "Form Wizard 2",
                    "Two Factor": "Two Factor",
                    "Form Layout": "Form Layout",
                    "Tables": "Tables",
                    "Reactstrap Tables": "Reactstrap Tables",
                    "Basic Tables": "Basic Tables",
                    "Table Components": "Table Components",
                    "Data Tables": "Data Tables",
                    "Basic Init": "Basic Init",
                    "Advance Init": "Advance Init",
                    "API": "API",
                    "Data Sources": "Data Sources",
                    "Components": "Components",
                    "Typography": "Typography",
                    "Avatars": "Avatars",
                    "Helper Classes": "Helper Classes",
                    "Grid": "Grid",
                    "Tag & Pills": "Tag & Pills",
                    "Progress": "Progress",
                    "Modal": "Modal",
                    "Alert": "Alert",
                    "Popover": "Popover",
                    "Tooltip": "Tooltip",
                    "Dropdown": "Dropdown",
                    "Accordion": "Accordion",
                    "Tabs": "Tabs",
                    "Lists": "Lists",
                    "Bonus Ui": "Bonus Ui",
                    "Scrollable": "Scrollable",
                    "Tree View": "Tree View",
                    "Toasts": "Toasts",
                    "Rating": "Rating",
                    "Dropzone": "Dropzone",
                    "Tour": "Tour",
                    "SweetAlert2": "SweetAlert2",
                    "Owl Carousel": "Owl Carousel",
                    "Ribbons": "Ribbons",
                    "Pagination": "Pagination",
                    "Breadcrumb": "Breadcrumb",
                    "Range Slider": "Range Slider",
                    "Image Cropper": "Image Cropper",
                    "Basic Card": "Basic Card",
                    "Creative Card": "Creative Card",
                    "Timeline": "Timeline",
                    "Icons": "Icons",
                    "Flag Icon": "Flag Icon",
                    "Font Awesome Icon": "Font Awesome Icon",
                    "Ico Icon": "Ico Icon",
                    "Themify Icon": "Themify Icon",
                    "Feather Icon": "Feather Icon",
                    "Weather Icon": "Weather Icon",
                    "Buttons": "Buttons",
                    "Charts": "Charts",
                    "Apex Chart": "Apex Chart",
                    "Google Chart": "Google Chart",
                    "Chartjs Chart": "Chartjs Chart",
                    "Pages": "Pages",
                    "Sample Page": "Sample Page",
                    "Others": "Others",
                    "Error Pages": "Error Pages",
                    "Error 400": "Error 400",
                    "Error 401": "Error 401",
                    "Error 403": "Error 403",
                    "Error 404": "Error 404",
                    "Error 500": "Error 500",
                    "Error 503": "Error 503",
                    "Login Simple": "Login Simple",
                    "Visual Login": "Visual Login",
                    "Visual Login 2": "Visual Login 2",
                    "Validate Login": "Validate Login",
                    "Tooltip Login": "Tooltip Login",
                    "Alert Login": "Alert Login",
                    "Register": "Register",
                    "Register Image": "Register Image",
                    "Visual Reg 2": "Register With Bg Two",
                    "Reg Wizard": "Reg Wizard",
                    "Unlock User": "Unlock User",
                    "Pwd Forget": "Pwd Forget",
                    "Pwd Reset": "Pwd Reset",
                    "Maintenance": "Maintenance",
                    "Coming Simple": "Coming Simple",
                    "Coming Bg Vid": "Coming Bg Vid",
                    "Coming Bg Img": "Coming Bg Img",
                    "Miscellaneous": "Miscellaneous",
                    "Gallery": "Gallery",
                    "Gallery Grid": "Gallery Grid",
                    "Gallery Grid Desc": "Gallery Grid Desc",
                    "Masonry Gallery": "Masonry Gallery",
                    "Masonry With Desc": "Masonry With Desc",
                    "Hover Effects": "Hover Effects",
                    "Blog": "Blog",
                    "Blog Details": "Blog Details",
                    "Blog Single": "Blog Single",
                    "Add Post": "Add Post",
                    "FAQ": "FAQ",
                    "Job Search": "Job Search",
                    "Cards View": "Cards View",
                    "List View": "List View",
                    "Job Detail": "Job Detail",
                    "Apply": "Apply",
                    "Learning": "Learning",
                    "Learning List": "Learning List",
                    "Detailed Course": "Detailed Course",
                    "Maps": "Maps",
                    "Google Maps": "Google Maps",
                    "Leaflet Maps": "Leaflet Maps",
                    "Editors": "Editors",
                    "Quill Editor": "Quill Editor",
                    "ACE Editor": "ACE Editor",
                    "Knowledgebase": "Knowledgebase",
                    "Support Ticket": "Support Ticket",
                    "Authentication": "Authentication",
                    "Login With Bg Image": "Login With Bg Image",
                    "Coming Soon": "Coming Soon",
                    "Pinned": "Pinned",
                    "Ui Kits": "Ui Kits"
                },
            },
            cn: {
                translations: {
                    "General": "通用",
                    "Dashboard": "仪表板",
                    "Default": "默认",
                    "Project": "项目",
                    "Ecommerce": "电子商务",
                    "Education": "教育",
                    "Widgets": "小部件",
                    "Chart": "图表",
                    "Applications": "应用程序",
                    "Project List": "项目列表",
                    "Create New": "创建新",
                    "File Manager": "文件管理器",
                    "Add Product": "添加产品",
                    "Product": "产品",
                    "Product Page": "产品页面",
                    "Product List": "产品列表",
                    "Payment Details": "付款详情",
                    "Order History": "订单历史",
                    "Invoice": "发票",
                    "Invoice-1": "发票-1",
                    "Invoice-2": "发票-2",
                    "Invoice-3": "发票-3",
                    "Invoice-4": "发票-4",
                    "Invoice-5": "发票-5",
                    "Invoice-6": "发票-6",
                    "Cart": "购物车",
                    "Wishlist": "愿望清单",
                    "Checkout": "结账",
                    "Pricing": "定价",
                    "Chats": "聊天",
                    "Users": "用户",
                    "Letter Box": "邮箱",
                    "Private Chat": "私聊",
                    "Group Chat": "群聊",
                    "User Profile": "用户资料",
                    "User Edit": "编辑用户",
                    "User Cards": "用户卡片",
                    "Bookmarks": "书签",
                    "Contacts": "联系人",
                    "Tasks": "任务",
                    "Calendar": "日历",
                    "Social App": "社交应用",
                    "To-Do": "待办事项",
                    "Search Result": "搜索结果",
                    "Form Validation": "表单验证",
                    "Base Inputs": "基本输入",
                    "Checkbox & Radio": "复选框和单选按钮",
                    "Input Groups": "输入组",
                    "Input Mask": "输入掩码",
                    "Mega Options": "超级选项",
                    "Datepicker": "日期选择器",
                    "Touchspin": "Touchspin",
                    "Switch": "开关",
                    "Forms": "表单",
                    "Form Controls": "表单控件",
                    "Forms & Table": "表单和表格",
                    "Typeahead": "类型提示",
                    "Clipboard": "剪贴板",
                    "Form Widgets": "表单小部件",
                    "Form Wizard 1": "表单向导1",
                    "Form Wizard 2": "表单向导2",
                    "Two Factor": "双因素认证",
                    "Form Layout": "表单布局",
                    "Tables": "表格",
                    "Reactstrap Tables": "Reactstrap 表格",
                    "Basic Tables": "基本表格",
                    "Table Components": "表格组件",
                    "Data Tables": "数据表",
                    "Basic Init": "基本初始化",
                    "Advance Init": "高级初始化",
                    "API": "API",
                    "Data Source": "数据源",
                    "Components": "组件",
                    "Typography": "排版",
                    "Avatars": "头像",
                    "Helper Classes": "辅助类",
                    "Grid": "网格",
                    "Tag & Pills": "标签和药丸",
                    "Progress": "进度",
                    "Modal": "模态框",
                    "Alert": "警告",
                    "Popover": "弹出框",
                    "Tooltip": "工具提示",
                    "Dropdown": "下拉菜单",
                    "Accordion": "手风琴",
                    "Tabs": "选项卡",
                    "Lists": "列表",
                    "Bonus Ui": "附加界面",
                    "Scrollable": "可滚动",
                    "Tree View": "树视图",
                    "Toasts": "消息提醒",
                    "Rating": "评分",
                    "Dropzone": "文件上传",
                    "Tour": "新手导览",
                    "SweetAlert2": "SweetAlert2",
                    "Owl Carousel": "Owl 走马灯",
                    "Ribbons": "带子",
                    "Pagination": "分页",
                    "Breadcrumb": "面包屑",
                    "Range Slider": "范围滑块",
                    "Image Cropper": "图片裁剪",
                    "Basic Card": "基本卡片",
                    "Creative Card": "创意卡片",
                    "Timeline": "时间线",
                    "Icons": "图标",
                    "Flag Icon": "国旗图标",
                    "Fontawesome Icon": "Font Awesome 图标",
                    "Ico Icon": "Ico 图标",
                    "Themify Icon": "Themify 图标",
                    "Feather Icon": "Feather 图标",
                    "Weather Icon": "天气图标",
                    "Buttons": "按钮",
                    "Charts": "图表",
                    "Apex Chart": "Apex 图表",
                    "Google Chart": "谷歌图表",
                    "Chartjs Chart": "Chartjs 图表",
                    "Pages": "页面",
                    "Sample Page": "示例页面",
                    "Others": "其他",
                    "Error Pages": "错误页面",
                    "Error 400": "错误 400",
                    "Error 401": "错误 401",
                    "Error 403": "错误 403",
                    "Error 404": "错误 404",
                    "Error 500": "错误 500",
                    "Error 503": "错误 503",
                    "Login Simple": "简单登录",
                    "Login with Bg Image": "带背景图片的登录",
                    "Login With Image Two": "带两张图片的登录",
                    "Login With Validation": "带验证的登录",
                    "Login With Tooltip": "带提示的登录",
                    "Login With Sweetalert": "带 SweetAlert 的登录",
                    "Register Simple": "简单注册",
                    "Register With Bg Image": "带背景图片的注册",
                    "Register With Bg Two": "带背景图片的注册",
                    "Register Wizard": "注册向导",
                    "Unlock User": "解锁用户",
                    "Forget Password": "忘记密码",
                    "Reset Password": "重设密码",
                    "Maintenance": "维护",
                    "Coming Simple": "即将上线",
                    "Coming With Bg Video": "带背景视频的即将上线",
                    "Coming With Bg Image": "带背景图片的即将上线",
                    "Miscellaneous": "其他",
                    "Gallery": "图库",
                    "Gallery Grids": "图库网格",
                    "Gallery Grid Desc": "图库网格描述",
                    "Masonry Gallery": "砖瓦图库",
                    "Masonry With Desc": "砖瓦图库描述",
                    "Hover Effect": "悬停效果",
                    "Blog": "博客",
                    "Blog Details": "博客详情",
                    "Blog Single": "单篇博客",
                    "Add Post": "添加文章",
                    "FAQ": "常见问题",
                    "Job Search": "职位搜索",
                    "Cards View": "卡片视图",
                    "List View": "列表视图",
                    "Job Detail": "职位详情",
                    "Apply": "申请",
                    "Learning": "学习",
                    "Learning List": "学习列表",
                    "Detailed Course": "详细课程",
                    "Maps": "地图",
                    "Google Map": "谷歌地图",
                    "Leaflet Map": "Leaflet 地图",
                    "Editors": "编辑器",
                    "Quill Editor": "Quill 编辑器",
                    "ACE Editor": "ACE 编辑器",
                    "Knowledgebase": "知识库",
                    "Ui-Kits": "界面套件",
                    "Support Ticket": "支持工单",
                    "Authentication": "身份验证",
                    "Login With Bg Image": "带背景图像的登录",
                    "Pinned": "已固定",
                    "Coming Soon": "即将上线"
                },
            },
            ae: {
                translations: {
                    "General": "عامة",
                    "Dashboard": "لوحات القيادة",
                    "Default": "افتراضي",
                    "Project": "مشروع",
                    "Ecommerce": "التجارة الإلكترونية",
                    "Education": "التعليم",
                    "Widgets": "حاجيات",
                    "Chart": "رسم بياني",
                    "Applications": "تطبيقات",
                    "Project List": "قائمة المشروع",
                    "Create New": "إنشاء جديد",
                    "File Manager": "مدير الملفات",
                    "Add Product": "إضافة منتج",
                    "Product": "منتج",
                    "Product Page": "صفحة المنتج",
                    "Product List": "قائمة المنتج",
                    "Payment Details": "تفاصيل الدفع",
                    "Order History": "تاريخ الطلب",
                    "Invoice": "فاتورة",
                    "Invoice-1": "فاتورة-1",
                    "Invoice-2": "فاتورة-2",
                    "Invoice-3": "فاتورة-3",
                    "Invoice-4": "فاتورة-4",
                    "Invoice-5": "فاتورة-5",
                    "Invoice-6": "فاتورة-6",
                    "Cart": "عربة التسوق",
                    "Wishlist": "قائمة الرغبات",
                    "Checkout": "السداد",
                    "Pricing": "التسعير",
                    "Chats": "دردشة",
                    "Users": "المستخدمين",
                    "Letter Box": "صندوق البريد",
                    "Private Chat": "الدردشة الخاصة",
                    "Group Chat": "الدردشة الجماعية",
                    "User Profile": "ملف المستخدم",
                    "User Edit": "تحرير المستخدم",
                    "User Cards": "بطاقات المستخدم",
                    "Bookmarks": "الإشارات المرجعية",
                    "Contacts": "جهات الاتصال",
                    "Tasks": "المهمة",
                    "Calendar": "التقويم",
                    "Social App": "تطبيق اجتماعي",
                    "To-Do": "قائمة المهام",
                    "Search Result": "نتائج البحث",
                    "Form Validation": "التحقق من النموذج",
                    "Base Inputs": "المدخلات الأساسية",
                    "Checkbox & Radio": "خانات الاختيار والراديو",
                    "Input Groups": "مجموعات المدخلات",
                    "Input Mask": "قناع المدخلات",
                    "Mega Options": "خيار ميجا",
                    "Datepicker": "منتقي التاريخ",
                    "Touchspin": "اللمس الدوار",
                    "Switch": "التبديل",
                    "Forms": "النماذج",
                    "Form Controls": "عناصر التحكم في النموذج",
                    "Forms & Table": "النماذج والجداول",
                    "Typeahead": "التنبؤ التلقائي",
                    "Clipboard": "الحافظة",
                    "Form Widgets": "عنصر واجهة النموذج",
                    "Form Wizard 1": "معالج النموذج 1",
                    "Form Wizard 2": "معالج النموذج 2",
                    "Two Factor": "العاملين بعاملين",
                    "Form Layout": "تخطيط النموذج",
                    "Tables": "الجدول",
                    "Reactstrap Tables": "جداول Reactstrap",
                    "Basic Tables": "الجداول الأساسية",
                    "Table Components": "مكونات الجدول",
                    "Data Tables": "جداول البيانات",
                    "Basic Init": "البدء الأساسي",
                    "Advance Init": "البدء المتقدم",
                    "API": "واجهة برمجة التطبيقات",
                    "Data Source": "مصدر البيانات",
                    "Components": "المكونات",
                    "Typography": "الأسلوب الطباعي",
                    "Avatars": "الصور الرمزية",
                    "Helper Classes": "فصول المساعدة",
                    "Grid": "الشبكة",
                    "Tag & Pills": "العلامة والأقراص",
                    "Progress": "التقدم",
                    "Modal": "نافذة منبثقة",
                    "Alert": "تنبيه",
                    "Popover": "البوب أوفر",
                    "Tooltip": "تلميح",
                    "Ui-Kits": "مجموعات واجهة المستخدم",
                    "Dropdown": "القائمة المنسدلة",
                    "Accordion": "الأكورديون",
                    "Tabs": "علامات التبويب",
                    "Lists": "القوائم",
                    "Bonus-Ui": "واجهة المكافآت",
                    "Scrollable": "قابل للتمرير",
                    "Tree View": "عرض الشجرة",
                    "Toasts": "التنبيهات",
                    "Rating": "التقييم",
                    "Dropzone": "منطقة الإفلات",
                    "Tour": "جولة",
                    "SweetAlert2": "التنبيه الحلو 2",
                    "Owl Carousel": "ساحة البوم البوم البوم",
                    "Ribbons": "الشرائط",
                    "Pagination": "ترقيم الصفحات",
                    "Breadcrumb": "فتات الخبز",
                    "Range Slider": "شريط النطاق",
                    "Image Cropper": "أداة القص للصور",
                    "Basic Card": "بطاقة أساسية",
                    "Creative Card": "بطاقة إبداعية",
                    "Timeline": "الجدول الزمني",
                    "Icons": "الأيقونات",
                    "Flag Icon": "أيقونة العلم",
                    "Fontawesome Icon": "أيقونة Fontawesome",
                    "Ico Icon": "أيقونة Ico",
                    "Themify Icon": "أيقونة Themify",
                    "Feather Icon": "أيقونة Feather",
                    "Weather Icon": "أيقونة الطقس",
                    "Buttons": "أزرار",
                    "Charts": "الرسوم البيانية",
                    "Apex Chart": "رسم بياني Apex",
                    "Google Chart": "رسم بياني Google",
                    "Chart JS Chart": "رسم بياني Chart JS",
                    "Pages": "الصفحات",
                    "Sample Page": "صفحة عينة",
                    "Others": "أخرى",
                    "Error Pages": "صفحات الخطأ",
                    "Error 400": "خطأ 400",
                    "Error 401": "خطأ 401",
                    "Error 403": "خطأ 403",
                    "Error 404": "خطأ 404",
                    "Error 500": "خطأ 500",
                    "Error 503": "خطأ 503",
                    "Login Simple": "تسجيل الدخول البسيط",
                    "Login with Bg Image": "تسجيل الدخول مع صورة الخلفية",
                    "Login With Image Two": "تسجيل الدخول مع صورتين",
                    "Login With Validation": "تسجيل الدخول مع التحقق",
                    "Login With Tooltip": "تسجيل الدخول مع تلميح",
                    "Login With Sweetalert": "تسجيل الدخول مع تنبيه Sweetalert",
                    "Register Simple": "التسجيل البسيط",
                    "Register With Bg Image": "التسجيل مع صورة الخلفية",
                    "Register With Bg Two": "التسجيل مع صورتين",
                    "Register Wizard": "معالج التسجيل",
                    "Unlock User": "فتح المستخدم",
                    "Forget Password": "نسيت كلمة المرور",
                    "Reset Password": "إعادة تعيين كلمة المرور",
                    "Maintenance": "الصيانة",
                    "Coming Simple": "قريبًا بسيط",
                    "Coming With Bg Video": "قريبًا مع فيديو الخلفية",
                    "Coming With Bg Image": "قريبًا مع صورة الخلفية",
                    "Miscellaneous": "متنوع",
                    "Gallery": "المعرض",
                    "Gallery Grids": "شبكات المعرض",
                    "Gallery Grid Desc": "وصف الشبكة في المعرض",
                    "Masonry Gallery": "معرض الطوب",
                    "Masonry With Desc": "مع الوصف",
                    "Hover Effect": "تأثير التحويم",
                    "Blog": "المدونة",
                    "Blog Details": "تفاصيل المدونة",
                    "Blog Single": "مدونة فردية",
                    "Add Post": "إضافة منشور",
                    "FAQ": "الأسئلة الشائعة",
                    "Job Search": "بحث عن وظيفة",
                    "Cards View": "عرض البطاقات",
                    "List View": "عرض القائمة",
                    "Job Detail": "تفاصيل الوظيفة",
                    "Apply": "تقديم الطلب",
                    "Learning": "التعلم",
                    "Learning List": "قائمة التعلم",
                    "Detailed Course": "دورة مفصلة",
                    "Maps": "الخريطة",
                    "Google Map": "الخريطة من Google",
                    "Leaflet Map": "الخريطة من Leaflet",
                    "Editors": "المحرر",
                    "Quill Editor": "محرر Quill",
                    "ACE Editor": "محرر ACE",
                    "Knowledgebase": "قاعدة المعرفة",
                    "SupportTicket": "تذكرة الدعم",
                    "Authentication": "المصادقة",
                    "Login With Bg Image": "تسجيل الدخول مع صورة خلفية",
                    "Pinned": "مثبت",
                    "Coming Soon": "قريبًا"
                },
            },
            du: {
                translations: {
                    "General": "Allgemein",
                    "Dashboard": "Dashboards",
                    "Default": "Standard",
                    "Project": "Projekt",
                    "Ecommerce": "E-Commerce",
                    "Education": "Bildung",
                    "Widgets": "Widgets",
                    "Chart": "Diagramm",
                    "Applications": "Anwendungen",
                    "Project List": "Projektliste",
                    "Create New": "Neu erstellen",
                    "File Manager": "Dateimanager",
                    "Add Product": "Produkt hinzufügen",
                    "Product": "Produkt",
                    "Product Page": "Produktseite",
                    "Product List": "Produktliste",
                    "Payment Details": "Zahlungsdetails",
                    "Order History": "Bestellverlauf",
                    "Invoice": "Rechnung",
                    "Invoice-1": "Rechnung-1",
                    "Invoice-2": "Rechnung-2",
                    "Invoice-3": "Rechnung-3",
                    "Invoice-4": "Rechnung-4",
                    "Invoice-5": "Rechnung-5",
                    "Invoice-6": "Rechnung-6",
                    "Cart": "Warenkorb",
                    "Wishlist": "Wunschliste",
                    "Checkout": "Kasse",
                    "Pricing": "Preisgestaltung",
                    "Chats": "Chats",
                    "Users": "Benutzer",
                    "Letter Box": "Briefkasten",
                    "Private Chat": "Privater Chat",
                    "Group Chat": "Gruppenchat",
                    "User Profile": "Benutzerprofil",
                    "User Edit": "Benutzer bearbeiten",
                    "User Cards": "Benutzerkarten",
                    "Bookmarks": "Lesezeichen",
                    "Contacts": "Kontakte",
                    "Tasks": "Aufgabe",
                    "Calendar": "Kalender",
                    "Social App": "Soziale App",
                    "To-Do": "To-Do",
                    "Search Result": "Suchergebnis",
                    "Form Validation": "Formularvalidierung",
                    "Base Inputs": "Grundlegende Eingaben",
                    "Checkbox & Radio": "Checkbox & Radio",
                    "Input Groups": "Eingabegruppen",
                    "Input Mask": "Eingabemaske",
                    "Mega Options": "Mega-Option",
                    "Datepicker": "Datumsauswahl",
                    "Touchspin": "Touchspin",
                    "Switch": "Schalter",
                    "Forms": "Formulare",
                    "Form Controls": "Formularelemente",
                    "Forms & Table": "Formulare & Tabelle",
                    "Typeahead": "Typsuche",
                    "Clipboard": "Zwischenablage",
                    "Form Widget": "Formularelement",
                    "Form Wizard 1": "Formular-Assistent 1",
                    "Form Wizard 2": "Formular-Assistent 2",
                    "Two Factor": "Zwei-Faktor",
                    "Form Layout": "Formularlayout",
                    "Tables": "Tabelle",
                    "Reactstrap Tables": "Reactstrap-Tabellen",
                    "Basic Tables": "Grundlegende Tabellen",
                    "Table Components": "Tabellenkomponenten",
                    "Data Tables": "Daten-Tabellen",
                    "Basic Init": "Grundlegende Initialisierung",
                    "Advance Init": "Erweiterte Initialisierung",
                    "API": "API",
                    "Data Source": "Datenquelle",
                    "Components": "Komponenten",
                    "Typography": "Typografie",
                    "Avatars": "Avatare",
                    "Helper Classes": "Hilfsklassen",
                    "Grid": "Raster",
                    "Tag & Pills": "Schlagwörter & Pillen",
                    "Progress": "Fortschritt",
                    "Modal": "Modales Fenster",
                    "Alert": "Benachrichtigung",
                    "Popover": "Popover",
                    "Tooltip": "Tooltip",
                    "Dropdown": "Dropdown",
                    "Accordion": "Akkordeon",
                    "Tabs": "Tabs",
                    "Lists": "Listen",
                    "Bonus-Ui": "Zusatz-UI",
                    "Scrollable": "Scrollbar",
                    "Tree View": "Baumansicht",
                    "Toasts": "Benachrichtigungen",
                    "Rating": "Bewertung",
                    "Dropzone": "Dateiablage",
                    "Tour": "Tour",
                    "SweetAlert2": "SweetAlert2",
                    "Owl Carousel": "Eulenkarussell",
                    "Ribbons": "Bänder",
                    "Pagination": "Seitennummerierung",
                    "Breadcrumb": "Brotkrumen",
                    "Range Slider": "Bereichsschieberegler",
                    "Image Cropper": "Bildzuschnitt",
                    "Basic Card": "Grundlegende Karte",
                    "Creative Card": "Kreative Karte",
                    "Timeline": "Zeitachse",
                    "Icons": "Symbole",
                    "Flag Icon": "Flaggensymbol",
                    "Fontawesome Icon": "Font Awesome Symbol",
                    "Ico Icon": "Ico Symbol",
                    "Themify Icon": "Themify Symbol",
                    "Feather Icon": "Feather Symbol",
                    "Weather Icon": "Wettersymbol",
                    "Buttons": "Schaltflächen",
                    "Charts": "Diagramme",
                    "Apex Chart": "Apex Diagramm",
                    "Ui Kits": "Benutzeroberflächen-Kits",
                    "Google Chart": "Google Diagramm",
                    "Chartjs Chart": "Chartjs Diagramm",
                    "Pages": "Seiten",
                    "Sample Page": "Beispielseite",
                    "Others": "Andere",
                    "Error Pages": "Fehlerseiten",
                    "Error 400": "Fehler 400",
                    "Error 401": "Fehler 401",
                    "Error 403": "Fehler 403",
                    "Error 404": "Fehler 404",
                    "Error 500": "Fehler 500",
                    "Error 503": "Fehler 503",
                    "Login Simple": "Einfaches Anmelden",
                    "Login with Bg Image": "Anmelden mit Hintergrundbild",
                    "Login With Image Two": "Anmelden mit zwei Bildern",
                    "Login With Validation": "Anmelden mit Validierung",
                    "Login With Tooltip": "Anmelden mit Tooltip",
                    "Login With Sweetalert": "Anmelden mit SweetAlert",
                    "Register Simple": "Einfache Registrierung",
                    "Register With Bg Image": "Registrieren mit Hintergrundbild",
                    "Register With Bg Two": "Registrieren mit zwei Bildern",
                    "Register Wizard": "Registrierungsassistent",
                    "Unlock User": "Benutzer entsperren",
                    "Forget Password": "Passwort vergessen",
                    "Reset Password": "Passwort zurücksetzen",
                    "Maintenance": "Wartung",
                    "Coming Simple": "Einfache Ankündigung",
                    "Coming With Bg Video": "Ankündigung mit Hintergrundvideo",
                    "Coming With Bg Image": "Ankündigung mit Hintergrundbild",
                    "Miscellaneous": "Sonstiges",
                    "Gallery": "Galerie",
                    "Gallery Grids": "Galerie Raster",
                    "Gallery Grid Desc": "Galerie Raster Beschreibung",
                    "Masonry Gallery": "Mauerwerk-Galerie",
                    "Masonry With Desc": "Mauerwerk mit Beschreibung",
                    "Hover Effect": "Hover-Effekt",
                    "Blog": "Blog",
                    "Blog Details": "Blogdetails",
                    "Blog Single": "Einzelner Blog",
                    "Add Post": "Beitrag hinzufügen",
                    "FAQ": "FAQ",
                    "Job Search": "Job-Suche",
                    "Cards View": "Kartenansicht",
                    "List View": "Listenansicht",
                    "Job Detail": "Jobdetails",
                    "Apply": "Bewerben",
                    "Learning": "Lernen",
                    "Learning List": "Lernliste",
                    "Detailed Course": "Detaillierter Kurs",
                    "Maps": "Karte",
                    "Google Map": "Google Karte",
                    "Leaflet Map": "Leaflet Karte",
                    "Editors": "Editors",
                    "Quill Editor": "Quill Editor",
                    "ACE Editor": "ACE Editor",
                    "Knowledgebase": "Wissensdatenbank",
                    "SupportTicket": "Support-Ticket",
                    "Authentication": "Authentifizierung",
                    "Login With Bg Image": "Anmeldung mit Hintergrundbild",
                    "Pinned": "Gepinnt",
                    "Coming Soon": "Demnächst"
                },
            },
            es: {
                translations: {
                    "General": "General",
                    "Dashboard": "Paneles",
                    "Default": "Predeterminado",
                    "Project": "Proyecto",
                    "Ecommerce": "Comercio Electrónico",
                    "Education": "Educación",
                    "Widgets": "Widgets",
                    "Chart": "Gráfico",
                    "Applications": "Aplicaciones",
                    "Project List": "Lista de Proyectos",
                    "Create New": "Crear Nuevo",
                    "File Manager": "Gestor de Archivos",
                    "Add Product": "Agregar Producto",
                    "Product": "Producto",
                    "Product Page": "Página de Producto",
                    "Product List": "Lista de Productos",
                    "Payment Details": "Detalles de Pago",
                    "Order History": "Historial de Pedidos",
                    "Invoice": "Factura",
                    "Invoice-1": "Factura-1",
                    "Invoice-2": "Factura-2",
                    "Invoice-3": "Factura-3",
                    "Invoice-4": "Factura-4",
                    "Invoice-5": "Factura-5",
                    "Invoice-6": "Factura-6",
                    "Cart": "Carrito",
                    "Wishlist": "Lista de Deseos",
                    "Checkout": "Pagar",
                    "Pricing": "Precios",
                    "Chats": "Chats",
                    "Users": "Usuarios",
                    "Letter Box": "Buzón de Correo",
                    "Private Chat": "Chat Privado",
                    "Group Chat": "Chat de Grupo",
                    "User Profile": "Perfil de Usuario",
                    "User Edit": "Editar Usuario",
                    "User Cards": "Tarjetas de Usuario",
                    "Bookmarks": "Marcadores",
                    "Contacts": "Contactos",
                    "Tasks": "Tarea",
                    "Calendar": "Calendario",
                    "Social App": "Aplicación Social",
                    "To-Do": "Tareas Pendientes",
                    "Search Result": "Resultado de Búsqueda",
                    "Form Validation": "Validación de Formulario",
                    "Base Inputs": "Entradas Básicas",
                    "Checkbox & Radio": "Casillas de Verificación y Radio",
                    "Input Groups": "Grupos de Entrada",
                    "Input Mask": "Máscara de Entrada",
                    "Mega Options": "Opción Mega",
                    "Datepicker": "Selector de Fecha",
                    "Touchspin": "Selector Numérico",
                    "Switch": "Interruptor",
                    "Forms": "Formularios",
                    "Form Controls": "Controles de Formulario",
                    "Forms & Table": "Formularios y Tabla",
                    "Typeahead": "Búsqueda Predictiva",
                    "Clipboard": "Portapapeles",
                    "Form Widgets": "Widget de Formulario",
                    "Form Wizard 1": "Asistente de Formulario 1",
                    "Form Wizard 2": "Asistente de Formulario 2",
                    "Two Factor": "Doble Factor",
                    "Form Layout": "Diseño de Formulario",
                    "Tables": "Tabla",
                    "Reactstrap Tables": "Tablas Reactstrap",
                    "Basic Tables": "Tablas Básicas",
                    "Table Components": "Componentes de Tabla",
                    "Data Tables": "Tablas de Datos",
                    "Basic Init": "Inicio Básico",
                    "Advance Init": "Inicio Avanzado",
                    "API": "API",
                    "Data Source": "Fuente de Datos",
                    "Components": "Componentes",
                    "Typography": "Tipografía",
                    "Avatars": "Avatares",
                    "Helper Classes": "Clases de Ayuda",
                    "Grid": "Cuadrícula",
                    "Tag & Pills": "Etiquetas y Píldoras",
                    "Progress": "Progreso",
                    "Modal": "Modal",
                    "Alert": "Alerta",
                    "Popover": "Popover",
                    "Tooltip": "Información sobre Herramientas",
                    "Dropdown": "Menú Desplegable",
                    "Accordion": "Acordeón",
                    "Tabs": "Pestañas",
                    "Lists": "Listas",
                    "Bonus-Ui": "UI Adicional",
                    "Scrollable": "Desplazable",
                    "Tree View": "Vista de Árbol",
                    "Toasts": "Notificaciones",
                    "Rating": "Valoración",
                    "Dropzone": "Zona de Descarga",
                    "Tour": "Recorrido",
                    "SweetAlert2": "SweetAlert2",
                    "Owl Carousel": "Carrusel Owl",
                    "Ribbons": "Cintas",
                    "Pagination": "Paginación",
                    "Breadcrumb": "Migas de Pan",
                    "Range Slider": "Control Deslizante de Rango",
                    "Image Cropper": "Recortador de Imagen",
                    "Basic Card": "Tarjeta Básica",
                    "Creative Card": "Tarjeta Creativa",
                    "Timeline": "Línea de Tiempo",
                    "Icons": "Iconos",
                    "Flag Icon": "Icono de Bandera",
                    "Fontawesome Icon": "Icono Font Awesome",
                    "Ico Icon": "Icono Ico",
                    "Themify Icon": "Icono Themify",
                    "Feather Icon": "Icono Feather",
                    "Weather Icon": "Icono del Tiempo",
                    "Buttons": "Botones",
                    "Charts": "Gráficos",
                    "Apex Chart": "Gráfico Apex",
                    "Google Chart": "Gráfico de Google",
                    "Chartjs Chart": "Gráfico de Chartjs",
                    "Pages": "Páginas",
                    "Sample Page": "Página de Ejemplo",
                    "Others": "Otros",
                    "Error Pages": "Páginas de Error",
                    "Error 400": "Error 400",
                    "Error 401": "Error 401",
                    "Error 403": "Error 403",
                    "Error 404": "Error 404",
                    "Error 500": "Error 500",
                    "Error 503": "Error 503",
                    "Login Simple": "Inicio de Sesión Simple",
                    "Login with Bg Image": "Inicio de Sesión con Imagen de Fondo",
                    "Login With Image Two": "Inicio de Sesión con Dos Imágenes",
                    "Login With Validation": "Inicio de Sesión con Validación",
                    "Login With Tooltip": "Inicio de Sesión con Información Emergente",
                    "Login With Sweetalert": "Inicio de Sesión con SweetAlert",
                    "Register Simple": "Registro Simple",
                    "Register With Bg Image": "Registro con Imagen de Fondo",
                    "Register With Bg Two": "Registro con Dos Imágenes",
                    "Register Wizard": "Asistente de Registro",
                    "Unlock User": "Desbloquear Usuario",
                    "Forget Password": "Olvidé mi Contraseña",
                    "Reset Password": "Restablecer Contraseña",
                    "Maintenance": "Mantenimiento",
                    "Coming Simple": "Próximamente Simple",
                    "Coming With Bg Video": "Próximamente con Video de Fondo",
                    "Coming With Bg Image": "Próximamente con Imagen de Fondo",
                    "Miscellaneous": "Misceláneo",
                    "Gallery": "Galería",
                    "Gallery Grid": "Galería en Cuadrícula",
                    "Gallery Grid Desc": "Descripción de la Galería en Cuadrícula",
                    "Masonry Gallery": "Galería de Albañilería",
                    "Masonry With Desc": "Albañilería con Descripción",
                    "Hover Effect": "Efecto de Desplazamiento",
                    "Blog": "Blog",
                    "Blog Details": "Detalles del Blog",
                    "Blog Single": "Entrada de Blog Individual",
                    "Add Post": "Agregar Entrada",
                    "FAQ": "Preguntas Frecuentes",
                    "Job Search": "Búsqueda de Trabajo",
                    "Cards View": "Vista de Tarjetas",
                    "List View": "Vista de Lista",
                    "Job Detail": "Detalle del Trabajo",
                    "Apply": "Solicitar",
                    "Learning": "Aprendizaje",
                    "Learning List": "Lista de Aprendizaje",
                    "Detailed Course": "Curso Detallado",
                    "Maps": "Mapa",
                    "Google Map": "Mapa de Google",
                    "Leaflet Map": "Mapa de Leaflet",
                    "Editors": "Editors",
                    "Quill Editor": "Editor Quill",
                    "ACE Editor": "Editor ACE",
                    "Knowledgebase": "Base de Conocimientos",
                    "Ui-Kits": "Kits de Interfaz de Usuario.",
                    "SupportTicket": "Ticket de Soporte",
                    "Authentication": "Autenticación",
                    "Login With Bg Image": "Inicio de sesión con imagen de fondo",
                    "Pinned": "Fijado",
                    "Coming Soon": "Próximamente"
                },
            },
            fr: {
                translations: {
                    "General": "Général",
                    "Dashboard": "Tableaux de bord",
                    "Default": "Défaut",
                    "Project": "Projet",
                    "Ecommerce": "Commerce électronique",
                    "Education": "Éducation",
                    "Widgets": "Widgets",
                    "Chart": "Graphique",
                    "Applications": "Applications",
                    "Project List": "Liste de projets",
                    "Create New": "Créer un nouveau",
                    "File Manager": "Gestionnaire de fichiers",
                    "Add Product": "Ajouter un produit",
                    "Product": "Produit",
                    "Product Page": "Page de produit",
                    "Product List": "Liste de produits",
                    "Payment Details": "Détails de paiement",
                    "Order History": "Historique des commandes",
                    "Invoice": "Facture",
                    "Invoice-1": "Facture-1",
                    "Invoice-2": "Facture-2",
                    "Invoice-3": "Facture-3",
                    "Invoice-4": "Facture-4",
                    "Invoice-5": "Facture-5",
                    "Invoice-6": "Facture-6",
                    "Cart": "Panier",
                    "Wishlist": "Liste de souhaits",
                    "Checkout": "Paiement",
                    "Pricing": "Tarification",
                    "Chats": "Discussion",
                    "Users": "Utilisateurs",
                    "Letter Box": "Boîte aux lettres",
                    "Private Chat": "Discussion privée",
                    "Group Chat": "Discussion de groupe",
                    "User Profile": "Profil de l'utilisateur",
                    "User Edit": "Modifier l'utilisateur",
                    "User Cards": "Cartes d'utilisateur",
                    "Bookmarks": "Signets",
                    "Contacts": "Contacts",
                    "Tasks": "Tâche",
                    "Calendar": "Calendrier",
                    "Social App": "Application sociale",
                    "To-Do": "À faire",
                    "Search Result": "Résultat de la recherche",
                    "Form Validation": "Validation de formulaire",
                    "Base Inputs": "Entrées de base",
                    "Checkbox & Radio": "Cases à cocher et boutons radio",
                    "Input Groups": "Groupes d'entrée",
                    "Input Mask": "Masque de saisie",
                    "Mega Options": "Option méga",
                    "Datepicker": "Sélecteur de date",
                    "Touchspin": "Touchspin",
                    "Switch": "Interrupteur",
                    "Forms": "Formulaires",
                    "Form Controls": "Contrôles de formulaire",
                    "Forms & Table": "Formulaires et table",
                    "Typeahead": "Suggestion de texte",
                    "Clipboard": "Presse-papiers",
                    "Form Widgets": "Widget de formulaire",
                    "Form Wizard 1": "Assistant de formulaire 1",
                    "Form Wizard 2": "Assistant de formulaire 2",
                    "Two Factor": "Double authentification",
                    "Form Layout": "Mise en page du formulaire",
                    "Tables": "Tableau",
                    "Reactstrap Tables": "Tableaux Reactstrap",
                    "Basic Tables": "Tables de base",
                    "Table Components": "Composants de tableau",
                    "Data Tables": "Tableaux de données",
                    "Basic Init": "Initialisation de base",
                    "Advance Init": "Initialisation avancée",
                    "API": "API",
                    "Data Source": "Source de données",
                    "Components": "Composants",
                    "Typography": "Typographie",
                    "Avatars": "Avatars",
                    "Helper Classes": "Classes d'aide",
                    "Grid": "Grille",
                    "Tag & Pills": "Étiquettes et pastilles",
                    "Progress": "Progression",
                    "Modal": "Fenêtre modale",
                    "Alert": "Alerte",
                    "Popover": "Infobulle",
                    "Tooltip": "Info-bulle",
                    "Dropdown": "Menu déroulant",
                    "Accordion": "Accordéon",
                    "Tabs": "Onglets",
                    "Lists": "Listes",
                    "Bonus-Ui": "Bonus-UI",
                    "Scrollable": "Déroulant",
                    "Tree View": "Vue arborescente",
                    "Toasts": "Notifications",
                    "Rating": "Évaluation",
                    "Dropzone": "Zone de dépôt",
                    "Tour": "Visite guidée",
                    "SweetAlert2": "SweetAlert2",
                    "Owl Carousel": "Carrousel Owl",
                    "Ribbons": "Rubans",
                    "Pagination": "Pagination",
                    "Breadcrumb": "Fil d'Ariane",
                    "Range Slider": "Curseur de plage",
                    "Image Cropper": "Recadrage d'image",
                    "Basic Card": "Carte de base",
                    "Creative Card": "Carte créative",
                    "Timeline": "Chronologie",
                    "Icons": "Icônes",
                    "Flag Icon": "Icône de drapeau",
                    "Fontawesome Icon": "Icône Fontawesome",
                    "Ico Icon": "Icône Ico",
                    "Themify Icon": "Icône Themify",
                    "Feather Icon": "Icône Feather",
                    "Weather Icon": "Icône météo",
                    "Buttons": "Boutons",
                    "Charts": "Graphiques",
                    "Apex Chart": "Graphique Apex",
                    "Google Chart": "Graphique Google",
                    "Chartjs Chart": "Graphique Chartjs",
                    "Pages": "Pages",
                    "Sample Page": "Page d'exemple",
                    "Others": "Autres",
                    "Error Pages": "Pages d'erreur",
                    "Error 400": "Erreur 400",
                    "Error 401": "Erreur 401",
                    "Error 403": "Erreur 403",
                    "Error 404": "Erreur 404",
                    "Error 500": "Erreur 500",
                    "Error 503": "Erreur 503",
                    "Login Simple": "Connexion simple",
                    "Login with Bg Image": "Connexion avec image de fond",
                    "Login With Image Two": "Connexion avec deux images",
                    "Login With Validation": "Connexion avec validation",
                    "Login With Tooltip": "Connexion avec info-bulle",
                    "Login With Sweetalert": "Connexion avec SweetAlert",
                    "Register Simple": "Inscription simple",
                    "Register With Bg Image": "Inscription avec image de fond",
                    "Register With Bg Two": "Inscription avec deux images de fond",
                    "Register Wizard": "Assistant d'inscription",
                    "Unlock User": "Déverrouiller l'utilisateur",
                    "Forget Password": "Mot de passe oublié",
                    "Reset Password": "Réinitialiser le mot de passe",
                    "Maintenance": "Maintenance",
                    "Coming Simple": "Bientôt disponible (version simple)",
                    "Coming With Bg Video": "Bientôt disponible avec vidéo de fond",
                    "Coming With Bg Image": "Bientôt disponible avec image de fond",
                    "Miscellaneous": "Divers",
                    "Gallery": "Galerie",
                    "Gallery Grid": "Grilles de galerie",
                    "Gallery Grid Desc": "Description de la grille de galerie",
                    "Masonry Gallery": "Galerie en maçonnerie",
                    "Masonry With Desc": "Maçonnerie avec description",
                    "Hover Effect": "Effet de survol",
                    "Blog": "Blog",
                    "Blog Details": "Détails du blog",
                    "Blog Single": "Article de blog unique",
                    "Add Post": "Ajouter un article",
                    "FAQ": "FAQ",
                    "Job Search": "Recherche d'emploi",
                    "Cards View": "Vue en cartes",
                    "List View": "Vue en liste",
                    "Job Detail": "Détails de l'emploi",
                    "Apply": "Postuler",
                    "Learning": "Apprentissage",
                    "Learning List": "Liste d'apprentissage",
                    "Detailed Course": "Cours détaillé",
                    "Maps": "Carte",
                    "Google Map": "Carte Google",
                    "Leaflet Map": "Carte Leaflet",
                    "Editors": "Éditeur",
                    "Quill Editor": "Éditeur Quill",
                    "ACE Editor": "Éditeur ACE",
                    "Knowledgebase": "Base de connaissances",
                    "Ui-Kits": "Kits d'interface utilisateur.",
                    "SupportTicket": "Ticket de support",
                    "Authentication": "Authentification",
                    "Login With Bg Image": "Connexion avec Image de Fond",
                    "Pinned": "Épinglé",
                    "Coming Soon": "Prochainement"
                },
            },
            pt: {
                translations: {
                    "General": "Geral",
                    "Dashboard": "Painéis",
                    "Default": "Padrão",
                    "Project": "Projeto",
                    "Ecommerce": "Comércio Eletrônico",
                    "Education": "Educação",
                    "Widgets": "Widgets",
                    "Chart": "Gráfico",
                    "Applications": "Aplicativos",
                    "Project List": "Lista de Projetos",
                    "Create New": "Criar Novo",
                    "File Manager": "Gerenciador de Arquivos",
                    "Add Product": "Adicionar Produto",
                    "Product": "Produto",
                    "Product Page": "Página do Produto",
                    "Product List": "Lista de Produtos",
                    "Payment Details": "Detalhes do Pagamento",
                    "Order History": "Histórico de Pedidos",
                    "Invoice": "Fatura",
                    "Invoice-1": "Fatura-1",
                    "Invoice-2": "Fatura-2",
                    "Invoice-3": "Fatura-3",
                    "Invoice-4": "Fatura-4",
                    "Invoice-5": "Fatura-5",
                    "Invoice-6": "Fatura-6",
                    "Cart": "Carrinho",
                    "Wishlist": "Lista de Desejos",
                    "Checkout": "Finalizar Compra",
                    "Pricing": "Preços",
                    "Chats": "Chats",
                    "Users": "Usuários",
                    "Letter Box": "Caixa de Correio",
                    "Private Chat": "Chat Privado",
                    "Group Chat": "Chat em Grupo",
                    "User Profile": "Perfil do Usuário",
                    "User Edit": "Editar Usuário",
                    "User Cards": "Cartões de Usuário",
                    "Bookmarks": "Marcadores",
                    "Contacts": "Contatos",
                    "Tasks": "Tarefa",
                    "Calendar": "Calendário",
                    "Social App": "Aplicativo Social",
                    "To-Do": "Lista de Tarefas",
                    "Search Result": "Resultado da Pesquisa",
                    "Form Validation": "Validação de Formulário",
                    "Base Inputs": "Entradas Básicas",
                    "Checkbox & Radio": "Caixas de Seleção e Rádio",
                    "Input Groups": "Grupos de Entrada",
                    "Input Mask": "Máscara de Entrada",
                    "Mega Options": "Opção Mega",
                    "Datepicker": "Seletor de Data",
                    "Touchspin": "Touchspin",
                    "Switch": "Interruptor",
                    "Forms": "Formulários",
                    "Form Controls": "Controles de Formulário",
                    "Forms & Table": "Formulários e Tabela",
                    "Typeahead": "Sugestão de Texto",
                    "Clipboard": "Área de Transferência",
                    "Form Widgets": "Widget de Formulário",
                    "Form Wizard 1": "Assistente de Formulário 1",
                    "Form Wizard 2": "Assistente de Formulário 2",
                    "Two Factor": "Autenticação em Dois Passos",
                    "Form Layout": "Layout do Formulário",
                    "Table": "Tabela",
                    "Reactstrap Tables": "Tabelas Reactstrap",
                    "Basic Tables": "Tabelas Básicas",
                    "Table Components": "Componentes de Tabela",
                    "Data Tables": "Tabelas de Dados",
                    "Basic Init": "Inicialização Básica",
                    "Advance Init": "Inicialização Avançada",
                    "API": "API",
                    "Data Source": "Fonte de Dados",
                    "Components": "Componentes",
                    "Typography": "Tipografia",
                    "Avatars": "Avatares",
                    "Helper Classes": "Classes de Ajuda",
                    "Grid": "Grade",
                    "Tag & Pills": "Etiqueta e Abas",
                    "Progress": "Progresso",
                    "Modal": "Modal",
                    "Alert": "Alerta",
                    "Popover": "Popover",
                    "Tooltip": "Dica de Ferramenta",
                    "Dropdown": "Menu Suspenso",
                    "Accordion": "Acordeão",
                    "Tabs": "Abas",
                    "Lists": "Listas",
                    "Bonus Ui": "UI Extra",
                    "Scrollable": "Rolável",
                    "Tree View": "Visualização de Árvore",
                    "Toasts": "Notificações",
                    "Rating": "Avaliação",
                    "Dropzone": "Zona de Soltar",
                    "Tour": "Tour",
                    "SweetAlert2": "SweetAlert2",
                    "Owl Carousel": "Carrossel Owl",
                    "Ribbons": "Fitas",
                    "Pagination": "Paginação",
                    "Breadcrumb": "Migalha de Pão",
                    "Range Slider": "Controle de Faixa",
                    "Image Cropper": "Cortador de Imagem",
                    "Basic Card": "Cartão Básico",
                    "Creative Card": "Cartão Criativo",
                    "Timeline": "Linha do Tempo",
                    "Icons": "Ícones",
                    "Flag Icon": "Ícone de Bandeira",
                    "Fontawesome Icon": "Ícone Fontawesome",
                    "Ico Icon": "Ícone Ico",
                    "Themify Icon": "Ícone Themify",
                    "Feather Icon": "Ícone Feather",
                    "Weather Icon": "Ícone de Clima",
                    "Buttons": "Botões",
                    "Charts": "Gráficos",
                    "Apex Chart": "Gráfico Apex",
                    "Google Chart": "Gráfico do Google",
                    "Chartjs Chart": "Gráfico Chartjs",
                    "Pages": "Páginas",
                    "Sample Page": "Página de Exemplo",
                    "Others": "Outros",
                    "Error Pages": "Páginas de Erro",
                    "Error 400": "Erro 400",
                    "Error 401": "Erro 401",
                    "Error 403": "Erro 403",
                    "Error 404": "Erro 404",
                    "Error 500": "Erro 500",
                    "Error 503": "Erro 503",
                    "Login Simple": "Login Simples",
                    "Login with Bg Image": "Login com Imagem de Fundo",
                    "Login With Image Two": "Login com Duas Imagens",
                    "Login With Validation": "Login com Validação",
                    "Login With Tooltip": "Login com Dica de Ferramenta",
                    "Login With Sweetalert": "Login com SweetAlert",
                    "Register Simple": "Registro Simples",
                    "Register With Bg Image": "Registro com Imagem de Fundo",
                    "Register With Bg Two": "Registro com Duas Imagens de Fundo",
                    "Register Wizard": "Assistente de Registro",
                    "Unlock User": "Desbloquear Usuário",
                    "Forget Password": "Esqueci a Senha",
                    "Reset Password": "Redefinir Senha",
                    "Maintenance": "Manutenção",
                    "Coming Simple": "Em Breve - Simples",
                    "Coming With Bg Video": "Em Breve com Vídeo de Fundo",
                    "Coming With Bg Image": "Em Breve com Imagem de Fundo",
                    "Miscellaneous": "Diversos",
                    "Gallery": "Galeria",
                    "Gallery Grids": "Grade de Galeria",
                    "Gallery Grid Desc": "Descrição da Grade de Galeria",
                    "Masonry Gallery": "Galeria em Alvenaria",
                    "Masonry With Desc": "Alvenaria com Descrição",
                    "Hover Effect": "Efeito de Passagem",
                    "Blog": "Blog",
                    "Blog Details": "Detalhes do Blog",
                    "Blog Single": "Postagem de Blog Única",
                    "Add Post": "Adicionar Postagem",
                    "FAQ": "Perguntas Frequentes",
                    "Job Search": "Busca de Empregos",
                    "Cards View": "Visualização em Cartões",
                    "List View": "Visualização em Lista",
                    "Job Detail": "Detalhe do Emprego",
                    "Apply": "Aplicar",
                    "Learning": "Aprendizado",
                    "Learning List": "Lista de Aprendizado",
                    "Detailed Course": "Curso Detalhado",
                    "Maps": "Mapa",
                    "Google Map": "Mapa do Google",
                    "Leaflet Map": "Mapa Leaflet",
                    "Editors": "Editors",
                    "Quill Editor": "Editor Quill",
                    "ACE Editor": "Editor ACE",
                    "Knowledgebase": "Base de Conhecimento",
                    "Ui Kits": "Kits de Interface de Usuário.",
                    "SupportTicket": "Ticket de Suporte",
                    "Authentication": "Autenticação",
                    "Login With Bg Image": "Login com Imagem de Fundo",
                    "Pinned": "Fixado",
                    "Coming Soon": "Em Breve"
                },
            },
        },
        fallbackLng: "en",
        debug: false,
        ns: ["translations"],
        defaultNS: "translations",
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;