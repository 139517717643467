import React, { useEffect, useState } from 'react'
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { Btn } from '../../../AbstractElements';
import * as Yup from 'yup';
import { Cancel, Previous, StrItinerary, SubmitAndNext } from '../../../Utils/Constants';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { updatePackageItineraryAction } from '../../../ReduxToolkit/Reducers/Layout/Package/PackageAction';
import { toast } from 'react-toastify';
import Select, { IndicatorSeparatorProps } from 'react-select';
import makeAnimated from 'react-select/animated';
import Loader from '../../../Layout/Loader';
import { Formik, Form, Field } from 'formik';
import ReactQuill from 'react-quill';
import { gethotelDropdownListAction } from '../../../ReduxToolkit/Reducers/Layout/Hotel/HotelAction';
import { PackageDetailType } from '../../../Types/InterfaceData.type';
import styled from '@emotion/styled';
import { ActiveCallbackProp } from './PackageTabContents';

const StyleForm = styled.div`
    .ql-editor{
        height: 150px;
        max-height: 150px;
        overflow: auto;
    }
`

const animatedComponents = makeAnimated();

export default function PackageItineraryForm({ activeCallBack, toggle, detail }: ActiveCallbackProp) {
    const [searchParams] = useSearchParams();
    const packageId = searchParams.get('id');
    const navigate = useNavigate();
    const [showLoader, setShowLoader] = useState(false);
    const [itineraryData, setItineraryData] = useState<any>([])
    const [hotelList, setHotelList] = useState<any>([])
    const [dropdownHotelList, setDropdownHotelList] = useState<any>([])
    const [dropdownHotelRoomList, setDropdownHotelRoomList] = useState<any>([])

    if (!packageId) {
        activeCallBack(1);
    }

    useEffect(() => {
        const response = gethotelDropdownListAction({});
        response.then((result: any) => {
            if (result.status === 200) {
                setHotelList(result.data);
                const listAr = result.data.map((Ob: any) => {
                    return { value: Ob.id, label: Ob.name }
                })
                setDropdownHotelList(listAr);
            }
        });
    }, [])

    useEffect(() => {
        if (detail) {
            setItineraryData(detail.ItineraryDetails);
        }
    }, [detail])

    const handleHotelRoomOptions = (value: any) => {
        const selectedHotelAr = hotelList.find((ob: any) => ob.id === value.value);
        const listAr = selectedHotelAr.hotelRoomsDetails.map((Ob: any) => {
            return { value: Ob.hotelRoomType.id, label: Ob.hotelRoomType.name }
        })
        setDropdownHotelRoomList(listAr);
    }

    const getSelectedHotel = (value: string) => {
        if (!value) return null;

        const hotelObj = dropdownHotelList && dropdownHotelList.length > 0 && dropdownHotelList.find((arr: any) => arr.value === value);
        return hotelObj;
    }

    const getSelectedRoom = (value: string, hotelId: string) => {
        if (!value) return null;

        const selectedHotelAr = hotelList.find((ob: any) => ob.id === hotelId);
        const listAr = selectedHotelAr && selectedHotelAr.hotelRoomsDetails.map((Ob: any) => {
            return { value: Ob.hotelRoomType.id, label: Ob.hotelRoomType.name }
        })

        const hotelRoomObj = listAr && listAr.length > 0 && listAr.find((arr: any) => arr.value === value);
        return hotelRoomObj;
    }

    const handleRemoveItineraryClick = (index: number) => {
        const itineraryDataTmp = [...itineraryData];
        itineraryDataTmp.splice(index, 1);
        setItineraryData(itineraryDataTmp);
    }

    const handleAddMoreItineraryClick = () => {
        const itineraryDataTmp = [...itineraryData];
        setItineraryData([...itineraryDataTmp, {
            hotelId: null,
            roomTypeId: null,
            title: null,
            detail: null
        }]);
    }

    const handleItineraryData = (value: any, index: number, type: string) => {
        const itineraryDataTmp = [...itineraryData];
        if (type === 'title') {
            itineraryDataTmp[index] = {
                title: value,
                hotelId: itineraryDataTmp[index]['hotelId'],
                roomTypeId: itineraryDataTmp[index]['roomTypeId'],
                detail: itineraryDataTmp[index]['detail']
            }
        } else if (type === 'detail') {
            itineraryDataTmp[index] = {
                title: itineraryDataTmp[index]['title'],
                hotelId: itineraryDataTmp[index]['hotelId'],
                roomTypeId: itineraryDataTmp[index]['roomTypeId'],
                detail: value
            }
        } else if (type === 'hotelId') {
            itineraryDataTmp[index] = {
                title: itineraryDataTmp[index]['title'],
                hotelId: value,
                roomTypeId: itineraryDataTmp[index]['roomTypeId'],
                detail: itineraryDataTmp[index]['detail']
            }
        } else if (type === 'roomTypeId') {
            itineraryDataTmp[index] = {
                title: itineraryDataTmp[index]['title'],
                hotelId: itineraryDataTmp[index]['hotelId'],
                roomTypeId: value,
                detail: itineraryDataTmp[index]['detail']
            }
        }
        setItineraryData(itineraryDataTmp);
    }

    const handleSubmit = () => {
        setShowLoader(true);
        const response = updatePackageItineraryAction({
            packageId: packageId,
            itineraryData: itineraryData
        });
        response.then((result: any) => {
            setShowLoader(false);
            if (result.status === 200) {
                toast.success(result.message);
                activeCallBack(4);
            } else {
                toast.error(result.message);
            }
        });
    }

    return (
        <StyleForm className="sidebar-body">
            <Loader show={showLoader} />
            <Formik
                enableReinitialize={detail && Object.keys(detail).length > 0}
                initialValues={{

                }}
                validationSchema={Yup.object().shape({})}
                onSubmit={values => {
                    handleSubmit();
                }}
            >
                {({ errors, touched, setFieldValue, values, setTouched }) => (
                    <Form>
                        <Row>
                            {itineraryData.length > 0 && itineraryData.map((item: any, index: number) => {
                                return (
                                    <Col sm={12} className='pb-3'>
                                        <div className="card-wrapper border rounded-3 checkbox-checked">
                                            <FormGroup>
                                                <Label className='f-w-700 font-dark'><strong>{StrItinerary}</strong></Label>
                                            </FormGroup>
                                            <Row>
                                                <Col sm={12}>
                                                    <FormGroup>
                                                        <Label className='required-field'><strong>{'Title'}</strong></Label>
                                                        <Input type="text" tag={Field} placeholder={'eg. Day 1'} name="title" value={item.title} onChange={(e: any) => handleItineraryData(e.target.value, index, 'title')} />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={12}>
                                                    <FormGroup>
                                                        <Label className='required-field'><strong>{'Detail'}</strong></Label>
                                                        <ReactQuill theme='snow' placeholder={'Itinerary Details'} value={item.detail} onChange={(e) => handleItineraryData(e, index, 'detail')} />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={6}>
                                                    <FormGroup>
                                                        <Label><strong>{'Hotel'}</strong></Label>
                                                        <Field render={(field: any) => <Select
                                                            {...field}
                                                            closeMenuOnSelect={true}
                                                            components={animatedComponents}
                                                            isMulti={false}
                                                            options={dropdownHotelList}
                                                            value={item.hotelId ? getSelectedHotel(item.hotelId) : ''}
                                                            onChange={(e: any) => { handleItineraryData(e, index, 'hotelId'); handleHotelRoomOptions(e) }}
                                                        />} />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={6}>
                                                    <FormGroup>
                                                        <Label><strong>{'Hotel Room'}</strong></Label>
                                                        <Field render={(field: any) => <Select
                                                            {...field}
                                                            closeMenuOnSelect={true}
                                                            components={animatedComponents}
                                                            isMulti={false}
                                                            options={dropdownHotelRoomList}
                                                            value={item.hotelId ? getSelectedRoom(item.roomTypeId, item.hotelId) : ''}
                                                            onChange={(e: any) => handleItineraryData(e, index, 'roomTypeId')}
                                                        />} />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={1}>
                                                    <Btn type='button' className={'b-ln-height b-r-8'}
                                                        outline={false}
                                                        size={'sm'}
                                                        color={'danger'}
                                                        onClick={() => { handleRemoveItineraryClick(index) }}
                                                    >
                                                        {'Remove'}
                                                    </Btn>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                )
                            })}
                            <Col xs={12}>
                                <div className={`d-grid gap-2 buttons-box col-12 mx-auto`}>
                                    <Btn type='button' onClick={() => handleAddMoreItineraryClick()} color={'light-info'}>{'Click To Add Itinerary'}</Btn>
                                </div>
                            </Col>
                        </Row>
                        <FormGroup className='p-t-20'>
                            <div className="d-md-flex justify-content-md-start common-flex">
                                {toggle ?
                                    <Btn type='button' color='secondary' onClick={toggle}>{Cancel}</Btn>
                                    :
                                    <Btn type='button' color='secondary' onClick={() => navigate('/packages')}>{Cancel}</Btn>
                                }
                                <Btn type='button' color='danger' onClick={() => activeCallBack(2)}>{Previous}</Btn>
                                <Btn type='submit' color='primary' disabled={showLoader}>{SubmitAndNext}</Btn>
                            </div>
                        </FormGroup>
                    </Form>
                )}
            </Formik>
        </StyleForm>
    )
}
