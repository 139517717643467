import { useState } from 'react'
import { Col, Card, CardBody } from 'reactstrap'
import { Btn, H2, H4, Image, P } from '../../AbstractElements'
import { dynamicImage } from '../../Utils'
import { InviteMembers } from '../../Utils/Constants';
import InviteMembermodal from './Invities/InviteMembermodal';

export default function WelcomeCard() {
    const [modal, setModal] = useState(false);
    const toggle = () => { setModal(!modal); }
    const data = {
        isOpen: modal,
        toggler: toggle,
        size: 'md'
    }

    return (
        <>
            <Col xl={4} sm={12}>
                <Card className="welcome-card dashboard-card">
                    <CardBody>
                        <H4 className="f-light f-w-500 mb-1">{'Tripper CRM'}</H4>
                        <H2 className="mb-3">{'Hi, Wecome Back'}</H2>
                        <Btn color='primary' type='button' className="btn-hover-effect" onClick={toggle}>{InviteMembers}</Btn>
                    </CardBody>
                    <div className="welcome-image">
                        <Image className="img-fluid" src={dynamicImage(`dashboard-3/1.png`)} alt="upgrade" />
                    </div>
                </Card>
            </Col>
            <InviteMembermodal data={data} toggle={toggle} modal={modal} setModal={setModal} />
        </>
    )
}
