import { useEffect, useState } from 'react'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import styled from '@emotion/styled';
import { StrCreatePaymentReference, StrPaymentReferencesList } from '../../../Utils/Constants'
import { Alerts, Btn, H5, LI, P, UL } from '../../../AbstractElements'
import parse from 'html-react-parser';
import CommonCardHeader from '../../../Utils/CommonComponents/CommonCardHeader';
import { toast } from 'react-toastify';
import PaymentReferenceForm from './PaymentReferenceForm';
import { deletePaymentTypeAction, getHotelPaymentTypeAction } from '../../../ReduxToolkit/Reducers/Layout/Hotel/HotelAction';
import Swal from 'sweetalert2';
import { Info } from 'react-feather';

const StyleContainer = styled(Container)`
`

interface InvitiesProps {
    id: number,
    userType: string,
    email: string,
    status: string,
    createdAt: string
}

interface ListDataProps {
    count: number,
    rows: InvitiesProps[]
}

const PaymentReferences = () => {
    const [modal, setModal] = useState(false);
    const toggle = () => { setModal(!modal); }
    const modalData = {
        isOpen: modal,
        toggler: toggle,
        size: 'md',
        center: true,
        backdrop: 'static'
    }
    const [selectedItemId, setSelectedItemId] = useState<number>(0);
    const [listData, setListData] = useState<ListDataProps>();

    useEffect(() => {
        handleGetListData();
    }, [])

    const handleGetListData = () => {
        setSelectedItemId(0);
        const response = getHotelPaymentTypeAction({});
        response.then((result: any) => {
            if(result.status === 200) {
                setListData(result.data);
            } else {
                toast.error(result.message);
            }
        });
    }

    const handleDelete = (id: number) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Once deleted, you will not be able to recover this payment-type!",
            icon: 'warning',
            showCancelButton: true
        }).then((result) => {
            if (result.isConfirmed) {
                const response = deletePaymentTypeAction({id: id});
                response.then((result: any) => {
                    if(result.status === 200) {
                        handleGetListData();
                        toast.success(result.message);
                    } else {
                        toast.error(result.message);
                    }
                });
            }
        })
    }

    return (
        <StyleContainer fluid>
            <Row className='project-cards'>
                <Col xl={12} sm={6} className="col-xl-30 order-xl-ii customer-order">
                    <Card className='title-line'>
                        <CommonCardHeader headClass='border-t-primary border-2 pb-2' 
                            title={StrPaymentReferencesList} 
                            subTitle={[{
                                text: 'Total',
                                code: `${listData && listData.count ? listData.count : 0}`
                            }]}
                            btnText={StrCreatePaymentReference}
                            faIcon={'plus-square-o'} handleOnClick={toggle} 
                        />
                        <CardBody className="transaction-list recent-orders">
                            <UL className="order-list mb-0 simple-list">
                                {listData && listData.rows && listData.rows.length > 0 ? listData.rows.map((item: any) => (
                                    <LI key={item.id}>
                                        <div className="order-content">
                                            <div>
                                                <H5 className="mb-1">{item.name}</H5>
                                                <span>
                                                    {/* <Badges color='light-primary'>{item.name}</Badges> */}
                                                    <span className="f-light f-w-500 f-15">{item.description ? parse(item.description) : ''}</span>
                                                </span>
                                            </div>
                                            <div className="text-end">
                                                <div className="d-md-flex justify-content-md-end common-flex">
                                                    <Btn type='button' color='secondary' onClick={() => {handleDelete(item.id)}}>{<i className="fa fa-trash fa-sm"></i>}</Btn>
                                                    <Btn type='button' color='primary' onClick={() => {setSelectedItemId(item.id)}}>{<i className="fa fa-edit fa-sm"></i>}</Btn>
                                                </div>
                                                {/* <span className="f-light f-w-500 f-15">{formatDate(item.createdAt, 'DD/MM/YY h:mm a')}</span> */}
                                            </div>
                                        </div>
                                    </LI>
                                )) : <Alerts color='light-danger' className={`light alert-dismissible fade text-dark border-left-wrapper show`}>
                                <Info />
                                <P>{'No data Found.'}</P>
                            </Alerts>}
                            </UL>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <PaymentReferenceForm itemId={selectedItemId} data={modalData} toggle={toggle} modal={modal} setModal={setModal} handleCallback={handleGetListData} />
        </StyleContainer>
    )
}

export default PaymentReferences;
