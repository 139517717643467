import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import SignUpForm from './SignUpForm'

export default function SignUp() {
    return (
        <Container className="p-0" fluid>
            <Row className="m-0">
                <Col xs={12} className="p-0">
                    <div className="login-card login-dark">
                        <SignUpForm />
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
