import { Col, Container } from 'reactstrap'
import { useNavigate } from 'react-router-dom'
import { Image, H2, P, Btn } from '../../AbstractElements' 
import { dynamicImage } from '../../Utils'

const Unauthorised = () => {
    const navigate = useNavigate();

    return (
        <div className="page-wrapper compact-wrapper" id="pageWrapper">
            <div className="error-wrapper">
                <Container>
                    <Image className="img-100" src={dynamicImage(`gif/sad.gif`)} alt="400 error" />
                    <div className="error-heading">
                        <H2 className={`headline font-danger`}>{'401'}</H2>
                    </div>
                    <Col md={8} className="offset-md-2">
                        <P className="sub-content">{'The page you are attempting to reach is currently not available. This may be because the page does not exist or has been moved.'}</P>
                    </Col>
                    <div>
                        <Btn type='button' onClick={() => navigate('/')} size="lg" color={`danger-gradien`}>{'BACK TO HOME PAGE'}</Btn>
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default Unauthorised;