import React from 'react'
import { Card, Col, FormGroup, Nav, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import { PlusSquare, Target } from 'react-feather'
import styled from '@emotion/styled';
import { StrAddPackage } from '../../../Utils/Constants'
import { leadStatus } from '../../../helpers'

interface ProjectListNavProps {
    activeTab: string;
    setActiveTab: (value: string) => void;
}

const StyleCard = styled(Card)`
    .btn-formgroup {
        margin-bottom: 0px !important;
    }
`

export default function PackagesListNav({ activeTab, setActiveTab }: ProjectListNavProps) {
    return (
        <StyleCard>
            <Row>
                <Col md={8}>
                    <Nav className="border-tab" tabs>
                    </Nav>
                </Col>
                <Col md={4}>
                    <div className='d-flex justify-content-end'>
                        <FormGroup className="mb-0 btn-formgroup">
                            <Link className="btn btn-primary" to={`/packages/create`}>
                                <PlusSquare /> {StrAddPackage}
                            </Link>
                        </FormGroup>
                    </div>
                </Col>
            </Row>
        </StyleCard>
    )
}
