import { Card, CardBody, Modal, ModalBody, ModalHeader, Form, Col, Row } from "reactstrap";
import { useState } from "react";
import Loader from '../../../Layout/Loader';
import { Btn, H6, Image } from "../../../AbstractElements";
import { Cancel, StrSubmit } from "../../../Utils/Constants";
import { toast } from "react-toastify";
import Dropzone from "react-dropzone-uploader";
import Slider from "react-slick";
import SvgIcon from "../../../Utils/CommonComponents/CommonIcons/CommonSvgIcons";
import { autoPlaySliderOptions, gDriveImgThumbnailLink } from "../../../Utils/Constants/ElementContans";
import CommonCarousel from "../../Common/CommonCarousel";
import { uploadHotelGalleryAction } from "../../../ReduxToolkit/Reducers/Layout/Hotel/HotelAction";
import { Gallery, Item } from "react-photoswipe-gallery";

const productGalleryData = [
    {
        id: 1,
        title: 'Package Gallery',
        icon: 'file-upload1',
        spanText: 'Add Package Gallery Images (Maximum - 3 files in one time)'
    },
];

const HotelGalleryForm = (props: any) => {
    const { hotelId, gallery } = props;
    const [stateModal, setStateModal] = useState({ modalOpen: false, tooltipOpen: false });
    const [showLoader, setShowLoader] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState<any>([]);

    const handleModalToggle = () => {
        setStateModal(prevState => ({
            ...prevState,
            modalOpen: !prevState.modalOpen
        }));
    };

    const modalData = {
        isOpen: stateModal.modalOpen,
        header: true,
        footer: true,
        toggler: handleModalToggle,
        center: true,
        size: 'lg',
        title: 'Upload Images',
        class: '',
        bodyClass: ''
    };

    const getUploadParams = () => {
        return { url: "https://httpbin.org/post", };
    };

    // called every time a file's `status` changes
    const handleChangeStatus = ({ meta, file }: any, status: any) => {
        if(['uploading'].includes(status)) {
            setShowLoader(true);
        }
        if(status === 'done') {
            setShowLoader(false);
            setUploadedFiles([file, ...uploadedFiles]);
        }
    }

    const handleSubmit = () => {
        setShowLoader(true);
        const response = uploadHotelGalleryAction({
            hotelId: hotelId,
            gallery: uploadedFiles
        });
        response.then((result: any) => {
            setShowLoader(false);
            if (result.status === 200) {
                toast.success(result.message);
                handleModalToggle();
            } else {
                toast.error(result.message);
            }
        })
    }

    return (<>
        <Btn color="secondary" onClick={handleModalToggle}>
            Gallery
        </Btn>
        <Modal className={modalData.class} isOpen={modalData.isOpen} toggle={modalData.toggler} centered={modalData.center} size={modalData.size}>
            {modalData.header && <ModalHeader toggle={modalData.toggler}>
                {modalData.title}
            </ModalHeader>}
            <ModalBody className={modalData.bodyClass}>
                <div className="sidebar-body">
                    <Loader show={showLoader} />
                    <Card>
                        <CardBody className="my-gallery gallery-with-description mb-0">
                            <Row>
                                <Gallery withCaption>
                                    <Slider {...autoPlaySliderOptions}>
                                    {gallery && gallery.length > 0 && gallery.map((item: any, i: number) => (
                                        <Col xl={3} sm={6} key={i}>
                                            <figure itemProp="caption description">
                                                <Item original={`${gDriveImgThumbnailLink}${item.image}`} caption="Using a camera's lens to record moments in time is the art form known as photography. To produce aesthetically striking photos, it entails the deft use of light, composition, and subject matter." width="802" height="476">
                                                    {({ ref, open }) => (
                                                        <>
                                                            <img style={{height: '150px'}} onClick={open} className='img-thumbnail border-bottom-0 p-2 rounded-0 rounded-top-1' ref={ref as React.MutableRefObject<HTMLImageElement>} src={`${gDriveImgThumbnailLink}${item.image}`} alt='Image_description' />
                                                            {/* <div className='caption border-top-0 p-2'>
                                                                <H4>{'}</H4>
                                                                <P>{"Using a camera's lens to record moments in time is the art form known as photography. To produce aesthetically striking photos, it entails the deft use of light, composition, and subject matter."}</P>
                                                            </div> */}
                                                        </>
                                                    )}
                                                </Item>
                                            </figure>
                                        </Col>
                                    ))}
                                    </Slider>
                                </Gallery>
                            </Row>
                        </CardBody>
                    </Card>
                    {productGalleryData.map((item) => (
                        <div className="product-upload" key={item.id}>
                            {/* <CommonCardHeader title={'Manage Gallery'} subTitle={[]} /> */}
                            <Form>
                                <Dropzone
                                    autoUpload={true}
                                    multiple={true}
                                    maxFiles={3}
                                    getUploadParams={getUploadParams}
                                    // onSubmit={handleSubmit}
                                    onChangeStatus={handleChangeStatus}
                                    accept="image/*"
                                    submitButtonDisabled
                                    inputContent={
                                        <div className='dz-message needsclick'>
                                            <SvgIcon iconId={item.icon} />
                                            <H6>{'Drag files here'}</H6>
                                            <span className='note needsclick'>{item.spanText}</span>
                                        </div>
                                    } />
                            </Form>
                        </div>
                    ))}
                    <div className="product-buttons1 position-bottom pb-2">
                        <div className="d-md-flex justify-content-md-start common-flex">
                            <Btn type='button' color='secondary' onClick={() => modalData.toggler()}>{Cancel}</Btn>
                            <Btn type='submit' color='primary' onClick={() => handleSubmit()}>{StrSubmit}</Btn>
                        </div>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    </>)
}

export default HotelGalleryForm;