import PackageCreate from "../../Components/AdminDashboard/Packages/PackageCreate"

const PackageCreatePage = () => {
    return (
        <div className='page-body main-project'>
            <PackageCreate />
        </div>
    )
}

export default PackageCreatePage