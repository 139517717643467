
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const PhoneNumberInput = (props: any) => {
    const { name, showMinusBtn, handleDelete, handleChange, value } = props;
    return(
        <PhoneInput
            country={'in'}
            inputStyle={{width: '250px'}}
            value={value}
            enableSearch={true}
            countryCodeEditable={true}
            enableAreaCodes={true}
            onChange={handleChange}
        />
    )
}

export default PhoneNumberInput;