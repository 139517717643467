import { createSlice, PayloadAction, SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit';
import { GenericStateInterface } from './reduxDTO';

const createGenericSlice = <
    Reducers extends SliceCaseReducers<GenericStateInterface>
>({
        name = '',
        initialState,
        reducers,
    }: {
    name: string
    initialState: GenericStateInterface
    reducers: ValidateSliceCaseReducers<GenericStateInterface, Reducers>
}) => {
    return createSlice({
        name,
        initialState,
        reducers: {
            /**
             * If you want to write to values of the state that depend on the generic
             * (in this case: `state.data`, which is T), you might need to specify the
             * State type manually here, as it defaults to `Draft<GenericState<T>>`,
             * which can sometimes be problematic with yet-unresolved generics.
             * This is a general problem when working with immer's Draft type and generics.
             */
            setLoading: state => {
                state.isError = false;
                state.isSuccess = false;
                state.status = 'none';
                state.isLoading = true;
                state.status = 'loading';
            },
            setError: (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.status = 'error';
                state.data = action.payload;
            },
            setSuccess(state, action: PayloadAction<any>) {
                state.isLoading = false;
                state.isSuccess = true;
                state.status = 'success';
                state.data = action.payload;
            },
            setReset: (state) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = false;
                state.status = 'none';
                state.data = {
                    body: [],
                    message: '',
                    statusCode: 0
                };
            },
            ...reducers,
        },
    });
};

export default createGenericSlice;