import { useState, useCallback } from 'react';
import { Row, Col, Card, CardBody, Container } from 'reactstrap';
import { StrBookPackage } from '../../../../Utils/Constants';
import CommonCardHeader from '../../../../Utils/CommonComponents/CommonCardHeader';
import Loader from '../../../../Layout/Loader';
import BookPackageFormNav from './BookPackageFormNav';
import BookPackageTabContents from './BookPackageTabContents';

const simpleTabSubTitle = [
    {
        text: 'Enter',
        code: 'Package'
    },
    {
        text: ' details to  ',
        code: 'Book a Package'
    }
];

export default function BookPackage(): JSX.Element {
    const [showLoader, setShowLoader] = useState(false);
    const [steps, setSteps] = useState(1);
    const activeCallBack = useCallback((tab: number) => {
        setSteps(tab);
    }, []);
    
    return (
        <Container fluid>
            <Loader show={showLoader} />
            <Row>
                <Col sm={12} xl={12}>
                    <Card className='title-line'>
                        <CommonCardHeader title={StrBookPackage} subTitle={simpleTabSubTitle} />
                        <CardBody>
                            <Row className="g-xl-5 g-3">
                                <BookPackageFormNav steps={steps} setSteps={setSteps} />
                                <BookPackageTabContents steps={steps} activeCallBack={activeCallBack} showLoader={showLoader} setShowLoader={setShowLoader} />
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}
