import { useEffect, useState } from 'react'
import { Row, Col, FormFeedback, FormGroup, Input, Label, Container, InputGroup } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { toast } from 'react-toastify';
import { Rating } from 'react-simple-star-rating';
import { Formik, Form, Field } from 'formik';
import { ConnectedFocusError } from 'focus-formik-error'
import Select, { IndicatorSeparatorProps } from 'react-select';
import makeAnimated from 'react-select/animated';
import * as Yup from 'yup';
// import { Country as CountryList, State as StateList, City as CityList }  from 'country-state-city';
import { BlockQuotes, Btn, H1, H3, H6, LI, P, UL } from '../../../AbstractElements';
import { Cancel, Submit, StrEmail, StrCity, StrHotelName, StrGroupName, StrRating, StrLocalityArea, StrPincode, StrStreetAddress, StrLandmark, StrLocation, StrMealPlan, StrCheckinTime, StrCheckoutTime, StrMinAge, StrMaxAge, StrPaymentPreference, StrOwnerNumber, StrManagerNumber, StrFrontDeskNumber } from '../../../Utils/Constants';
import PhoneNumberInput from './PhoneNumberInput';
import { MinusCircle } from 'react-feather';
import { createHotelAction, getHotelMealPlanTypeAction, getHotelPaymentTypeAction, getHotelRoomTypeAction, updateHotelAction } from '../../../ReduxToolkit/Reducers/Layout/Hotel/HotelAction';
import TimePickerComponent from '../../../CommonElements/Datepicker/TimePicker/TimePickerComponent';
import DateRange from '../../../CommonElements/Datepicker/DateRange';
import { HotelDetailType } from '../../../Types/InterfaceData.type';
import { getLocationsAction } from '../../../ReduxToolkit/Reducers/Layout/Location/LocationAction';

const animatedComponents = makeAnimated();

const simpleTabSubTitle = [
    {
        text: 'Enter / Select',
        code: 'Hotel'
    },
    {
        text: ' details to  ',
        code: 'create '
    }
];

const StyleContainer = styled(Container)`
    
`

const StyleIndicatorSeparator = {
    alignSelf: 'stretch',
    backgroundColor: '#00B8D9',
    marginBottom: 8,
    marginTop: 8,
    width: 1,
};

const phoneRegExp = /^[6-9]{1}[0-9]{9}$/
const formSchema = Yup.object().shape({
    hotelName: Yup.string().required('Hotel Name is required'),
    email: Yup.string().email('Email must be a valid email').required('Email is required'),
    ownerNumber: Yup.string().required('Owner number is required'),
    // managerNumber: Yup.string().required('Manager number is required'),
    // frontdeskNumber: Yup.string().required('Frontdesk number is required'),
    location: Yup.string().required('Location is required'),
    streetAddress: Yup.string().required('Street address is required'),
    city: Yup.string().required('City is required'),
    pincode: Yup.string().required('Pincode is required'),
    totalRooms: Yup.string().required('Total Rooms is required'),
    locality: Yup.string().required('Locality is required'),
    landmark: Yup.string().required('Landmark is required'),
    paymentReference: Yup.string().required('Payment ref is required')
})

interface HotelFormProps {
    detail?: HotelDetailType,
    handleModalToggle?: any
}

const HotelForm = ({ detail, handleModalToggle }: HotelFormProps) => {
    const navigate = useNavigate();
    const [rating, setRating] = useState(3.5);
    const [cityArr, setCityArr] = useState<any>([]);
    const [contactNumberCount, setContactNumberCount] = useState<number>(1);
    const [contactNumbersArr, setContactNumbersArr] = useState<any>([{
        phone: null,
        isPrimary: false
    }])
    const [roomTypeExtraBedArr, setRoomTypeExtraBedArr] = useState<any>([{
        roomTypes: null,
        allowedExtraBed: 0,
        noOfRooms: 0,
        mealPlan: null,
        price: 0
    }])

    const [mealTypeOptions, setMealTypeOptions] = useState<any>([]);
    const [roomTypeOptions, setRoomTypeOptions] = useState<any>([]);
    const [paymentTypePrefOptions, setPaymentTypePrefOptions] = useState<any>([]);
    const [offSeasonArr, setOffSeasonArr] = useState<any>([]);
    const [peakSeasonArr, setPeakSeasonArr] = useState<any>([]);

    useEffect(() => {
        if(detail && detail.hotelRoomsDetails && detail.hotelRoomsDetails.length > 0) {
            let roomInfo: any[] = [];
            detail.hotelRoomsDetails.forEach((roomData: any) => {
                roomInfo = [...roomInfo, {
                    roomTypes: roomData.roomTypeId,
                    allowedExtraBed: roomData.extraBeds,
                    noOfRooms: roomData.noOfRooms,
                    mealPlan: roomData.mealTypeId,
                    price: roomData.price,
                }]
            })
            setRoomTypeExtraBedArr(roomInfo);
        }
        if(detail && detail.hotelSeasonsInfo && detail.hotelSeasonsInfo.length > 0) {
            let offSeasonInfo: any[] = [];
            let peakSeasonInfo: any[] = [];
            detail.hotelSeasonsInfo.forEach((seasonData: any) => {
                if(seasonData.type === 'OffSeason') {
                    offSeasonInfo = [...offSeasonInfo, {
                        date: [seasonData.fromDate, seasonData.toDate]
                    }]
                }
                if(seasonData.type === 'PeakSeason') {
                    peakSeasonInfo = [...peakSeasonInfo, {
                        date: [seasonData.fromDate, seasonData.toDate]
                    }]
                }
            })
            setOffSeasonArr(offSeasonInfo);
            setPeakSeasonArr(peakSeasonInfo);
        }
    }, [detail])

    useEffect(() => {
        const response = getHotelMealPlanTypeAction({});
        response.then((result: any) => {
            if (result.status === 200) {
                const mealTypeAr = result.data.map((Ob: any) => {
                    return { value: Ob.id, label: Ob.name }
                })
                setMealTypeOptions(mealTypeAr);
            }
        })

        const respRoomTypes = getHotelRoomTypeAction({});
        respRoomTypes.then((result: any) => {
            if (result.status === 200) {
                const roomTypeAr = result.data.rows.map((Ob: any) => {
                    return { value: Ob.id, label: Ob.name }
                })
                setRoomTypeOptions(roomTypeAr);
            }
        })

        const respPaymentTypes = getHotelPaymentTypeAction({});
        respPaymentTypes.then((result: any) => {
            if (result.status === 200) {
                const paymentTypeAr = result.data.rows.map((Ob: any) => {
                    return { value: Ob.id, label: Ob.name }
                })
                setPaymentTypePrefOptions(paymentTypeAr);
            }
        })

        const respLocations = getLocationsAction({});
        respLocations.then((result: any) => {
            if (result.status === 200) {
                const locationAr = result.data.rows.map((Ob: any) => {
                    return { value: Ob.id, label: Ob.name }
                })
                setCityArr(locationAr);
            }
        })
    }, [])

    const handleSubmit = (request: any) => {
        // const validateContactNos = contactNumbersArr.find((Ob: any) => { return Ob.phone === null });
        // if(validateContactNos && Object.keys(validateContactNos).length > 0) {
        //     toast.error('Contact nos can not be empty!');
        //     return;
        // }
        // let offSeasonsData: string[] = [];
        // if(offSeasonArr.length > 0) {
        //     offSeasonArr.forEach((data: any) => {
        //         offSeasonsData.push(data['date'][0] + '~' + data['date'][0])
        //     })
        // }
        // let peakSeasonsData: string[] = [];
        // if(peakSeasonArr.length > 0) {
        //     peakSeasonArr.forEach((data: any) => {
        //         peakSeasonsData.push(data['date'][0] + '~' + data['date'][0])
        //     })
        // }
        
        const payload = {...request, ...{
            hotelRooms: roomTypeExtraBedArr,
            offSeasons: offSeasonArr,
            peakSeasons: peakSeasonArr
        }};
        if(detail && detail.id) {
            payload.id = detail.id;
            const response = updateHotelAction(payload);
            response.then((result: any) => {
                if(result.status === 200) {
                    toast.success(result.message);
                    handleModalToggle();
                    navigate('/hotels');
                } else {
                    toast.error(result.message);
                }
            });
        } else {
            const response = createHotelAction(payload);
            response.then((result: any) => {
                if(result.status === 200) {
                    toast.success(result.message);
                    navigate('/hotels');
                } else {
                    toast.error(result.message);
                }
            });
        }
    };

    const handleContactChange = (value: any, data: any, index: number) => {
        setContactNumbersArr([]);
        const contactAr = contactNumbersArr;
        contactAr[index] = { phone: value, isPrimary: contactAr[index]['isPrimary'] }
        setContactNumbersArr(contactAr);
    }

    const handleNumberChange = (value: any, data: any) => {
        setContactNumbersArr([]);
        const contactAr = contactNumbersArr;
    }

    const handleAddContact = () => {
        const contactAr = contactNumbersArr;
        setContactNumbersArr([...contactAr, {phone: null, isPrimary: false}]);
        setContactNumberCount(contactNumberCount+1)
    }

    const handleDeleteContact = (index: number) => {
        setContactNumbersArr([]);
        const contactAr = contactNumbersArr;
        contactAr.splice(index, 1)
        setContactNumbersArr(contactAr);
        setContactNumberCount(contactNumberCount-1)
    }

    const handlePrimaryContact = (index: number) => {
        const contactAr = contactNumbersArr.map((contactObj: any, i: number) => {
            if(index === i) {
                return { phone: contactObj.phone, isPrimary: true }
            }
            return { phone: contactObj.phone, isPrimary: false }
        })
        setContactNumbersArr(contactAr);
    }

    const handleRoomExtraBedClick = () => {
        const roomTypeAr = [...roomTypeExtraBedArr];
        setRoomTypeExtraBedArr([...roomTypeAr, {
            roomTypes: null,
            allowedExtraBed: 0,
            noOfRooms: 0,
            mealPlan: null,
            price: 0
        }]);
    }

    const handleRemoveRoomExtraBedClick = (index: number) => {
        // setRoomTypeExtraBedArr([]);
        const roomTypeAr = [...roomTypeExtraBedArr];
        roomTypeAr.splice(index, 1);
        setRoomTypeExtraBedArr(roomTypeAr);
    }

    const handleRoomExtraBedChange = (value: any, index: number, type: string) => {
        const roomTypeAr = [...roomTypeExtraBedArr];
        if(type === 'roomType') {
            // const roomTypes = value.map((Ob: any) => { return Ob.value })
            roomTypeAr[index] = { 
                roomTypes: value,
                mealPlan: roomTypeAr[index]['mealPlan'],
                allowedExtraBed: roomTypeAr[index]['allowedExtraBed'], 
                noOfRooms: roomTypeAr[index]['noOfRooms'],
                price: roomTypeAr[index]['price'],
            }
        } else if(type === 'extraBed') {
            roomTypeAr[index] = { 
                roomTypes: roomTypeAr[index]['roomTypes'],
                mealPlan: roomTypeAr[index]['mealPlan'],
                allowedExtraBed: value, 
                noOfRooms: roomTypeAr[index]['noOfRooms'],
                price: roomTypeAr[index]['price']
            }
        } else if(type === 'noOfRoom') {
            roomTypeAr[index] = {
                roomTypes: roomTypeAr[index]['roomTypes'],
                mealPlan: roomTypeAr[index]['mealPlan'],
                allowedExtraBed: roomTypeAr[index]['allowedExtraBed'], 
                noOfRooms: value,
                price: roomTypeAr[index]['price']
            }
        } else if(type === 'mealPlan') {
            roomTypeAr[index] = {
                roomTypes: roomTypeAr[index]['roomTypes'],
                mealPlan: value,
                allowedExtraBed: roomTypeAr[index]['allowedExtraBed'], 
                noOfRooms: roomTypeAr[index]['noOfRooms'],
                price: roomTypeAr[index]['price'],
            }
        }  else if(type === 'price') {
            roomTypeAr[index] = {
                roomTypes: roomTypeAr[index]['roomTypes'],
                mealPlan: roomTypeAr[index]['mealPlan'],
                allowedExtraBed: roomTypeAr[index]['allowedExtraBed'], 
                noOfRooms: roomTypeAr[index]['noOfRooms'],
                price: value
            }
        }
        setRoomTypeExtraBedArr(roomTypeAr);
    }

    const handleAddMoreOffSeason = () => {
        const offSeasonTmpArr = [...offSeasonArr];
        setOffSeasonArr([...offSeasonTmpArr, {
            date: []
        }]);
    }

    const handleAddMorePeakSeason = () => {
        const peakSeasonTmpArr = [...peakSeasonArr];
        setPeakSeasonArr([...peakSeasonTmpArr, {
            date: []
        }]);
    }

    const handleRemoveOffSeason = (index: number) => {
        const offSeasonTmpArr = [...offSeasonArr];
        offSeasonTmpArr.splice(index, 1);
        setOffSeasonArr(offSeasonTmpArr);
    }

    const handleRemovePeakSeason = (index: number) => {
        const peakSeasonTmpArr = [...peakSeasonArr];
        peakSeasonTmpArr.splice(index, 1);
        setPeakSeasonArr(peakSeasonTmpArr);
    }

    const handleDateRangeChange = (date: any, index: number, type: string) => {
        if(type === 'offSeason') {
            const offSeasonTmpArr = [...offSeasonArr];
            offSeasonTmpArr[index] = {
                date: [new Date(date[0]), new Date(date[1])]
            }
            setOffSeasonArr(offSeasonTmpArr);
        }
        if(type === 'peakSeason') {
            const peakSeasonTmpArr = [...peakSeasonArr];
            peakSeasonTmpArr[index] = {
                date: [new Date(date[0]), new Date(date[1])]
            }
            setPeakSeasonArr(peakSeasonTmpArr);
        }
    };

    const getSelectedCity = (city: string) => {
        if(!city) return null;

        const selected = cityArr && cityArr.length > 0 && cityArr.find((ar: any) => ar.value === parseInt(city))
        return { value: selected.value, label: selected.label }
    }

    const getSelectedRoom = (roomType: string) => {
        if(!roomType) return null;

        const roomObj = roomTypeOptions && roomTypeOptions.length > 0 && roomTypeOptions.find((arr: any) => arr.value === roomType);
        return roomObj;
    }

    const getSelectedMeal = (value: string) => {
        if(!value) return null;

        const mealObj = mealTypeOptions && mealTypeOptions.length > 0 && mealTypeOptions.find((arr: any) => arr.value === value);
        return mealObj;
    }

    const getSelectedPaymentRef = (value: string) => {
        if(!value) return null;

        const paymentRefObj = paymentTypePrefOptions && paymentTypePrefOptions.length > 0 && paymentTypePrefOptions.find((arr: any) => arr.value === value);
        return paymentRefObj;
    }

    return <>
        <Formik
            enableReinitialize={detail && Object.keys(detail).length > 0}
            initialValues={{
                hotelName: detail && detail.name,
                groupName: detail && detail.groupName,
                email: detail && detail.email,
                ownerNumber: detail && detail.ownerNumber,
                managerNumber: detail && detail.managerNumber,
                frontdeskNumber: detail && detail.frontdeskNumber,
                rating: detail ? detail.rating : 3.5,
                location: detail && detail.location,
                city: detail && detail.city,
                pincode: detail && detail.pincode,
                streetAddress: detail && detail.streetAddress,
                locality: detail && detail.locality,
                landmark: detail && detail.landmark,
                totalRooms: detail && detail.totalRooms,
                checkInTime: detail && detail.checkInTime ? new Date(detail.checkInTime) : new Date(new Date().setHours(12, 0, 0)),
                checkOutTime: detail && detail.checkOutTime ? new Date(detail.checkOutTime) : new Date(new Date().setHours(11, 59, 0)),
                childMinAge: detail ? detail.childMinAge : 6,
                childMaxAge: detail ? detail.childMaxAge : 12,
                paymentReference: detail ? detail.paymentReferenceId : '',
                hotelImages: ''
            }}
            validationSchema={formSchema}
            onSubmit={values => {
                handleSubmit(values);
            }}
        >
            {({ errors, touched, setFieldValue, values, setTouched }) => (
                <Form>
                    {/* <>{console.log(errors)}</> */}
                    <ConnectedFocusError />
                    <Row>
                        <BlockQuotes className={`blockquote light-card bg-light-primary`}>
                            <H1><span className="txt-primary pb-1"> {'Basic Details'}</span></H1>
                            <P className="mb-0 txt-dark">{"Provide basic details e.g. name, star rating and address."}</P>
                        </BlockQuotes>
                        <Col sm={4}>
                            <FormGroup>
                                <Col><Label className='required-field'>{StrHotelName}</Label></Col>
                                <Input type="text" tag={Field} placeholder={StrHotelName} name="hotelName" value={values.hotelName} invalid={touched.hotelName && errors.hotelName ? true : false} />
                                {touched.hotelName && errors.hotelName && <FormFeedback>{errors.hotelName}</FormFeedback>}
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup>
                                <Col><Label>{StrGroupName}</Label></Col>
                                <Input type="text" placeholder={StrGroupName} name="groupName" tag={Field} value={values.groupName} invalid={touched.groupName && errors.groupName ? true : false} />
                                {touched.groupName && errors.groupName && <FormFeedback>{errors.groupName}</FormFeedback>}
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup>
                                <Col><Label className='required-field'>{StrEmail}</Label></Col>
                                <Input type="email" placeholder={StrEmail} name="email" tag={Field} value={values.email} invalid={touched.email && errors.email ? true : false} />
                                {touched.email && errors.email && <FormFeedback>{errors.email}</FormFeedback>}
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup>
                                <Col><Label className='required-field'>{StrOwnerNumber}</Label></Col>
                                <PhoneNumberInput
                                    name={'ownerNumber'}
                                    value={values.ownerNumber}
                                    handleChange={(value: any, data: any) => setFieldValue('ownerNumber', value)}
                                />
                                {touched.ownerNumber && errors.ownerNumber && <FormFeedback>{errors.ownerNumber}</FormFeedback>}
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup>
                                <Col><Label>{StrManagerNumber}</Label></Col>
                                <PhoneNumberInput
                                    name={'managerNumber'}
                                    value={values.managerNumber}
                                    handleChange={(value: any, data: any) => setFieldValue('managerNumber', value)}
                                />
                                {touched.managerNumber && errors.managerNumber && <FormFeedback>{errors.managerNumber}</FormFeedback>}
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup>
                                <Col><Label>{StrFrontDeskNumber}</Label></Col>
                                <PhoneNumberInput
                                    name={'frontdeskNumber'}
                                    value={values.frontdeskNumber}
                                    handleChange={(value: any, data: any) => setFieldValue('frontdeskNumber', value)}
                                />
                                {touched.frontdeskNumber && errors.frontdeskNumber && <FormFeedback>{errors.frontdeskNumber}</FormFeedback>}
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup>
                                <Col><Label className='required-field'>{StrLocation}</Label></Col>
                                <Input type="text" placeholder={StrLocation} name="location" tag={Field} value={values.location} invalid={touched.location && errors.location ? true : false} />
                                {touched.location && errors.location && <FormFeedback>{errors.location}</FormFeedback>}
                                <P className={`mb-0 txt-dark`}><small>{"Hotel's city location. Must match with uploaded data. This will used during filters and quote sharing"}</small></P>
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup>
                                <Col><Label className='required-field'>{StrStreetAddress}</Label></Col>
                                <Input type="text" placeholder={StrStreetAddress} name="streetAddress" tag={Field} value={values.streetAddress} invalid={touched.streetAddress && errors.streetAddress ? true : false} />
                                {touched.streetAddress && errors.streetAddress && <FormFeedback>{errors.streetAddress}</FormFeedback>}
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup>
                                <Col><Label className='required-field'>{StrLocalityArea}</Label></Col>
                                <Input type="text" placeholder={'Colony / Street / Locality'} name="locality" tag={Field} value={values.locality} invalid={touched.locality && errors.locality ? true : false} />
                                {touched.locality && errors.locality && <FormFeedback>{errors.locality}</FormFeedback>}
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup>
                                <Col><Label className='required-field'>{StrLandmark}</Label></Col>
                                <Input type="text" placeholder={StrLandmark} name="landmark" tag={Field} value={values.landmark} invalid={touched.landmark && errors.landmark ? true : false} />
                                {touched.landmark && errors.landmark && <FormFeedback>{errors.landmark}</FormFeedback>}
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup>
                                <Col><Label className='required-field'>{StrCity}</Label></Col>
                                <Field name="city" render={(field: any) => <Select
                                    {...field}
                                    closeMenuOnSelect={true}
                                    components={{ StyleIndicatorSeparator }}
                                    value={values.city ? getSelectedCity(values.city) : ''}
                                    options={cityArr}
                                    onChange={(e: any) => {
                                        setFieldValue('city', e.value)
                                    }}
                                />} />
                                {touched.city && errors.city && <FormFeedback>{errors.city}</FormFeedback>}
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup>
                                <Col><Label className='required-field'>{StrPincode}</Label></Col>
                                <Input type="text" placeholder={'Area Code'} name="pincode" tag={Field} value={values.pincode} invalid={touched.pincode && errors.pincode ? true : false} />
                                {touched.pincode && errors.pincode && <FormFeedback>{errors.pincode}</FormFeedback>}
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup>
                                <Col><Label className='required-field'>{StrRating}</Label></Col>
                                <Field name="rating" render={(field: any) => <div className="rating">
                                    <div className='d-flex'>
                                        <Rating
                                            {...field}
                                            value={values.rating}
                                            initialValue={values.rating}
                                            allowFraction={true}
                                            size={30}
                                            transition
                                            fillColor="gold"
                                            emptyColor="gray"
                                            onClick={(newRating) => { setRating(newRating); setFieldValue('rating', newRating) }}
                                        />
                                    </div>
                                    <span className="text-primary current-rating">{rating}</span>
                                </div>} />
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label className='required-field'>{'Hotel Total Rooms'}</Label>
                                <Input type="number" placeholder={'Hotel Total Rooms'} name="totalRooms" tag={Field} value={values.totalRooms} invalid={touched.totalRooms && errors.totalRooms ? true : false} />
                                {touched.totalRooms && errors.totalRooms && <FormFeedback>{errors.totalRooms}</FormFeedback>}
                            </FormGroup>
                        </Col>
                    </Row>
                    {offSeasonArr.map((item: any, index: number) => {
                        return (<Row>
                            <Col sm={4}>
                                <FormGroup>
                                    <Label>Off Seasons</Label>
                                    <InputGroup className="flatpicker-calender"><DateRange value={item.date} handleDateChange={(date: any) => handleDateRangeChange(date, index, 'offSeason')} /></InputGroup>
                                </FormGroup>
                            </Col>
                            <Col sm={2}>
                                <Btn type='button' className={'b-ln-height b-r-8 mt-4'}
                                    style={{ padding: '5px 10px' }}
                                    outline={false}
                                    disabled={false}
                                    size='sm'
                                    color={'danger'}
                                    onClick={() => { handleRemoveOffSeason(index) }}
                                >
                                    {<MinusCircle />}
                                </Btn>
                            </Col>
                        </Row>)
                    })}
                    <Row className='pb-2'>
                        <Col xs={3}>
                            <div className={`d-grid gap-2 buttons-box col-12 mx-auto`}>
                                <Btn type='button' onClick={() => handleAddMoreOffSeason()} color={'light-primary'}>{'Click To Add Off Seasons'}</Btn>
                            </div>
                        </Col>
                    </Row>
                    {peakSeasonArr.map((item: any, index: number) => {
                        return (<Row>
                            <Col sm={4}>
                                <FormGroup>
                                    <Label>Peak Seasons</Label>
                                    <InputGroup className="flatpicker-calender"><DateRange value={item.date} handleDateChange={(date: any) => handleDateRangeChange(date, index, 'peakSeason')} /></InputGroup>
                                </FormGroup>
                            </Col>
                            <Col sm={2}>
                                <Btn type='button' className={'b-ln-height b-r-8 mt-4'}
                                    style={{ padding: '5px 10px' }}
                                    outline={false}
                                    disabled={false}
                                    size='sm'
                                    color={'danger'}
                                    onClick={() => { handleRemovePeakSeason(index) }}
                                >
                                    {<MinusCircle />}
                                </Btn>
                            </Col>
                        </Row>)
                    })}
                    <Row className='pb-2'>
                        <Col xs={3}>
                            <div className={`d-grid gap-2 buttons-box col-12 mx-auto`}>
                                <Btn type='button' onClick={() => handleAddMorePeakSeason()} color={'light-secondary'}>{'Click To Add Peak Seasons'}</Btn>
                            </div>
                        </Col>
                    </Row>
                    <H3><span className="txt-primary pb-1"> {'Room Types with Allowed Extra Bed(s)/Mattress(es)'}</span></H3>
                    <H6><span className="pb-1"> {'Please select room types available in this hotel along with allowed extra beds. Group room types that have same number of allowed extra beds'}</span></H6>
                    <Row>
                        {roomTypeExtraBedArr.map((item: any, index: number) => {
                            return <Row>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label><strong>{'Room Types'}</strong></Label>
                                        <Field render={(field: any) => <Select
                                            {...field}
                                            closeMenuOnSelect={true}
                                            components={animatedComponents}
                                            value={item.roomTypes ? getSelectedRoom(item.roomTypes) : ''}
                                            isMulti={false}
                                            options={roomTypeOptions}
                                            onChange={(e: any) => handleRoomExtraBedChange(e, index, 'roomType')}
                                        />} />
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label><strong>{StrMealPlan}</strong></Label>
                                        <Field render={(field: any) => <Select
                                            {...field}
                                            closeMenuOnSelect={true}
                                            components={animatedComponents}
                                            value={item.roomTypes ? getSelectedMeal(item.mealPlan) : ''}
                                            isMulti={false}
                                            options={mealTypeOptions}
                                            onChange={(e: any) => handleRoomExtraBedChange(e, index, 'mealPlan')}
                                        />} />
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label><strong>{'Allowed extra bed(s)'}</strong></Label>
                                        <Input type="number" placeholder={'0'} tag={Field} value={item.allowedExtraBed} onChange={(e: any) => handleRoomExtraBedChange(e.target.value, index, 'extraBed')} />
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label><strong>{'No. of Rooms'}</strong></Label>
                                        <Input type="number" placeholder={'0'} tag={Field} value={item.noOfRooms} onChange={(e: any) => handleRoomExtraBedChange(e.target.value, index, 'noOfRoom')} />
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label><strong>{'Price'}</strong></Label>
                                        <Input type="number" placeholder={'0'} tag={Field} value={item.price} onChange={(e: any) => handleRoomExtraBedChange(e.target.value, index, 'price')} />
                                    </FormGroup>
                                </Col>
                                {roomTypeExtraBedArr.length > 1 &&
                                    <Col sm={1}>
                                        <Btn type='button' className={'b-ln-height b-r-8 mt-4'}
                                            style={{ padding: '5px 10px' }}
                                            outline={false} disabled={false} size='sm'
                                            color={'danger'}
                                            onClick={() => { handleRemoveRoomExtraBedClick(index) }}
                                        >
                                            {<MinusCircle />}
                                        </Btn>
                                    </Col>
                                }
                            </Row>
                        })}
                        <Row className='pb-2'>
                            <Col sm={3}>
                                <Btn className={'btn-pill'}
                                    outline={true}
                                    size={'sm'} color={'primary'}
                                    onClick={() => handleRoomExtraBedClick()}
                                >
                                    {'Add Rooms with Different Extra Beds'}
                                </Btn>
                            </Col>
                        </Row>
                    </Row>
                    <Row>
                        <BlockQuotes className={`blockquote light-card bg-light-primary`}>
                            <H1><span className="txt-primary pb-1"> {'Configuration'}</span></H1>
                            <P className="mb-0 txt-dark">{"Please provide the checkin/checkout time along with age of children for extra beds."}</P>
                        </BlockQuotes>
                        <Col sm={4}>
                            <FormGroup className='timePicker'>
                                <Col><Label className='required-field'>{StrCheckinTime}</Label></Col>
                                <InputGroup><TimePickerComponent startDate={values.checkInTime} handleChange={(date: any) => setFieldValue('checkInTime', date)} /></InputGroup>
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup className='timePicker'>
                                <Col><Label className='required-field'>{StrCheckoutTime}</Label></Col>
                                <InputGroup><TimePickerComponent startDate={values.checkOutTime} handleChange={(date: any) => setFieldValue('checkOutTime', date)} /></InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <H3><span className="txt-primary pb-1"> {'Children Age range for Charges on Child No Bed / Child Extra Bed/Mattress'}</span></H3>
                        <Col sm={4}>
                            <FormGroup>
                                <Col><Label className='required-field'>{StrMinAge}</Label></Col>
                                <Input type="number" placeholder={StrMinAge} name="childMinAge" tag={Field} value={values.childMinAge} invalid={touched.childMinAge && errors.childMinAge ? true : false} />
                                {touched.childMinAge && errors.childMinAge && <FormFeedback>{errors.childMinAge}</FormFeedback>}
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup>
                                <Col><Label className='required-field'>{StrMaxAge}</Label></Col>
                                <Input type="number" placeholder={StrMaxAge} name="childMaxAge" tag={Field} value={values.childMaxAge} invalid={touched.childMaxAge && errors.childMaxAge ? true : false} />
                                {touched.childMaxAge && errors.childMaxAge && <FormFeedback>{errors.childMaxAge}</FormFeedback>}
                            </FormGroup>
                        </Col>
                        <H6>
                            <span className="pb-1">
                                <UL className='list-group-numbered'>
                                    <LI>Below this range, children are counted as <strong>complementary kids.</strong></LI>
                                    <LI>Above this range, children are counted as <strong>Adults.</strong></LI>
                                    <LI>Between this range, charges for <strong>no extra beds, extra beds or meal</strong> will be applicable</LI>
                                </UL>
                            </span>
                        </H6>
                    </Row>
                    <Row>
                        <BlockQuotes className={`blockquote light-card bg-light-primary`}>
                            <H1><span className="txt-primary pb-1"> {'Payment Preferences'}</span></H1>
                            <P className="mb-0 txt-dark">{"Select / Create a payment preference for by which this hotel accepts the payments."}</P>
                        </BlockQuotes>
                        <Col sm={4}>
                            <FormGroup>
                                <Col><Label className='required-field'>{StrPaymentPreference}</Label></Col>
                                <Field name="paymentReference" render={(field: any) => <Select
                                    closeMenuOnSelect={true}
                                    {...field}
                                    components={animatedComponents}
                                    value={values.paymentReference ? getSelectedPaymentRef(values.paymentReference) : ''}
                                    isMulti={false}
                                    options={paymentTypePrefOptions}
                                    onChange={(e: any) => {
                                        setFieldValue('paymentReference', e.value)
                                    }}
                                />} />
                                {touched.paymentReference && errors.paymentReference && <FormFeedback>{errors.paymentReference}</FormFeedback>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <BlockQuotes className={`blockquote light-card bg-light-primary`}>
                            <H1><span className="txt-primary pb-1"> {'More Details'}</span></H1>
                            <P className="mb-0 txt-dark">{"Attach more details such as hotel's details link etc."}</P>
                        </BlockQuotes>
                        <Col sm={4}>
                            <FormGroup>
                                <Col><Label>{'Hotel Images / Details Link'}</Label></Col>
                                <Input type="text" placeholder={''} name="hotelImages" tag={Field} value={values.hotelImages} invalid={touched.hotelImages && errors.hotelImages ? true : false} />
                                {touched.hotelImages && errors.hotelImages && <FormFeedback>{errors.hotelImages}</FormFeedback>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup className='p-t-20'>
                        <div className="d-md-flex justify-content-md-start common-flex">
                            { handleModalToggle ?
                                <Btn type='button' color='secondary' onClick={() => handleModalToggle()}>{Cancel}</Btn>
                                :
                                <Btn type='button' color='secondary' onClick={() => navigate('/hotels')}>{Cancel}</Btn>
                            }
                            <Btn type='submit' color='primary'>{Submit}</Btn>
                        </div>
                    </FormGroup>
                </Form>
            )}
        </Formik>
    </>
}

export default HotelForm