
import { ApexOptions } from "apexcharts";
import { CustomComponentProps } from "../../Types/Dashboard.type";
import { Href } from "../../Utils/Constants";

export const workspaceDashboardHeader = [
    { id: 1, head: "Workspace Name" },
    { id: 2, head: "Email" },
    { id: 3, head: "Phone Number" },
    { id: 4, head: "First Name" },
    { id: 5, head: "Last Name" },
    { id: 6, head: "Payment Status" },
    { id: 7, head: "Status" },
    { id: 8, head: "Start Date" },
    { id: 9, head: "End Date" },
];

export const workspaceHeader = [
    { id: 1, head: "Workspace Name" },
    { id: 2, head: "Email" },
    { id: 3, head: "Phone Number" },
    { id: 4, head: "First Name" },
    { id: 5, head: "Last Name" },
    { id: 6, head: "Payment Status" },
    { id: 7, head: "Status" },
    { id: 8, head: "Start Date" },
    { id: 9, head: "End Date" },
    { id: 10, head: "Action" },
];

export const InvitiesDashboardHeader = [
    { id: 1, head: "Email" },
    { id: 2, head: "Invitation Type" },
    { id: 3, head: "Invitated Date" },
];

const CustomOrderId = ({ span }: CustomComponentProps) => (
    <a className="f-w-500" href={Href}>{span}</a>
)

export const AdminDashboardTeamOverview = [
    { id: 1, head: "Teammates" },
    { id: 2, head: "Assigned Leads" },
    { id: 3, head: "Closed Leads" }
]

export const AdminDashboardLeadOverview = [
    { id: 1, head: "Total" },
    { id: 2, head: "Connected" },
    { id: 3, head: "Inprocess" },
    { id: 3, head: "Inprocess" },
    { id: 3, head: "Not connected" }
]

export const leadsListHeader = [
    { id: 1, head: "Source Type" },
    { id: 2, head: "Full Name" },
    { id: 3, head: "Email" },
    { id: 4, head: "Phone Number" },
    { id: 4, head: "Assign To" },
    { id: 5, head: "Created On" },
    { id: 6, head: "Update On" },
    { id: 7, head: "Status" }
];

//charts
const widgetRevenueOption: ApexOptions = {
    series: [
        {
            name: "Sales",
            data: [5, 25, 3, 20, 15],
        },
        {
            name: "Revenue",
            data: [5, 15, 3, 14, 15],
        },
    ],
    chart: {
        height: 140,
        type: "line",
        toolbar: {
            show: false,
        },
    },
    stroke: {
        width: 2,
        curve: "smooth",
    },
    xaxis: {
        type: "category",
        categories: ["Sat", "Sun", "Mon", "Tue", "Wed"],
        tickAmount: 6,
        axisBorder: {
            show: false,
        },
        axisTicks: {
            show: false,
        },
        labels: {
            style: {
                colors: ["var(--body-light-font-color)"],
            },
        },
    },
    grid: {
        show: true,
        borderColor: "var(--chart-border)",
        strokeDashArray: 6,
        position: "back",
    },
    colors: ["#80be70", "#c8e7e5"],
    fill: {
        type: "gradient",
        gradient: {
            shade: "dark",
            gradientToColors: ["#029eb4"],
            shadeIntensity: 1,
            type: "horizontal",
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
        },
    },
    legend: {
        show: false,
    },
    yaxis: {
        min: 0,
        max: 30,
        tickAmount: 3,
    },

    responsive: [
        {
            breakpoint: 1296,
            options: {
                chart: {
                    width: "100%",
                },
                xaxis: {
                    labels: {
                        show: false,
                    },
                },
                yaxis: {
                    labels: {
                        show: false,
                    },
                },
            },
        },
        {
            breakpoint: 1200,
            options: {
                chart: {
                    width: "100%",
                    height: 120,
                },
            },
        },
    ],
};

const widgetPipelineOptions: ApexOptions = {
    series: [10, 60, 30],
    labels: ["Store", "Ad", "Search"],
    chart: {
        width: 270,
        height: 270,
        type: "donut",
    },
    plotOptions: {
        pie: {
            startAngle: -90,
            endAngle: 270,
            donut: {
                labels: {
                    show: true,
                    name: {
                        offsetY: 4,
                    },
                    total: {
                        show: true,
                        fontSize: "12px",
                        fontFamily: "Inter, sans-serif",
                        fontWeight: 600,
                        label: "88%",
                        color: "#000",
                    },
                },
            },
        },
    },
    dataLabels: {
        enabled: false,
    },
    colors: ["#f99b0d", "#009DB5", "#7fbe71"],
    fill: {
        type: "gradient",
    },
    legend: {
        formatter: function (val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex];
        },
    },
    responsive: [
        {
            breakpoint: 480,
            options: {
                chart: {
                    width: 200,
                },
                legend: {
                    position: "bottom",
                },
            },
        },
        {
            breakpoint: 680,
            options: {
                chart: {
                    width: "100%",
                    height: 155,
                },
                legend: {
                    show: false,
                },
            },
        },
        {
            breakpoint: 735,
            options: {
                chart: {
                    width: 165,
                    height: 165,
                },
                legend: {
                    show: false,
                },
            },
        },
        {
            breakpoint: 1263,
            options: {
                chart: {
                    offsetX: 10,
                    width: 150,
                    height: 150,
                },
                legend: {
                    show: false,
                },
            },
        },
        {
            breakpoint: 1466,
            options: {
                chart: {
                    offsetX: 10,
                    width: 140,
                    height: 140,
                },
                legend: {
                    show: false,
                },
            },
        },
    ],
};

export const generalSmallChartsData = [
    {
        id: 1,
        colClass: 'col-xl-4 col-md-5 col-sm-4 order-md-v',
        cardClass: 'sales-pipeline pipeline-chart1',
        title: 'Sales Overview',
        span: 'Special Discount',
        span2: '60% OFF',
        chartClass: 'pipeline',
        chartId: 'pipeline-chart1',
        chart: {
            ...widgetPipelineOptions,
            series: widgetPipelineOptions.series,
            type: widgetPipelineOptions.chart?.type,
            height: widgetPipelineOptions.chart?.height,
            width: widgetPipelineOptions.chart?.width
        },
    },
    // {
    //     id: 2,
    //     colClass: 'col-xl-4 col-sm-6 col-md-3 order-md-i',
    //     cardClass: 'revenue-wrapper',
    //     title: 'Revenue',
    //     span: 'Today Revenue ',
    //     span2: '30% OFF',
    //     chartClass: 'revenue',
    //     chartId: 'revenue-chart1',
    //     chart: {
    //         ...widgetRevenueOption,
    //         series: widgetRevenueOption.series,
    //         type: widgetRevenueOption.chart?.type,
    //         height: widgetRevenueOption.chart?.height,
    //         width: widgetRevenueOption.chart?.width
    //     },
    // },
];