import React, { useEffect, useState } from 'react'
import { Col, TabContent, TabPane } from 'reactstrap'
import PackageDetailForm from './PackageDetailForm';
import PackageGalleryForm from './PackageGalleryForm';
import PackageItineraryForm from './PackageItineraryForm';
import { PackageDetailType } from '../../../Types/InterfaceData.type';
import { getPackageDetailAction } from '../../../ReduxToolkit/Reducers/Layout/Package/PackageAction';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PackageInclusionExclusionForm from './PackageInclusionExclusionForm';
import PackageTermForm from './PackageTermForm';

export interface PackageTabContentProp {
    activeCallBack: (tab: number) => void;
    steps: number,
    showLoader: boolean,
    setShowLoader: (val: boolean) => void,
}

export interface ActiveCallbackProp {
    toggle?: () => void,
    activeCallBack: (tab: number) => void;
    detail?: PackageDetailType,
    showLoader: boolean,
    setShowLoader: (val: boolean) => void,
}

export default function PackageTabContents({ steps, activeCallBack, showLoader, setShowLoader }: PackageTabContentProp) {
    const [detail, setDetail] = useState<PackageDetailType>()
    const [searchParams] = useSearchParams();
    const packageId = searchParams.get('id');

    useEffect(() => {
        if(packageId) {
            const response = getPackageDetailAction({ id: packageId });
            response.then((result: any) => {
                if (result.status === 200) {
                    setDetail(result.data);
                } else {
                    toast.error(result.message);
                }
            });
        }
    }, [packageId])

    return (
        <Col xxl={9} xl={8} className="box-col-8 position-relative">
            <TabContent activeTab={steps}>
                <TabPane tabId={1}>
                    <PackageDetailForm detail={detail} activeCallBack={activeCallBack} showLoader={showLoader} setShowLoader={setShowLoader} />
                </TabPane>
                <TabPane tabId={2}>
                    <PackageGalleryForm detail={detail} activeCallBack={activeCallBack} showLoader={showLoader} setShowLoader={setShowLoader} />
                </TabPane>
                <TabPane tabId={3}>
                    <PackageItineraryForm detail={detail} activeCallBack={activeCallBack} showLoader={showLoader} setShowLoader={setShowLoader} />
                </TabPane>
                <TabPane tabId={4}>
                    <PackageInclusionExclusionForm detail={detail} activeCallBack={activeCallBack} showLoader={showLoader} setShowLoader={setShowLoader} />
                </TabPane>
                <TabPane tabId={5}>
                    <PackageTermForm detail={detail} activeCallBack={activeCallBack} showLoader={showLoader} setShowLoader={setShowLoader} />
                </TabPane>
            </TabContent>
        </Col>
    )
}

