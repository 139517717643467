
import { useEffect, useState } from "react"
import { Formik, Form, Field } from "formik"
import ReactQuill from "react-quill"
import { Fragment } from "react/jsx-runtime"
import { Row, Col, FormGroup, Label, Input } from "reactstrap"
import * as Yup from 'yup';
import { Btn } from "../../../AbstractElements"
import { ActiveCallbackProp } from "./PackageTabContents"
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader"
import { Cancel, Previous, Submit } from "../../../Utils/Constants"
import { useNavigate, useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"
import { updateTermConditionAction } from "../../../ReduxToolkit/Reducers/Layout/Package/PackageAction"

const PackageTermForm = ({ activeCallBack, toggle, detail, showLoader, setShowLoader }: ActiveCallbackProp) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const packageId = searchParams.get('id');
    const [termConditionData, setTermConditionData] = useState<any>([])

    if (!packageId) {
        activeCallBack(1);
    }

    useEffect(() => {
        if (detail) {
            const groupByCategory = detail.additionalDetails.reduce((group: any, addDetail: any) => {
                const { infoType } = addDetail;
                group[infoType] = group[infoType] ?? [];
                group[infoType].push(addDetail);
                return group;
            }, {});
            if(groupByCategory && groupByCategory.TERMS && groupByCategory.TERMS.length > 0) {
                setTermConditionData(groupByCategory.TERMS);
            }
        }
    }, [detail])

    const handleAddMoreTermConditionClick = () => {
        const termConditionDataTmp = [...termConditionData];
        setTermConditionData([...termConditionDataTmp, {
            title: null,
            description: null
        }]);
    }

    const handleRemoveTermConditionClick = (index: number) => {
        const termConditionDataTmp = [...termConditionData];
        termConditionDataTmp.splice(index, 1);
        setTermConditionData(termConditionDataTmp);
    }

    const handleTermsData = (value: any, index: number, type: string) => {
        const termConditionDataTmp = [...termConditionData];
        if (type === 'title') {
            termConditionDataTmp[index] = {
                title: value,
                description: termConditionDataTmp[index]['description']
            }
        }
        if (type === 'description') {
            termConditionDataTmp[index] = {
                title: termConditionDataTmp[index]['title'],
                description: value
            }
        }
        setTermConditionData(termConditionDataTmp);
    }

    const handleSubmit = () => {
        setShowLoader(true);
        const response = updateTermConditionAction({
            packageId: packageId,
            termConditionData: termConditionData,
        });
        response.then((result: any) => {
            setShowLoader(false);
            if (result.status === 200) {
                toast.success(result.message);
                navigate('/packages');
            } else {
                toast.error(result.message);
            }
        });
    }

    return <Fragment>
        <Formik
            enableReinitialize={detail && Object.keys(detail).length > 0}
            initialValues={{

            }}
            validationSchema={Yup.object().shape({})}
            onSubmit={values => {
                handleSubmit();
            }}
        >
            {({ errors, touched, setFieldValue, values, setTouched }) => (
                <Form>
                    <Row className='pt-2'>
                        <CommonCardHeader title={'Manage Term & Condition'} subTitle={[]} />
                        {termConditionData && termConditionData.length > 0 && termConditionData.map((item: any, index: number) => {
                            return (
                                <Col sm={12} className='pb-2'>
                                    <div className="card-wrapper border rounded-3 checkbox-checked mb-2">
                                        <Row>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label className='required-field'><strong>{'Title'}</strong></Label>
                                                    <Input type="text" tag={Field} placeholder={'eg. Easy Cancellation'} name="title" value={item.title} onChange={(e: any) => handleTermsData(e.target.value, index, 'title')} />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label className='required-field'><strong>{'Term & Condition'}</strong></Label>
                                                    <ReactQuill theme='snow' placeholder={'Term & Condition'} value={item.description} onChange={(e: any) => handleTermsData(e, index, 'description')} />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={1}>
                                                <Btn type='button' className={'b-ln-height b-r-8'}
                                                    outline={false}
                                                    size={'sm'}
                                                    color={'danger'}
                                                    onClick={() => { handleRemoveTermConditionClick(index) }}
                                                >
                                                    {'Remove'}
                                                </Btn>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            )
                        })}
                        <Col xs={12}>
                            <div className={`d-grid gap-2 buttons-box col-12 mx-auto`}>
                                <Btn type='button' onClick={() => handleAddMoreTermConditionClick()} color={'light-primary'}>{'Click To Add Term & Condition'}</Btn>
                            </div>
                        </Col>
                    </Row>
                    <FormGroup className='p-t-20'>
                        <div className="d-md-flex justify-content-md-start common-flex">
                            {toggle ?
                                <Btn type='button' color='secondary' onClick={toggle}>{Cancel}</Btn>
                                :
                                <Btn type='button' color='secondary' onClick={() => navigate('/packages')}>{Cancel}</Btn>
                            }
                            <Btn type='button' color='danger' onClick={() => activeCallBack(3)}>{Previous}</Btn>
                            <Btn type='submit' color='primary' disabled={showLoader}>{Submit}</Btn>
                        </div>
                    </FormGroup>
                </Form>
            )}
        </Formik>
    </Fragment>
}

export default PackageTermForm