import { Card, CardBody, Nav, NavItem, NavLink, TabContent } from "reactstrap"
import styled from '@emotion/styled';
import AsyncSelect from 'react-select/async';
import ReactQuill from 'react-quill';
import Select, { IndicatorSeparatorProps } from 'react-select';
import { toast } from "react-toastify";
import parse from 'html-react-parser';
import { H2, UL, LI, P, H6, Badges, Btn } from "../../../AbstractElements"
import { Href, StrLeadsInfo, Submit, Cancel } from "../../../Utils/Constants"
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import { Fragment, useEffect, useState } from "react";
import { formatDate, historyStatusColor, leadStatus, statusColor } from "../../../helpers";
import { workspaceUsersAction } from "../../../ReduxToolkit/Reducers/Layout/Auth/LoginAction";
import { assignLeadAction, leadCommentUpdateAction, leadStatusUpdateAction } from "../../../ReduxToolkit/Reducers/Layout/Lead/LeadAction";
import 'react-quill/dist/quill.snow.css';
import { Link, useNavigate } from "react-router-dom";
import ReminderForm from "./ReminderForm";

const StyleCardBody = styled(CardBody)`
    min-height: 400px;
    overflow-y: scroll;
    .text-div {
        display: flex;
        gap: 50px;
        align-items: center;
    }
    .text-title {
        padding: 0px;
        width: 20%;
    }
    .ql-editor{
        height: 200px;
        max-height: 200px;
        overflow: auto;
    }
    .comment-title {
        padding-bottom: 10px;
    }
`

const StyleFaEditIcon = styled.span`
    margin-left: 0px;
    cursor: pointer;
`

const StyleHistoryDiv = styled.div`
    overflow-y: auto;
    max-height: 400px;
`

const StyleIndicatorSeparator = {
    alignSelf: 'stretch',
    backgroundColor: '#00B8D9',
    marginBottom: 8,
    marginTop: 8,
    width: 1,
};

const IndicatorSeparator = ({
    innerProps,
}: IndicatorSeparatorProps<any, true>) => {
    return <span style={StyleIndicatorSeparator} {...innerProps} />;
};

const LeadInfo = (props: any) => {
    const navigate = useNavigate();
    const { leadDetail, fetchLeadInfo } = props;
    const [activeTab, setActiveTab] = useState('history');
    const [assigneeListData, setAssigneeListData] = useState<any>();
    const [selectedAssigneeIndex, setSelectedAssigneeIndex] = useState<number>();
    const [selectedStatusIndex, setSelectedStatusIndex] = useState<number>();
    const [statusEditableMode, setStatusEditableMode] = useState<boolean>(false);
    const [commentEditableMode, setCommentEditableMode] = useState<boolean>(false);
    const [statusOptions, setStatusOptions] = useState<any>();
    const [leadComment, setLeadComment] = useState('');

    useEffect(() => {
        if(leadDetail && Object.keys(leadDetail).length > 0) {
            setLeadComment(leadDetail.comment);
            const response = workspaceUsersAction({});
            response.then((result: any) => {
                if (result.status === 200) {
                    const assigneeList = result.data.length > 0 && result.data.map((user: any) => {
                        return { value: user.id, label: `${user.firstName} ${user.lastName}` }
                    })
                    setAssigneeListData(assigneeList);
                    if(leadDetail && leadDetail.joinAssignedTo && Object.keys(leadDetail.joinAssignedTo).length > 0 && result.data.length > 0) {
                        const selectedUserIndex = result.data.findIndex((u: any) => u.id === leadDetail.joinAssignedTo.id);
                        setSelectedAssigneeIndex(selectedUserIndex)
                    } else {
                        setSelectedAssigneeIndex(-1)
                    }
                }
            });
            const statusOpt = leadStatus().length > 0 && leadStatus().map((status: any) => {
                return { value: status, label: status }
            })
            setStatusOptions(statusOpt);
            if(leadDetail && leadDetail.status) {
                const index = leadStatus().findIndex((u: any) => u === leadDetail.status);
                setSelectedStatusIndex(index)
            }
        }
    }, [leadDetail])

    const filterAssignee = (inputValue: string) => {
        return assigneeListData.length > 0 && assigneeListData.filter((i: any) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const loadAssigneeOptions = (
        inputValue: string,
        callback: (options: any) => void
    ) => {
        setTimeout(() => {
            callback(filterAssignee(inputValue));
        }, 1000);
    };

    const handleLeadAssignment = (request: any) => {
        const response = assignLeadAction({
            assigneeId: request.value,
            leadId: leadDetail.id
        });
        response.then((result: any) => {
            if (result.status === 200) {
                const selectedUserIndex = assigneeListData.findIndex((u: any) => u.value === request.value);
                setSelectedAssigneeIndex(selectedUserIndex)
                fetchLeadInfo();
                toast.success(result.message);
            } else {
                toast.error(result.message);
            }
        })
    }

    const handleStatusChange = (request: any) => {
        const response = leadStatusUpdateAction({
            status: request.value,
            leadId: leadDetail.id
        });
        response.then((result: any) => {
            if (result.status === 200) {
                const index = leadStatus().findIndex((u: any) => u === request.value);
                setSelectedStatusIndex(index)
                fetchLeadInfo();
                toast.success(result.message);
            } else {
                toast.error(result.message);
            }
        })
    }

    const handleLeadComment = () => {
        const response = leadCommentUpdateAction({
            comment: leadComment,
            leadId: leadDetail.id
        });
        response.then((result: any) => {
            if (result.status === 200) {
                setCommentEditableMode(!commentEditableMode);
                fetchLeadInfo();
            } else {
                toast.error(result.message);
            }
        })
    }
    
    return <Card>
        <CommonCardHeader headClass='border-t-primary border-2' title={StrLeadsInfo} />
        <StyleCardBody>
            {leadDetail && Object.keys(leadDetail).length > 0 &&
                <Fragment>
                    <div className='product-page-details pb-2'>
                        <div className='text-div'><span className="text-title">Name : </span><H2>{leadDetail?.fullName}</H2></div>
                    </div>
                    <div className='product-page-details pb-2'>
                        <div className='text-div'><span className="text-title">Status : </span>
                        {statusEditableMode === true ?
                        <>
                            <Select
                                closeMenuOnSelect={false}
                                components={{ IndicatorSeparator }}
                                value={selectedStatusIndex !== undefined && statusOptions[selectedStatusIndex]}
                                options={statusOptions}
                                onChange={(e) => handleStatusChange(e)}
                            />
                            <StyleFaEditIcon onClick={() => setStatusEditableMode(!statusEditableMode)}><i className="fa fa-times fa-lg"></i></StyleFaEditIcon>
                        </>
                        :
                        <>
                            <Badges color={`${statusColor(leadDetail?.status)}`} className={`${statusColor(leadDetail?.status)} f-12`}>{leadDetail?.status}</Badges>
                            <StyleFaEditIcon onClick={() => setStatusEditableMode(!statusEditableMode)}><i className="fa fa-edit fa-lg"></i></StyleFaEditIcon>
                        </>
                        }
                        
                    </div>
                    </div>
                    <div className='product-page-details pb-2'>
                        <div className='text-div'><span className="text-title">Phone : </span><H6>{leadDetail?.phoneNumber}</H6></div>
                    </div>
                    <div className='product-page-details pb-2'>
                        <div className='text-div'><span className="text-title">Email : </span><H6>{leadDetail?.email}</H6></div>
                    </div>
                    <div className='product-page-details pb-2'>
                        <div className='text-div'><span className="text-title">City : </span><H6>{leadDetail?.city}</H6></div>
                    </div>
                    <div className='product-page-details pb-2'>
                        <div className='text-div'><span className="text-title">Assigned To : </span>
                            <H6>
                                <AsyncSelect 
                                    value={selectedAssigneeIndex !== undefined && assigneeListData[selectedAssigneeIndex]}
                                    cacheOptions
                                    loadOptions={loadAssigneeOptions}
                                    defaultOptions={assigneeListData}
                                    components={{ IndicatorSeparator }}
                                    onChange={(e) => handleLeadAssignment(e)}
                                />
                            </H6>
                        </div>
                    </div>
                    <div className='product-page-details pb-2'>
                        <div className='text-div'><span className="text-title">Created on : </span><H6>{formatDate(leadDetail?.createdAt, 'DD-MM-YYYY h:mm a')}</H6></div>
                    </div>
                    <div className='product-page-details pb-2'>
                        <div className='text-div'><span className="text-title">Last updated : </span><H6>{formatDate(leadDetail?.updatedAt, 'DD-MM-YYYY h:mm a')}</H6></div>
                    </div>
                    {/* <hr /> */}
                    {/* <StarRating /> */}
                    <hr />
                    <P>
                        <H6 className="comment-title">{'Note'} <StyleFaEditIcon onClick={() => setCommentEditableMode(!commentEditableMode)}><i className="fa fa-edit fa-lg"></i></StyleFaEditIcon></H6>
                        { commentEditableMode === true ?
                            <>
                                <ReactQuill theme='snow' value={leadComment} onChange={(value) => setLeadComment(value)} />
                                <div className="d-md-flex justify-content-md-start common-flex m-t-10">
                                    <Btn type='button' color='secondary' onClick={() => setCommentEditableMode(!commentEditableMode)}>{Cancel}</Btn>
                                    <Btn type='button' onClick={() => handleLeadComment()} color='primary'>{Submit}</Btn>
                                </div>
                            </>
                            :
                            <p>{leadComment && parse(leadComment)}</p>
                        }
                    </P>
                    <hr />
                    <div className="m-t-15 common-flex">
                        <Link to={`/leads/book-package?leadId=${leadDetail?.id}`} target="_blank">
                            <Btn color="primary">
                                Book Package
                            </Btn>
                        </Link>
                        { leadDetail?.bookingInfo &&
                            <Link to={`/leads/invoice?bookingId=${leadDetail?.bookingInfo.id}`} target="_blank">
                                <Btn color="info">
                                    Print
                                </Btn>
                            </Link>
                        }
                        <ReminderForm leadId={leadDetail.id}  />
                    </div>
                    <hr />
                    <div className="p-t-20">
                        <Nav tabs className='simple-wrapper'>
                            <NavItem>
                                <NavLink href={Href} className={`txt-primary ${activeTab === 'history' ? 'active' : ''}`} onClick={() => setActiveTab('history')}>{'History'}</NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={'history'} className='p-t-20'>
                            <StyleHistoryDiv className=' box-col-6 notification main-timeline'>
                                <Card className='height-equal title-line'>
                                    <CardBody>
                                        <UL className='square-timeline simple-list timeline-lists'>
                                            {leadDetail && leadDetail.historyInfo.map((history: any, index: number) => {
                                                return (
                                                    <LI className="d-flex" key={index}>
                                                        <div className={`timeline-dot-${historyStatusColor(history.historyType)}`} />
                                                        <div className="w-100 ms-3">
                                                            <P className="d-flex justify-content-between mb-2">
                                                                <span className="date-content light-background">{formatDate(history.createdAt)}</span>
                                                                <span>{formatDate(history.createdAt, 'h:mm:ss a')}</span>
                                                            </P>
                                                            <H6>
                                                                { history.historyType === 'CREATED' &&
                                                                    <>New lead created by <mark> {history.joinCreatedBy.firstName} {history.joinCreatedBy.lastName} </mark></>
                                                                }
                                                                { history.historyType === 'ASSIGNMENT' &&
                                                                    <>Lead is assigned from <mark> {history.joinAssignFrom ? history.joinAssignFrom.firstName + ' ' + history.joinAssignFrom.lastName : '-'}</mark>{' '} to <mark> {history.joinAssignTo.firstName} {history.joinAssignTo.lastName} </mark> by <mark> {history.joinCreatedBy.firstName} {history.joinCreatedBy.lastName} </mark></>
                                                                }
                                                                { history.historyType === 'STATUS_CHANGED' &&
                                                                    <>Lead status change from <mark>{history.statusFrom ?? '-'}</mark> to <mark>{history.statusTo}</mark> by <mark> {history.joinCreatedBy.firstName} {history.joinCreatedBy.lastName} </mark></>
                                                                }
                                                                { history.historyType === 'COMMENT' &&
                                                                    <>Lead comment added by <mark> {history.joinCreatedBy.firstName} {history.joinCreatedBy.lastName} </mark></>
                                                                }
                                                                <span className="dot-notification" />
                                                            </H6>
                                                            <P className="f-light">{history.comment ? parse(history.comment) : ''}</P>
                                                        </div>
                                                    </LI>
                                                )
                                            })}
                                        </UL>
                                        <hr />
                                    </CardBody>
                                </Card>
                            </StyleHistoryDiv>
                        </TabContent>
                    </div>
                </Fragment>
            }
        </StyleCardBody>
    </Card>
}

export default LeadInfo