import GenerateLead from "../../Components/AdminDashboard/Leads/GenerateLead"

const GenerateLeadPage = () => {
    return (
        <div className='page-body main-project'>
            <GenerateLead />
        </div>
    )
}

export default GenerateLeadPage