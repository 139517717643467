import React, { useState } from 'react'
import { Row, Col, FormFeedback, FormGroup, Input, Label, Card, CardBody, Container } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { toast } from 'react-toastify';
import { Formik, Form, Field } from 'formik';
import { City as CityList } from 'country-state-city';
import * as Yup from 'yup';
import { Btn } from '../../../AbstractElements';
import { Cancel, Submit, PhoneNumber, StrGenerateLead, StrFullName, StrEmail, StrCity } from '../../../Utils/Constants';
import CommonCardHeader from '../../../Utils/CommonComponents/CommonCardHeader';
import { generateLeadAction } from '../../../ReduxToolkit/Reducers/Layout/Lead/LeadAction';
import Loader from '../../../Layout/Loader';

const phoneRegExp = /^[6-9]{1}[0-9]{9}$/
const LeadFormSchema = Yup.object().shape({
    fullName: Yup.string().required('Full name is required'),
    email: Yup.string().email('Email must be a valid email').required('Email is required'),
    phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Phone number is required'),
    city: Yup.string().required('City is required'),
}).required()

const simpleTabSubTitle = [
    {
        text: 'Enter / Select',
        code: 'Lead'
    },
    {
        text: ' details to  ',
        code: 'create '
    }
];

const StyleContainer = styled(Container)`
    
`

export default function GenerateLead() {
    const navigate = useNavigate();
    const [cityArr, setCityArr] = useState<any>(CityList.getCitiesOfCountry('IN'));
    const [showLoader, setShowLoader] = useState(false);

    const handleSubmit = (request: any) => {
        const payload = {
            fullName: request.fullName,
            email: request.email,
            phoneNumber: request.phoneNumber,
            city: request.city,
            source: 'Manual',
        };
        setShowLoader(true);
        const response = generateLeadAction(payload);
        response.then((result: any) => {
            if(result.status === 200) {
                toast.success(result.message);
                navigate('/leads');
            } else {
                toast.error(result.message);
            }
            setShowLoader(false);
        });
    };

    return (
        <StyleContainer fluid>
            <Loader show={showLoader} />
            <Row>
                <Col sm={12} xl={12}>
                    <Card className='title-line'>
                        <CommonCardHeader title={StrGenerateLead} subTitle={simpleTabSubTitle} />
                        <CardBody>
                            <Formik
                                initialValues={{
                                    fullName: '',
                                    email: '',
                                    phoneNumber: '',
                                    city: '',
                                }}
                                validationSchema={LeadFormSchema}
                                onSubmit={values => {
                                    handleSubmit(values);
                                }}
                            >
                                {({ errors, touched, setFieldValue, values }) => (
                                    <Form className="theme-form">
                                        <Row>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Col><Label>{StrFullName}</Label></Col>
                                                    <Input type="text" tag={Field} placeholder={StrFullName} name="fullName" value={values.fullName} invalid={touched.fullName && errors.fullName ? true : false} />
                                                    {touched.fullName && errors.fullName && <FormFeedback>{errors.fullName}</FormFeedback>}
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Col><Label>{StrEmail}</Label></Col>
                                                    <Input type="email" placeholder={StrEmail} name="email" tag={Field} value={values.email} invalid={touched.email && errors.email ? true : false} />
                                                    {touched.email && errors.email && <FormFeedback>{errors.email}</FormFeedback>}
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Col><Label>{PhoneNumber}</Label></Col>
                                                    <Input type="number" placeholder={PhoneNumber} name="phoneNumber" tag={Field} value={values.phoneNumber} invalid={touched.phoneNumber && errors.phoneNumber ? true : false} />
                                                    {touched.phoneNumber && errors.phoneNumber && <FormFeedback>{errors.phoneNumber}</FormFeedback>}
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <Col><Label>{StrCity}</Label></Col>
                                                <Field name="city" render={(field: any) => <Input onChange={(e) => setFieldValue('city', e.target.value)} type="select" {...field} value={values.city} invalid={touched.city && errors.city ? true : false}>
                                                    <option value=''>{'Choose...'}</option>
                                                    {cityArr.length > 0 && cityArr.map((cityObj: any) => {
                                                        return <option value={cityObj.isoCode}>{cityObj.name}</option>
                                                    })}
                                                </Input>} />
                                                {touched.city && errors.city && <FormFeedback>{errors.city}</FormFeedback>}
                                            </Col>
                                        </Row>
                                        <FormGroup className='p-t-20'>
                                            <div className="d-md-flex justify-content-md-start common-flex">
                                                <Btn type='button' color='secondary' onClick={() => navigate('/leads')} disabled={showLoader}>{Cancel}</Btn>
                                                <Btn type='submit' color='primary' disabled={showLoader}>{Submit}</Btn>
                                            </div>
                                        </FormGroup>
                                    </Form>
                                )}
                            </Formik>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </StyleContainer>
    )
}
