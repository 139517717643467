import { createSlice } from "@reduxjs/toolkit";
import { AuthStateProps } from "../../../Types/Layout.type";

const initialState: AuthStateProps = {
    isSearchBarOpen: false,
    bookMarkClass: false,
    pinedMenu: [],
    sideBarToggle: false,
    sidebarSearchTerm: ''
};

const AuthSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        userLogin: (state, action) => {
            state.sidebarSearchTerm = action.payload;
        },
    },
});
export const { userLogin } = AuthSlice.actions;
export default AuthSlice.reducer;
