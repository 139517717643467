import React, { useState } from 'react'
import { Col, Container, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Btn, H2, P } from '../../AbstractElements';
import { NewPassword, Password, ResetYourPassword, RetypePassword, SignIn, Submit } from '../../Utils/Constants';
import AppLogo from '../../Components/Common/AppLogo';
import { toast } from 'react-toastify';
import { resetPasswordAction } from '../../ReduxToolkit/Reducers/Layout/Auth/LoginAction';

const ResetPasswordFormSchema = Yup.object().shape({
    password: Yup.string().required('Password is required'),
    retypePassword: Yup.string().required('Retype Password is required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
}).required()

export default function ResetPassword() {
    const navigate = useNavigate();
    const [ searchParams ] = useSearchParams();
    const [isPasswordVisible, setPasswordVisible] = useState(false);
    const toggle = () => setPasswordVisible(!isPasswordVisible);

    const handleSubmit = (request: any) => {
        const response = resetPasswordAction({
            requestId: searchParams.get('requestId'),
            password: request.password
        });
        response.then((result: any) => {
            if(result.status === 200) {
                toast.success(result.message);
                navigate('/login');
            } else {
                toast.error(result.message);
            }
        });
    }
    return (
        <div className="page-wrapper">
            <Container fluid className="p-0">
                <Row>
                    <Col xs={12}>
                        <div className="login-card login-dark">
                            <div>
                                <AppLogo />
                                <div className="login-main">
                                    <Formik
                                        initialValues={{
                                            password: '',
                                            retypePassword: '',
                                        }}
                                        validationSchema={ResetPasswordFormSchema}
                                        onSubmit={values => {
                                            handleSubmit(values);
                                        }}
                                    >
                                    {({ errors, touched, setFieldValue, values }) => (
                                    <Form className="theme-form">
                                        <H2>{ResetYourPassword}</H2>
                                        <Row className="g-2 pt-3">
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Input type={isPasswordVisible ? 'text' : 'password'} placeholder={Password} name="password" tag={Field} value={values.password} invalid={ touched.password && errors.password ? true : false } />
                                                    <div className='show-hide' onClick={toggle}>
                                                        <span className={!isPasswordVisible ? 'show' : ''}></span>
                                                    </div>
                                                    {touched.password && errors.password && <FormFeedback>{errors.password}</FormFeedback>}
                                                </FormGroup>
                                            </Col>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Input type={isPasswordVisible ? 'text' : 'password'} placeholder={RetypePassword} name="retypePassword" tag={Field} value={values.retypePassword} invalid={ touched.retypePassword && errors.retypePassword ? true : false } />
                                                    <div className='show-hide' onClick={toggle}>
                                                        <span className={!isPasswordVisible ? 'show' : ''}></span>
                                                    </div>
                                                    {touched.retypePassword && errors.retypePassword && <FormFeedback>{errors.retypePassword}</FormFeedback>}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <FormGroup className="mb-0">
                                            <Btn color='primary' className="w-100" block>{Submit}</Btn>
                                        </FormGroup>
                                        <P className="mt-4 mb-0 text-center">{"Already have an account?"}
                                            <Link to={`/login`} className='ms-2'>{SignIn}</Link>
                                        </P>
                                    </Form>
                                    )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
