import React from 'react'
import { Label, Table } from 'reactstrap'
import { H6, P } from '../../../../../AbstractElements'
import InvoiceFooter from './InvoiceFooter'
import ItineraryInfo from './ItineraryInfo';

const invoiceSixTable = ["Passender Name", "Gender", "Age"];

export default function InvoicePassengerTable(props: any) {
    const { detail } = props;
    return (
        <div>
            <div className="table-responsive invoice-table" id="table">
                <Table bordered striped>
                    <tbody>
                        <tr>
                            {invoiceSixTable.map((data, i) => (
                                <td className="item" key={i}>
                                    <H6 className="p-0 mb-0">{data}</H6>
                                </td>
                            ))}
                        </tr>
                        {detail && detail.passengerDetails.length > 0 && detail.passengerDetails.map((data: any, i: number) => (
                            <tr key={i}>
                                <td>
                                    <Label>{data.passengerName}</Label>
                                </td>
                                <td><P className="itemtext">{data.passengerGender.label}</P></td>
                                <td><P className="itemtext">{data.passengerAge}</P></td>
                            </tr>
                        ))}
                        <tr>
                            <td />
                            <td className="Rate">
                                <H6 className="mb-0 p-2">{'Hotel Charges'}</H6>
                            </td>
                            <td className="payment">
                                <H6 className="mb-0 p-2">{detail.hotelCharges}</H6>
                            </td>
                        </tr>
                        <tr>
                            <td />
                            <td className="Rate">
                                <H6 className="mb-0 p-2">{'Travel Charges'}</H6>
                            </td>
                            <td className="payment">
                                <H6 className="mb-0 p-2">{detail.travelCharges}</H6>
                            </td>
                        </tr>
                        <tr>
                            <td />
                            <td className="Rate">
                                <H6 className="mb-0 p-2">{'Total'}</H6>
                            </td>
                            <td className="payment">
                                <H6 className="mb-0 p-2">{detail.totalCharges}</H6>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>
            <ItineraryInfo detail={detail} />
            <InvoiceFooter />
        </div>
    )
}
