import { configureStore } from "@reduxjs/toolkit";
import layoutReducer from "./Reducers/Layout/LayoutReducer";
import ThemeCustomizerReducer from "./Reducers/Layout/ThemeCustomizerReducer";
import AuthReducer from "./Reducers/Layout/AuthReducer";
import LoginSlice from "./Reducers/Layout/Auth/LoginSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import WorkspaceSlice from "./Reducers/Layout/Workspace/WorkspaceSlice";
import WorkspaceListSlice from "./Reducers/Layout/WorkspaceList/WorkspaceListSlice";
import WorkspaceCountSlice from "./Reducers/Layout/WorkspaceCount/WorkspaceCountSlice";

export const store = configureStore({
    reducer: {
        layout: layoutReducer,
        auth: AuthReducer,
        loginReducer: LoginSlice,
        workspaceReducer: WorkspaceSlice,
        workspaceListReducer: WorkspaceListSlice,
        workspaceCountReducer: WorkspaceCountSlice,
        themeCustomizer: ThemeCustomizerReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useReduxDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useReduxSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;
