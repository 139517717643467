export const mealType: any = {
    'CP' : 'CP',
    'MAP' : 'MAP'
}

export const paymentReferences: any = {
    "100% 1 day before Checkout" : "100% 1 day before Checkout",
    "100% 7 days after Checkout" : "100% 7 days after Checkout",
    "25% on Booking, 75% 2 days before Checkin" : "25% on Booking, 75% 2 days before Checkin",
    "40% on Booking, 60% 7 days before Checkin" : "40% on Booking, 60% 7 days before Checkin",
    "50% on Booking, 50% 15 days before Checkin" : "50% on Booking, 50% 15 days before Checkin",
    "50% on Booking, 50% 30 days before Checkin" : "50% on Booking, 50% 30 days before Checkin"
}

export const roomTypes: any = {

}

export const packageIncludesArr: any = [
    { label: "Flight", value: "Flight" },
    { label: "Hotel", value: "Hotel" },
    { label: "Sightseeing", value: "Sightseeing" },
    { label: "Transfer", value: "Transfer" },
    { label: "Meal", value: "Meal" }
]

export const perPageLimit = 16;

export const gDriveImgDownloadLink = 'https://drive.google.com/uc?export=download&id=';
export const gDriveImgThumbnailLink = 'https://drive.google.com/thumbnail?id=';
export const defaultImage = '1OOMtQpDQP3rv_dzA-u4hsZ6A0H4nRD_4';

export const slickResponsive = [
    {
        breakpoint: 1400,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
        }
    },
    {
        breakpoint: 800,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
        }
    },
    {
        breakpoint: 600,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 1
        }
    },
    {
        breakpoint: 480,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
        }
    }
]

export const autoPlaySliderOptions = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    adaptiveHeight: true,
    centerPadding: '10px',
    draggable: true,
    responsive: slickResponsive
};