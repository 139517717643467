import InvoiceContainer from "../../Components/AdminDashboard/Packages/BookPackage/Invoice/InvoiceContainer"

const InvoicePage = () => {
    return (
        <div className='page-body main-project'>
            <InvoiceContainer />
        </div>
    )
}

export default InvoicePage