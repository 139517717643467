import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Card, CardBody, Col, Container, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { useNavigate, useSearchParams } from 'react-router-dom';
import parse from 'html-react-parser';
import { UL, LI, H4, P, H3, H1, Badges } from "../../../AbstractElements";
import { Href } from "../../../Utils/Constants";
import { useEffect, useState } from "react";
import PackagePriceCard from "./PackagePriceCard";
import PackageIncludeCard from "./PackageIncludeCard";
import NeedHelpCard from "./NeedHelpCard";
import Slider from "./Slider";
import { getPackageDetailAction } from "../../../ReduxToolkit/Reducers/Layout/Package/PackageAction";
import { toast } from "react-toastify";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import './index.css';
import { ChevronUp, ChevronDown } from "react-feather";

const PackageDetail = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const packageId = searchParams.get('id');
    const [detail, setDetail] = useState<any>();
    const [additionalDetail, setAdditionalDetail] = useState<any>();
    const [open, setOpen] = useState(false);
    const [openAcc, setOpenAcc] = useState('1');
    const [activeTab, setActiveTab] = useState<string>('overview');
    const toggle = () => { setOpen(!open); }

    const toggleAcc = (id: string) => {
        openAcc === id ? setOpenAcc('') : setOpenAcc(id);
    };

    useEffect(() => {
        if (!packageId) {
            navigate('/packages');
        }
    }, [])

    useEffect(() => {
        const response = getPackageDetailAction({ id: packageId });
        response.then((result: any) => {
            if (result.status === 200) {
                setDetail(result.data);
                const groupByCategory = result.data.additionalDetails.reduce((group: any, addDetail: any) => {
                    const { infoType } = addDetail;
                    group[infoType] = group[infoType] ?? [];
                    group[infoType].push(addDetail);
                    return group;
                }, {});
                setAdditionalDetail(groupByCategory);
            } else {
                toast.error(result.message);
            }
        });
    }, [packageId])

    return (
        <Container fluid>
            <Row>
                <Col xl={9} className="xl-70 order-xl-0 order-1 box-col-12">
                    <div className="blog-single">
                        <div className="blog-box blog-details">
                            {/* <Image className="img-fluid w-100" src={dynamicImage(`faq/learning-1.png`)} alt="blog-main" /> */}
                            <div className="blog-details pb-0 full-text">
                                <div className="d-flex">
                                    <H1 className="mt-2 txt-primary"><u>{detail?.packageName}</u></H1>
                                    <H3 className="p-2"><Badges color={'success'}>{detail?.duration}</Badges></H3>
                                </div>
                                <H4 className="mt-0 pb-3 txt-secondary">{detail?.placesVisit}</H4>
                                {/* <div className="single-blog-content-top">
                                    <div className={'ribbon-wrapper border border-1 height-equal'}>
                                        <Ribbon className={'ribbon ribbon-dark ribbon-right'}>{'Package Overview'}</Ribbon>
                                        {detail ? parse(detail.description) : ''}
                                    </div>
                                </div> */}
                                { detail && detail.packageImages &&
                                    <Slider darkVariantData={detail.packageImages}  />
                                }
                                <UL className="simple-list flex-row blog-social mt-3">
                                    { detail && detail.highLights && detail.highLights.length > 0 && detail.highLights.map((hlData: any) => {
                                        return (
                                            <LI>{hlData}</LI>
                                        )
                                    })}
                                </UL>
                                <div className="p-t-20">
                                    <Nav tabs className='simple-wrapper'>
                                        <NavItem>
                                            <NavLink href={Href} className={`txt-primary ${activeTab === 'overview' ? 'active' : ''}`} onClick={() => setActiveTab('overview')}>{'Overview'}</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink href={Href} className={`txt-primary ${activeTab === 'itinerary' ? 'active' : ''}`} onClick={() => setActiveTab('itinerary')}>{'Itinerary'}</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink href={Href} className={`txt-primary ${activeTab === 'inclusions_exclusions' ? 'active' : ''}`} onClick={() => setActiveTab('inclusions_exclusions')}>{'Inclusions / Exclusions'}</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink href={Href} className={`txt-primary ${activeTab === 'term_condition' ? 'active' : ''}`} onClick={() => setActiveTab('term_condition')}>{'Term & Condition'}</NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={activeTab} className='p-t-20'>
                                        <TabPane className={`fade ${activeTab === 'overview' ? 'show' : ''}`} tabId={'overview'}>
                                            <Card className='card-absolute'>
                                                <CommonCardHeader headClass='bg-primary' titleClass='txt-light' title={'Package Overview'} />
                                                <CardBody>
                                                    <div className='d-flex list-behavior-1 align-items-center'>
                                                        {/* <div className='flex-shrink-0'>
                                                    <Image className='tab-img img-fluid' src={dynamicImage(`blog/img.png`)} alt='home' />
                                                </div> */}
                                                        <div className='flex-grow-2'>
                                                            <P className='mb-xl-0 mb-sm-4 mt-0'>{detail ? parse(detail?.description) : ''}</P>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </TabPane>
                                        <TabPane className={`fade ${activeTab === 'itinerary' ? 'show' : ''}`} tabId={'itinerary'}>
                                            <Card className='card-absolute'>
                                                <CommonCardHeader headClass='bg-primary' titleClass='txt-light' title={'Day wise Itinerary'} />
                                                <CardBody className="pt-5">
                                                    <UL className="square-timeline simple-list">
                                                        {detail && detail.ItineraryDetails.length > 0 && detail.ItineraryDetails.map((itiItem: any, index: number) => {
                                                            return (
                                                                <LI className='timeline-event' key={index}>
                                                                    <Label className='timeline-event-icon' />
                                                                    <div className='timeline-event-wrapper'>
                                                                        <P className='timeline-thumbnail'>{itiItem.title}</P>
                                                                        {/* <H5>{Location}</H5> */}
                                                                        {/* <div className='text-muted'>{"Largo Febo,10225012-Calvisano BS"}</div> */}
                                                                        <P className='pt-1 mb-4'>{itiItem.detail ? parse(itiItem.detail) : ''}</P>
                                                                    </div>
                                                                </LI>
                                                            )
                                                        })}
                                                    </UL>
                                                </CardBody>
                                            </Card>
                                        </TabPane>
                                        <TabPane className={`fade ${activeTab === 'inclusions_exclusions' ? 'show' : ''}`} tabId={'inclusions_exclusions'}>
                                            <Card className='card-absolute'>
                                                <CommonCardHeader headClass='bg-primary' titleClass='txt-light' title={'Inclusions'} />
                                                <CardBody className="pt-5">
                                                    <UL className='list-content'>
                                                        {additionalDetail && additionalDetail.INCLUSIONS && additionalDetail.INCLUSIONS.length > 0 && additionalDetail.INCLUSIONS.map((IncData: any) => {
                                                            return (<LI> <i className="icofont icofont-checked"></i> {IncData.title}</LI>)
                                                        })}
                                                    </UL>
                                                </CardBody>
                                            </Card>
                                            <Card className='card-absolute mt-5'>
                                                <CommonCardHeader headClass='bg-danger' titleClass='txt-light' title={'Exclusions'} />
                                                <CardBody className="pt-5">
                                                    <UL className='list-content'>
                                                        {additionalDetail && additionalDetail.EXCLUSIONS && additionalDetail.EXCLUSIONS.length > 0 && additionalDetail.EXCLUSIONS.map((excData: any) => {
                                                            return (<LI> <i className="icofont icofont-close-squared-alt"></i> {excData.title}</LI>)
                                                        })}
                                                    </UL>
                                                </CardBody>
                                            </Card>
                                        </TabPane>
                                        <TabPane className={`fade ${activeTab === 'term_condition' ? 'show' : ''}`} tabId={'term_condition'}>
                                            <Card className='card-absolute'>
                                                <CommonCardHeader headClass='bg-primary' titleClass='txt-light' title={'Term & Condition'} />
                                                <CardBody className="pt-5">
                                                    <Accordion open={openAcc} toggle={toggleAcc} className='dark-accordion'>
                                                        {additionalDetail && additionalDetail.TERMS && additionalDetail.TERMS.length > 0 && additionalDetail.TERMS.map((termsData: any, index: number) => {
                                                            return (
                                                                <AccordionItem>
                                                                    <AccordionHeader className='bg-light-primary txt-primary' targetId={(index+1).toString()}>
                                                                        {termsData.title} {openAcc === (index+1).toString() ? <ChevronUp className='svg-color' /> : <ChevronDown className='svg-color' />}
                                                                    </AccordionHeader>
                                                                    <AccordionBody accordionId={(index+1).toString()}>
                                                                        <P className="mt-0">{parse(termsData.description)}</P>
                                                                    </AccordionBody>
                                                                </AccordionItem>
                                                            )
                                                        })}
                                                    </Accordion>
                                                </CardBody>
                                            </Card>
                                        </TabPane>
                                    </TabContent>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xl={3} className="xl-30 box-col-12 learning-filter">
                    <div className='md-sidebar'>
                        <div className={`md-sidebar-aside job-sidebar ${open ? 'open' : ''}`}>
                            <div className='default-according style-1 faq-accordion job-accordion' id='accordionoc'>
                                <Row>
                                    <PackagePriceCard detail={detail} />
                                    <PackageIncludeCard data={detail?.packageIncludes ?? []} />
                                    <NeedHelpCard />
                                </Row>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>)
}

export default PackageDetail;