import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import LayoutRoutes from './LayoutRoutes'
import SignIn from '../Pages/Auth';
import PrivateRoutes from './PrivateRoutes';
import SignUp from '../Pages/Signup';
import Unauthorised from '../Pages/Unauthorised';
import ForgotPassword from '../Pages/ForgotPassword';
import ResetPassword from '../Pages/ResetPassword';

export default function Routers() {
    const login = localStorage.getItem("login");

    return (
        <BrowserRouter basename={'/'}>
            <Routes>
                {login ? (
                    <>
                        <Route
                            path={'/'}
                            element={<Navigate to={`/dashboard`} />}
                        />
                    </>
                ) : (
                    ""
                )}
                <Route path={"/"} element={<PrivateRoutes />}>
                    <Route path={`/*`} element={<LayoutRoutes />} />
                </Route>
                <Route path={`/login`} element={<SignIn />} />
                <Route path={`/signup`} element={<SignUp />} />
                <Route path={`/forgot-password`} element={<ForgotPassword />} />
                <Route path={`/reset-password`} element={<ResetPassword />} />
                <Route path={`/create-user`} element={<SignUp />} />
                <Route path={`/unauthorised`} element={<Unauthorised />} />
            </Routes>
        </BrowserRouter>
    )
}
