import { useEffect, useState } from "react";
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { Row, Col, FormGroup, Label, Input, FormFeedback } from "reactstrap";
import { H2, P, Btn } from "../../../AbstractElements";
import { Cancel, StrRoomTypeDescription, StrRoomTypeName, StrSubmit } from "../../../Utils/Constants";
import CommonModal from "../../Common/CommonModal";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import Loader from "../../../Layout/Loader";
import { createRoomTypeAction, getRoomTypeByIdAction, updateRoomTypeAction } from "../../../ReduxToolkit/Reducers/Layout/Hotel/HotelAction";

const InviteFormSchema = Yup.object().shape({
    name: Yup.string().required('Room Type is required'),
}).required()

interface DetailType {
    id: number,
    name: string,
    description: string
}

const RoomTypeForm = (props: any) => {
    const { data, toggle, modal, setModal, handleCallback, itemId } = props;
    const [showLoader, setShowLoader] = useState(false);
    const [detail, setDetail] = useState<DetailType>();

    useEffect(() => {
        if(itemId > 0) {
            setShowLoader(true);
            const response = getRoomTypeByIdAction({id: itemId});
            response.then((result: any) => {
                setShowLoader(false);
                if(result.status === 200) {
                    setDetail(result.data);
                    toggle();
                } else {
                    toast.error(result.message);
                }
            });
        }
    }, [itemId])

    const handleSubmit = (request: any) => {
        if(itemId > 0) {
            setShowLoader(true);
            request['id'] = itemId;
            const response = updateRoomTypeAction(request);
            response.then((result: any) => {
                setShowLoader(false);
                if(result.status === 200) {
                    toast.success(result.message);
                    handleCallback();
                    toggle();
                } else {
                    toast.error(result.message);
                }
            });
        } else {
            setShowLoader(true);
            const response = createRoomTypeAction(request);
            response.then((result: any) => {
                setShowLoader(false);
                if(result.status === 200) {
                    setModal(!modal);
                    toast.success(result.message);
                    handleCallback();
                    toggle();
                } else {
                    toast.error(result.message);
                }
            });
        }
    }

    return <CommonModal modalData={data}>
        <Loader show={showLoader} />
        <div className="modal-toggle-wrapper">
            <H2>{StrRoomTypeName}</H2>
            <Formik
                initialValues={{
                    name: detail && detail.name,
                    description: detail && detail.description,
                }}
                validationSchema={InviteFormSchema}
                onSubmit={values => {
                    handleSubmit(values);
                }}
            >
                {({ errors, touched, setFieldValue, values }) => (
                    <Form className="theme-form">
                        <P>{"Enter Room type details"}</P>
                        <Row>
                            <Col sm={12}>
                                <FormGroup>
                                    <Col><Label className="required-field">{StrRoomTypeName}</Label></Col>
                                    <Input type="text" tag={Field} placeholder={''} name="name" value={values.name} invalid={touched.name && errors.name ? true : false} />
                                    {touched.name && errors.name && <FormFeedback>{errors.name}</FormFeedback>}
                                </FormGroup>
                            </Col>
                            <Col sm={12}>
                            <FormGroup>
                                <Col><Label>{StrRoomTypeDescription}</Label></Col>
                                <ReactQuill theme='snow' value={values.description ?? ''} onChange={(value: any) => setFieldValue('description', value)} style={{height: '200px', maxHeight: '200px', overflow: 'auto'}} />
                            </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup className='p-t-20'>
                            <div className='d-flex justify-content-end'>
                                <Btn color='danger' disabled={showLoader} className="align-items-center text-light m-r-5" type="button" onClick={toggle}>
                                    {Cancel}
                                </Btn>
                                <Btn color='primary' disabled={showLoader} className="align-items-center text-light" type="submit">
                                    {StrSubmit}
                                </Btn>
                            </div>
                        </FormGroup>
                    </Form>
                )}
            </Formik>
        </div>
    </CommonModal>
}

export default RoomTypeForm