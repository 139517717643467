import { SidebarMenuType } from "../../Types/Layout.type";

export const menuList: SidebarMenuType[] = [
    {
        // title: 'General',
        menu: [
            {
                id: 1,
                title: 'Dashboard',
                icon: "home",
                type: "link",
                show: true,
                url: `/dashboard`,
                accessTo: ['Super Admin', 'Admin', 'Manager', 'User']
            }
        ]
    },
    {
        menu: [
            {
                id: 2,
                title: 'Workspace',
                type: 'link',
                url: `/workspace`,
                icon: 'project',
                badge: false,
                show: true,
                badgeName: "New",
                badgeColor: "light-secondary",
                active: false,
                accessTo: ['Super Admin']
            }
        ],
    },
    {
        menu: [
            {
                id: 3,
                title: 'Leads',
                icon: 'contact',
                badge: false,
                badgeName: "3",
                badgeColor: "light-primary",
                type: 'sub',
                active: false,
                accessTo: ['Admin', 'Manager', 'User'],
                menu: [
                    {
                        title: 'Leads',
                        type: 'link',
                        url: `/leads`,
                        accessTo: ['Admin', 'Manager', 'User'],
                    },
                    {
                        title: 'Generate Lead',
                        type: 'link',
                        url: `/leads/generate`,
                        accessTo: ['Admin', 'Manager', 'User'],
                    },
                    {
                        title: 'Upload Lead',
                        type: 'link',
                        url: `/leads/upload`,
                        accessTo: ['Admin', 'Manager', 'User'],
                    },
                    {
                        title: 'Book Package',
                        type: 'link',
                        url: `/leads/book-package`,
                        accessTo: [],
                    }
                ],
            }
        ],
    },
    {
        menu: [
            {
                id: 3,
                title: 'Tour & Travels',
                icon: 'board',
                badge: false,
                badgeName: "3",
                badgeColor: "light-primary",
                type: 'sub',
                active: false,
                accessTo: ['Admin', 'Manager', 'User'],
                menu: [
                    {
                        title: 'Room Types',
                        type: 'link',
                        url: `/room-types`,
                        accessTo: ['Admin', 'Manager'],
                    },
                    {
                        title: 'Payment References',
                        type: 'link',
                        url: `/payment-references`,
                        accessTo: ['Admin', 'Manager'],
                    },
                    {
                        title: 'Locations',
                        type: 'link',
                        url: `/locations`,
                        accessTo: ['Admin', 'Manager'],
                    },
                    {
                        title: 'Transports',
                        type: 'link',
                        url: `/vehicles`,
                        accessTo: ['Admin', 'Manager'],
                    },
                    {
                        title: 'Hotels',
                        type: 'link',
                        url: `/hotels`,
                        accessTo: ['Admin', 'Manager', 'User'],
                    },
                    {
                        title: 'Add Hotel',
                        type: 'link',
                        url: `/hotels/create`,
                        accessTo: [],
                    },
                    {
                        title: 'Update Hotel',
                        type: 'link',
                        url: `/hotels/update`,
                        accessTo: [],
                    },
                    {
                        title: 'Room Types',
                        type: 'link',
                        url: `/hotels/room-types`,
                        accessTo: [],
                    },
                    {
                        title: 'Packages',
                        type: 'link',
                        url: `/packages`,
                        accessTo: ['Admin', 'Manager', 'User'],
                    },
                    {
                        title: 'Packages',
                        type: 'link',
                        url: `/packages/create`,
                        accessTo: [],
                    },
                    {
                        title: 'Packages',
                        type: 'link',
                        url: `/packages/update`,
                        accessTo: [],
                    },
                    {
                        title: 'Packages',
                        type: 'link',
                        url: `/packages/detail`,
                        accessTo: [],
                    },
                ],
            }
        ],
    },
    {
        menu: [
            {
                id: 4,
                title: 'Invities',
                type: 'link',
                url: `/invities`,
                icon: 'contact',
                badge: false,
                show: true,
                badgeName: "New",
                badgeColor: "light-secondary",
                active: false,
                accessTo: ['Admin']
            }
        ],
    },
    {
        menu: [
            {
                id: 5,
                title: 'Users',
                type: 'link',
                url: `/users`,
                icon: 'user',
                badge: false,
                show: true,
                badgeName: "New",
                badgeColor: "light-secondary",
                active: false,
                accessTo: ['Admin']
            }
        ],
    }
];