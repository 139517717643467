import { useEffect, useState } from 'react';
import { Row, Col, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Formik, Form, Field } from 'formik';
import { ConnectedFocusError } from 'focus-formik-error'
import * as Yup from 'yup';
import { MultiSelect } from "react-multi-select-component";
import Select, { IndicatorSeparatorProps } from 'react-select';
import { Btn } from '../../../AbstractElements';
import { Cancel, StrpackageName, StrPackageOverview, StrHighlights, StrPackageDuration, StrPlacesToVisit, StrPackageIncludes, StrPackageDiscount, StrPackageCharges, StrTravelCharges, SubmitAndNext, StrLocation } from '../../../Utils/Constants';
import ReactQuill from 'react-quill';
import styled from '@emotion/styled';
import { TagsInput } from 'react-tag-input-component';
import { packageIncludesArr } from '../../../Utils/Constants/ElementContans';
import { createPackageAction, updatePackageAction } from '../../../ReduxToolkit/Reducers/Layout/Package/PackageAction';
import { ActiveCallbackProp } from './PackageTabContents';
import { getLocationsAction } from '../../../ReduxToolkit/Reducers/Layout/Location/LocationAction';

const formSchema = Yup.object().shape({
    packageName: Yup.string().required('Package Name is required'),
    description: Yup.string().required('Description is required'),
    duration: Yup.string().required('Duration is required'),
    placesVisit: Yup.string().required('Places to visit is required'),
    packageCharges: Yup.string().required('Package charges are required'),
})

const StyleForm = styled.div`
    .ql-editor{
        height: 150px;
        max-height: 150px;
        overflow: auto;
    }
`

const StyleIndicatorSeparator = {
    alignSelf: 'stretch',
    backgroundColor: '#00B8D9',
    marginBottom: 8,
    marginTop: 8,
    width: 1,
};


const PackageDetailForm = ({ toggle, activeCallBack, detail, showLoader, setShowLoader }: ActiveCallbackProp) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const packageId = searchParams.get('id');
    const [inclusionsData, setInclusionsData] = useState<any>([])
    const [exclusionsData, setExclusionsData] = useState<any>([])
    const [locationArr, setLocationArr] = useState<any>([]);
    const [termConditionData, setTermConditionData] = useState<any>([])
    
    useEffect(() => {
        if(!packageId) {
            navigate('/packages/create')
        }
        const respLocations = getLocationsAction({});
        respLocations.then((result: any) => {
            if (result.status === 200) {
                const locationAr = result.data.rows.map((Ob: any) => {
                    return { value: Ob.id, label: Ob.name }
                })
                setLocationArr(locationAr);
            }
        })
    }, [])

    useEffect(() => {
        if(detail) {
            const groupByCategory = detail.additionalDetails.reduce((group: any, addDetail: any) => {
                const { infoType } = addDetail;
                group[infoType] = group[infoType] ?? [];
                group[infoType].push(addDetail);
                return group;
            }, {});
            if(groupByCategory && groupByCategory.INCLUSIONS && groupByCategory.INCLUSIONS.length > 0) {
                setInclusionsData(groupByCategory.INCLUSIONS);
            }
            if(groupByCategory && groupByCategory.EXCLUSIONS && groupByCategory.EXCLUSIONS.length > 0) {
                setExclusionsData(groupByCategory.EXCLUSIONS);
            }
            if(groupByCategory && groupByCategory.TERMS && groupByCategory.TERMS.length > 0) {
                setTermConditionData(groupByCategory.TERMS);
            }
        }
    }, [detail])

    const handleSubmit = (request: any) => {
        setShowLoader(true);
        const payload = {...request, ...{
            packageDiscount: request.packageDiscount ?? 0,
            travelCharges: request.travelCharges ?? 0,
            // itineraryData: itineraryData,
            // inclusionsData: inclusionsData,
            // exclusionsData: exclusionsData,
            // termConditionData: termConditionData
        }};
        if(detail && detail.id) {
            payload.id = detail.id;
            const response = updatePackageAction(payload);
            response.then((result: any) => {
                setShowLoader(false);
                if (result.status === 200) {
                    toast.success(result.message);
                    activeCallBack(2);
                } else {
                    toast.error(result.message);
                }
            });
        } else {
            const response = createPackageAction(payload);
            response.then((result: any) => {
                setShowLoader(false);
                if (result.status === 200) {
                    toast.success(result.message);
                    activeCallBack(2);
                    navigate(`/packages/update?id=${result.data.id}`);
                } else {
                    toast.error(result.message);
                }
            });
        }
    };

    const getSelectedLocation = (location: string) => {
        if(!location) return null;

        const selected = locationArr && locationArr.length > 0 && locationArr.find((ar: any) => ar.value === parseInt(location))
        return { value: selected.value, label: selected.label }
    }

    return (<StyleForm>
        <Formik
            enableReinitialize={detail && Object.keys(detail).length > 0}
            initialValues={{
                packageName: detail && detail.packageName,
                description: detail && detail.description,
                duration: detail && detail.duration,
                placesVisit: detail && detail.placesVisit,
                location: detail && detail.location,
                packageIncludes: detail && detail.packageIncludes ? detail.packageIncludes : [],
                highLights: detail && detail.highLights ? detail.highLights : [],
                travelCharges: detail && detail.travelCharges,
                packageDiscount: detail && detail.packageDiscount,
                packageCharges: detail && detail.packageCharges,
            }}
            validationSchema={formSchema}
            onSubmit={values => {
                handleSubmit(values);
            }}
        >
            {({ errors, touched, setFieldValue, values, setTouched }) => (
                <Form>
                    <ConnectedFocusError />
                    <Row>
                        <Col sm={12}>
                            <FormGroup>
                                <Label className='required-field'><strong>{StrpackageName}</strong></Label>
                                <Input type="text" tag={Field} placeholder={StrpackageName} name="packageName" value={values.packageName} invalid={touched.packageName && errors.packageName ? true : false} />
                                {touched.packageName && errors.packageName && <FormFeedback>{errors.packageName}</FormFeedback>}
                            </FormGroup>
                        </Col>
                        <Col sm={12}>
                            <FormGroup>
                                <Label className='required-field'><strong>{StrPackageOverview}</strong></Label>
                                <Field name="overview" render={(field: any) => <ReactQuill {...field} theme='snow' placeholder={StrPackageOverview} value={values.description} onChange={(value) => setFieldValue('description', value)} />} />
                                {touched.description && errors.description && <FormFeedback>{errors.description}</FormFeedback>}
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Label className='required-field'><strong>{StrPackageDuration}</strong></Label>
                                <Input type="text" tag={Field} placeholder={'eg. 4 Nights & 5 Days'} name="duration" value={values.duration} invalid={touched.duration && errors.duration ? true : false} />
                                {touched.duration && errors.duration && <FormFeedback>{errors.duration}</FormFeedback>}
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Label className='required-field'><strong>{StrPlacesToVisit}</strong></Label>
                                <Input type="text" tag={Field} placeholder={'eg. 2N Phuket | 2N Krabi'} name="placesVisit" value={values.placesVisit} invalid={touched.placesVisit && errors.placesVisit ? true : false} />
                                {touched.placesVisit && errors.placesVisit && <FormFeedback>{errors.placesVisit}</FormFeedback>}
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Label><strong>{StrTravelCharges}</strong></Label>
                                <Input type="number" tag={Field} placeholder={'0'} name="travelCharges" value={values.travelCharges} invalid={touched.travelCharges && errors.travelCharges ? true : false} />
                                {touched.travelCharges && errors.travelCharges && <FormFeedback>{errors.travelCharges}</FormFeedback>}
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Label><strong>{StrPackageDiscount}</strong></Label>
                                <Input type="number" tag={Field} placeholder={'0'} name="packageDiscount" value={values.packageDiscount} invalid={touched.packageDiscount && errors.packageDiscount ? true : false} />
                                {touched.packageDiscount && errors.packageDiscount && <FormFeedback>{errors.packageDiscount}</FormFeedback>}
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Label className='required-field'><strong>{StrPackageCharges}</strong></Label>
                                <Input type="number" tag={Field} placeholder={'0'} name="packageCharges" value={values.packageCharges} invalid={touched.packageCharges && errors.packageCharges ? true : false} />
                                <small>Package charges will be calculated based on Room, Travel Chanrges & Discount</small>
                                {touched.packageCharges && errors.packageCharges && <FormFeedback>{errors.packageCharges}</FormFeedback>}
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Col><Label className='required-field'>{StrLocation}</Label></Col>
                                <Field name="city" render={(field: any) => <Select
                                    {...field}
                                    closeMenuOnSelect={true}
                                    components={{ StyleIndicatorSeparator }}
                                    value={values.location ? getSelectedLocation(values.location) : ''}
                                    options={locationArr}
                                    onChange={(e: any) => {
                                        setFieldValue('location', e.value)
                                    }}
                                />} />
                                {touched.location && errors.location && <FormFeedback>{errors.location}</FormFeedback>}
                            </FormGroup>
                        </Col>
                        <Col sm={12}>
                            <FormGroup>
                                <Label><strong>{StrPackageIncludes}</strong></Label>
                                <MultiSelect
                                    options={packageIncludesArr}
                                    value={values.packageIncludes}
                                    onChange={(val: any) => setFieldValue('packageIncludes', val)}
                                    labelledBy="Select"
                                    className='multiselect-custom'
                                />
                                {touched.packageIncludes && errors.packageIncludes && <FormFeedback>{errors.packageIncludes}</FormFeedback>}
                            </FormGroup>
                        </Col>
                        <Col sm={12}>
                            <FormGroup>
                                <Label><strong>{StrHighlights}</strong></Label>
                                <Field name="highLights" render={({ }) => <TagsInput
                                    onChange={(e) => setFieldValue('highLights', e)}
                                    name="tags"
                                    value={values.highLights}
                                    placeHolder="eg. Free Wifi, AC"
                                />} />
                                <em>Press enter or comma to add highlight points</em>
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup className='p-t-20'>
                        <div className="d-md-flex justify-content-md-start common-flex">
                            { toggle ?
                                <Btn type='button' color='secondary' onClick={toggle}>{Cancel}</Btn>
                                :
                                <Btn type='button' color='secondary' onClick={() => navigate('/packages')}>{Cancel}</Btn>
                            }
                            <Btn type='submit' color='primary' disabled={showLoader}>{SubmitAndNext}</Btn>
                        </div>
                    </FormGroup>
                </Form>
            )}
        </Formik>
    </StyleForm>)
}

export default PackageDetailForm