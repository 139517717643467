import React, { useState } from 'react'
import DatePicker, { DateObject } from 'react-multi-date-picker';

interface MultiplesDateOptions {
    validatedValue: string | string[];
    input: HTMLElement;
    isTyping: boolean;
}

export default function DateRange(props: any) {
    const { handleDateChange, value } = props;
    // const [value, setValue] = useState<any>([]);
    const handleDateRangeChange = (date: DateObject[] | null, options: MultiplesDateOptions) => {
        handleDateChange(date);
    };

    return (
        <DatePicker
            format='DD/MM'
            inputClass="form-control"
            placeholder='Select Range'
            range
            value={value}
            onChange={handleDateRangeChange}
        />
    )
}