import WorkspaceEdit from "../../Components/Workspace/WorkspaceEdit"

const WorkspaceEditPage = () => {
    return (
        <div className='page-body main-project'>
            <WorkspaceEdit />
        </div>
    )
}

export default WorkspaceEditPage