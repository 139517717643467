import { setLoading, setError, setSuccess, setReset } from './WorkspaceSlice';
import { AnyAction, ThunkAction } from '@reduxjs/toolkit';
import { RootState } from '../../../Store';
import { createWorkspaceService, getWorkspaceById, getWorkspaceManagersService, updateWorkspaceService } from '../../../../Services/Service';

export const createWorkspaceAction = (payload: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(setReset());
        dispatch(setLoading());
        try {
            const response: any = await createWorkspaceService(payload);
            (response && response.data && response.data.status && response.data.status === 200) ? dispatch(setSuccess(response.data)) : dispatch(setError(response.data));
        } catch (e: any) {
            dispatch(setError(e.response.data));
        }
    };
};

export const updateWorkspaceAction = async (payload: any) => {
    return await updateWorkspaceService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const getWorkspaceByIdAction = async (payload: any) => {
    return await getWorkspaceById(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response;
    });
};

export const resetWorkspaceAction = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(setReset());
    };
};

export const getWorkspaceManagersAction = async (payload: any) => {
    return await getWorkspaceManagersService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};