import React, { useEffect, useState, useLayoutEffect } from 'react'
import { Row, Col, FormFeedback, FormGroup, Input, Label, Card, CardBody, Container } from 'reactstrap';
import DatePicker from "react-datepicker";
import moment from 'moment'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Btn, H2, H6, Image, P } from '../../AbstractElements';
import { Password, WorkspaceUpdateTitle, Cancel, WorkspaceName, WorkspaceNoOfIds, PerIdRate, Submit, FirstName, LastName, PhoneNumber, EmailUsername, PaymentStatus, Status, StartingDate, EndingDate } from '../../Utils/Constants';
import { updateWorkspaceAction, getWorkspaceByIdAction } from '../../ReduxToolkit/Reducers/Layout/Workspace/WorkspaceAction';
import { WorkspaceDetailType } from '../../Types/InterfaceData.type';

const phoneRegExp = /^[6-9]{1}[0-9]{9}$/
const WorkspaceFormSchema = Yup.object().shape({
    workSpaceName: Yup.string().required('Workspace name is required'),
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().email('Email must be a valid email').required('Email is required'),
    phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Phone number is required'),
    noOfIds: Yup.string().required('Ids are required'),
    perIdRate: Yup.string().required('Per Id rate is required'),
    startDate: Yup.date().typeError('Start date is required'),
    endDate: Yup.date().typeError('End Date is required'),
}).required()

const WorkspaceEdit = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [ searchParams ] = useSearchParams();
    const [isPasswordVisible, setPasswordVisible] = useState(false);
    const toggle = () => setPasswordVisible(!isPasswordVisible);
    const [workspaceDetail, setWorkspaceDetail] = useState<WorkspaceDetailType>();

    useEffect(() => {
        const response = getWorkspaceByIdAction({
            id: searchParams.get('id')
        });
        response.then((result) => {
            if(result.status === 200) {
                setWorkspaceDetail(result.data);
            }
        });
    }, [])

    useEffect(() => {
        // if(Object.keys(workspaceDetail).length > 0) {
        //     Formik.
        // }
    }, [workspaceDetail])

    const handleSubmit = (request: any) => {
        const payload = {
            id: searchParams.get('id'),
            userId: workspaceDetail && workspaceDetail.userInfo.id,
            workSpaceName: request.workSpaceName,
            firstName: request.firstName,
            lastName: request.lastName,
            email: request.email,
            username: request.email,
            phoneNumber: request.phoneNumber,
            password: request.password,
            noOfIds: request.noOfIds,
            perIdRate: request.perIdRate,
            paymentStatus: request.paymentStatus === true ? 'Completed' : 'Pending',
            status: request.status === true ? 'Active' : 'Inactive',
            startDate: request.startDate,
            endDate: request.endDate,
        };
        const response = updateWorkspaceAction(payload);
        response.then((result: any) => {
            if(result.status === 200) {
                toast.success(result.message);
            } else {
                toast.error(result.message);
            }
        });
    };

    return (
        <Container fluid>
            <Row>
                <Col sm={12}>
                    <Card>
                        <CardBody>
                            <Formik
                                initialValues={{
                                    workSpaceName: workspaceDetail && workspaceDetail.workSpaceName,
                                    firstName: workspaceDetail && workspaceDetail.userInfo.firstName,
                                    lastName: workspaceDetail && workspaceDetail.userInfo.lastName,
                                    email: workspaceDetail && workspaceDetail.userInfo.email,
                                    phoneNumber: workspaceDetail && workspaceDetail.userInfo.phoneNumber,
                                    password: '',
                                    noOfIds: workspaceDetail && workspaceDetail.noOfIds ? workspaceDetail.noOfIds : 5,
                                    perIdRate: workspaceDetail && workspaceDetail.perIdRate ? workspaceDetail.perIdRate : 500,
                                    startDate: workspaceDetail && moment(workspaceDetail.startDate).toDate(),
                                    endDate: workspaceDetail && moment(workspaceDetail.endDate).toDate(),
                                    paymentStatus: workspaceDetail && workspaceDetail.paymentStatus === 'Completed' ? true : false,
                                    status: workspaceDetail && workspaceDetail.status === 'Active' ? true : false,
                                }}
                                validationSchema={WorkspaceFormSchema}
                                enableReinitialize={true}
                                onSubmit={values => {
                                    handleSubmit(values);
                                }}
                            >
                                {({ errors, touched, setFieldValue, values }) => (
                                    <Form className="theme-form">
                                        <H2>{WorkspaceUpdateTitle}</H2>
                                        <P>{"Enter Workspace details to update"}</P>
                                        <Row>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Col><Label>{WorkspaceName}</Label></Col>
                                                    <Input type="text" tag={Field} placeholder={WorkspaceName} name="workSpaceName" value={values.workSpaceName} invalid={touched.workSpaceName && errors.workSpaceName ? true : false} />
                                                    {touched.workSpaceName && errors.workSpaceName && <FormFeedback>{errors.workSpaceName}</FormFeedback>}
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Col><Label>{FirstName}</Label></Col>
                                                    <Input type="text" placeholder={FirstName} name="firstName" tag={Field} value={values.firstName} invalid={touched.firstName && errors.firstName ? true : false} />
                                                    {touched.firstName && errors.firstName && <FormFeedback>{errors.firstName}</FormFeedback>}
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Col><Label>{LastName}</Label></Col>
                                                    <Input type="text" placeholder={LastName} name="lastName" tag={Field} value={values.lastName} invalid={touched.lastName && errors.lastName ? true : false} />
                                                    {touched.lastName && errors.lastName && <FormFeedback>{errors.lastName}</FormFeedback>}
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Col><Label>{PhoneNumber}</Label></Col>
                                                    <Input type="number" placeholder={PhoneNumber} name="phoneNumber" tag={Field} value={values.phoneNumber} invalid={touched.phoneNumber && errors.phoneNumber ? true : false} />
                                                    {touched.phoneNumber && errors.phoneNumber && <FormFeedback>{errors.phoneNumber}</FormFeedback>}
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Col><Label>{EmailUsername}</Label></Col>
                                                    <Input type="email" placeholder={EmailUsername} name="email" tag={Field} value={values.email} invalid={touched.email && errors.email ? true : false} />
                                                    {touched.email && errors.email && <FormFeedback>{errors.email}</FormFeedback>}
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Col><Label>{Password}</Label></Col>
                                                    <Input type="text" tag={Field} placeholder={Password} name="password" value={values.password} invalid={touched.password && errors.password ? true : false} />
                                                    {touched.password && errors.password && <FormFeedback>{errors.password}</FormFeedback>}
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Col><Label>{WorkspaceNoOfIds}</Label></Col>
                                                    <Input type="number" tag={Field} placeholder={WorkspaceNoOfIds} name="noOfIds" value={values.noOfIds} invalid={touched.noOfIds && errors.noOfIds ? true : false} />
                                                    {touched.noOfIds && errors.noOfIds && <FormFeedback>{errors.noOfIds}</FormFeedback>}
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Col><Label>{PerIdRate}</Label></Col>
                                                    <Input type="number" tag={Field} placeholder={PerIdRate} name="perIdRate" value={values.perIdRate} invalid={touched.perIdRate && errors.perIdRate ? true : false} />
                                                    {touched.perIdRate && errors.perIdRate && <FormFeedback>{errors.perIdRate}</FormFeedback>}
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup className='d-flex flex-column align-items-stretch'>
                                                    <Col><Label>{StartingDate}</Label></Col>
                                                    <Field name="startDate" render={(field: any) => <DatePicker className="form-control" selected={values.startDate} {...field} onChange={(date: Date) => setFieldValue('startDate', date)} />} />
                                                    {touched.startDate && errors.startDate && <FormFeedback style={{display: 'block'}}>{errors.startDate}</FormFeedback>}
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup className='d-flex flex-column align-items-stretch'>
                                                    <Col><Label>{EndingDate}</Label></Col>
                                                    <Field name="endDate" render={(field: any) => <DatePicker className="form-control" selected={values.endDate} {...field} onChange={(date: Date) => setFieldValue('endDate', date)} />} />
                                                    {touched.endDate && errors.endDate && <FormFeedback style={{display: 'block'}}>{errors.endDate}</FormFeedback>}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <div className="d-flex" key={'paymentStatus'}>
                                                        <Label className="col-form-label m-l-10 p-r-10">{PaymentStatus}</Label>
                                                        <div className={`text-end switch-md icon-state switch-outline`}>
                                                            <Label className="switch mb-0" htmlFor='paymentStatus'>
                                                                <Input type="checkbox" tag={Field} name='paymentStatus' id={`paymentStatus`} defaultChecked={false} disabled={false} />
                                                                <span className={`switch-state bg-primary`}></span>
                                                            </Label>
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <div className="d-flex" key={'status'}>
                                                        <Label className="col-form-label m-l-10 p-r-10">{Status}</Label>
                                                        <div className={`text-end switch-md icon-state switch-outline`}>
                                                            <Label className="switch mb-0" htmlFor='status'>
                                                                <Input type="checkbox" tag={Field} name='status' id={`status`} defaultChecked={false} disabled={false} />
                                                                <span className={`switch-state bg-primary`}></span>
                                                            </Label>
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <FormGroup className='p-t-20'>
                                            <div className="d-md-flex justify-content-md-start common-flex">
                                                <Btn type='button' color='secondary' onClick={() => navigate('/workspace')}>{Cancel}</Btn>
                                                <Btn type='submit' color='primary'>{Submit}</Btn>
                                            </div>
                                        </FormGroup>
                                    </Form>
                                )}
                            </Formik>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default WorkspaceEdit
