import { useState, useCallback } from 'react';
import { Row, Col, Card, CardBody, Container } from 'reactstrap';
import { StrCreatePackage } from '../../../Utils/Constants';
import CommonCardHeader from '../../../Utils/CommonComponents/CommonCardHeader';
import PackageFormNav from './PackageFormNav';
import PackageTabContents from './PackageTabContents';
import Loader from '../../../Layout/Loader';

const simpleTabSubTitle = [
    {
        text: 'Enter / Select',
        code: 'Package'
    },
    {
        text: ' details to  ',
        code: 'create '
    }
];

export default function PackageCreate(): JSX.Element {
    const [showLoader, setShowLoader] = useState(false);
    const [steps, setSteps] = useState(1);
    const activeCallBack = useCallback((tab: number) => {
        setSteps(tab);
    }, []);
    
    return (
        <Container fluid>
            <Loader show={showLoader} />
            <Row>
                <Col sm={12} xl={12}>
                    <Card className='title-line'>
                        <CommonCardHeader title={StrCreatePackage} subTitle={simpleTabSubTitle} />
                        <CardBody>
                            <Row className="g-xl-5 g-3">
                                <PackageFormNav steps={steps} setSteps={setSteps} />
                                <PackageTabContents steps={steps} activeCallBack={activeCallBack} showLoader={showLoader} setShowLoader={setShowLoader} />
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}
