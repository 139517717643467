import { SidebarItemType } from "../../Types/Layout.type";
import { Href } from "../../Utils/Constants";

//Notitications
export const notiticationsData = [
    {
        id: 1,
        image: '3.jpg',
        color: 'danger',
        icon: 'heart',
        name: 'Emay Walter',
        text: 'liked your post',
        time: '10 minutes ago'
    },
    {
        id: 2,
        image: '4.jpg',
        color: 'info',
        icon: 'share-alt',
        name: 'Allie Grater',
        text: 'shared your post',
        time: '5 hours ago'
    },
    {
        id: 3,
        image: '7.jpg',
        color: 'success',
        icon: 'wechat',
        name: 'Colin Sik',
        text: 'commented on your post',
        time: 'yesterday'
    },
]

//ChatHeader
export const chatHeaderData = [
    {
        id: 1,
        image: '1.jpg',
        text: 'Hello.how are you?'
    },
    {
        id: 2,
        image: '3.jpg',
        text: "Hi, i'm fine.what about you?"
    },
    {
        id: 3,
        image: '1.jpg',
        text: 'I am facing js issue can you help me?'
    },
    {
        id: 4,
        image: '3.jpg',
        text: 'Sure, i will help you.'
    },
];

//CartHeader
export const cartHeaderData = [
    {
        id: 1,
        image: 'img2.jpg',
        name: 'Beauty cosmetic',
        price: 20,
        quantity: 2
    },
    {
        id: 2,
        image: 'img-1.jpg',
        name: 'Simple shoes',
        price: 30,
        quantity: 1
    },
];

//Profile
export const profileHeaderData = [
    // {
    //     id: 1,
    //     link: `auth/sign_up`,
    //     icon: 'user',
    //     text: 'Account'
    // },
    // {
    //     id: 2,
    //     link: `applications/email/letter_box`,
    //     icon: 'stroke-email',
    //     text: 'Inbox'
    // },
    // {
    //     id: 3,
    //     link: `applications/task`,
    //     icon: 'notepad',
    //     text: 'Taskboard'
    // },
    {
        id: 4,
        link: `applications/users/edit_profile`,
        icon: 'settings',
        text: 'Settings'
    },
]

export const languagesData = [
    {
        data: "en",
        language: "English",
    },
    {
        data: "du",
        language: "Deutsch",
    },
    {
        data: "es",
        language: "Español",
    },
    {
        data: "fr",
        language: "Français",
    },
    {
        data: "pt",
        language: "Português",
    },
    {
        data: "cn",
        language: "简体中文",
    },
    {
        data: "ae",
        language: "لعربية",
    },
];

export const bookMarkData: SidebarItemType[] = [
    {
        icon: "form",
        url: Href,
        title: "Form",
    },
    {
        icon: "user",
        url: Href,
        title: "Profile",
    },
    {
        icon: "table",
        url: Href,
        title: "Tables",
    },
];