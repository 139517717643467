import React from 'react'
import { Card, CardBody, Col } from 'reactstrap'
import { useNavigate } from 'react-router-dom';
import { Btn, H2, H4, Image } from '../../../AbstractElements'
import { dynamicImage } from '../../../Utils'

export default function WelcomeCard() {
    const navigate = useNavigate();
    
    return (
        <Col xl={4} sm={12}>
            <Card className="welcome-card dashboard-card">
                <CardBody>
                    <H4 className="f-light f-w-500 mb-1">{'Tripper CRM'}</H4>
                    <H2 className="mb-3">{'Hi, Wecome Back'}</H2>
                    <Btn color='primary' type='button' onClick={() => navigate(`/workspace/create`)} className="btn-hover-effect">{'Create Workspace'}</Btn>
                </CardBody>
                <div className="welcome-image">
                    <Image className="img-fluid" src={dynamicImage(`dashboard-3/1.png`)} alt="upgrade" />
                </div>
            </Card>
        </Col>
    )
}
