import React, { useState, CSSProperties, useEffect } from 'react'
import { Row, Col, FormFeedback, FormGroup, Card, CardBody, Container, TabContent, TabPane } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2'
import { useCSVReader,
    lightenDarkenColor,
    formatFileSize } from 'react-papaparse'
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Btn, H4, H5 } from '../../../AbstractElements';
import { Cancel, StrNext, StrUploadLeads } from '../../../Utils/Constants';
import CommonCardHeader from '../../../Utils/CommonComponents/CommonCardHeader';
import { bulkLeadGenerationAction } from '../../../ReduxToolkit/Reducers/Layout/Lead/LeadAction';
import LeadFormNav from './UploadLeadFormNav';
import { MultiSelect } from "react-multi-select-component";
import { workspaceUsersAction } from '../../../ReduxToolkit/Reducers/Layout/Auth/LoginAction';
import { defaultLeadStatus, userRoles } from '../../../helpers';
import PercentageBox from './PercentageBox';

const csvSample = require.context(`/public/assets/csv`, true);

const simpleTabSubTitle = [
    {
        text: 'Upload',
        code: 'CSV'
    },
    {
        text: ' to upload ',
        code: 'bulk leads '
    }
];

const StyleContainer = styled(Container)`
    .user-section {
        padding: 20px;
    }
    .user-label {
        width: 40%;
    }
    .input-section {
        width: 10%;
    }
    .percentage {
        padding: 5px;
        width: 10%;
    }
    .lead-distributed {
        padding: 5px;
    }
    .multiselect-custom {
        width: 100%;
    }
`

const GREY = '#CCC';
const GREY_LIGHT = 'rgba(255, 255, 255, 0.4)';
const DEFAULT_REMOVE_HOVER_COLOR = '#A01919';
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
  DEFAULT_REMOVE_HOVER_COLOR,
  40
);
const GREY_DIM = '#686868';

const styles = {
  zone: {
    alignItems: 'center',
    border: `2px dashed ${GREY}`,
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    padding: 70,
  } as CSSProperties,
  file: {
    background: 'linear-gradient(to bottom, #EEE, #DDD)',
    borderRadius: 20,
    display: 'flex',
    height: 120,
    width: 120,
    position: 'relative',
    zIndex: 10,
    flexDirection: 'column',
    justifyContent: 'center',
  } as CSSProperties,
  info: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    paddingRight: 10,
  } as CSSProperties,
  size: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    marginBottom: '0.5em',
    justifyContent: 'center',
    display: 'flex',
  } as CSSProperties,
  name: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    fontSize: 12,
    marginBottom: '0.5em',
  } as CSSProperties,
  progressBar: {
    bottom: 14,
    position: 'absolute',
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
  } as CSSProperties,
  zoneHover: {
    borderColor: GREY_DIM,
  } as CSSProperties,
  default: {
    borderColor: GREY,
  } as CSSProperties,
  remove: {
    height: 23,
    position: 'absolute',
    right: 6,
    top: 6,
    width: 23,
  } as CSSProperties,
};

const StyleIndicatorSeparator = {
    alignSelf: 'stretch',
    backgroundColor: '#00B8D9',
    marginBottom: 8,
    marginTop: 8,
    width: 1,
};

export default function UploadLead() {
    const { CSVReader } = useCSVReader();
    const [zoneHover, setZoneHover] = useState(false);
    const [csvData, setCsvData] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [assigneeListData, setAssigneeListData] = useState<any>();
    const [leadDistributionDetails, setLeadDistributionDetails] = useState<any>();
    const [tempState, setTempState] = useState<number>();
    const [isDistributionCompleted, setIsDistributionCompleted] = useState<boolean>(false);
    const [removeHoverColor, setRemoveHoverColor] = useState(
        DEFAULT_REMOVE_HOVER_COLOR
    );
    const [steps, setSteps] = useState('lead-upload');
    const navigate = useNavigate();

    useEffect(() => {
        const response = workspaceUsersAction({});
        response.then((result: any) => {
            if (result.status === 200) {
                const assigneeList = result.data.length > 0 && result.data.map((user: any) => {
                    return { value: user.id, label: `${user.firstName} ${user.lastName} (${userRoles(user.roleId)})` }
                })
                setAssigneeListData(assigneeList);
            }
        });
    }, [])

    const handleOnClick = () => {
        const url = csvSample('./lead_sample.csv');
        const link = document.createElement('a');
        link.target = '_blank';
        link.href = url;
        link.download = 'lead_sample.csv';
        link.click();
    }

    useEffect(() => {
        if(selectedUsers && selectedUsers.length > 0) {
            const filterDistribution: any = {};
            selectedUsers.map(function(obj: any) {
                if(leadDistributionDetails[obj.value]) {
                    filterDistribution[obj.value] = leadDistributionDetails[obj.value]
                } else {
                    filterDistribution[obj.value] = {
                        'label': obj.label,
                        'percentage': 0,
                        'leadCount': 0
                    }
                }
            })
            setIsDistributionCompleted(false);
            setLeadDistributionDetails(filterDistribution);
        } else {
            setLeadDistributionDetails({});
        }
    }, [selectedUsers])

    const handlePercentageChange = (event: any, userId: number, label: string) => {
        let prevLeadDistribution = leadDistributionDetails;
        // setLeadDistributionDetails({});
        const timeout = setTimeout(() => {
            const percentVal = parseInt(event.target.value);
            prevLeadDistribution[userId] = {
                'label': label,
                'percentage': percentVal,
                'leadCount': 0
            }
            setTempState(Math.random());
        }, 10);
        setIsDistributionCompleted(false);
        setLeadDistributionDetails(prevLeadDistribution);
        return () => clearTimeout(timeout);
    }

    useEffect(() => {
        if(leadDistributionDetails) {
            setLeadDistributionDetails(leadDistributionDetails);
        }
    }, [tempState])

    const handleLeadAutoDistribution = () => {
        if(leadDistributionDetails && Object.keys(leadDistributionDetails).length > 0) {
            const totalUsers = selectedUsers.length;
            const distributionPercentage = Math.ceil(100/totalUsers);
            let leadsConsumed = 0;
            let distributionInfo:any = {};
            Object.keys(leadDistributionDetails).forEach((userLead: any) => {
                const userLeadInfo = leadDistributionDetails[userLead];
                const totalLead = csvData.length - 1;
                const leadDistributedLeft = totalLead - leadsConsumed;
                if(leadDistributedLeft > 0) {
                    const percent = (distributionPercentage/100);
                    const userTotalLead = Math.ceil(totalLead * percent);
                    const userassignedLead = userTotalLead < leadDistributedLeft ? userTotalLead : leadDistributedLeft;
                    leadsConsumed = leadsConsumed+userassignedLead;
                    distributionInfo[userLead] = {
                        'label': userLeadInfo['label'],
                        'percentage': distributionPercentage,
                        'leadCount': userassignedLead
                    }   
                } else {
                    distributionInfo[userLead] = {
                        'label': userLeadInfo['label'],
                        'percentage': distributionPercentage,
                        'leadCount': 0
                    }  
                }
            })
            setIsDistributionCompleted(true);
            setLeadDistributionDetails(distributionInfo);
        }
    }
    
    const handleLeadDistributionCalc = () => {
        if(leadDistributionDetails && Object.keys(leadDistributionDetails).length > 0) {
            let leadsConsumed = 0;
            let distributionInfo:any = {};
            Object.keys(leadDistributionDetails).forEach((userLead: any) => {
                const userLeadInfo = leadDistributionDetails[userLead];
                const percentVal = parseInt(userLeadInfo['percentage']);
                const totalLead = csvData.length - 1;
                const leadDistributedLeft = totalLead - leadsConsumed;
                if(leadDistributedLeft > 0) {
                    const percent = (percentVal/100);
                    const userTotalLead = Math.ceil(totalLead * percent);
                    const userassignedLead = userTotalLead < leadDistributedLeft ? userTotalLead : leadDistributedLeft;
                    leadsConsumed = leadsConsumed+userassignedLead;
                    distributionInfo[userLead] = {
                        'label': userLeadInfo['label'],
                        'percentage': percentVal,
                        'leadCount': userassignedLead
                    }   
                }
            })
            setLeadDistributionDetails(distributionInfo);
            setIsDistributionCompleted(true);
        } else {
            toast.info('Select user to distribute lead');
        }
    }
console.log(leadDistributionDetails, 'leadDistributedLeft-somb');
    // receives array of files that are done uploading when submit button is clicked
    const handleUploadSubmit = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want upload the csv with the selected distribution percentage!",
            icon: 'warning',
            showCancelButton: true
        }).then((result) => {
            if (result.isConfirmed) {
                let leadDistribution: any = {};
                if(leadDistributionDetails && Object.keys(leadDistributionDetails).length > 0) {
                    let totalLeadDistributedCnt = 0;
                    const totalLead = csvData.length - 1;
                    Object.keys(leadDistributionDetails).forEach((userLead: any) => {
                        const userLeadInfo = leadDistributionDetails[userLead];
                        if(userLeadInfo['leadCount'] > 0 && totalLeadDistributedCnt <= totalLead) {
                            for(let i = totalLeadDistributedCnt; i < totalLeadDistributedCnt+userLeadInfo['leadCount']; i++) {
                                leadDistribution[i] = userLead;
                            }
                            totalLeadDistributedCnt = totalLeadDistributedCnt+userLeadInfo['leadCount'];
                        }
                    })
                }
                let finalData: { fullName: never; email: never; phoneNumber: never; city: never; source: never; status: string; createdBy: any; assignedTo: any }[] = [];
                const userLocalStorage = JSON.parse(localStorage.getItem('jwtToken')!) || null;
                csvData.forEach((csv, index) => {
                    if(csv[2] !== 'full_name') {
                        finalData.push({
                            fullName: csv[2],
                            email: csv[3],
                            phoneNumber: csv[4],
                            city: csv[5],
                            source: csv[1] ?? 'Manual',
                            status: defaultLeadStatus,
                            createdBy: userLocalStorage.userId,
                            assignedTo: leadDistribution[index-1] ? parseInt(leadDistribution[index-1]) : null,
                        });
                    }
                })
                console.log(finalData, 'finalData');
                const response = bulkLeadGenerationAction({
                    csvData: finalData
                });
                response.then((result: any) => {
                    if(result.status === 200) {
                        toast.success(result.message);
                        navigate('/leads');
                    } else {
                        toast.error(result.message);
                    }
                });
            }
        })
    }

    return (
        <StyleContainer fluid>
            <Row>
                <Col sm={12} xl={12}>
                    <Card className='title-line'>
                        <CommonCardHeader title={StrUploadLeads} subTitle={simpleTabSubTitle} btnText='Download Sample CSV' faIcon={'download'} handleOnClick={() => handleOnClick()} />
                        <CardBody>
                            <Container fluid>
                                <Row>
                                    <Col xs={12}>
                                        <Card>
                                            <CardBody>
                                                <Row className="g-xl-5 g-3">
                                                    <LeadFormNav steps={steps} setSteps={setSteps} />
                                                    <Col xxl={9} xl={8} className="box-col-8 position-relative">
                                                        <TabContent activeTab={steps}>
                                                            <TabPane tabId={'lead-upload'}>
                                                            <Formik
                                                                initialValues={{
                                                                    csvFile: null
                                                                }}
                                                                validationSchema={Yup.object().shape({
                                                                    csvFile: Yup.mixed().required('File is required')
                                                                })}
                                                                onSubmit={values => {
                                                                    // handleUploadSubmit(values);
                                                                }}
                                                            >
                                                                {({ errors, touched, setFieldValue, values }) => (
                                                                    <Form className="theme-form upload-form">
                                                                        <Row className='p-t-0'>
                                                                            <Field name="csvFile" render={(field: any) => 
                                                                                <CSVReader
                                                                                    onUploadAccepted={(results: any) => {
                                                                                        const finalResult = results.data.filter((res: any) => {
                                                                                            return res[0] !== '';
                                                                                        })
                                                                                        setCsvData(finalResult);
                                                                                        setZoneHover(false);
                                                                                    }}
                                                                                    onDragOver={(event: DragEvent) => {
                                                                                        event.preventDefault();
                                                                                        setZoneHover(true);
                                                                                    }}
                                                                                    onDragLeave={(event: DragEvent) => {
                                                                                        event.preventDefault();
                                                                                        setZoneHover(false);
                                                                                    }}
                                                                                >
                                                                                {({
                                                                                  getRootProps,
                                                                                  acceptedFile,
                                                                                  ProgressBar,
                                                                                  getRemoveFileProps,
                                                                                  Remove,
                                                                                }: any) => (
                                                                                  <>
                                                                                    <div
                                                                                      {...getRootProps()}
                                                                                      style={Object.assign(
                                                                                        {},
                                                                                        styles.zone,
                                                                                        zoneHover && styles.zoneHover
                                                                                      )}
                                                                                    >
                                                                                      {acceptedFile ? (
                                                                                        <>
                                                                                          <div style={styles.file}>
                                                                                            <div style={styles.info}>
                                                                                              <span style={styles.size}>
                                                                                                {formatFileSize(acceptedFile.size)}
                                                                                              </span>
                                                                                              <span style={styles.name}>{acceptedFile.name}</span>
                                                                                            </div>
                                                                                            <div style={styles.progressBar}>
                                                                                              <ProgressBar />
                                                                                            </div>
                                                                                            <div
                                                                                              {...getRemoveFileProps()}
                                                                                              style={styles.remove}
                                                                                              onMouseOver={(event: Event) => {
                                                                                                event.preventDefault();
                                                                                                setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                                                                                              }}
                                                                                              onMouseOut={(event: Event) => {
                                                                                                event.preventDefault();
                                                                                                setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                                                                                              }}
                                                                                            >
                                                                                              <Remove color={removeHoverColor} />
                                                                                            </div>
                                                                                          </div>
                                                                                        </>
                                                                                      ) : (
                                                                                        'Drop CSV file here or click to upload'
                                                                                      )}
                                                                                    </div>
                                                                                  </>
                                                                                )}
                                                                              </CSVReader>
                                                                            }
                                                                            />
                                                                            {touched.csvFile && errors.csvFile && <FormFeedback>{errors.csvFile}</FormFeedback>}
                                                                        </Row>
                                                                        <FormGroup className='p-t-20'>
                                                                            <div className="d-md-flex justify-content-md-end common-flex">
                                                                                <Btn type='button' color='secondary' onClick={() => navigate('/leads')}>{Cancel}</Btn>
                                                                                <Btn type='submit' color='primary' disabled={csvData.length === 0} onClick={() => setSteps('lead-distribution')}>{StrNext}</Btn>
                                                                            </div>
                                                                        </FormGroup>
                                                                    </Form>
                                                                )}
                                                            </Formik>
                                                            </TabPane>
                                                            <TabPane tabId={'lead-distribution'}>
                                                                <H4 className='txt-primary p-b-10'>Total Leads to Assign : {csvData.length-1}</H4>
                                                                <H5 className='txt-secondary p-b-10'>Select users & set lead assignment in percentage</H5>
                                                                <div className='text-div'>
                                                                    <MultiSelect
                                                                        options={assigneeListData ?? []}
                                                                        value={selectedUsers}
                                                                        onChange={setSelectedUsers}
                                                                        labelledBy="Select"
                                                                        className='multiselect-custom'
                                                                    />
                                                                </div>
                                                                <div className='text-div user-section'>
                                                                    { leadDistributionDetails && Object.keys(leadDistributionDetails).length > 0 &&
                                                                        <>
                                                                        <div className="d-md-flex justify-content-md-end common-flex p-t-20">
                                                                            <Btn type='submit' outline={true} color='primary-2x' onClick={() => handleLeadAutoDistribution()}>{'Auto Distribute'}</Btn>
                                                                        </div>
                                                                        {Object.keys(leadDistributionDetails).map((user: any, index: number) => {
                                                                            return <div className='d-flex flex-row mb-2' key={index}>
                                                                                <PercentageBox leadDistributionDetails={leadDistributionDetails[user]} userId={user} handlePercentageChange={handlePercentageChange} />
                                                                            </div>
                                                                        })}
                                                                        </>
                                                                    }
                                                                </div>
                                                                <div className="d-md-flex justify-content-md-end common-flex p-t-20">
                                                                    <Btn type='submit' color='info' onClick={() => setSteps('lead-upload')}>{'Back'}</Btn>
                                                                    <Btn type='submit' color='primary' disabled={isDistributionCompleted} onClick={() => handleLeadDistributionCalc()}>{'Distribute'}</Btn>
                                                                    <Btn type='submit' color='primary' disabled={!isDistributionCompleted} onClick={() => handleUploadSubmit()}>{'Submit'}</Btn>
                                                                </div>
                                                            </TabPane>
                                                        </TabContent>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </StyleContainer>
    )
}
