import React, { useEffect, useState } from 'react'
import { Container, Row } from 'reactstrap'
import WelcomeCard from './WelcomeCard';
import Widget1Cards from '../../Utils/CommonComponents/DashboardCommon/ProjectCommon/Widget1Cards';
import { useReduxDispatch, useReduxSelector } from '../../ReduxToolkit/Store';
import { getWorkspaces, resetWorkspaceList } from '../../ReduxToolkit/Reducers/Layout/WorkspaceList/WorkspaceListAction';
import { getWorkspaceCount, resetWorkspaceCount } from '../../ReduxToolkit/Reducers/Layout/WorkspaceCount/WorkspaceCountAction';
import RecentInvities from './RecentInvities';
import OverviewCard from './OverviewCard';
import TeamOverview from './TeamOverview';
import SalesOverview from './SalesOverview';
import LeadsOverview from './LeadsOverview';
import RevenueSaleCard from './RevenueSaleCard';
import { generalSmallChartsData } from '../../Data/Table/TableHeader';
import EarningReportsCard from '../../Utils/CommonComponents/DashboardCommon/DefaultCommon/EarningReportsCard';
import ProjectsOverview from '../../Utils/CommonComponents/DashboardCommon/ProjectCommon/ProjectsOverview';
import Loader from '../../Layout/Loader';

const AdminDashboard = () => {
    const { data, isError, isSuccess, isLoading } = useReduxSelector(state => state.workspaceListReducer);
    const workspaceCountReducer = useReduxSelector(state => state.workspaceCountReducer);
    const dispatch = useReduxDispatch();
    const [listData, setListDate] = useState([]);
    const [workspacesCountObj, setWorkspacesCountObj] = useState({});
    const [modal, setModal] = useState(false);
    const [showLoader, setShowLoader] = useState(true);
    const toggle = () => { setModal(!modal); }
    
    useEffect(() => {
        dispatch(getWorkspaces({
            pageNo: 1,
            recordPerPage: 5,
            uuid: 1,
            orderBy: 'DESC'
        }));

        dispatch(getWorkspaceCount({
            filterBy: 'status'
        }));

        return () => {
            dispatch(resetWorkspaceList());
            dispatch(resetWorkspaceCount());
        };
    }, [])

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShowLoader(false);
        }, 2000);

        return () => {
            clearTimeout(timeout);
        };
    }, [showLoader]);

    useEffect(() => {
        if(isSuccess && data && data.data) {
            setListDate(data.data.rows);
        }
    }, [isSuccess])

    useEffect(() => {
        if(workspaceCountReducer.isSuccess) {
            setWorkspacesCountObj(workspaceCountReducer.data.data);
        }
    }, [workspaceCountReducer.isSuccess])

    return <Container fluid>
        <Loader show={showLoader} />
        <Row>
            <WelcomeCard />
            <OverviewCard />
            <Widget1Cards />
            <RevenueSaleCard data={generalSmallChartsData} />
            <EarningReportsCard colClass='order-md-iii' />
            <ProjectsOverview colClass='order-md-vii' height2={180} height={290} />
            {/* <SalesOverview /> */}
            <LeadsOverview />
            <TeamOverview />
            {/* <RecentInvities listData={[]} /> */}
        </Row>
        </Container>
}

export default AdminDashboard;
