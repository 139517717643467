import moment from 'moment'

export const formatDate = (date: any, _format: string='') => {
    if(_format) {
        return moment(date).format(_format)
    } else {
        return moment(date).format("DD/MM/YYYY")
    }
}

export const defaultLeadStatus = 'Unassigned';

export const leadStatus = () => {
    return [
        'Unassigned',
        'New',
        'FollowUp',
        'Potential',
        'Positive',
        'Converted',
        'Closed'
    ]
}


export const leadFilterOprions = () => {
    return [
        'Unassigned',
        'New',
        'FollowUp',
        'Potential',
        'Positive',
        'Converted',
        'Closed'
    ]
}

export const userStatusColor = (status: string) => {
    const statusColor: any = {
        'Active': 'success',
        'Inactive': 'danger'
    }
    return statusColor[status];
}

export const statusColor = (status: string) => {
    const statusColor: any = {
        'Unassigned': 'info',
        'New' : 'secondary',
        'FollowUp' : 'success',
        'Potential': 'danger',
        'Positive': 'dark',
        'Converted': 'primary',
        'Closed': 'success'
    }
    return statusColor[status];
}

export const historyStatusColor = (status: string) => {
    const statusColor: any = {
        'CREATED': 'primary',
        'ASSIGNMENT': 'secondary',
        'COMMENT': 'info',
        'STATUS_CHANGED': 'danger'
    }
    return statusColor[status];
}

export const userRoles = (currRole: number) => {
    const roles: any = {
        1: 'Super Admin',
        2: 'Admin',
        3: 'Manager',
        4: 'User'
    }
    return roles[currRole];
}

export const hotelFilterOptions = () => {
    return [
        
    ]
}

export const bookingSourceTypes = () => {
    return [
        { value: 'direct', label: 'Direct' },
        { value: 'reference', label: 'Reference' }
    ]
}

export const genderTypes = () => {
    return [
        { value: 'male', label: 'Male' },
        { value: 'female', label: 'Female' },
        { value: 'intersex', label: 'Intersex' },
    ]
}

export const vehicleAcTypes = () => {
    return [
        { value: 'ac', label: 'With AC' },
        { value: 'nonac', label: 'Without AC' }
    ]
}

export const allowExtraBedTypes = () => {
    return [
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' }
    ]
}