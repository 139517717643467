import React, { useEffect, useState } from 'react'
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, ButtonGroup, Col, Input, Label, Row } from 'reactstrap';
import { ChevronDown, ChevronUp } from 'react-feather';
import { IndicatorSeparatorProps } from 'react-select';
import { Btn } from '../../../AbstractElements';
import { leadFilterOprions } from '../../../helpers';
import { workspaceUsersAction } from '../../../ReduxToolkit/Reducers/Layout/Auth/LoginAction';
import AsyncSelect from 'react-select/async';

const StyleIndicatorSeparator = {
    alignSelf: 'stretch',
    backgroundColor: '#00B8D9',
    marginBottom: 8,
    marginTop: 8,
    width: 1,
};

const IndicatorSeparator = ({
    innerProps,
}: IndicatorSeparatorProps<any, true>) => {
    return <span style={StyleIndicatorSeparator} {...innerProps} />;
};

const LeadFilter = (props: any) => {
    const { selectedFilters, handleOnClick, handleOnAssigneeChange } = props;
    const [ assigneeListData, setAssigneeListData ] = useState<any>();
    const [ selectedAssignee, setSelectedAssignee ] = useState<any>();

    useEffect(() => {
        const response = workspaceUsersAction({});
        response.then((result: any) => {
            if (result.status === 200) {
                const assigneeList = result.data.length > 0 && result.data.map((user: any) => {
                    return { value: user.id, label: `${user.firstName} ${user.lastName}` }
                })
                setAssigneeListData(assigneeList);
            }
        });
    }, [])

    const [open, setOpen] = useState('1');

    const toggle = (id: string) => {
        open === id ? setOpen('') : setOpen(id);
    };

    const filterAssignee = (inputValue: string) => {
        return assigneeListData.length > 0 && assigneeListData.filter((i: any) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const loadAssigneeOptions = (
        inputValue: string,
        callback: (options: any) => void
    ) => {
        setTimeout(() => {
            callback(filterAssignee(inputValue));
        }, 1000);
    };

    const handleAssigneeSelector = (event: any) => {
        setSelectedAssignee(event);
        handleOnAssigneeChange(event);
    }

    return (<>
    <Accordion open={open} toggle={toggle} className='dark-accordion pb-2'>
        <AccordionItem>
            <AccordionHeader className='bg-light-primary txt-primary' targetId="1">
                {'Click to apply filters'} {open === '1' ? <ChevronUp className='svg-color'/> : <ChevronDown className='svg-color'/>}
            </AccordionHeader>
            <AccordionBody color='primary' accordionId="1">
                <>
                <Row>
                    <Col xl={12}>
                        <ButtonGroup className="btn-option">
                            {leadFilterOprions().map((item) => {
                                return (
                                    <Btn color='light-info' type='button' key={item}>
                                        <div className="checkbox checkbox-dark">
                                            <Input id={`checkbox-primary-${item}`} type="checkbox" checked={selectedFilters?.includes(item)} />
                                            <Label className="mb-0" htmlFor={`checkbox-primary-${item}`} value={item} onClick={() => handleOnClick(item)}>{item}</Label>
                                        </div>
                                    </Btn>
                                )
                            })}
                        </ButtonGroup>
                        <div className="mt-2" style={{width: '300px'}}>
                            <AsyncSelect 
                                value={selectedAssignee}
                                cacheOptions
                                loadOptions={loadAssigneeOptions}
                                defaultOptions={assigneeListData}
                                components={{ IndicatorSeparator }}
                                onChange={(e) => handleAssigneeSelector(e)}
                                isMulti={true}
                                className='assignee-select-box'
                            />
                        </div>
                    </Col>
                </Row>
                </>
            </AccordionBody>
        </AccordionItem>
    </Accordion>
    </>)
}

export default LeadFilter;