import React, { useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap';
import { Rating } from 'react-simple-star-rating';
import { H6 } from '../../AbstractElements';

export default function StarRating(props: any) {
    const { label, initialRating, readonly, allowFraction=false } = props;
    const [rating, setRating] = useState(initialRating ?? 1);

    useEffect(() => {
        setRating(initialRating);
    }, [initialRating])

    const handleRatingChange = (newRating: number) => {
        setRating(newRating);
    };

    return (
        <Row className='g-2'>
            <Col md={4}>
                <H6 className="product-title">{label ?? 'Rate Now'}</H6>
            </Col>
            <Col md={8}>
                <div className='d-flex'>
                    <Rating
                        initialValue={rating}
                        allowFraction={allowFraction}
                        readonly={readonly ?? false}
                        size={20}
                        transition
                        fillColor="gold"
                        emptyColor="gray"
                        onClick={handleRatingChange}
                    />
                </div>
            </Col>
        </Row>
    )
}
