import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from "reactstrap"
import HotelsListNav from "./HotelsListNav"
import styled from '@emotion/styled';
import { isBrowser, isMobile } from 'react-device-detect';
import DropdownWithHeader from "../../../Utils/CommonComponents/DropdownWithHeader";
import { StrHotelList } from "../../../Utils/Constants";
import { Badges, H6, LI, UL, Image, H4, H2, Alerts, P } from "../../../AbstractElements";
import { toast } from 'react-toastify';
import { hotelFilterOptions, leadFilterOprions, leadStatus, statusColor } from '../../../helpers';
import HotelInfo from './HotelInfo';
import { getHotelsAction } from '../../../ReduxToolkit/Reducers/Layout/Hotel/HotelAction';
import { Info } from 'react-feather';

const StyleListContainer = styled(Container)`
    height: 100%;
    /* .list-section {
        height: 100vh;
        padding-bottom: 10px;
    }
    .info-section {
        padding-bottom: 10px;
    } */

    .delivery-content {
        min-height: 400px;
        height: 500px;
        overflow-y: scroll; 
    }
    .order-list {
        height: 100%
    }
    .delivery-card {
        .card-header {
            .card-header-right-icon {
                display: block;
            }
        }
    }
    .lead-filter-dropdown .dropdown-menu {
        z-index: 99;
    }
    .leads-item {
        cursor: pointer;
        padding: 10px;
        border-radius: 10px;
        &:hover{
            background: #D0E8EB;
        }
    }
    .active {
        color: #fff;
    }
`
const StyleListContent = styled.div`
    .avatar {
        text-align: center;
        padding-bottom: 15px;
    }
`
const HotelsList = () => {
    const [listData, setListData] = useState<any>();
    const [selectedItem, setSelectedItem] = useState<any>();
    const [filterAssignee, setFilterAssignee] = useState<string[]>([]);
    const [activeItemId, setActiveItemId] = useState<number>();
    const [activeFilter, setActiveFilter] = useState('All');
    const [selectedFilters, setSelectedFilters] = useState(leadFilterOprions());

    useEffect(() => {
        const payload = {}
        const response = getHotelsAction(payload);
        response.then((result: any) => {
            if(result.status === 200) {
                setListData(result.data);
                if(result.data.rows.length > 0) {
                    setActiveItemId(result.data.rows[0].id);
                } else {
                    setActiveItemId(0);
                    setSelectedItem({});
                }
            } else {
                toast.error(result.message);
            }
        });
    }, [])

    return (
        <StyleListContainer fluid>
            <Row className='project-cards'>
                { isBrowser &&
                    <Col md={12} className="project-list">
                        <HotelsListNav activeTab={activeFilter} setActiveTab={setActiveFilter} />
                    </Col>
                }
                <Col xl={5} sm={12} className="list-section">
                {/* <div className={'col-xl-5 col-md-12 list-section'}> */}
                    <Card className={`title-line height-equal delivery-card`}>
                        <DropdownWithHeader end caret dropDownClass='lead-filter-dropdown' 
                            headerClass='pb-2 border-t-primary border-2 leads-filter' 
                            heading={StrHotelList} span={`Total ${listData?.count ?? 0}`} 
                            spanClass='f-12 f-w-500 f-light d-block'
                            handleOnClick={(item: any) => {}} 
                            dropDownTitle={'Filter'} dropDownList={hotelFilterOptions()} 
                            selectedFilters={selectedFilters}
                            dropDownIcon={true}
                            advanceFilterBtn={false}
                        />
                        <CardBody className="delivery-content pt-3">
                            <UL className="order-list mb-0 custom-scrollbar simple-list">
                                {listData && listData.rows.length > 0 ?
                                <>
                                {listData.rows.map((item: any) => (
                                    <LI key={item.id} className={`leads-item list-group-item-action list-group-item ${item.id === activeItemId ? 'active bg-primary' : ''}`}>
                                        <StyleListContent className={`order-content`} onClick={() => setActiveItemId(item.id)}>
                                            <div>
                                                <H4 className="mb-1">{item.name}</H4>
                                                <span> 
                                                    <span className={`${item.id === activeItemId ? 'f-dark' : 'f-light'} f-w-500 f-14`}>{item.groupName}</span>
                                                    <span className={`${item.id === activeItemId ? 'f-dark' : 'f-light'} f-w-500 f-14`}>{item.email}</span>
                                                    <span className={`${item.id === activeItemId ? 'f-dark' : 'f-light'} f-w-500 f-14`}>{item.locationInfo.name}</span>
                                                </span>
                                            </div>
                                            <div className="text-end">
                                                {/* <Dropdown isOpen={open} toggle={() => {}} className="icon-dropdown">
                                                    <DropdownToggle color='transparent'><SvgIcon iconId='more-horizontal' /></DropdownToggle>
                                                    <DropdownMenu end={true}>
                                                        <DropdownItem href={`${process.env.PUBLIC_URL}/applications/ecommerce/cart`}>{'Add to cart'}</DropdownItem>
                                                        <DropdownItem href={Href}>{'Cancel'}</DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown> */}
                                                {/* <div className="avatar">
                                                    <Image className={`img-40 rounded-circle`} src={dynamicImage(`user.png`)} alt="user profile" />
                                                </div> */}
                                                <Badges color={`${statusColor(item.status)}`} className={`${statusColor(item.status)} f-12`}>{item.status}</Badges>
                                            </div>
                                        </StyleListContent>
                                    </LI>
                                ))}
                                </>:
                                    <Alerts color='light-danger' className={`light alert-dismissible fade text-dark border-left-wrapper show`}>
                                        <Info />
                                        <P>{'No data Found.'}</P>
                                    </Alerts>
                                }
                            </UL>
                        </CardBody>
                    </Card>
                </Col>
                {/* <div className={'col-xl-7 col-md-12 info-section'}> */}
                <Col xl={7} sm={12} className="info-section">
                    <HotelInfo activeItemId={activeItemId} selectedItem={selectedItem} />
                </Col>
                {/* </div> */}
            </Row>
        </StyleListContainer>
    )
}

export default HotelsList
