import { setLoading, setError, setSuccess, setReset } from './WorkspaceCountSlice';
import { AnyAction, ThunkAction } from '@reduxjs/toolkit';
import { RootState } from '../../../Store';
import { getWorkspaceCountService } from '../../../../Services/Service';

export const getWorkspaceCount = (payload: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(setReset());
        dispatch(setLoading());
        try {
            const response: any = await getWorkspaceCountService(payload);
            (response && response.data && response.data.status && response.data.status === 200) ? dispatch(setSuccess(response.data)) : dispatch(setError(response.data));
        } catch (e: any) {
            dispatch(setError(e.response?.data));
        }
    };
};

export const resetWorkspaceCount = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(setReset());
    };
};