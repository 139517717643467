import { Modal, ModalBody, ModalHeader, Row, Col, FormGroup, FormFeedback, Input, Label } from "reactstrap";
import { Formik, Form, Field } from 'formik';
import { useState } from "react";
import * as Yup from 'yup';
import { ConnectedFocusError } from 'focus-formik-error'
import { Btn } from "../../../AbstractElements";
import { Cancel, StrMessage, StrReminderTime, Submit } from "../../../Utils/Constants";
import { setLeadReminderAction } from "../../../ReduxToolkit/Reducers/Layout/Lead/LeadAction";
import { toast } from "react-toastify";
import moment from "moment";

const formSchema = Yup.object().shape({
    message: Yup.string().required('Message is required'),
    reminderDatetime: Yup.string().required('Reminder date time is required')
})

enum CalendarItemType {
    DateTimeLocal = 'datetime-local',
    Date = 'date',
    Month = 'month',
    Week = 'week',
    Time = 'time',
}

const ReminderForm = (props: any) => {
    const { leadId } = props;
    const [stateModal, setStateModal] = useState({ modalOpen: false, tooltipOpen: false });
    const [showLoader, setShowLoader] = useState(false);

    const handleModalToggle = () => {
        setStateModal(prevState => ({
            ...prevState,
            modalOpen: !prevState.modalOpen
        }));
    };

    const modalData = {
        isOpen: stateModal.modalOpen,
        header: true,
        footer: true,
        toggler: handleModalToggle,
        center: true,
        size: 'md',
        title: 'Set Timer',
        class: '',
        bodyClass: ''
    };

    const handleSubmit = (request: any) => {
        const response = setLeadReminderAction({
            leadId: leadId,
            message: request.message,
            reminderDatetime: request.reminderDatetime,
        });
        response.then((result: any) => {
            if (result.status === 200) {
                toast.success(result.message);
                handleModalToggle();
            } else {
                toast.error(result.message);
            }
        })
    }

    return (<>
        <Btn color="secondary" onClick={handleModalToggle}>
            Set Reminder
        </Btn>
        <Modal className={modalData.class} isOpen={modalData.isOpen} toggle={modalData.toggler} centered={modalData.center} size={modalData.size}>
            {modalData.header && <ModalHeader toggle={modalData.toggler}>
                {modalData.title}
            </ModalHeader>}
            <ModalBody className={modalData.bodyClass}>
                <Formik
                    enableReinitialize={false}
                    initialValues={{
                        message: '',
                        reminderDatetime: moment(new Date()).format("YYYY-MM-DD 12:57:00")
                    }}
                    validationSchema={formSchema}
                    onSubmit={values => {
                        handleSubmit(values);
                    }}
                >
                    {({ errors, touched, setFieldValue, values, setTouched }) => (
                        <Form>
                            <ConnectedFocusError />
                            <Row>
                                <Col sm={12}>
                                    <FormGroup>
                                        <Label className="required-field"><strong>{StrMessage}</strong></Label>
                                        <Input type="textarea" tag={Field} placeholder={'Type your message here...'} name="message" value={values.message} invalid={touched.message && errors.message ? true : false} />
                                        {touched.message && errors.message && <FormFeedback>{errors.message}</FormFeedback>}
                                    </FormGroup>
                                </Col>
                                <Col sm={12}>
                                    <FormGroup>
                                        <Label className="required-field"><strong>{StrReminderTime}</strong></Label>
                                        <Input tag={Field} min={moment(new Date()).format("YYYY-MM-DD 12:01:00")} className="digits" name="reminderDatetime" type={CalendarItemType.DateTimeLocal} defaultValue={''} />
                                        {touched.reminderDatetime && errors.reminderDatetime && <FormFeedback>{errors.reminderDatetime}</FormFeedback>}
                                    </FormGroup>
                                </Col>
                                <FormGroup className='p-t-20'>
                                    <div className="d-md-flex justify-content-md-start common-flex">
                                        <Btn type='button' color='secondary' onClick={modalData.toggler}>{Cancel}</Btn>
                                        <Btn type='submit' color='primary' disabled={showLoader}>{Submit}</Btn>
                                    </div>
                                </FormGroup>
                            </Row>
                        </Form>
                    )}
            </Formik>
            </ModalBody>
        </Modal>
    </>)
}

export default ReminderForm;