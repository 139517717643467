import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';
import { Col } from 'reactstrap'

export default function PaginationCard(props: any) {
    const { totalPages, currentPage, setCurrentPage } = props;

    return (
        <Col sm={12}>
            <div className="job-pagination">
                <ResponsivePagination
                    current={currentPage}
                    total={totalPages}
                    onPageChange={setCurrentPage}
                    maxWidth={300}
                />
            </div>
        </Col>
    )
}
