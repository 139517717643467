import React from 'react'
import { Card, CardBody, Col } from 'reactstrap'
import parse from 'html-react-parser';
import { H6, LI, P, UL } from '../../../../../AbstractElements'

export default function ItineraryInfo(props: any) {
    const { detail } = props;

    return (
        <Col xl={12} className=' box-col-6 notification main-timeline'>
            <Card className='height-equal title-line'>
                <CardBody className='dark-timeline'>
                    <UL className='simple-list timeline-lists'>
                        {detail && detail.itineraryDetails.length > 0 &&  detail.itineraryDetails.map((item: any, index: number) => (
                            <LI className="d-flex" key={index}>
                                <div className={`timeline-dot-primary`} />
                                <div className="w-100 ms-3">
                                    <P className="d-flex justify-content-between mb-2">
                                        <span className="date-content light-background">{item.title}</span>
                                        {/* <span>dfssdf</span> */}
                                    </P>
                                    <H6 className='mb-0'>{item && item.detail && parse(item.detail)}<span className="dot-notification" /></H6>
                                    { item.hotelId &&
                                        <span className="f-light">
                                            <span className="date-content light-background">
                                                <strong>Hotel</strong> : {item.hotelInfo.name}
                                                { item.roomTypeId &&
                                                    <><strong> , Room Type</strong> : {item.roomTypeInfo.name}</>
                                                }
                                                { item.mealPlan &&
                                                    <><strong> , Meal Plan</strong> : {item.mealPlanInfo.name}</>
                                                }
                                                { item.noOfBeds &&
                                                    <><strong> , Beds</strong> : {item.noOfBeds}</>
                                                }
                                                { item.allowExtraBeds &&
                                                    <><strong> , Allow Extra Beds</strong> : {item.allowExtraBeds}</>
                                                }
                                                { item.hotelCharges &&
                                                    <><strong> , Hotel Charges</strong> : {item.hotelCharges}</>
                                                }
                                            </span>
                                        </span>
                                    }
                                    {  item.transportType &&
                                        <span className="f-light pt-2">
                                            <span className="date-content light-background">
                                                <strong>Transport Type</strong> : {item.transportInfo.name}
                                                <strong> , seats</strong> : {item.transportInfo.seats}
                                                <strong> , AC Type</strong> : {`${item.acAvailable === 'ac' ? 'With AC' : 'Without AC'}`}
                                                <strong> , Transport Charges</strong> : {item.transportCharges}
                                            </span>
                                        </span>
                                    }
                                </div>
                            </LI>
                        ))}
                    </UL>
                </CardBody>
            </Card>
        </Col>
    )
}
