import axios from 'axios'
// import { API_BASE_URL } from '../Config/ServerApiConfig';

const getToken = () => {
  const token = JSON.parse(localStorage.getItem('jwtToken')!) || null;
  return token ? token.uuid : ''
}

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {},
})
axiosInstance.interceptors.request.use(
  (config: any) => {
    if (!config) {
      config = {}
    }
    if (!config.headers) {
      config.headers = {}
    }
    config.headers['Access-Control-Allow-Origin'] = '*';
    if(!config.headers['Content-Type']) {
      config.headers['Content-Type'] = 'application/json';
    }
    config.headers['Authorization'] = `${'Bearer' + ' ' + getToken()}`
    config.headers['jwt_secret'] = `${getToken()}`
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error)
  }
)

export default axiosInstance
