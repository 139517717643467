import { setLoading, setError, setSuccess, setReset, setSignOut } from './LoginSlice';
import { AnyAction, ThunkAction } from '@reduxjs/toolkit';
import { RootState } from '../../../Store';
import { assignManagerService, forgotPasswordService, getInvitedUsersService, getUserDetailService, getUsersService, resetPasswordService, sendUserInvitation, userLoginService, userSignupService, userStatusUpdateService, validateUserInvitation, workspaceUsersService } from '../../../../Services/Service';

export const userLogin = (payload: any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(setReset());
        dispatch(setLoading());
        try {
            const response: any = await userLoginService(payload);
            (response && response.data && response.data.status && response.data.status === 200) ? dispatch(setSuccess(response.data)) : dispatch(setError(response.data));
        } catch (e: any) {
            dispatch(setError({
                message: e.response.data.message,
                statusCode: e.response.data.status,
                data: e.response.data
            }));
        }
    };
};

export const userLogout = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        try {
            dispatch(setSignOut());
            dispatch(setReset());
        } catch (e: any) {
            dispatch(setError({
                message: e.message,
                statusCode: 500
            }));
        }
    };
};

export const resetLogin = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(setReset());
    };
};

export const sendUserInvitaionAction = async (payload: any) => {
    return await sendUserInvitation(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const validateUserInvitaionAction = async (payload: any) => {
    return await validateUserInvitation(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const userSignupAction = async (payload: any) => {
    return await userSignupService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const forgotPasswordAction = async (payload: any) => {
    return await forgotPasswordService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const resetPasswordAction = async (payload: any) => {
    return await resetPasswordService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const workspaceUsersAction = async (payload: any) => {
    return await workspaceUsersService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const getInvitedUsersAction = async (payload: any) => {
    return await getInvitedUsersService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const getUsersAction = async (payload: any) => {
    return await getUsersService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const getUserDetailAction = async (payload: any) => {
    return await getUserDetailService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const assignManagerAction = async (payload: any) => {
    return await assignManagerService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const userStatusUpdateAction = async (payload: any) => {
    return await userStatusUpdateService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};