import React from 'react'
import { Card, CardBody, CardHeader, Col } from 'reactstrap'
import { TrendingDown, TrendingUp } from 'react-feather'
import { ActiveWorkspace, InActiveWorkspace } from '../../../Constants'
import { H2, H3, H6, LI, ProgressBar, UL } from '../../../../AbstractElements'
import CommonDropdown from '../../CommonDropdown'
import SvgIcon from '../../CommonIcons/CommonSvgIcons'
import { progressDetailsData } from '../../../../Data/Dashboard/Project'
import { WorkspaceStatusWidgetProp } from '../../../../Types/Dashboard.type'
import { dailyDropdownList } from '../../../../Data/Dashboard/Default'

export default function WorkspaceStatusWidget(props: any) {
    const { colClass, workspacesCountObj } = props;
    return (
        <Col xl={4} sm={12} className={colClass ? colClass : ''}>
            <Card className="project-widget widget-1 title-line dashboard-card">
                <CardHeader className="card-no-border pb-0">
                    <div className="header-top">
                        <div>
                            <span className="f-12 f-light f-w-500">{'Total Workspaces by status'}</span>
                            <H3 className="f-w-600">{ActiveWorkspace}</H3>
                        </div>
                        <div className="card-header-right-icon">
                            <CommonDropdown dropDownClass='icon-dropdown d-xxl-none1' dropDownIcon end dropDownList={dailyDropdownList} />
                        </div>
                    </div>
                    <div className="widget-middle-content">
                        <div className="d-flex align-items-center">
                            <H2>{workspacesCountObj.activeWorkspaces}</H2>
                            {/* <span className="f-w-500 txt-warning f-12"><TrendingUp className='me-1' /><span>{'+50%'}</span></span> */}
                        </div>
                    </div>
                </CardHeader>
                <CardBody className="widget-bottom-content">
                    <div className="header-top">
                        <div>
                            <H3 className="f-w-600">{InActiveWorkspace}</H3>
                            {/* <span className="f-12 f-light f-w-500">{'Wokspace this month'}</span> */}
                        </div>
                        {/* <div className="card-header-right-icon">
                            <CommonDropdown dropDownClass='icon-dropdown d-xxl-none1' dropDownIcon end dropDownList={dailyDropdownList} />
                        </div> */}
                    </div>
                    <div className="widget-middle-content">
                        <div className="d-flex align-items-center">
                            <H2>{workspacesCountObj.inactiveWorkspaces}</H2>
                            {/* <span className="f-w-500 txt-warning f-12"><TrendingDown className='me-1' /><span>{'-50%'}</span></span> */}
                        </div>
                    </div>
                </CardBody>
            </Card>
        </Col>
    )
}
