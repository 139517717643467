import BookPackage from "../../Components/AdminDashboard/Packages/BookPackage/BookPackage"

const BookPackagePage = () => {
    return (
        <div className='page-body main-project'>
            <BookPackage />
        </div>
    )
}

export default BookPackagePage