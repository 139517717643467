import { Fragment } from "react/jsx-runtime";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import { Col, Row, FormGroup, Label, Input } from "reactstrap";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Btn } from "../../../AbstractElements";
import { useEffect, useState } from "react";
import { ActiveCallbackProp } from "./PackageTabContents";
import { Cancel, Previous, SubmitAndNext } from "../../../Utils/Constants";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { updateInclusionExclusionAction } from "../../../ReduxToolkit/Reducers/Layout/Package/PackageAction";

const PackageInclusionExclusionForm = ({ activeCallBack, toggle, detail, showLoader, setShowLoader }: ActiveCallbackProp) => {
    const [searchParams] = useSearchParams();
    const packageId = searchParams.get('id');
    const navigate = useNavigate();
    const [inclusionsData, setInclusionsData] = useState<any>([])
    const [exclusionsData, setExclusionsData] = useState<any>([])

    if (!packageId) {
        activeCallBack(1);
    }

    useEffect(() => {
        if (detail) {
            const groupByCategory = detail.additionalDetails.reduce((group: any, addDetail: any) => {
                const { infoType } = addDetail;
                group[infoType] = group[infoType] ?? [];
                group[infoType].push(addDetail);
                return group;
            }, {});
            if(groupByCategory && groupByCategory.INCLUSIONS && groupByCategory.INCLUSIONS.length > 0) {
                setInclusionsData(groupByCategory.INCLUSIONS);
            }
            if(groupByCategory && groupByCategory.EXCLUSIONS && groupByCategory.EXCLUSIONS.length > 0) {
                setExclusionsData(groupByCategory.EXCLUSIONS);
            }
        }
    }, [detail])

    const handleInclusionsExclustionsData = (value: any, index: number, type: string) => {
        if (type === 'inclusions') {
            const inclusionsDataTmp = [...inclusionsData];
            inclusionsDataTmp[index] = {
                title: value
            }
            setInclusionsData(inclusionsDataTmp);
        }
        if (type === 'exclusions') {
            const exclusionsDataTmp = [...exclusionsData];
            exclusionsDataTmp[index] = {
                title: value
            }
            setExclusionsData(exclusionsDataTmp);
        }
    }

    const handleAddMoreInclusionsClick = () => {
        const inclusionsDataTmp = [...inclusionsData];
        setInclusionsData([...inclusionsDataTmp, {
            title: null
        }]);
    }

    const handleRemoveInclusionsClick = (index: number) => {
        const inclusionsDataTmp = [...inclusionsData];
        inclusionsDataTmp.splice(index, 1);
        setInclusionsData(inclusionsDataTmp);
    }

    const handleAddMoreExclusionsClick = () => {
        const exclusionsDataTmp = [...exclusionsData];
        setExclusionsData([...exclusionsDataTmp, {
            title: null
        }]);
    }

    const handleRemoveExclusionsClick = (index: number) => {
        const exclusionsDataTmp = [...exclusionsData];
        exclusionsDataTmp.splice(index, 1);
        setExclusionsData(exclusionsDataTmp);
    }

    const handleSubmit = () => {
        setShowLoader(true);
        const response = updateInclusionExclusionAction({
            packageId: packageId,
            inclusionsData: inclusionsData,
            exclusionsData: exclusionsData
        });
        response.then((result: any) => {
            setShowLoader(false);
            if (result.status === 200) {
                toast.success(result.message);
                activeCallBack(5);
            } else {
                toast.error(result.message);
            }
        });
    }

    return (
        <Fragment>
            <Formik
                enableReinitialize={detail && Object.keys(detail).length > 0}
                initialValues={{

                }}
                validationSchema={Yup.object().shape({})}
                onSubmit={values => {
                    handleSubmit();
                }}
            >
                {({ errors, touched, setFieldValue, values, setTouched }) => (
                    <Form>
                        <CommonCardHeader title={'Manage Inclusions'} subTitle={[]} />
                        <Row className='pt-2'>
                            {inclusionsData && inclusionsData.length > 0 && inclusionsData.map((item: any, index: number) => {
                                return (
                                    <Col sm={12} className='pb-2'>
                                        <div className="card-wrapper border rounded-3 checkbox-checked mb-2">
                                            <Row>
                                                <Col sm={12}>
                                                    <FormGroup>
                                                        <Label className='required-field'><strong>{'Title'}</strong></Label>
                                                        <Input type="text" tag={Field} placeholder={'eg. Daily Breakfast at hotel.'} name="title" value={item.title} onChange={(e: any) => handleInclusionsExclustionsData(e.target.value, index, 'inclusions')} />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={1}>
                                                    <Btn type='button' className={'b-ln-height b-r-8'}
                                                        outline={false}
                                                        size={'sm'}
                                                        color={'danger'}
                                                        onClick={() => { handleRemoveInclusionsClick(index) }}
                                                    >
                                                        {'Remove'}
                                                    </Btn>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                )
                            })}
                            <Col xs={12}>
                                <div className={`d-grid gap-2 buttons-box col-12 mx-auto`}>
                                    <Btn type='button' onClick={() => handleAddMoreInclusionsClick()} color={'light-success'}>{'Click To Add Inclusions'}</Btn>
                                </div>
                            </Col>
                        </Row>
                        <CommonCardHeader title={'Manage Exclusion'} subTitle={[]} />
                        <Row className='pt-2'>
                            {exclusionsData && exclusionsData.length > 0 && exclusionsData.map((item: any, index: number) => {
                                return (
                                    <Col sm={12} className='pb-2'>
                                        <div className="card-wrapper border rounded-3 checkbox-checked mb-2">
                                            <Row>
                                                <Col sm={12}>
                                                    <FormGroup>
                                                        <Label className='required-field'><strong>{'Title'}</strong></Label>
                                                        <Input type="text" tag={Field} placeholder={'eg. Airafare.'} name="title" value={item.title} onChange={(e: any) => handleInclusionsExclustionsData(e.target.value, index, 'exclusions')} />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={1}>
                                                    <Btn type='button' className={'b-ln-height b-r-8'}
                                                        outline={false}
                                                        size={'sm'}
                                                        color={'danger'}
                                                        onClick={() => { handleRemoveExclusionsClick(index) }}
                                                    >
                                                        {'Remove'}
                                                    </Btn>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                )
                            })}
                            <Col xs={12}>
                                <div className={`d-grid gap-2 buttons-box col-12 mx-auto`}>
                                    <Btn type='button' onClick={() => handleAddMoreExclusionsClick()} color={'light-secondary'}>{'Click To Add Exclusions'}</Btn>
                                </div>
                            </Col>
                        </Row>
                        <FormGroup className='p-t-20'>
                            <div className="d-md-flex justify-content-md-start common-flex">
                                {toggle ?
                                    <Btn type='button' color='secondary' onClick={toggle}>{Cancel}</Btn>
                                    :
                                    <Btn type='button' color='secondary' onClick={() => navigate('/packages')}>{Cancel}</Btn>
                                }
                                <Btn type='button' color='danger' onClick={() => activeCallBack(3)}>{Previous}</Btn>
                                <Btn type='submit' color='primary' disabled={showLoader}>{SubmitAndNext}</Btn>
                            </div>
                        </FormGroup>
                    </Form>
                )}
            </Formik>
        </Fragment>
    )
}

export default PackageInclusionExclusionForm;