import { Col, Nav, NavItem, NavLink } from 'reactstrap'
import { Href } from '../../../Utils/Constants'
import SvgIcon from '../../../Utils/CommonComponents/CommonIcons/CommonSvgIcons'
import { H6, P } from '../../../AbstractElements'

interface FormNavProps {
    steps: number;
    setSteps: (step: number) => void;
}

const addFormNav = [
    {
        id: 1,
        icon: 'product-detail',
        title: 'Package Details',
        subTitle: 'Add/Update package name & details'
    },
    {
        id: 2,
        icon: 'product-gallery',
        title: 'Package Gallery',
        subTitle: 'Thumbnail & add package gallery'
    },
    {
        id: 3,
        icon: 'product-category',
        title: 'Package Itinerary',
        subTitle: 'Update Package Itinerary'
    },
    {
        id: 4,
        icon: 'advance',
        title: 'Package Inclusions/Exclusions',
        subTitle: 'Update Package Inclusions/Exclusions'
    },
    {
        id: 5,
        icon: 'advance',
        title: 'Package Term & Condition',
        subTitle: 'Update Package Term & Condition'
    }
];

export default function PackageFormNav({ steps, setSteps }: FormNavProps) {
    return (
        <Col xxl={3} xl={4} className="box-col-4e sidebar-left-wrapper">
            <Nav className="sidebar-left-icons" pills>
                {addFormNav.map((data) => (
                    <NavItem key={data.id}>
                        <NavLink className={`${steps === data.id ? "active" : ''}`} href={Href} onClick={() => setSteps(data.id)}>
                            <div className="nav-rounded">
                                <div className="product-icons">
                                    <SvgIcon className="stroke-icon" iconId={data.icon} />
                                </div>
                            </div>
                            <div className="product-tab-content">
                                <H6>{data.title}</H6>
                                <P>{data.subTitle}</P>
                            </div>
                        </NavLink>
                    </NavItem>
                ))}
            </Nav>
        </Col>
    )
}

