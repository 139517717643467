import React from 'react'
import { Col, Row } from 'reactstrap'
import CountUp from 'react-countup';
import { H3, H4, Image, P } from '../../../../../AbstractElements'
import { dynamicImage } from '../../../../../Utils'
import { formatDate } from '../../../../../helpers';

export default function InvoiceSixHeader(props: any) {
    const { detail } = props;

    return (
        <div>
            <Row>
                <Col sm={6}>
                    <div className="d-flex">
                        <div className="media-left">
                            <Image className='media-object img-60 for-light' src={dynamicImage(`logo/logo_dark.jpeg`)} alt="logo" />
                            <Image className='media-object img-60 for-dark' src={dynamicImage(`logo/logo_dark.jpeg`)} alt="logo" />
                        </div>
                        <div className="flex-grow-1 m-l-20 text-right">
                            <H4 className="media-heading">{'Tripper CRM'} </H4>
                            <P>{'crmtripper@gmail.com'}<br /><span>{'289-335-6503'}</span></P>
                        </div>
                    </div>
                </Col>
                <Col sm={6}>
                    <div className="text-md-end text-xs-center">
                        <H3>
                            {'Booking Id'}<CountUp end={detail.id} prefix='#' className='counter' />
                        </H3>
                        <P>{'Booked On: '}<span> {formatDate(detail.createdAt, 'MMM DD, YYYY')}</span><br /> {'Travel Date:'} <span>{formatDate(detail.travelStartdate, 'MMM DD, YYYY')}</span></P>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

