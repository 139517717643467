import React, { useEffect, useState } from 'react'
import { Col, TabContent, TabPane } from 'reactstrap'
import { useSearchParams } from 'react-router-dom';
import { BookPackageDetailType } from "../../../../Types/InterfaceData.type";
import { toast } from 'react-toastify';
import UserDetailForm from './UserDetailForm';
import { getLeadBookingByLeadAction } from '../../../../ReduxToolkit/Reducers/Layout/Lead/LeadAction';
import HotelDetailForm from './HotelDetailForm';
import { getLocationsAction } from '../../../../ReduxToolkit/Reducers/Layout/Location/LocationAction';
import BookingOverview from './BookingOverview';
import TravelDetailForm from './TravelDetailForm';

export interface PackageTabContentProp {
    activeCallBack: (tab: number) => void;
    steps: number,
    showLoader: boolean,
    setShowLoader: (val: boolean) => void,
}

export default function BookPackageTabContents({ steps, activeCallBack, showLoader, setShowLoader }: PackageTabContentProp) {
    const [searchParams] = useSearchParams();
    const leadId = searchParams.get('leadId');
    const [detail, setDetail] = useState<BookPackageDetailType>()
    const [locationArr, setLocationArr] = useState<any>([]);

    useEffect(() => {
        handleFetchLeadBooking();
        const respLocations = getLocationsAction({});
        respLocations.then((result: any) => {
            if (result.status === 200) {
                const locationAr = result.data.rows.map((Ob: any) => {
                    return { value: Ob.id, label: Ob.name }
                })
                setLocationArr(locationAr);
            }
        })
    }, [])

    const handleFetchLeadBooking = () => {
        const response = getLeadBookingByLeadAction({leadId: leadId});
        response.then((result: any) => {
            if (result.status === 200) {
                setDetail(result.data);
            }
        })
    }

    return (
        <Col xxl={9} xl={8} className="box-col-8 position-relative">
            <TabContent activeTab={steps}>
                <TabPane tabId={1}>
                    { leadId &&
                        <UserDetailForm locationsList={locationArr} detail={detail} leadId={leadId} handleCallback={handleFetchLeadBooking} activeCallBack={activeCallBack} showLoader={showLoader} setShowLoader={setShowLoader} />
                    }
                </TabPane>
                <TabPane tabId={2}>
                    { leadId &&
                        <HotelDetailForm locationsList={locationArr} detail={detail} leadId={leadId} handleCallback={handleFetchLeadBooking} activeCallBack={activeCallBack} showLoader={showLoader} setShowLoader={setShowLoader} />
                    }
                </TabPane>
                <TabPane tabId={3}>
                    { leadId &&
                        <TravelDetailForm locationsList={locationArr} handleCallback={handleFetchLeadBooking} detail={detail} leadId={leadId} activeCallBack={activeCallBack} showLoader={showLoader} setShowLoader={setShowLoader} />
                    }
                </TabPane>
            </TabContent>
        </Col>
    )
}

