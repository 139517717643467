import { useEffect } from "react";
import { Formik, Form, Field } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import { ConnectedFocusError } from 'focus-formik-error'
import makeAnimated from 'react-select/animated';
import * as Yup from 'yup';
import Select, { IndicatorSeparatorProps } from 'react-select';
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { BookPackageDetailType } from "../../../../Types/InterfaceData.type";
import { Btn } from "../../../../AbstractElements";
import { Cancel, StrPrintInvoince, Submit } from "../../../../Utils/Constants";
import { manageBookingPriceDetailAction } from "../../../../ReduxToolkit/Reducers/Layout/Lead/LeadAction";
import { toast } from "react-toastify";

interface ActiveCallbackProp {
    toggle?: () => void,
    activeCallBack: (tab: number) => void,
    handleCallback: () => void,
    detail?: BookPackageDetailType,
    locationsList: any,
    showLoader: boolean,
    setShowLoader: (val: boolean) => void,
    leadId: string,
}

const StyleIndicatorSeparator = {
    alignSelf: 'stretch',
    backgroundColor: '#00B8D9',
    marginBottom: 8,
    marginTop: 8,
    width: 1,
};

const IndicatorSeparator = ({
    innerProps,
}: IndicatorSeparatorProps<any, true>) => {
    return <span style={StyleIndicatorSeparator} {...innerProps} />;
};

const formSchema = Yup.object().shape({
    totalCharges: Yup.string().required('Total charges is required'),
})
const animatedComponents = makeAnimated();

const TravelDetailForm = ({ toggle, activeCallBack, showLoader, setShowLoader, leadId, detail, locationsList, handleCallback }: ActiveCallbackProp) => {
    const navigate = useNavigate();
    
    if (!detail) {
        activeCallBack(1);
    }

    useEffect(() => {
        
    }, [detail])
    
    const handleSubmit = (request: any) => {
        setShowLoader(true);
        const payload = {
            ...request, ...{
                bookingId: detail?.id
            }
        };
        const response = manageBookingPriceDetailAction(payload);
        response.then((result: any) => {
            setShowLoader(false);
            if (result.status === 200) {
                toast.success(result.message);
                handleCallback();
            } else {
                toast.error(result.message);
            }
        });
    }

    const getSelectedLocation = (location: string) => {
        if (!location) return { value: '', label: 'All' };

        const selected = locationsList && locationsList.length > 0 && locationsList.find((ar: any) => ar.value === parseInt(location))
        return { value: selected.value, label: selected.label }
    }

    return (
        <>
            <Formik
                enableReinitialize={detail && Object.keys(detail).length > 0}
                initialValues={{
                    hotelCharges: detail && detail.hotelCharges,
                    travelCharges: detail && detail.travelCharges,
                    totalCharges: detail && detail.totalCharges,
                }}
                validationSchema={formSchema}
                onSubmit={values => {
                    handleSubmit(values);
                }}
            >
                {({ errors, touched, setFieldValue, values, setTouched }) => (
                    <Form>
                        <ConnectedFocusError />
                        <Row>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label className="required-field"><strong>{'Hotel Charges'}</strong></Label>
                                    <Input type="number" min={0} name="hotelCharges" placeholder={'0'} tag={Field} value={values.hotelCharges} readOnly />
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label className="required-field"><strong>{'Transport Charges'}</strong></Label>
                                    <Input type="number" min={0} name="travelCharges" placeholder={'0'} tag={Field} value={values.travelCharges} readOnly />
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label className="required-field"><strong>{'Total Charges'}</strong></Label>
                                    <Input type="number" min={0} name="totalCharges" placeholder={'0'} tag={Field} value={values.totalCharges} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <hr />
                        <FormGroup className='p-t-20'>
                            <div className="d-md-flex justify-content-md-start common-flex">
                                {toggle ?
                                    <Btn type='button' color='secondary' onClick={toggle}>{Cancel}</Btn>
                                    :
                                    <Btn type='button' color='secondary' onClick={() => navigate('/packages')}>{Cancel}</Btn>
                                }
                                <Btn type='submit' color='primary' disabled={showLoader}>{Submit}</Btn>
                                <Link to={`/leads/invoice?bookingId=${detail?.id}`} target="_blank">
                                <Btn type='button' color='danger' disabled={showLoader}>{StrPrintInvoince}</Btn>
                                </Link>
                            </div>
                        </FormGroup>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default TravelDetailForm;