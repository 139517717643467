import React, { useState } from 'react'
import { Card, CardBody, CardHeader, Col, Collapse } from 'reactstrap';
import { Badges, Btn, H3, H5, LI, UL } from '../../../AbstractElements';
import { CategoriesTitle, Href, StrPackageInclude } from '../../../Utils/Constants';
import { ChevronDown, ChevronUp } from 'react-feather';

export default function PackageIncludeCard(props: any) {
    const { data } = props;
    const [isDesign, setIsDesign] = useState(true);
    const toggle = () => { setIsDesign(!isDesign); }
    
    return (
        <Col xl={12}>
            <Card>
                <CardHeader>
                    <H5 className='mb-0'>
                        <Btn color='transparent' className='btn-link d-flex align-items-center gap-2 justify-content-between' onClick={toggle}>{StrPackageInclude}
                            {isDesign ? <ChevronDown /> : <ChevronUp />}
                        </Btn>
                    </H5>
                </CardHeader>
                <Collapse isOpen={isDesign}>
                    <CardBody>
                        <div className='d-flex flex-wrap'>
                        { data && data.length > 0 && data.map((item: any, index: number) => {
                            return (
                                <H3 className="p-2" key={index}><Badges color={'success'}>{item?.label}</Badges></H3>
                            )
                        })}
                        </div>
                    </CardBody>
                </Collapse>
            </Card>
        </Col>
    )
}
