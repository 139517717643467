import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from "reactstrap"
import styled from '@emotion/styled';
import DropdownWithHeader from "../../../Utils/CommonComponents/DropdownWithHeader";
import { StrUsersList } from "../../../Utils/Constants";
import { Badges, H6, LI, UL, Image, H4, H2, Alerts, P } from "../../../AbstractElements";
import { dynamicImage } from "../../../Utils";
import { toast } from 'react-toastify';
import { leadStatus, userStatusColor } from '../../../helpers';
import CommonDropdown from '../../../Utils/CommonComponents/CommonDropdown';
import CommonCardHeader from '../../../Utils/CommonComponents/CommonCardHeader';
import UserInfo from './UserInfo';
import { getUserDetailAction, getUsersAction } from '../../../ReduxToolkit/Reducers/Layout/Auth/LoginAction';

const StyleListContainer = styled(Container)`
    height: 100%;

    .delivery-content {
        min-height: 400px;
        height: 500px;
        overflow-y: scroll; 
    }
    .order-list {
        height: 100%
    }
    .delivery-card {
        .card-header {
            .card-header-right-icon {
                display: block;
            }
        }
    }
    .lead-filter-dropdown .dropdown-menu {
        z-index: 99;
    }
    .list-item {
        cursor: pointer;
        padding: 10px;
        border-radius: 10px;
        &:hover{
            background: #D0E8EB;
        }
    }
    .active {
        color: #fff;
    }
`
const StyleListContent = styled.div`
    .avatar {
        text-align: center;
        padding-bottom: 15px;
    }
`
interface InvitiesProps {
    id: number,
    userType: string,
    email: string,
    status: string,
    createdAt: string
}

interface ListDataProps {
    count: number,
    rows: InvitiesProps[]
}

const UserList = () => {
    const [listData, setListData] = useState<ListDataProps>();
    const [userDetail, setUserDetail] = useState<any>();
    const [activeId, setActiveId] = useState<number>();
    const [activeFilter, setActiveFilter] = useState('All');

    useEffect(() => {
        const response = getUsersAction({ status: activeFilter });
        response.then((result: any) => {
            if (result.status === 200) {
                setActiveId(result.data.rows[0].id)
                setListData(result.data);
            } else {
                toast.error(result.message);
            }
        });
    }, [activeFilter])

    useEffect(() => {
        fetchUserInfo();
    }, [activeId])

    const fetchUserInfo = () => {
        if (!activeId) {
            return;
        }
        const response = getUserDetailAction({
            id: activeId
        });
        response.then((result: any) => {
            if (result.status === 200) {
                setUserDetail(result.data);
            } else {
                toast.error(result.message);
            }
        });
    }

    const handleFilterSelection = (value: string) => {
        setActiveFilter(value);
    }

    return (
        <StyleListContainer fluid>
            <Row className='project-cards'>
                <Col xl={5} sm={12} className="list-section">
                    <Card className={`title-line height-equal delivery-card`}>
                        <DropdownWithHeader end caret dropDownClass='lead-filter-dropdown' headerClass='pb-2 border-t-primary border-2 leads-filter' heading={StrUsersList} span={`Total ${listData?.count ?? 0}`} spanClass='f-12 f-w-500 f-light d-block'
                            handleOnClick={() => handleFilterSelection} dropDownTitle={activeFilter} dropDownList={['All', ...leadStatus()]} />
                        <CardBody className="delivery-content pt-3">
                            <UL className="order-list mb-0 custom-scrollbar simple-list">
                                {listData && listData.rows.length > 0 ?
                                    <>
                                        {listData.rows.map((item: any) => (
                                            <LI key={item.id} className={`list-item list-group-item-action list-group-item ${item.id === activeId ? 'active bg-primary' : ''}`}>
                                                <StyleListContent className={`order-content`} onClick={() => setActiveId(item.id)}>
                                                    <div>
                                                        <H4 className="mb-1">{`${item.firstName} ${item.lastName}`}</H4>
                                                        <span>
                                                            <span className={`${item.id === activeId ? 'f-dark' : 'f-light'} f-w-500 f-14`}>{item.phoneNumber}</span>
                                                            <span className={`${item.id === activeId ? 'f-dark' : 'f-light'} f-w-500 f-14`}>{item.email}</span>
                                                            <span className={`${item.id === activeId ? 'f-dark' : 'f-light'} f-w-500 f-14`}>{item.city}</span>
                                                        </span>
                                                    </div>
                                                    <div className="text-end">
                                                        {/* <Dropdown isOpen={open} toggle={toggle} className="icon-dropdown">
                                                    <DropdownToggle color='transparent'><SvgIcon iconId='more-horizontal' /></DropdownToggle>
                                                    <DropdownMenu end={true}>
                                                        <DropdownItem href={`${process.env.PUBLIC_URL}/applications/ecommerce/cart`}>{'Add to cart'}</DropdownItem>
                                                        <DropdownItem href={Href}>{'Cancel'}</DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown> */}
                                                        <div className="avatar">
                                                            <Image className={`img-40 rounded-circle`} src={dynamicImage(`user.png`)} alt="user profile" />
                                                        </div>
                                                        <Badges color={`${userStatusColor(item.status)}`} className={`${userStatusColor(item.status)} f-12`}>{item.status}</Badges>
                                                    </div>
                                                </StyleListContent>
                                            </LI>
                                        ))}
                                    </> :
                                    <Alerts color={'secondary'} className='dark'>
                                        <P>
                                            No User found
                                        </P>
                                    </Alerts>
                                }
                            </UL>
                        </CardBody>
                    </Card>
                </Col>
                <Col xl={7} sm={12} className="info-section">
                    <UserInfo userDetail={userDetail} fetchUserInfo={fetchUserInfo} />
                </Col>
            </Row>
        </StyleListContainer>
    )
}

export default UserList
