import React, { useState } from 'react'
import { CardHeader, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input } from 'reactstrap'
import { Btn, H2 } from '../../AbstractElements'
import SvgIcon from './CommonIcons/CommonSvgIcons';
import { CommonHeaderWithDropdownProps } from '../../Types/CommonComponent.type';

export default function DropdownWithHeader({ heading, start, end, mainDivClass,
    headerIconClass, dropDownList, headerClass, span, span2,
    spanClass, span2Class, headingClass, dropDownTitle, caret, dropDownClass,
    dropDownIcon, handleOnClick, selectedFilters, advanceFilterBtn, toggleDrawer }: CommonHeaderWithDropdownProps) {
    const [open, setOpen] = useState(false);
    const toggle = () => {
        setOpen(!open);
    };
    
    return (
        <CardHeader className={headerClass ? headerClass : 'border-t-danger border-3'}>
            <div className={`header-top ${mainDivClass ? mainDivClass : ''}`}>
                <H2 className={headingClass ? headingClass : ''}>{heading}
                    {span && (
                        <span className={spanClass}>
                            {span} {span2 && (<span className={span2Class} dangerouslySetInnerHTML={{ __html: span2 }} />)}
                        </span>
                    )}
                </H2>
                <div className={`d-flex card-header-right-icon ${headerIconClass ? headerIconClass : ''}`}>
                    <Dropdown autoFocus={true} isOpen={open} toggle={toggle} className={dropDownClass ? dropDownClass : ''} onClick={(event) => {}}>
                        <DropdownToggle color='transparent' caret={caret ? true : false}>
                            {dropDownIcon ? <i className="fa fa-filter fa-lg"></i> : dropDownTitle}
                        </DropdownToggle>
                        <DropdownMenu start={start ? 'true' : 'false'} end={end ? true : false}>
                            {dropDownList.map((item, index) => (
                                <DropdownItem toggle={false} key={index} onClick={() => handleOnClick(item)} className={`f-w-600 ${dropDownTitle === item ? 'font-danger' : 'font-primary'}`}>
                                    <Input className="checkbox-primary m-r-10 p-2" checked={selectedFilters?.includes(item)} type="checkbox" value={item} />{item}
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </Dropdown>
                    { advanceFilterBtn &&
                        <Btn type='button' className='m-l-5' color='primary' onClick={toggleDrawer}>{<i className="fa fa-search fa-lg"></i>}</Btn>
                    }
                </div>
            </div>
        </CardHeader>
    )
}
