import React, { useEffect, useState } from 'react'

export default function Loader(props: any) {
    const { show } = props;

    return (
        <>
            {show && (
                <div className="loader-wrapper">
                    <div className="loader"></div>
                </div>
            )}
        </>)
}
