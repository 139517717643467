import { assignLeadService, bulkLeadGenerationCsvService, bulkLeadGenerationService, generateLeadService, getLeadBookingService, getLeadInfoService, getLeadsService, leadCommentUpdateService, leadStatusUpdateService, createLeadBookingService, setLeadReminderService, manageBookingHotelDetailService, manageBookingPriceDetailService, getLeadBookingByLeadService } from '../../../../Services/Service';

export const getLeadsAction = async (payload: any) => {
    return await getLeadsService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const getLeadInfoAction = async (payload: any) => {
    return await getLeadInfoService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const generateLeadAction = async (payload: any) => {
    return await generateLeadService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const assignLeadAction = async (payload: any) => {
    return await assignLeadService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const leadStatusUpdateAction = async (payload: any) => {
    return await leadStatusUpdateService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const leadCommentUpdateAction = async (payload: any) => {
    return await leadCommentUpdateService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const bulkLeadGenerationAction = async (payload: any) => {
    return await bulkLeadGenerationService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const bulkLeadGenerationCsvAction = async (payload: any) => {
    return await bulkLeadGenerationCsvService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const setLeadReminderAction = async (payload: any) => {
    return await setLeadReminderService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const createLeadBookingAction = async (payload: any) => {
    return await createLeadBookingService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const manageBookingHotelDetailAction = async (payload: any) => {
    return await manageBookingHotelDetailService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const manageBookingPriceDetailAction = async (payload: any) => {
    return await manageBookingPriceDetailService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const getLeadBookingAction = async (payload: any) => {
    return await getLeadBookingService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};

export const getLeadBookingByLeadAction = async (payload: any) => {
    return await getLeadBookingByLeadService(payload).then((result) => {
        return result.data;
    }).catch((error) => {
        return error.response.data;
    });
};